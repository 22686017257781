import React from 'react';
import { Modal } from './index';
import { ModalProps, T } from '../../model';
import { isFunction, noop } from '../../utils/common';
import { initFalsy } from '../../constants';

export interface GenModelProps extends ModalProps {
  actions?: string | T;
  content?: string | T;
  contentScrolling?: boolean;
  hasClose?: boolean;
  header?: string | T;
  initialize: boolean;
  onAction?: Function;
  onCancel: Function;
}

const GenericModal = (props: GenModelProps) => {
  const {
    actions,
    initialize,
    content,
    contentScrolling = !initFalsy,
    hasClose = !initFalsy,
    header,
    onAction,
    onCancel,
    size,
    ...custom
  } = props;
  const modalProps = { onActionClick: isFunction(onAction) ? () => onAction && onAction() : noop };
  return (
    <Modal
      {...modalProps}
      open={initialize}
      closeIcon={hasClose}
      onClose={() => onCancel()}
      size={size || 'mini'}
      {...custom}
    >
      {header && <Modal.Header content={header} />}

      {content && (
        <Modal.Content scrolling={contentScrolling}>
          <Modal.Description content={content} />
        </Modal.Content>
      )}

      {actions && <Modal.Actions content={actions} />}
    </Modal>
  );
};

export default GenericModal;

import React from 'react';
import { Button, Confirm } from '../Inputs';
import { CONFIRM_MESSAGES, initFalsy } from '../../constants';

type Props = { className: string; onFinalCounts: Function };

const FC: React.FC<Props> = ({ className, onFinalCounts }: Props) => {
  const [fCounting, setFCounting]: [boolean, Function] = React.useState(initFalsy),
    [initFC, setFC]: [boolean, Function] = React.useState(initFalsy),
    confirmButton = (
      <Button content='Yes, Perform Final Count' loading={fCounting} primary={!initFalsy} type='button' />
    ),
    onFC = async () => {
      setFCounting(!initFalsy);
      await onFinalCounts();
      setFC(initFalsy);
      setFCounting(initFalsy);
    },
    fcButtonProps = {
      className: `${className || ''}`,
      content: 'Final Counts',
      onClick: () => setFC(!initFalsy),
      primary: !initFalsy,
    },
    fcConfirmProps = {
      content: CONFIRM_MESSAGES.SUB_FINAL_COUNT,
      header: 'Final Count - Be careful, This is irreversible!!!',
      confirmButton: confirmButton,
      open: initFC,
      onCancel: () => setFC(initFalsy),
      onConfirm: () => onFC(),
    };

  return (
    <>
      <Button {...fcButtonProps} />

      {initFC && <Confirm {...fcConfirmProps} />}
    </>
  );
};

export default FC;

import React from 'react';
import { useHistory } from 'react-router';
import { Button, Card, FormField, FormFieldType, Grid } from '../../Inputs';
import { ButtonProps, Project } from '../../../model';
import { initFalsy } from '../../../constants';

type NotesProps = {
  buttons: ButtonProps[];
  projectInfo: Project;
  isView?: boolean;
};
const Notes: React.FC<NotesProps> = (p: NotesProps) => {
  const notesTextAreaProps = { name: 'notes', label: 'Notes', placeholder: 'Project Notes Here...' },
    hist = useHistory();
  return (
    <Grid.Row>
      <Grid.Column mobile={16} tablet={16} computer={8}>
        <Card fluid={!initFalsy} className='mb20'>
          <Card.Header>
            <h2 className='subTitle'>Project Scope Details</h2>
            {p.isView && !p.projectInfo.deletedAt && (
              <Button
                className='asAnchor float-right'
                content='Edit'
                icon='edit'
                onClick={() => hist.push(`/admin/projects/${p.projectInfo.id}`)}
              />
            )}
          </Card.Header>
          <Card.Content>
            <FormField {...notesTextAreaProps} type={FormFieldType.TextArea} disabled={p.isView} />
            <Grid.Column mobile={16} tablet={16} computer={16} className='mt15' textAlign='right'>
              {p.buttons.map((buttonProps: ButtonProps, key: number) => (
                <Button key={key} {...buttonProps} />
              ))}
            </Grid.Column>
          </Card.Content>
        </Card>
      </Grid.Column>
    </Grid.Row>
  );
};

export default Notes;

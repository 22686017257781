import { Action, ActionType, User } from '../../model';
import createReducer from './createReducer';

const initialAuth: Partial<User> = {};
export const auth = createReducer(initialAuth, {
  [ActionType.LOGIN_SUCCESS](_store: User, action: Action<Partial<User>>) {
    return action.payload;
  },
  [ActionType.LOAD_SESSION](_store: Function, action: Action<Partial<User>>) {
    return action.payload;
  },
  [ActionType.LOGOUT](_store: User, action: Action<void>) {
    return action.payload;
  },
});

import React from 'react';
import { Card, Form, Grid, TextArea, TextBox } from '../Inputs';
import { RC, T } from '../../model';
import { initFalsy } from '../../constants';

interface CallDetailListProps {
  listItems: T[];
  listMainTitle?: string;
  listSubTitle?: RC | string;
}

interface TAProps {
  custom: T;
  disabled: boolean;
  label: string;
  value: string;
}

const TA = ({ custom, disabled, label, value }: TAProps) => {
  const styles = {
    color: '#262626',
    background: '#FFFFFF',
    border: 0,
    fontWeight: 'bolder !important',
    opacity: '0.9',
  };
  Object.assign(styles, { width: custom.width });
  return (
    <Form.Field>
      <label>{label}</label>
      <TextArea className='bolder' disabled={disabled} rows={2} style={styles} value={value} {...custom} />
    </Form.Field>
  );
};

const CallDetail: React.FC<CallDetailListProps> = (props) => {
  const { listItems, listMainTitle, listSubTitle } = props;
  return (
    <Card className='mb20 disabledList' fluid={initFalsy} id={'cd'}>
      {listMainTitle && (
        <Card.Header>
          <h2 className='subTitle'>{listMainTitle}</h2>
        </Card.Header>
      )}
      <Card.Content>
        <Grid>
          <Grid.Row>
            {listSubTitle && (
              <Grid.Column computer={16} tablet={16} mobile={16}>
                <h3 className='w100 subHeaderInner'>{listSubTitle}</h3>
              </Grid.Column>
            )}
            {listItems.map((f, k) => {
              const elemId = 'cd';
              const { as, label, value, ...fProps } = f;
              const isAnchor = as && as === 'a';
              const isBig = !!(value && value.length >= 35);
              const width = (window as T).document.getElementById(elemId)?.clientWidth / 2;
              Object.assign(fProps, { width });
              return (
                <Grid.Column key={k} computer={16} tablet={16} mobile={16}>
                  {isAnchor && (
                    <div className='ui field'>
                      <label>{label}</label>
                      <span>{value}</span>
                    </div>
                  )}
                  {!isBig && <TextBox disabled={!initFalsy} label={label} value={value} {...fProps} />}
                  {isBig && <TA custom={fProps} disabled={!initFalsy} label={label} value={value} />}
                </Grid.Column>
              );
            })}
          </Grid.Row>
        </Grid>
      </Card.Content>
    </Card>
  );
};

export default CallDetail;

import * as React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { PrivateRouteProps } from '../model';
import { setPageTitle } from '../utils/common';
import { initFalsy } from '../constants';

const PrivateRoute: React.FC<PrivateRouteProps> = (props: PrivateRouteProps) => {
  let redirectPath: string = '';
  const { allowAllRoles, authRole, authenticationPath, isAuthenticated, routeRole } = props;

  if (!isAuthenticated) {
    redirectPath = authenticationPath;
  }

  // check if route is restricted by role

  if (!allowAllRoles && authRole !== routeRole) {
    setPageTitle(authenticationPath);
    redirectPath = '/';
  }

  if (redirectPath) {
    const renderComponent = () => <Redirect to={{ pathname: redirectPath }} />;
    return <Route exact={!initFalsy} {...props} component={renderComponent} render={undefined} />;
  }

  return <Route exact={!initFalsy} {...props} />;
};

export default PrivateRoute;

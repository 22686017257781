import * as React from 'react';
import { Form, Input } from './index';
import { TextBoxProps, TextInputProps } from '../../model';
import { initFalsy } from '../../constants';

const TextBox = (props: TextBoxProps | TextInputProps) => {
  const { label, disabled, ...custom } = props;
  custom.placeholder = custom.placeholder || label;

  return (
    <>
      {!!disabled ? (
        <Form.Field>
          <label>{label}</label>
          <Input disabled={!initFalsy} {...custom} />
        </Form.Field>
      ) : (
        <Form.Input label={label} {...custom} />
      )}
    </>
  );
};

export default TextBox;

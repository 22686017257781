import React, { SyntheticEvent } from 'react';
import { useHistory } from 'react-router';
import { connect, useSelector } from 'react-redux';
import { BtnSwitch, Grid, Icon, ProgressGoals, Search, Select, Table } from '../Inputs';
import { Account, ButtonProps, IconProps, NU, Project, SearchProps, SelectValue, ST, StoreT, T } from '../../model';
import { List } from '../Generic';
import { Loader } from '../index';
import { useActions } from '../../redux/actions';
import * as projectActions from '../../redux/actions/projects';
import * as userActions from '../../redux/actions/users';
import { DEFAULT_RECORDS_PER_PAGE, GoalsHeader, initFalsy, PLSort, ProjectIcons as PLI, ROLES } from '../../constants';
import { Path } from '../../routes';
import { aSort, numArray, va } from '../../utils/arrayUtils';
import { getSkipLimit, joinAsFullName, isValidSearch } from '../../utils/common';
import { usDateFormat } from '../../utils/dateUtils';
import { sleep } from '../../utils/objectUtils';

interface ProjectsListProps {
  isManager: boolean;
  managers: Account[];
  user: Account;
}

interface ProgressBarSplitterProps {
  compGoals: NU;
  isWeekly: boolean;
  reportWeeks: NU;
  reqGoals: NU;
  surveysRP?: NU;
}

const ProgressBarSplitter: React.FC<ProgressBarSplitterProps> = (props) => {
  const { compGoals, isWeekly, reportWeeks, reqGoals, surveysRP } = props;
  const hasValidReportWeeks = reportWeeks && reportWeeks > 0;
  const progressBar = <ProgressGoals compGoals={compGoals} reqGoals={reqGoals} surveysRP={surveysRP} />;
  const splitProgressBar = (
    <>
      {hasValidReportWeeks && (
        <Table celled={!initFalsy} compact={!initFalsy} className='grid-progress-bar' singleLine={!initFalsy}>
          <Table.Body>
            <Table.Row>
              {reportWeeks &&
                hasValidReportWeeks &&
                numArray(reportWeeks).map((_i: number, key: number) => (
                  <Table.Cell className='grid-progress-bar' key={key} />
                ))}
            </Table.Row>
          </Table.Body>
        </Table>
      )}
      {progressBar}
    </>
  );

  return isWeekly ? progressBar : splitProgressBar;
};

const storeMapper = (store: StoreT) => ({
  managers: store.users as Account[],
  user: store.auth.user,
});

const ProjectsList: React.FunctionComponent<ProjectsListProps> = ({ isManager, managers, user }) => {
  const hist = useHistory();
  const _projectA = useActions(projectActions);
  const _userAction = useActions(userActions);
  const initPageNo = 1;
  const initSort: { column: string; direction: ST } = { column: 'name', direction: 'ASC' };
  const actionIconProps: IconProps[] = [
    { className: 'hand-pointer', name: 'cog', title: 'Project Detail', type: PLI.Details },
    { className: 'hand-pointer', name: 'eye', title: 'Project Sampling', type: PLI.Sampling },
    { className: 'hand-pointer', name: 'cloud upload', title: 'Roster Upload', type: PLI.RosterUpload },
    { className: 'hand-pointer', name: 'file pdf', title: 'Surveyor Guidelines', type: PLI.PdfFileUpload },
    { className: 'hand-pointer', name: 'cloud download', title: 'Call Log Export', type: PLI.RosterExport },
    { className: 'hand-pointer', name: 'download', title: 'Survey Data Export', type: PLI.QSurveyExport },
    { className: 'hand-pointer', name: 'info', title: 'Project Additional Info', type: PLI.AdditionalInfo },
  ];
  const [limit, setPageSize] = React.useState(DEFAULT_RECORDS_PER_PAGE);
  const [activePage, setPageChange] = React.useState(initPageNo);
  const [loader, isLoading] = React.useState(initFalsy);
  const [isWeeklySelected, setWeekly] = React.useState(!initFalsy);
  const [sortVars, setSortVars] = React.useState(initSort);
  const [selManager, setManagers]: [T, Function] = React.useState('');
  const [searchVal, setSearchVal]: [T, Function] = React.useState('');
  const sortKeys = ['client.name', initSort.column, ''];
  !isManager && sortKeys.push('user.firstName');
  sortKeys.push(PLSort.LatestRosterUpload, PLSort.UnresolvedEscalations, PLSort.UndefinedSamplingParameters);
  const goalsHeader = isWeeklySelected
    ? `${GoalsHeader.Weekly} / ${GoalsHeader.WeeklyTotal}`
    : `${GoalsHeader.RP} / ${GoalsHeader.RPTotal}`;
  const headers = ['Client Name', 'Project Name', goalsHeader]
    .concat(isManager ? [] : ['Project Manager'])
    .concat(['Latest Roster Upload', 'Unresolved Escalations', 'Undefined Sampling Parameters', 'Actions']);
  const widthCss = isManager
    ? ['w10', 'w20', 'w40', 'w5', 'w10', 'w5', 'w10']
    : ['w10', 'w20', 'w20', 'w10', 'w20', 'w10', 'w5', 'w5', 'w10'];
  const onChangeType = (isWeekly: boolean) => {
    const skip = getSkipLimit(activePage, limit);
    fetchProjects(skip, limit, sortVars.column, sortVars.direction, isWeekly).then();
    setWeekly(isWeekly);
  };
  const reportButtProps: ButtonProps[] = [
    { active: isWeeklySelected === !initFalsy, content: 'Current Week', onClick: () => onChangeType(!initFalsy) },
    { as: 'div', className: 'or', key: 'or' },
    { active: isWeeklySelected === initFalsy, content: 'Reporting Period', onClick: () => onChangeType(initFalsy) },
  ];
  const onAction = async (p: Partial<Project>, type: string) => {
    const routeState = { projectId: p.id, name: p.name, managerId: p.managerId };
    let path = '';
    switch (type) {
      case PLI.Details:
        path = `${isManager ? Path.ProjectDetail : Path.OPSProjectDetail}/${p.id}`;
        hist.push(path, routeState);
        break;
      case PLI.RosterExport:
        path = isManager ? Path.PMRosterExport : Path.OPSRosterExport;
        hist.push(path, routeState);
        break;
      case PLI.Sampling:
        path = `${isManager ? Path.SamplingParams : Path.OPSSamplingParams}/${p.id}`;
        hist.push(path, routeState);
        break;
      case PLI.RosterUpload:
        path = isManager ? Path.Rosters : Path.OPSRosters;
        hist.push(path, routeState);
        break;
      case PLI.AdditionalInfo:
        path = `${isManager ? Path.ProjectAdditionalInfo : Path.OPSAddInfo}/${p.id}`;
        hist.push(path, routeState);
        break;
      case PLI.QSurveyExport:
        path = `${isManager ? Path.PMSurveyExport : Path.OPSurveyExport}/${p.id}`;
        hist.push(path);
        break;
      case PLI.PdfFileUpload:
        path = `${isManager ? Path.PMSurveyorGuidelines : Path.OPSSvGuidelines}/${p.id}`;
        hist.push(path, routeState);
        break;
    }
  };
  const navFux = (activePage: number, gridSize: number) => {
    const skip = getSkipLimit(activePage, gridSize);
    fetchProjects(skip, gridSize, sortVars.column, sortVars.direction, isWeeklySelected).then();
  };
  const onSort = (column: string, direction: ST) => {
    setSortVars({ column, direction });
    const skip = getSkipLimit(activePage, limit);
    fetchProjects(skip, limit, column, direction, isWeeklySelected).then();
  };
  const onSearchChange = (_e: SyntheticEvent, data: SearchProps) => {
    if (isValidSearch(data.value as string, loader)) {
      return sleep(0);
    }

    setPageChange(initPageNo);
    setSearchVal(data.value);
  };
  const mapPM = () => {
    if (!va(managers)) {
      return [];
    }
    const _arr: T[] = (managers || []).map((m, key) => ({
      text: joinAsFullName(m.firstName, m.lastName),
      value: m.id,
      key,
    }));
    if (_arr.findIndex((i) => i.key === -1) === -1) {
      _arr.unshift({ key: -1, value: '', text: '' });
    }

    return _arr.sort(aSort('text')) || [];
  };
  const searchProps = {
    loading: loader,
    onSearchChange: onSearchChange,
    placeholder: 'Search Client / Projects...',
    showNoResults: initFalsy,
  };
  const selectProps = {
    clearable: !initFalsy,
    name: 'pm',
    options: mapPM(),
    placeholder: 'Filter By Project Manager...',
    value: selManager,
  };
  const fcl = () => {
    const skip = getSkipLimit(activePage, limit);
    fetchProjects(skip, limit, sortVars.column, sortVars.direction).then(
      () => !isManager && _userAction.fetchUsers(ROLES['Project Manager'], '', initFalsy),
    );

    return () => _projectA.flushProjects();
  };
  const fetchProjects = async (
    skip = 0,
    lim = DEFAULT_RECORDS_PER_PAGE,
    sortOn = sortVars.column,
    sortBy = sortVars.direction,
    isWeekly = !initFalsy,
  ) => {
    isLoading(!initFalsy);
    const managerId = selManager || (isManager ? user.id : undefined);
    await _projectA.fetchProjects(
      skip,
      lim,
      sortOn,
      sortBy,
      undefined,
      managerId,
      initFalsy,
      isWeekly,
      !initFalsy,
      searchVal,
    );
    isLoading(initFalsy);
  };
  const { rows, count } = useSelector((state: StoreT) => state.projects) || {};
  const projects = va(rows)
    ? rows.map((project) => {
        const {
          client,
          compGoals,
          id,
          isLocked,
          lastRosterUpload,
          name,
          reqGoals,
          reportWeeks,
          surveysRP,
          user,
          uncleanedEscalation,
          undefinedProviders,
        } = project;
        const splitPBProps = { compGoals, isWeekly: isWeeklySelected, reportWeeks, reqGoals, surveysRP };
        const _projectItem = {
          _id: id,
          _lock: isLocked,
          clientName: client?.name,
          name,
          progress: <ProgressBarSplitter {...splitPBProps} />,
        };
        if (!isManager) {
          Object.assign(_projectItem, { managerId: joinAsFullName(user?.firstName, user?.lastName) });
        }
        return Object.assign(_projectItem, {
          lastRosterUpload: lastRosterUpload ? usDateFormat(lastRosterUpload) : '',
          uncleanedEscalation,
          undefinedProviders,
          Action: (
            <>
              {actionIconProps.map((a, i) => (
                <Icon key={i} {...a} onClick={() => onAction({ id, name }, a.type)} />
              ))}
            </>
          ),
        });
      })
    : [];
  const gridProps = { activeSortKey: sortVars.column, headers, list: projects, onSort, sortKeys, widthCss };
  const pgProps = { activePage, navigate: navFux, pageSize: limit, setPageChange, setPageSize, totalRecords: count };

  React.useEffect(fcl, [searchVal, selManager]);

  return (
    <>
      {loader && <Loader />}

      <Grid>
        <Grid.Row className='headerTop'>
          <Grid.Column width={16}>
            <h1 className='mainTitle'>My Projects</h1>
            <BtnSwitch buttonProps={reportButtProps} />
            <Search {...searchProps} />
            {!isManager && <Select {...selectProps} onChange={(value: SelectValue) => setManagers(value)} />}
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={16}>
            <List gridProps={gridProps} pgProps={pgProps} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};

export default connect(storeMapper)(ProjectsList);

import React from 'react';
import { FieldArray } from 'formik';
import { FAProps } from '../../model';

const FA: React.FC<FAProps> = (props: FAProps) => {
  const ChildComponent = props && props.children;
  return (
    <FieldArray {...props}>
      {(faChildProps) => {
        return <ChildComponent {...faChildProps} {...props} />;
      }}
    </FieldArray>
  );
};

export default FA;

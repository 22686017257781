import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { setPageTitle } from '../utils/common';
import { T } from '../model';

class Wrapper extends React.PureComponent<T> {
  static get propTypes() {
    return {
      children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    };
  }

  componentDidMount() {
    const {
      location: { pathname },
    } = this.props;
    setPageTitle(pathname);
  }

  render():
    | React.ReactElement
    | string
    | number
    | {}
    | React.ReactNodeArray
    | React.ReactPortal
    | boolean
    | null
    | undefined {
    return <>{this.props.children}</>;
  }
}

export default withRouter(connect(null, null)(Wrapper) as T);

import { T } from '../model';
import { getProp } from './objectUtils';
import { flc, isStr } from './stringUtils';

/**
 * Is valid array
 * @param arr
 */
export const va = (arr: T): boolean => arr && Array.isArray(arr) && !!arr.length;

/**
 * Get enum keys or values
 * @param Foo [Any Typescript Enum]
 * @param keys [Default is values] false
 */
export const enumAsArray = (Foo: T, keys: boolean = false): Array<string | number> =>
  Object.keys(Foo).filter((k: T) => typeof Foo[k] === (keys ? 'number' : 'string'));

/**
 * Get enum as Array of Object
 * @param Foo [Any Typescript Enum]
 * @param key [Obj key] defaults to key
 * @param value [value key] defaults to value
 */
export const enumAsAO = (Foo: T, key: string = 'text', value: string = 'value'): Array<T> =>
  enumAsArray(Foo, true).map((f, ix) => ({ key: ix, [key]: f, [value]: Foo[f] }));

/**
 * Generates an array of numbers given size with its indexes as values
 * @param arrSize
 */
export const numArray: (arrSize: number) => number[] = (arrSize: number) =>
  new Array(arrSize)
    .join()
    .split(',')
    .map((_item: string, index: number) => index);

/**
 * Generates an array of given size and object (default is empty object)
 * @param arrSize
 * @param object
 * */
export const anyArray = (arrSize: number, object: T = {}) => numArray(arrSize).map(() => object);

type Key = { [x: string]: string };
/**
 * Sort an Array of Objects alphabetically ascending on a single key, prefix (-) sign for descending
 * @param property
 */
export const aSort = (property: string) => {
  let sortOrder = 1;
  if (property[0] === '-') {
    sortOrder = -1;
    property = property.substr(1);
  }

  return (a: Key, b: Key) => {
    const aProp = flc(a[property]);
    const bProp = flc(b[property]);
    const result = aProp < bProp ? -1 : aProp > bProp ? 1 : 0;
    return result * sortOrder;
  };
};

/**
 * Max value in a numeric array
 * @param arr
 */
export const aMaxVal = (arr: number[]) => Math.ceil(Math.max.apply(Math, va(arr) ? arr : [0]));

/**
 * Difference bet two symmetric arrays
 * @param array1
 * @param array2
 */
export const diff = (array1: T[], array2: T[]) =>
  array1.filter((x) => !array2.includes(x)).concat(array2.filter((x) => !array1.includes(x)));

/**
 * Get Unique Values
 * @param arr
 */
export const uniq = (arr: T[]) => {
  return [...(new Set(arr) as T)] as T[];
};

/**
 * Grouping based on key
 * @param items
 * @param key
 */
export const groupBy = (items: T[], key: string | number) =>
  items.reduce(
    (result, item) => ({
      ...result,
      [item[key]]: [...(result[item[key]] || []), item],
    }),
    {},
  );

/**
 * Array Sort By Key
 * Use with Array.sort() function
 * @param property
 * @param desc
 * @param isDate
 */
export const byKey = (property: string, desc: boolean = false, isDate: boolean = false) => {
  let sortOrder = 1;
  if (property[0] === '-') {
    sortOrder = -1;
    property = property.substr(1);
  }

  return (a: T, b: T) => {
    const aProp = isDate
      ? new Date(a[property]).getTime()
      : isStr(a[property])
        ? a[property].toLowerCase()
        : a[property];
    const bProp = isDate
      ? new Date(b[property]).getTime()
      : isStr(b[property])
        ? b[property].toLowerCase()
        : b[property];
    sortOrder = aProp < bProp ? -1 : aProp > bProp ? 1 : 0;

    return desc ? sortOrder * -1 : sortOrder;
  };
};

/**
 * Sum of array of numbers
 * @param arr
 */
export const sum = (arr: number[]) => {
  const reducer = (accumulator: number, currentValue: number) => accumulator + currentValue;
  return arr.reduce(reducer);
};

/**
 * Filter Array values with Object Keys
 */
export const getArrObjDiff = (arr: T, obj: T) =>
  arr.reduce((r: T, e: T) => {
    const o = obj[e] || getProp(obj, e);
    const s = o ? { [e]: o } : { [e]: null };
    return Object.assign(r, s);
  }, {});

import { connect } from 'react-redux';
import React, { FunctionComponent, SyntheticEvent } from 'react';
import { Button, Card, Grid, GridView, Icon, Input, Paging, Search, TextBox } from '../Inputs';
import { MiniLoader } from '../Loader';
import {
  BN,
  ButtonProps,
  DepartmentList,
  E,
  ProviderList,
  SearchProps,
  SiteList,
  ST,
  StoreT,
  SU,
  T,
} from '../../model';
import { initFalsy, LanguageSamplingParams, NA, SamplingParams } from '../../constants';
import { va } from '../../utils/arrayUtils';
import isMobileDevice from '../../utils/deviceUtils';
import { fixedInt } from '../../utils/numUtils';
import { getSkipLimit } from '../../utils/common';

interface SamProviderProps {
  buttProps: ButtonProps;
  calcTot: Function;
  departments: DepartmentList;
  editRequired: Function;
  fetchSamProviders: Function;
  isEditReq: BN;
  languages: T[];
  onLangSort: Function;
  onReqChange: Function;
  onSearch: (data: SearchProps) => void;
  onSort: Function;
  providers: ProviderList;
  refreshing: boolean;
  samSwitch: T[];
  searchVal: SU;
  setSR: Function;
  sites: SiteList;
  sortVars: { column: string; direction: ST };
  totals: T[];
  type: number;
  updating: boolean;
}

const Providers: FunctionComponent<SamProviderProps> = (props) => {
  // const [searchVal, setSearch]: [string | undefined, Function] = React.useState();
  const {
    buttProps,
    calcTot,
    departments,
    editRequired,
    fetchSamProviders,
    isEditReq,
    languages,
    onLangSort,
    onReqChange,
    onSearch,
    onSort,
    providers,
    refreshing,
    samSwitch,
    searchVal,
    setSR,
    sites,
    sortVars,
    totals,
    type,
    updating,
  } = props;
  const initTypeValue = -1,
    initGridSize = 50,
    initPageNo = 1,
    [pageSize, setPageSize] = React.useState(initGridSize),
    [activePage, setPageChange] = React.useState(initPageNo),
    widthClass = isMobileDevice ? 'mr10 mb15' : 'mr10',
    sortKeys = [
      'name',
      'surveyRequired',
      'surveys',
      'surveyRemaining',
      'surveyRemainingP',
      'lastEncounter',
      'eligibleEnc',
      'encounters',
    ],
    bulkSurveyRequiredProps = {
      className: 'float-left mr10 mb15 labeledInput',
      label: 'Survey Required',
      onBlur: (e: E) => setSR(e.target.value),
      type: 'number',
    },
    refreshButtonProps = {
      className: 'float-right mb20',
      content: 'Re-Calc Total',
      onClick: () => calcTot(),
      primary: !initFalsy,
    },
    searchProps = {
      className: `float-left ${widthClass}`,
      placeholder: 'Search...',
      showNoResults: initFalsy,
    },
    isLang = SamplingParams.Language === type,
    onRequiredChange = async (id: number, value: string) => {
      await onReqChange(id, value);
      // call refresh
      const skip = getSkipLimit(activePage, pageSize);
      await fetchSamProviders(searchVal, type, sortVars.column, sortVars.direction, skip, pageSize);
    },
    editableRequired = (surReq: number, id: number) => {
      const textBoxProps = {
        defaultValue: surReq,
        disabled: isEditReq !== id,
        onBlur: (e: E) => onRequiredChange(id, e.target.value),
        type: 'number',
      };
      return (
        <>
          <span>{surReq}</span>
          <Icon name='edit' onClick={() => editRequired(id)} />
          {!!isEditReq && isEditReq === id && <TextBox {...textBoxProps} style={{ border: '1px solid black' }} />}
        </>
      );
    },
    commonList = (arr: T[]) =>
      arr.map((pro) => {
        const { id, name, encounters, eligibleEnc, lastEncounter, surveyRemaining, surveyRequired, surveys } = pro;
        return {
          _id: id,
          name,
          surveyRequired: editableRequired(surveyRequired, id as number),
          surveys,
          surveyRemaining,
          percentRemaining: `${fixedInt((surveyRemaining / surveyRequired) * 100, 1)}%`,
          recentEncounter: new Date(lastEncounter).toUSDateString(),
          eligibleEnc,
          encounters,
        };
      }),
    commonHeaders = (isLang: boolean) => {
      const name = `${type !== initTypeValue ? SamplingParams[type] : ''} Name`;
      const h1 = ['Survey Completed', 'Survey Remaining'];
      const h2 = ['Most Recent Encounters', 'Eligible Encounters', 'Total # of Encounters'];
      let headers: string[];
      if (isLang) {
        headers = h1.concat(h2);
      } else {
        headers = h1.concat(['Percent Remaining'].concat(h2));
        headers.unshift('Survey Required');
      }
      headers.unshift(name);
      return headers;
    },
    onProviderSearch = async (_e: SyntheticEvent, data: SearchProps) => {
      await onSearch(data);
      // setSearch(data.value);
      if (data.value && data.value.length > 1) {
        setPageChange(initPageNo);
        const skip = getSkipLimit(initPageNo, pageSize);
        await fetchSamProviders(searchVal, type, sortVars.column, sortVars.direction, skip, pageSize);
      }
    },
    pageNav = async (pNo: number, gridSize: number) => {
      const skip = getSkipLimit(pNo, gridSize);
      await fetchSamProviders(searchVal, type, sortVars.column, sortVars.direction, skip, gridSize);
    },
    onProviderSort = async (column: string, direction: ST) => {
      const skip = getSkipLimit(activePage, pageSize);
      onSort(column, direction);
      await fetchSamProviders(searchVal, type, column, direction, skip, pageSize);
    };

  let [sProviders, count]: [T[], number] = [[], 0];

  switch (type) {
    case SamplingParams.Department:
      sProviders = va(departments.rows) ? departments.rows : [];
      count = departments.count;
      break;
    case SamplingParams.Provider:
      sProviders = va(providers.rows) ? providers.rows : [];
      count = providers.count;
      break;
    case SamplingParams.Site:
      sProviders = va(sites.rows) ? sites.rows : [];
      count = sites.count;
      break;
  }

  const langList = va(languages)
      ? languages.map(({ eligibleEnc, encounters, name, recentEncounter, surveyRemaining, samplingId, surveys }) => ({
          name,
          surveys,
          surveyRemaining: LanguageSamplingParams.Set === samplingId ? surveyRemaining : NA,
          recentEncounter: !!recentEncounter ? new Date(recentEncounter).toUSDateString() : NA,
          eligibleEnc,
          encounters,
        }))
      : [],
    paginationProps = {
      activePage: activePage,
      navigate: pageNav,
      pageSize: pageSize,
      setPageChange: setPageChange,
      setPageSize: setPageSize,
      totalRecords: count,
    },
    gridProps = {
      footer: totals,
      headers: commonHeaders(isLang),
      list: !isLang ? commonList(sProviders) : langList,
      onSort: !isLang ? onProviderSort : onLangSort,
      sortKeys: !isLang ? sortKeys : [],
    };

  return (
    <>
      <Grid.Column computer={16} tablet={16} mobile={16}>
        <Card fluid={!initFalsy}>
          <Card.Content className='pb0'>
            <Grid>
              <Grid.Row>
                <Grid.Column>
                  {type !== initTypeValue && <Search {...searchProps} onSearchChange={onProviderSearch} />}
                  {type !== initTypeValue && <Input {...bulkSurveyRequiredProps} />}
                  {(updating || type !== initTypeValue) && <Button {...buttProps} className='mb15' />}
                  <Button.Group className='float-right btnSwitch mb15 ml10'>{samSwitch}</Button.Group>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Card.Content>
        </Card>
      </Grid.Column>
      <Grid.Column computer={16} tablet={16} mobile={16}>
        <Card fluid={!initFalsy}>
          <Card.Content>
            {refreshing && (
              <Grid>
                <Grid.Row>
                  <Grid.Column width={16}>
                    <MiniLoader />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            )}

            {<GridView {...gridProps} />}

            {va(sProviders) && count > initGridSize && <Paging {...paginationProps} />}
          </Card.Content>
        </Card>
      </Grid.Column>
      {(type !== initTypeValue || type !== SamplingParams.Language) && (
        <Grid.Column computer={16} tablet={16} mobile={16}>
          <Button {...refreshButtonProps} />
        </Grid.Column>
      )}
    </>
  );
};

const mapStateToProps = (state: StoreT) => {
  return {
    departments: state.departments,
    providers: state.providers,
    sites: state.sites,
  };
};
export default connect(mapStateToProps)(Providers);

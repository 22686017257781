import { Account, Action, ActionType, ExtUInfo, MiniAccount, SurList2, UsersList } from '../../model';
import createReducer from './createReducer';

const initialUsers: Array<Partial<Account>> = [];
const initialUser: Partial<Account> = {};

export const users = createReducer(initialUsers, {
  [ActionType.ADMIN_PULL_USERS_BEGIN](_store: Function, action: Action<void>) {
    return action.payload;
  },
  [ActionType.ADMIN_PULL_USERS_SUCCESS](_store: Function, action: Action<UsersList>) {
    return action.payload;
  },
  [ActionType.ADMIN_PULL_USERS_FLUSH](_store: Function, action: Action<void>) {
    return action.payload;
  },

  [ActionType.ADMIN_ADD_USER](_store: Function, action: Action<Account>) {
    return action.payload;
  },
  [ActionType.ADMIN_PUT_USER](_store: Function, action: Action<Account>) {
    return action.payload;
  },
  [ActionType.ADMIN_DEL_USER](_store: Function, action: Action<Account>) {
    return action.payload;
  },
  [ActionType.ADMIN_USER_DEACTIVATE](_store: Function, action: Action<Account>) {
    return action.payload;
  },
});

export const userInfo = createReducer(initialUser, {
  [ActionType.ADMIN_USER_INFO](_store: Function, action: Action<Account>) {
    return action.payload;
  },
  [ActionType.USER_INFO_FLUSH](_store: Function, action: Action<Account>) {
    return action.payload;
  },
});

export const surveyors = createReducer(initialUser, {
  [ActionType.PM_SURVEYORS_FETCH](_store: Function, action: Action<SurList2>) {
    return action.payload;
  },
  [ActionType.FLUSH_SURVEYOR](_store: Function, action: Action<void>) {
    return action.payload;
  },
});

export const surveyorInfo = createReducer(initialUser, {
  [ActionType.PM_SURVEYORS_INFO_FETCH](_store: Function, action: Action<MiniAccount>) {
    return action.payload;
  },
  [ActionType.FLUSH_SURVEYOR_INFO](_store: Function, action: Action<void>) {
    return action.payload;
  },
});

export const userExtInfo = createReducer(initialUser, {
  [ActionType.ADMIN_FETCH_EXT_USER](_store: Function, action: Action<ExtUInfo>) {
    return action.payload;
  },
  [ActionType.ADMIN_FLUSH_EXT_USER](_store: Function, action: Action<ExtUInfo>) {
    return action.payload;
  },
});

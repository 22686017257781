import React from 'react';
import { ButtonProps, Func, SurveyComment, T } from '../../model';
import { Button, Card, Form, Grid, TA } from '../Inputs';
import { initFalsy } from '../../constants';
import { getTextContent } from '../../utils/common';
import isMobileDevice from '../../utils/deviceUtils';
import { cfl, flc, fuc, pez } from '../../utils/stringUtils';
import { vo } from '../../utils/objectUtils';

export interface EDProps {
  comment?: string;
  id: string;
  label: string;
}

interface CCSProps {
  additionalButtons?: ButtonProps[];
  captureContent: Function;
  pnHeader: string;
  comment: string;
  commentInfo: SurveyComment;
  getElemId: Function;
  isPN: boolean;
  isOpenPositive: boolean;
  isOpenNegative: boolean;
  setWithNSM: Function;
  setWithoutNSM: Function;
  withNSM: NSM;
  withoutNSM: NSM;
}

type NSM = { normal: string; negative: string; positive: string };

export enum CCConstants {
  WNsm = 'withNSM',
  WONsm = 'withoutNSM',
  PWNsm = 'pWithNSM',
  PWONsm = 'pWithoutNSM',
  NWNsm = 'nWithNSM',
  NWONsm = 'nWithoutNSM',
  NTA = 'NAME TO ADMIN',
  DTA = 'DESCRIPTION TO ADMIN',
  ORG = 'Organization Name',
}

enum Toggle {
  Capitalized = 'Toggle Capitalized',
  Lower = 'Toggle Lower',
  Upper = 'Toggle Upper',
}

const EditableDiv = ({ comment, id, label }: EDProps) => {
  const edProps = {
    className: 'cc-textarea textarea w100',
    contentEditable: !initFalsy,
    id: pez(id, 'textarea'),
    suppressContentEditableWarning: !initFalsy,
  };
  return (
    <Form.Field className='ui form'>
      {label && <label>{label}</label>}
      <div {...edProps}>{comment}</div>
    </Form.Field>
  );
};
const UnEditableTA = (props: T) => {
  return (
    <Grid.Row>
      <Grid.Column computer={16} tablet={16} mobile={16}>
        <TA {...props} />
      </Grid.Column>
    </Grid.Row>
  );
};

const CCS: React.FC<CCSProps> = (props) => {
  const { captureContent, commentInfo, getElemId, isPN, isOpenPositive, isOpenNegative, withNSM, withoutNSM } = props;
  const getKey = () => {
    return isOpenPositive ? 'positive' : isOpenNegative ? 'negative' : 'normal';
  };
  const getVal = (nsm: NSM) => {
    return isOpenPositive ? nsm.positive : isOpenNegative ? nsm.negative : nsm.normal;
  };
  const clearToggle = captureContent;
  const isValidSelection = (sel: T, caretNRange?: boolean) => {
    const wElemId = getElemId(initFalsy);
    const woElemId = getElemId(!initFalsy);
    const isValidArea =
      sel.focusNode?.id === wElemId ||
      sel.focusNode?.id === woElemId ||
      sel.focusNode?.parentElement.id === wElemId ||
      sel.focusNode?.parentElement.id === woElemId;
    return sel && (caretNRange || sel.type === 'Range') && isValidArea;
  };
  const handleToggle: Func = (e) => {
    const sel = (window as T).getSelection();
    if (!isValidSelection(sel)) {
      return;
    }

    const selectedText = sel.toString();
    const btnName = e.currentTarget.innerHTML;
    if (selectedText.trim() !== '') {
      if (!!sel && sel.rangeCount) {
        let modifiedText = sel.toString();
        const range = sel.getRangeAt(0);
        if (flc(btnName) === flc(Toggle.Upper)) {
          modifiedText = fuc(selectedText);
        } else if (flc(btnName) === flc(Toggle.Capitalized)) {
          modifiedText = cfl(selectedText);
        } else if (flc(btnName) === flc(Toggle.Lower)) {
          modifiedText = flc(selectedText);
        }

        range.deleteContents();
        range.insertNode(document.createTextNode(modifiedText));
      }
    }
  };
  const replaceSelTxt = (replacementText: string) => {
    let sel, range;
    sel = window.getSelection();

    if (!isValidSelection(sel, !initFalsy)) {
      return;
    }

    if (!!sel && sel.rangeCount) {
      range = sel.getRangeAt(0);
      range.deleteContents();
      range.insertNode(document.createTextNode(replacementText));
    }

    captureContent();
  };
  const copyToNsm = () => {
    const woElemId = getElemId(!initFalsy);
    const woText = getTextContent(woElemId);
    const cpwNSM = Object.assign({}, withNSM, { [getKey()]: woText });
    captureContent();
    props.setWithNSM(cpwNSM);
  };
  const cbp = { className: `mt10 ${isMobileDevice ? '' : 'ml10'}` };
  const commonButtons: ButtonProps[] = [
    { ...cbp, content: Toggle.Capitalized, onMouseLeave: clearToggle, onClick: handleToggle, secondary: !initFalsy },
    { ...cbp, content: Toggle.Lower, onMouseLeave: clearToggle, onClick: handleToggle, secondary: !initFalsy },
    { ...cbp, content: Toggle.Upper, onMouseLeave: clearToggle, onClick: handleToggle, secondary: !initFalsy },
    {
      ...cbp,
      color: 'orange',
      content: 'Provider Name',
      onClick: () => replaceSelTxt(pez(commentInfo?.ch.providerName)),
    },
    { ...cbp, color: 'green', content: 'Name To Admin', onClick: () => replaceSelTxt(CCConstants.NTA) },
    { ...cbp, color: 'twitter', content: 'Description To Admin', onClick: () => replaceSelTxt(CCConstants.DTA) },
    { ...cbp, color: 'red', content: 'Copy To NSM', onClick: () => copyToNsm() },
  ];
  const buttonProps = commonButtons.concat(props.additionalButtons || []);
  const uncleanedCommentProps = {
    disabled: !initFalsy,
    label: props.pnHeader,
    value: props.comment,
  };
  const withNSMProps: EDProps = {
    id: getElemId(initFalsy),
    label: `Cleaned ${isPN ? (isOpenPositive ? 'Positive' : 'Negative') : ''} Comment With NSM`,
  };
  const withoutNSMProps: EDProps = {
    id: getElemId(!initFalsy),
    label: `Cleaned ${isPN ? (isOpenPositive ? 'Positive' : 'Negative') : ''} Comment Without NSM`,
  };

  return (
    <Card>
      <Card.Content>
        {!vo(commentInfo) && <div className='text-center'>No data found</div>}
        {vo(commentInfo) && (
          <Grid>
            <Grid.Row>
              <Grid.Column computer={14} mobile={16} tablet={10}>
                <UnEditableTA {...uncleanedCommentProps} />
                <EditableDiv {...withoutNSMProps} comment={getVal(withoutNSM)} />
                <EditableDiv {...withNSMProps} comment={getVal(withNSM)} />
              </Grid.Column>
              <Grid.Column computer={2} mobile={16} tablet={6}>
                <Grid.Row className='mt20'>
                  {buttonProps.map((btProps: ButtonProps, idx) => (
                    <Grid.Column key={idx} width={16}>
                      <Button {...btProps} className='mb10 w100' />
                    </Grid.Column>
                  ))}
                </Grid.Row>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}
      </Card.Content>
    </Card>
  );
};

export default CCS;

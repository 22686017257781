import React from 'react';
import { CallStats } from '../../components/Calls';
import { initFalsy } from '../../constants';

const Stats = () => {
  return (
    <>
      <CallStats isSurveyor={!initFalsy} />
    </>
  );
};

export default Stats;

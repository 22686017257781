import {
  ActionType,
  CleanResponse,
  Dispatcher,
  LogicPayload,
  PayloadQuestionnaire,
  ProjectsList,
  PutQuestionnaire,
  PutQuestionnaireResponse,
  QAProjects,
  QsLogic,
  QuestionnaireItem,
  QuestionnaireList,
  T,
} from '../../model';
import { setAlert, setSuccess } from './alert';
import { initFalsy, Language, ROLES } from '../../constants';
import { fetchProjectList } from './projects';
import { flc } from '../../utils/stringUtils';

type AQ = (payload: PayloadQuestionnaire, projectId: number) => Dispatcher;
type FQ = (
  userId: number,
  isManager: boolean,
  skip?: number,
  limit?: number,
  isPaginated?: boolean,
  search?: string,
) => Dispatcher;
type FQL = (
  projectId: number,
  isLogic?: boolean,
  isDeleted?: boolean,
  isSvProject?: boolean,
  languageId?: number,
) => Dispatcher;
type CQ = (
  fromProjectId: number,
  toProjectId: number,
  fromName: string,
  toName: string,
  includeLogic: boolean,
) => Dispatcher;
type CQS = (projectId: number, sequence: number, isInc: boolean) => Dispatcher;
type RQ = (projectId: number, sequence: number) => Dispatcher;
type RR = (projectId: number, ansId: number, sequence: number) => Dispatcher;
type QuestionnaireLogicList = { logic: QsLogic[]; questionnaire: QuestionnaireItem[] };

const apiPath = 'questionnaire';
const apiLogicPath = `${apiPath}/logic`;

/**
 * Flush Questionnaires List
 */
export const flushQuestionnaires = () => async (dispatch: Function) => {
  dispatch({ type: ActionType.QUESTIONNAIRE_LIST_FLUSH, payload: {} });
};

/**
 * Flush Questionnaire
 */
export const flushQuestionnaire = () => async (dispatch: Function) => {
  dispatch({ type: ActionType.QUESTIONNAIRE_FLUSH, payload: [] });
};

/**
 * Fetch Questionnaires List
 * @param userId
 * @param isManager
 * @param skip
 * @param limit
 * @param isPaginated
 * @param search
 */
export const fetchQuestionnaires: FQ =
  (userId, isManager, skip = 0, limit = Number.MAX_SAFE_INTEGER, isPaginated = !initFalsy, search?: string) =>
  async (dispatch, _store, api) => {
    try {
      const query = { isManager, isPaginated, limit, skip, userId, search };
      const data: QuestionnaireList = await api.get(apiPath, null, query);
      dispatch({ type: ActionType.QUESTIONNAIRE_LIST_FETCH, payload: data });
      return data;
    } catch (err) {
      dispatch(setAlert(err));
    }
  };

/**
 * Fetch Questionnaire For Project (Builder/Viewer)
 * @param projectId
 */
export const fetchQuestionnaire: (projectId: number) => Dispatcher = (projectId) => async (dispatch, store, api) => {
  try {
    const query = { isPaginated: initFalsy, limit: 1, projectId, skip: 0, questionnaire: !initFalsy };
    const user = store().auth.user;
    const managerId = user.role === ROLES['Project Manager'] ? user.id : undefined;
    if (managerId) {
      Object.assign(query, { managerId });
    }
    const data: QuestionnaireItem[] = await api.get(apiPath, null, query);
    dispatch({ type: ActionType.QUESTIONNAIRE_FETCH, payload: data });
    return data;
  } catch (err) {
    dispatch(setAlert(err));
    dispatch({ type: ActionType.QUESTIONNAIRE_FETCH, payload: {} });
  }
};

/**
 * Fetch Available Projects For Questionnaire
 */
export const fetchProjectAvailable: () => Dispatcher = (searchVal?: string) => async (dispatch, store) => {
  const user = store().auth.user,
    isManager = user.role === ROLES['Project Manager'],
    isOps = user.role === ROLES.Operations,
    fp = await dispatch(fetchProjectList(isOps, isManager, searchVal)),
    qa: QuestionnaireList = await dispatch(
      fetchQuestionnaires(user.id, isManager, undefined, undefined, undefined, searchVal),
    ),
    projects: ProjectsList = store().projects || fp.payload,
    questionnaire = qa.rows.map((i) => i.projectId),
    all = projects.rows.map(({ id, name }) => ({ id, name })),
    available = projects.rows.filter((p) => !questionnaire.includes(p.id as number));

  return { all, available, qa } as QAProjects;
};

/**
 * Copy Questionnaire
 * @param fromProjectId
 * @param toProjectId
 * @param fromName
 * @param toName
 * @param includeLogic
 */
export const copyQuestionnaire: CQ =
  (fromProjectId, toProjectId, fromName, toName, includeLogic) => async (dis, _st, api) => {
    try {
      const apiPathCopy = `${apiPath}/copy`;
      const payload = { fromProjectId, toProjectId, fromName, toName, includeLogic };
      const res: CleanResponse = await api.put(apiPathCopy, payload);
      dis({ type: ActionType.QUESTIONNAIRE_COPY });
      dis(setSuccess(res.message));
      return dis(fetchProjectAvailable());
    } catch (err) {
      dis(setAlert(err));
    }
  };

/**
 * Update Questionnaire Sequence
 * @param projectId
 * @param sequence
 * @param isInc
 */
export const changeQuestionnaireSequence: CQS = (projectId, sequence, isInc) => async (dis, _store, api) => {
  try {
    const apiPathSeq = `${apiPath}/change-sequence`;
    const payload = { projectId, sequence, isInc };
    const res: CleanResponse = await api.put(apiPathSeq, payload);
    dis({ type: ActionType.QUESTIONNAIRE_CHANGE_SEQUENCE });
    dis(setSuccess(res.message));
    return dis(fetchQuestionnaire(projectId));
  } catch (err) {
    dis(setAlert(err));
  }
};

export const addQuestionnaire: AQ = (payload, projectId) => async (dispatch, _store, api) => {
  try {
    const res: CleanResponse = await api.post(apiPath, payload);
    dispatch({ type: ActionType.QUESTIONNAIRE_ADD, payload: res.data });
    setSuccess(res.message);
    return dispatch(fetchQuestionnaire(projectId));
  } catch (err) {
    dispatch(setAlert(err));
  }
};

export const updateQuestionnaire: (payload: PutQuestionnaire) => Dispatcher =
  (payload) => async (dispatch, _store, api) => {
    try {
      const res: CleanResponse = await api.post(`${apiPath}/update-responses`, payload);
      dispatch({ type: ActionType.QUESTIONNAIRE_UPDATE, payload: res.data });
      setSuccess(res.message);
      return;
    } catch (err) {
      dispatch(setAlert(err));
    }
  };

export const removeQuestionnaire: RQ = (projectId: number, sequence: number) => async (dis, _store, api) => {
  try {
    const payload = { sequence, projectId };
    const res: CleanResponse = await api.delete(apiPath, payload);
    dis({ type: ActionType.QUESTIONNAIRE_DEL, payload: res.data });
    setSuccess(res.message);
    return dis(fetchQuestionnaire(projectId));
  } catch (err) {
    dis(setAlert(err));
  }
};

export const removeResponse: RR = (projectId, ansId, sequence) => async (dispatch, _store, api) => {
  try {
    const payload = { sequence, projectId, ansId };
    const res: CleanResponse = await api.delete(apiPath, payload);
    setSuccess(res.message);
    return;
  } catch (err) {
    dispatch(setAlert(err));
  }
};

/**
 * Fetch Questionnaire & Logic For Project (Builder/Viewer)
 * @param projectId
 * @param isLogic
 * @param isDeleted
 * @param isSvProject
 * @param langId
 */
export const fetchQsNLogic: FQL =
  (projectId, isLogic, isDeleted, isSvProject, langId) => async (dispatch, store, api) => {
    try {
      const languageId = !!langId ? langId : Language.English;
      const query = { isDeleted, languageId, projectId };
      const user = store().auth.user;
      const managerId = user.role === ROLES['Project Manager'] ? user.id : undefined;
      const surveyorId = user.role === ROLES.Surveyor || user.role === ROLES['CIMR Surveyor'] ? user.id : undefined;
      if (managerId) {
        Object.assign(query, { managerId, isSvProject });
      } else if (surveyorId) {
        Object.assign(query, { surveyorId });
      }
      const logicData: QuestionnaireLogicList = await api.get(apiLogicPath, null, query);
      dispatch({ type: ActionType.QUESTIONNAIRE_FETCH, payload: logicData.questionnaire });
      if (isLogic) {
        const logic: T[] = [];
        logicData.logic.forEach((l) => {
          let flIdx = -1;
          const fl = logic.find((i) => i.questionId === l.questionId && !l.rosterField) as T;
          flIdx = logic.findIndex((i) => i.questionId === l.questionId && i.answerId === l.answerId && !l.rosterField);
          const fla = logic[flIdx] as T;
          const _captureAndPush = () => {
            const lItm = { ...l, id: [l.id] as number[], affectedQuestionId: [l.affectedQuestionId] as number[] };
            logic.push(lItm);
          };
          const _captureAndReplace = (affectedQuestionId: number[], _idx: number, id: number[]) => {
            affectedQuestionId.push(l.affectedQuestionId as number);
            id.push(l.id as number);
            logic[_idx] = { ...logic[_idx], affectedQuestionId };
          };

          if (fl && fla) {
            _captureAndReplace(fla.affectedQuestionId, flIdx, fla.id);
          } else if (fl) {
            _captureAndPush();
          } else {
            const flr = logic.find(
              (i) => flc(i.rosterField) === flc(l.rosterField) && !l.questionId && l.rosterField,
            ) as T;
            flIdx = logic.findIndex(
              (i) =>
                flc(i.rosterField) === flc(l.rosterField) &&
                flc(i.rosterValue) === flc(l.rosterValue) &&
                !l.questionId &&
                l.rosterField,
            );
            const flv = logic[flIdx];
            if (flv && flr) {
              _captureAndReplace(flv.affectedQuestionId, flIdx, flv.id);
            } else if (flr) {
              _captureAndPush();
            } else {
              _captureAndPush();
            }
          }
        });
        dispatch({ type: ActionType.QUESTIONNAIRE_LOGIC_FETCH, payload: logic });
        return logic;
      } else {
        dispatch({ type: ActionType.QUESTIONNAIRE_LOGIC_FETCH, payload: logicData.logic });
        return logicData.questionnaire;
      }
    } catch (err) {
      dispatch(setAlert(err));
    }
  };

/**
 * Save Qs Logic
 * @param payload
 */
export const addQLogic: (payload: LogicPayload) => Dispatcher = (payload) => async (dis, _st, api) => {
  try {
    const data: CleanResponse = await api.post(apiLogicPath, payload);
    dis({ type: ActionType.QUESTIONNAIRE_LOGIC_SAVE, payload: data.data });
    return dis(setSuccess(data.message));
  } catch (err) {
    dis(setAlert(err));
  }
};

/**
 * Delete Qs Logic
 * @param id
 */
export const removeQsLogic: (id: number[]) => Dispatcher = (id) => async (dis, _st, api) => {
  try {
    const data = { id };
    const res: CleanResponse = await api.delete(apiLogicPath, data);
    dis({ type: ActionType.QUESTIONNAIRE_LOGIC_DEL, payload: res.data });
    return dis(setSuccess(res.message));
  } catch (err) {
    dis(setAlert(err));
  }
};

/**
 * Flush Questionnaire Logic
 */
export const flushQsLogic = () => async (dispatch: Function) => {
  dispatch({ type: ActionType.QUESTIONNAIRE_LOGIC_FLUSH, payload: [] });
};

/**
 * Change Questionnaire Responses
 * @param payload
 * @returns
 */
export const updateQuestionnaireResponse: (payload: PutQuestionnaireResponse) => Dispatcher =
  (payload) => async (dispatch, _store, api) => {
    try {
      const res: CleanResponse = await api.put(`${apiPath}/change-response-order`, payload);
      dispatch({ type: ActionType.QUESTIONNAIRE_UPDATE, payload: res.data });
      setSuccess(res.message);
      return;
    } catch (err) {
      dispatch(setAlert(err));
    }
  };

/**
 * Update Questionnaire Logic
 */
export const updateQLogic: (payload: LogicPayload) => Dispatcher = (payload) => async (dispatch, _store, api) => {
  try {
    const res: CleanResponse = await api.put(apiLogicPath, payload);
    dispatch({ type: ActionType.QUESTIONNAIRE_LOGIC_UPDATE, payload: res.data });
    setSuccess(res.message);
  } catch (err) {
    dispatch(setAlert(err));
  }
};

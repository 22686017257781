import React from 'react';
import { Dimmer, SemanticLoader } from '../components/Inputs';
import { LoaderProps } from '../model';
import { initFalsy } from '../constants';

const initTruthy = !initFalsy;

export const MiniLoader: React.FC<LoaderProps> = (props: LoaderProps) => {
  const color = props.color || 'dark';
  const style = !!props.style ? props.style : { height: '100px', width: '100%' };

  return (
    <Dimmer active={initTruthy} className={'text-center'} inverted={initTruthy} style={style}>
      <SemanticLoader active={initTruthy} color={color} content={props.children} />
    </Dimmer>
  );
};

const Loader: React.FC<LoaderProps> = (props: LoaderProps) => {
  const color = props.color || 'dark';

  return (
    <Dimmer active={initTruthy} className={'text-center'} inverted={initTruthy} style={{ width: '100%' }}>
      <SemanticLoader color={color} />
      {props.children}
    </Dimmer>
  );
};

export default Loader;

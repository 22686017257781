/**
 * Get a Random Number
 * @param minLength
 */
import { T } from '../model';

export const randomNumber = (minLength: number = 1) => Math.ceil(Math.random() * Math.pow(10, minLength - 1));
export const rn = randomNumber;

type NF = (num: T) => boolean;
/**
 * Is Valid Number, Can Be Zero
 * @param num
 */
export const isNum: NF = (num) => Number.isInteger(num as number) && isValidNumber(num);
export const isValidNumber: NF = (num) => !Number.isNaN(num as number) && Number.isFinite(num as number);

/**
 * Parse Int Or Zero
 * @param numStr
 * @param radix
 */
export const piz: (numStr: T, radix?: number) => number = (numStr, radix = 10) => {
  const num = parseInt(numStr, radix);
  return isNaN(num) ? 0 : num;
};

/**
 * Fixed number as string
 * @param val
 * @param fractionalDigits
 */
export const fixedInt = (val: T, fractionalDigits: number = 2) => piz(val).toFixed(fractionalDigits);

/**
 * Parse as float or Zero
 * @param val
 */
export const pfz = (val: T): number => (isNaN(parseFloat(val)) ? 0 : parseFloat(val));

/**
 * Fixed float as string
 * @param val
 * @param fractionalDigits
 */
export const fixedFloat = (val: T, fractionalDigits: number = 2) => pfz(val).toFixed(fractionalDigits);

/**
 * Format as currency
 * @param val
 * @param minimumFractionDigits
 */
export const asCurrency = (val: T, minimumFractionDigits = 2) => {
  const locale = 'en-US';
  const style = { currency: 'USD', minimumFractionDigits, style: 'currency' };
  return new Intl.NumberFormat(locale, style).format(val);
};

/**
 * Format as currency without fraction digits
 * @param val
 */

export const currencyWithoutFrac = (val: T) => {
  const locale = 'en-US';
  const style = { currency: 'USD', style: 'currency', minimumFractionDigits: 0, maximumFractionDigits: 0 };
  return new Intl.NumberFormat(locale, style).format(val);
};

/**
 * Calculate Percenatge of any two numbers
 */

export const calPercentage = (partialVal: number, total: number) => {
  return (partialVal * 100) / total;
};

import createReducer from './createReducer';
import { Action, ActionType, Client, ClientsList } from '../../model';

const initClientInfo: Partial<Client> = {};
const initClients: ClientsList | null = null;
export const clients = createReducer(initClients, {
  [ActionType.ADMIN_PULL_CLIENTS](_store: Function, action: Action<ClientsList>) {
    return action.payload;
  },

  [ActionType.ADMIN_CLIENTS_FLUSH](_store: Function, action: Action<void>) {
    return action.payload;
  },

  [ActionType.ADMIN_CLIENTS_ADD](_store: Function, action: Action<Client>) {
    return action.payload;
  },

  [ActionType.ADMIN_DEL_CLIENT](_store: Function, action: Action<Client>) {
    return action.payload;
  },

  [ActionType.ADMIN_DEL_CLIENT_CONTACT](_store: Function, action: Action<Client>) {
    return action.payload;
  },
});

export const clientInfo = createReducer(initClientInfo, {
  [ActionType.ADMIN_CLIENT_INFO](_store: Function, action: Action<Client>) {
    return action.payload;
  },

  [ActionType.ADMIN_CLIENT_INFO_FLUSH](_store: Function, action: Action<void>) {
    return action.payload;
  },
});

import React from 'react';
import { SurveyExport } from '../../components/Surveys';
import { initFalsy } from '../../constants';

const SurveyCsvExport = () => {
  return (
    <>
      <SurveyExport isPM={!initFalsy} />
    </>
  );
};

export default SurveyCsvExport;

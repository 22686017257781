import * as React from 'react';
import { ErrorMessage, FastField, Field, Form } from './index';
import { T, TextFieldProps } from '../../model';

export const FieldSet = ({ name, label, ...rest }: T) => (
  <React.Fragment>
    <label htmlFor={name}>{label}</label>
    <Field id={name} name={name} {...rest} />
    <ErrorMessage name={name} />
  </React.Fragment>
);

const FField = (props: TextFieldProps) => {
  const { error, fast, label, disabled, ...custom } = props;
  custom.placeholder = custom.placeholder || label;

  const hasError = !!error;
  const Input = !!fast ? FastField : Field;

  return (
    <Form.Field error={hasError}>
      <label>{label}</label>
      {hasError && (
        <div className='ui pointing below prompt label' role='alert' aria-atomic='true'>
          {error}
        </div>
      )}
      <div className={disabled ? 'ui disabled input' : ''}>
        <Input {...custom} disabled={disabled} />
      </div>
    </Form.Field>
  );
};

export default FField;

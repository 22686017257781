import React from 'react';
import { QuestionnaireBuilder } from '../../components/Questionnaire';

const PMQuestionnaireBuilder = () => {
  return (
    <>
      <QuestionnaireBuilder />
    </>
  );
};

export default PMQuestionnaireBuilder;

import React from 'react';
import { Button, Confirm, GridView, Icon } from '../Inputs';
import { va } from '../../utils/arrayUtils';
import { BN, ButtonProps, Client, IconProps, T } from '../../model';
import { CONFIRM_MESSAGES, Del, initFalsy, STATE } from '../../constants';
import { pez } from '../../utils/stringUtils';

type L = { dataList: T[]; onSort: Function; onAction: Function };
const List: React.FC<L> = (props: L) => {
  const headers = ['Client Name', 'Client Abbreviation', 'State', 'Creation Date', 'Action'],
    sortKeys = ['name', 'abbr', 'stateId', 'createdAt'],
    { dataList, onSort } = props,
    [delOpen, showDelConfirm]: [BN, Function] = React.useState(initFalsy),
    [deleting, isDeleting]: [boolean, Function] = React.useState(initFalsy),
    [deactivated, setDeactivated] = React.useState(initFalsy),
    [isDelBtn, setDelBtn] = React.useState(!initFalsy),
    performDelOperation = async (id: BN) => {
      isDeleting(!initFalsy);
      await props.onAction({
        id,
        value: 'del',
        isDeactivated: !isDelBtn ? undefined : !deactivated ? !initFalsy : initFalsy,
      });
      isDeleting(initFalsy);
      showDelConfirm(initFalsy);
    },
    DelButton = () => {
      const content = !isDelBtn ? Del.YD : !deactivated ? Del.YDA : Del.YRA;
      const delButtProps: ButtonProps = {
        color: !isDelBtn ? 'red' : !deactivated ? 'red' : undefined,
        content,
        loading: deleting,
        onClick: () => performDelOperation(delOpen),
        primary: !isDelBtn ? initFalsy : !deactivated ? initFalsy : !initFalsy,
        type: 'button',
      };
      return <Button {...delButtProps} />;
    },
    confirmProps = {
      content: !isDelBtn
        ? CONFIRM_MESSAGES.CONTACT_AND_PROJECT
        : !deactivated
          ? CONFIRM_MESSAGES.CLIENT_DEC
          : CONFIRM_MESSAGES.CLIENT_RAC,
      header: !isDelBtn ? Del.DC : !deactivated ? Del.DACTC : Del.RACTC,
      confirmButton: DelButton,
      open: !!delOpen,
      onCancel: () => showDelConfirm(initFalsy),
      onConfirm: () => performDelOperation(delOpen),
    },
    onDelete = (id?: number, deletedAt?: T) => {
      if (deletedAt !== undefined) {
        setDelBtn(!initFalsy);
        !!deletedAt ? setDeactivated(!initFalsy as T) : setDeactivated(initFalsy as T);
      } else {
        setDelBtn(initFalsy);
      }

      showDelConfirm(id);
    },
    clientsList = va(dataList)
      ? dataList.map((r: Client) => {
          const { abbr, deletedAt, name, stateId, createdAt, id } = r;
          const clickHandler = (id: number, value: string, deletedAt?: string | null) =>
            props.onAction({ id, value, deletedAt });
          const state = STATE[stateId];
          const creationDate = new Date(pez(createdAt)).toDateString();
          const deactivateIconProps: IconProps = {
            name: !!deletedAt ? 'lock' : 'lock open',
            onClick: () => onDelete(id, deletedAt),
            title: !!deletedAt ? Del.RACTC : Del.DACTC,
          };
          const actionIcons = [
            { name: 'eye', title: 'View Details', onClick: () => clickHandler(id as number, 'view', deletedAt) },
            { name: 'edit', title: 'Edit', onClick: () => clickHandler(id as number, 'edit', deletedAt) },
            { name: 'trash alternate', title: 'Delete', onClick: () => onDelete(id) },
            deactivateIconProps,
          ];
          const Action = actionIcons.map((iconProps: T, key: number) => (
            <Icon key={key} className='hand-pointer' {...iconProps} />
          ));

          return { _warn: deletedAt, name, abbr, state, creationDate, Action };
        })
      : [];

  return (
    <>
      <GridView headers={headers} list={clientsList} onSort={onSort} sortKeys={sortKeys} />

      {delOpen && <Confirm {...confirmProps} />}
    </>
  );
};

export default List;

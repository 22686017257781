import React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { Loader } from '../index';
import { Account, MiniAccount, Project, SelectValue, StoreT, T } from '../../model';
import { Button, Card, DatePicker, Form, Grid, Select } from '../../components/Inputs';
import { useActions } from '../../redux/actions';
import * as surveyExportActions from '../../redux/actions/survey';
import * as userAccActions from '../../redux/actions/users';
import * as projectActions from '../../redux/actions/projects';
import * as languagesActions from '../../redux/actions/languages';
import { newDate, toEnd } from '../../utils/dateUtils';
import { piz } from '../../utils/numUtils';
import { va } from '../../utils/arrayUtils';
import { Frequency, initFalsy, StatusCode } from '../../constants';
import { joinAsFullName } from '../../utils/common';

interface SVExport {
  isPM?: boolean;
  projectLanguage: Array<T>;
  projectInfo: Project;
  surveyors: Array<MiniAccount>;
  user: Account;
}

const SurveyExport: React.FC<SVExport> = ({ isPM, projectLanguage, projectInfo, surveyors, user }: SVExport) => {
  const { id }: T = useParams();
  const toDay = newDate();
  const [isLoading, setIsLoading] = React.useState(initFalsy);
  const svExportAction = useActions(surveyExportActions);
  const projectA = useActions(projectActions);
  const langA = useActions(languagesActions);
  const userAction = useActions(userAccActions);
  const [selLanguage, setLanguage] = React.useState('');
  const [selSurveyor, setSurveyor] = React.useState('');
  const [startDate, setStartDate] = React.useState(
    new Date(new Date().setDate(toDay.getDate() - Frequency.Weekly)).toISOString(),
  );
  const [endDate, setEndDate] = React.useState(toDay.toISOString());
  const [isAssignedQues, setIsAssignedQues] = React.useState(initFalsy);
  const opts = projectLanguage.map((l) => ({ text: l.name, value: l.id }));
  const surveyorOpt = va(surveyors)
    ? surveyors.map((i: MiniAccount) => ({
        text: `${joinAsFullName(i?.firstName, i?.lastName)} ${i?.email}`,
        value: i?.id,
      }))
    : [];
  const datesFilter = [
    { placeholder: 'From', name: 'fromDate', value: startDate, setValue: setStartDate },
    { placeholder: 'To', name: 'toDate', value: endDate, setValue: setEndDate },
  ];
  const exportButtonProps = {
    content: 'Export Csv',
    primary: !initFalsy,
  };
  const selSvProps = {
    className: 'float-left',
    hasFirstEmptyOpt: !initFalsy,
    name: 'surveyorId',
    options: surveyorOpt,
    placeholder: 'Select Surveyor',
    value: selSurveyor,
  };
  const selLangProps = {
    className: 'float-right',
    hasFirstEmptyOpt: !initFalsy,
    name: 'languageId',
    options: opts || [],
    placeholder: 'Select Languages',
    value: selLanguage,
  };
  const fetchSurveyUserList = async (_isWeekly: boolean = !initFalsy) => {
    await userAction.fetchSvInfo(isPM ? user.id : undefined);
  };
  const fr = () => {
    setIsLoading(!initFalsy);
    fetchSurveyUserList().then();
    setIsLoading(initFalsy);
    projectA.getProjectInfo(piz(id)).then((p: Project) => {
      if (p.statusCode === StatusCode.Unauthorized) {
        setIsLoading(!initFalsy);
        setIsAssignedQues(!initFalsy);
      }
    });
    langA.fetchProjectLanguages(piz(id));
    return () => {
      userAction.flushSurveyUserList();
      projectA.flushProjectInfo();
    };
  };
  const fetchReport = (projectId: number, doosraWay?: boolean) => {
    const ed = toEnd(endDate);
    const payload = {
      from: startDate,
      language: opts,
      languageId: selLanguage,
      name: projectInfo.name,
      projectId,
      surveyorId: selSurveyor,
      to: ed,
    };
    setIsLoading(!initFalsy);
    svExportAction.surveyCsvExport(payload, doosraWay).then(() => setIsLoading(initFalsy));
  };

  React.useEffect(fr, []);

  return (
    <>
      {isLoading && <Loader />}
      <Grid>
        <Grid.Row className='headerTop'>
          <Grid.Column width={16}>
            <h1 className='mainTitle'>Survey Export for {projectInfo.name}</h1>
          </Grid.Column>
        </Grid.Row>
        {!isAssignedQues && (
          <Grid.Row>
            <Grid.Column width={16}>
              <Card fluid>
                <Card.Content>
                  <Form>
                    <Grid>
                      <Grid.Row>
                        {datesFilter.map((fProps: T, i) => {
                          return (
                            <Grid.Column key={i} mobile={16} tablet={16} computer={6}>
                              <DatePicker {...fProps} />
                            </Grid.Column>
                          );
                        })}
                        <Grid.Column mobile={16} tablet={16} computer={4} floated='right'>
                          <Button {...exportButtonProps} onClick={() => fetchReport(id)} />
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Form>
                  <Grid>
                    <Grid.Row>
                      <Grid.Column mobile={16} tablet={16} computer={6}>
                        <Select {...selLangProps} label={''} onChange={(value: SelectValue) => setLanguage(value)} />
                      </Grid.Column>
                      <Grid.Column mobile={16} tablet={16} computer={6}>
                        <Select {...selSvProps} label={''} onChange={(value: T) => setSurveyor(value)} />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Card.Content>
              </Card>
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>
    </>
  );
};

const mapToProps = (state: StoreT) => {
  return {
    projectLanguage: state.languages,
    projectInfo: state.projectInfo,
    surveyors: state.surveyorInfo,
    user: state.auth.user,
  };
};

export default connect(mapToProps)(SurveyExport);

import React from 'react';
import { Card, Grid } from '../Inputs';
import { DocumentViewer } from '../Iframe';
import { fileUrl } from '../../utils/common';

interface SGProps {
  url: string;
}

const Guidelines = ({ url }: SGProps) => {
  const strEmpty = '';
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column className='mt20 mb10' width={16}>
          <Card>
            <Card.Header>
              <h1 className='mainTitle'>Surveyor Guideline</h1>
            </Card.Header>
            <Card.Content id='frameContainer'>
              {url && url !== strEmpty && <DocumentViewer url={fileUrl(encodeURIComponent(url))} />}
              {!url && <p className='center-force'>No guidelines uploaded yet.</p>}
            </Card.Content>
          </Card>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default Guidelines;

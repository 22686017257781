import React, { SyntheticEvent, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import {
  Button,
  Card,
  ChkBox,
  Dropdown,
  FieldArray,
  Form,
  FormField,
  FormFieldGroup,
  FormFieldType,
  Grid,
  Item,
  RadioGroup,
  Search,
} from '../../Inputs';
import { useActions } from '../../../redux/actions';
import * as clientAccActions from '../../../redux/actions/clients';
import * as userAccActions from '../../../redux/actions/users';
import {
  addClientsForm,
  COLLABORATIVE_GROUP,
  initFalsy,
  PRJ_CONTACT_TYPE,
  PROJECT_APPROVAL,
  PROJECT_SAMPLING,
  PROJECT_STATUS,
  PROJECT_TYPE,
  REPORTING_FREQ,
  ROLES,
  TimeZones,
} from '../../../constants';
import {
  ButtonProps,
  Client,
  Contact,
  FieldArrayRenderProps,
  FieldProps,
  FormFieldProps,
  FormProps,
  Project,
  SearchProps,
  SelectProps,
  SelectValue,
  StoreT,
  T,
  UsersList,
} from '../../../model';
import { aSort, enumAsAO, enumAsArray, va } from '../../../utils/arrayUtils';
import { joinAsFullName, mapSelectOption, mapSelectOpts } from '../../../utils/common';
import { pez } from '../../../utils/stringUtils';
import { sleep, vo } from '../../../utils/objectUtils';

type LocationState = { state: { clientInfo: Client } };

type CG = { text: string | number; value: string; key: number };

type ExtraProps = {
  buttons: ButtonProps[];
  projectInfo: Project;
  isView?: boolean;
};

interface SFF extends FieldProps {
  hasFirstEmptyOpt?: boolean;
}

interface AddProjectDetailsProps extends FormProps, ExtraProps {}

const Details: React.FC<AddProjectDetailsProps> = (props: AddProjectDetailsProps) => {
  const { buttons, values, setFieldValue, isView, projectInfo } = props,
    hist = useHistory(),
    { contacts } = addClientsForm,
    loc: LocationState = useLocation(),
    clientAx = useActions(clientAccActions),
    userAx = useActions(userAccActions),
    users = useSelector((state: StoreT) => state.users as UsersList) || { rows: [] },
    languageOptions = useSelector((state: StoreT) => state.languages) || [],
    initSearchResults = { contacts: [], value: '' },
    [collaborativeOpt, setCollaborativeOpt] = React.useState([] as CG[]),
    [searchResults, onContactSearch] = React.useState(initSearchResults),
    { id, clientId, deletedAt } = projectInfo,
    isEdit = vo(projectInfo),
    subTitle = `${isEdit ? 'Edit' : isView ? 'View' : 'Add'} Project Type`,
    managers = va(users.rows)
      ? users.rows.map(({ id, firstName, lastName }) => ({ text: joinAsFullName(firstName, lastName), value: id }))
      : [],
    lfy = () => {
      userAx.fetchUserAccounts(0, 100, 'firstName', 'ASC', ROLES['Project Manager'], null);
      setCollaborativeOpt(mapSelectOption(enumAsArray(COLLABORATIVE_GROUP, !initFalsy), !initFalsy) as CG[]);
    },
    resultRenderer = ({ firstName, lastName, email, phoneNumber }: T) => {
      return (
        <>
          <Item content={`${joinAsFullName(firstName, lastName)}, Email: ${pez(email)} Phone: ${pez(phoneNumber)}`} />
        </>
      );
    },
    onSearchChange = async (_e: SyntheticEvent, data: SearchProps) => {
      if (data.value && data.value.length <= 1) {
        onContactSearch({ ...{}, ...initSearchResults, value: pez(data.value) });
        return sleep(0);
      }

      const { clientInfo }: { clientInfo: Client } = loc.state || {};

      const contacts = await clientAx.findContacts(isEdit ? clientId : clientInfo.id, data.value);
      onContactSearch({ ...{}, ...initSearchResults, contacts, value: pez(data.value) });
    },
    onResultSelect = (_e: SyntheticEvent, data: Contact, fps: FieldArrayRenderProps) => {
      const { firstName, lastName, email, phoneNumber, typeId } = data;
      fps.push({ firstName, lastName, email, phoneNumber, typeId });
      onContactSearch(initSearchResults);
      _e.preventDefault();
    },
    prjTypes = enumAsAO(PROJECT_TYPE).sort(aSort('text')),
    addFormJSON: SFF[] = [
      { label: 'Project Name', name: 'name', type: FormFieldType.Text, disabled: isView },
      { label: 'Project Type', name: 'typeId', options: prjTypes, type: FormFieldType.DropDown, disabled: isView },
      {
        label: 'Status',
        name: 'statusId',
        options: enumAsAO(PROJECT_STATUS),
        type: FormFieldType.DropDown,
        disabled: isView,
      },
      {
        label: 'Project Manager',
        name: 'managerId',
        options: managers,
        type: FormFieldType.DropDown,
        disabled: isView,
      },
      {
        label: 'Reporting Frequency',
        name: 'reportFreqId',
        options: enumAsAO(REPORTING_FREQ),
        type: FormFieldType.DropDown,
        disabled: isView,
      },
      { label: 'Benchmarked Project', name: 'isBenchmarked', type: FormFieldType.CheckBox, disabled: isView },
    ],
    collabField =
      isEdit || isView
        ? {
            disabled: isView,
            label: 'Collaborative Group',
            name: 'collabGrp',
            type: FormFieldType.Text,
          }
        : {
            allowAdditions: !initFalsy,
            disabled: isView,
            label: 'Collaborative Group',
            name: 'collabGrp',
            onAddItem: (_e: React.KeyboardEvent<HTMLElement>, data: SelectProps) => {
              const value = data.value as string;
              const _collaborativeOpt = { text: value, value, key: -1 };
              setFieldValue('collabGrp', value);
              collaborativeOpt.push(_collaborativeOpt);
              setCollaborativeOpt(collaborativeOpt);
            },
            options: collaborativeOpt,
            type: FormFieldType.DropDown,
          },
    addFormDatesJson: SFF[] = [
      { label: 'Start Date', name: 'startDate', type: FormFieldType.DatePicker, disabled: isView },
      { label: 'Contract Date', name: 'contractDate', type: FormFieldType.DatePicker, disabled: isView },
    ],
    addContactsJSON: SFF[] = contacts.slice(0).map((a) => ({ ...a, type: FormFieldType.Text })),
    contactTypes = enumAsAO(PRJ_CONTACT_TYPE),
    approvalSts = mapSelectOpts(PROJECT_APPROVAL),
    samplingMethods = mapSelectOpts(PROJECT_SAMPLING),
    cirIBProps: ButtonProps = {
      className: 'float-right mt-5',
      circular: !initFalsy,
      type: 'button',
      compact: !initFalsy,
      disabled: isView,
    },
    addressTextAreaProps = { label: 'Address', placeholder: 'Client Billing St. Address Here...' },
    selTZProps = {
      label: 'Time Zone',
      name: 'timezone',
      options: enumAsArray(TimeZones, !initFalsy).map((tz) => ({ text: tz, value: tz })),
      disabled: isView,
    },
    conSearchProps = {
      fluid: !initFalsy,
      placeholder: 'Name Database Addition...',
      minCharacters: 2,
      onSearchChange,
      resultRenderer,
      results: searchResults.contacts,
      noResultsMessage: 'No such contact.',
      value: searchResults.value,
      disabled: isView,
    },
    renderContacts = (fps: FieldArrayRenderProps) => {
      return (
        <>
          <Grid.Column mobile={16} tablet={16} computer={16}>
            <Search {...conSearchProps} onResultSelect={(e, d) => onResultSelect(e, d.result as Contact, fps)} />
          </Grid.Column>

          {va(values.contacts) &&
            values.contacts.map((_c: T, i: number) => {
              return (
                <React.Fragment key={i}>
                  <Grid.Column mobile={16} tablet={16} computer={16}>
                    <h3 className='w100 subHeaderInner'>
                      <span>{PRJ_CONTACT_TYPE[_c.typeId]}</span>
                      {<Button {...cirIBProps} color='red' icon='trash alternate' onClick={() => fps.remove(i)} />}
                    </h3>
                  </Grid.Column>
                  {addContactsJSON.map((a, ix) => {
                    const name = `contacts.${i}.${a.name}`;
                    const tfProps = { ...a, name, disabled: isView };
                    return (
                      <Grid.Column key={ix} mobile={16} tablet={16} computer={8}>
                        <FormFieldGroup fields={tfProps as FormFieldProps} />
                      </Grid.Column>
                    );
                  })}
                </React.Fragment>
              );
            })}

          <Grid.Column mobile={16} tablet={16} computer={16}>
            {
              <h3 className='w100 subHeaderInner'>
                <span>Add Contact</span>
                <Button primary={!initFalsy} {...cirIBProps}>
                  <Dropdown icon='plus' compact={!initFalsy}>
                    <Dropdown.Menu className='contactMenu'>
                      {contactTypes.map((opt, ix) => (
                        <Dropdown.Item content={opt.text} key={ix} onClick={() => fps.push({ typeId: opt.value })} />
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </Button>
              </h3>
            }
          </Grid.Column>
        </>
      );
    };

  addFormJSON.push(collabField);

  useEffect(lfy, []);

  return (
    <Grid.Row>
      <Grid.Column mobile={16} tablet={16} computer={16}>
        <Card fluid={!initFalsy} className='mb20'>
          <Card.Header>
            <h2 className='subTitle'>{subTitle}</h2>
            {isView && !deletedAt && (
              <Button
                className='asAnchor float-right'
                content='Edit'
                icon='edit'
                onClick={() => hist.push(`/admin/projects/${id}`)}
              />
            )}
          </Card.Header>
          <Card.Content>
            <Grid>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={8}>
                  <Grid>
                    <Grid.Row>
                      {addFormJSON.map((field: SFF, key: number) => (
                        <Grid.Column key={key} mobile={16} tablet={16} computer={8}>
                          <FormFieldGroup fields={field as T} />
                        </Grid.Column>
                      ))}
                    </Grid.Row>
                    <Grid.Row>
                      {addFormDatesJson.map((field, k) => (
                        <Grid.Column key={k} mobile={16} tablet={16} computer={8}>
                          <FormFieldGroup fields={field as T} />
                        </Grid.Column>
                      ))}
                    </Grid.Row>
                  </Grid>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={8}>
                  <Grid>
                    <Grid.Column mobile={16} tablet={16} computer={16}>
                      <h3 className='w100 subHeaderInner'>
                        <span>Name Database Addition</span>
                      </h3>
                    </Grid.Column>
                    <FieldArray name='contacts' render={renderContacts} />
                  </Grid>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={8}>
                  <h3 className='w100 subHeaderInner'>
                    <span>Project - Languages</span>
                  </h3>
                  <Form.Field>
                    {languageOptions.map(({ id, name }, i) => {
                      const tfProps = {
                        ...{},
                        name: 'langIds',
                        label: name,
                        value: id,
                        onChange: (val: SelectValue) => {
                          const set = new Set(values.langIds);
                          if (set.has(val)) {
                            set.delete(val);
                          } else {
                            set.add(val);
                          }
                          setFieldValue('langIds', Array.from(set));
                        },
                        checked: values.langIds.includes(id),
                        disabled: isView,
                      };
                      return <ChkBox className='customCheckGroup' {...tfProps} key={i} />;
                    })}
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={8}>
                  <Grid>
                    <Grid.Row>
                      <Grid.Column mobile={16} tablet={16} computer={16}>
                        <h3 className='w100 subHeaderInner'>
                          <span>Client Billing Address</span>
                        </h3>
                      </Grid.Column>
                      <Grid.Column mobile={16} tablet={8} computer={8}>
                        <FormField
                          {...addressTextAreaProps}
                          disabled={isView}
                          name='clientBuzAdd'
                          type={FormFieldType.TextArea}
                        />
                      </Grid.Column>
                      <Grid.Column mobile={16} tablet={8} computer={8}>
                        <FormField {...selTZProps} type={FormFieldType.Select} disabled={isView} />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={16}>
                  <Grid>
                    <Grid.Column mobile={16} tablet={16} computer={16}>
                      <h3 className='w100 subHeaderInner'>
                        <span>Project OnBoarding Status</span>
                      </h3>
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={16} computer={8}>
                      <RadioGroup
                        className='customRadioGroup'
                        name='approvalStatus'
                        label='Approval Status'
                        options={approvalSts}
                        onChange={(val: SelectValue) => setFieldValue('approvalStatus', val)}
                        value={props.values['approvalStatus']}
                        disabled={isView}
                      />
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={16} computer={8}>
                      <FormField
                        className='customCheckGroup'
                        name='associateAgreement'
                        label='Business Associate Agreement'
                        type={FormFieldType.CheckBox}
                        disabled={isView}
                      />
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={16} computer={8}>
                      <Form.Field>
                        {samplingMethods.map((f, i) => {
                          const tfProps = {
                            ...{},
                            name: 'sampling',
                            label: f.text,
                            value: f.value,
                            onChange: (val: SelectValue) => {
                              const set = new Set(values.sampling);
                              if (set.has(val)) {
                                set.delete(val);
                              } else {
                                set.add(val);
                              }
                              setFieldValue('sampling', Array.from(set));
                            },
                            checked: values.sampling.includes(f.value),
                            disabled: isView,
                          };
                          return <ChkBox className='customCheckGroup' {...tfProps} key={i} />;
                        })}
                      </Form.Field>
                    </Grid.Column>
                  </Grid>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={16} className='mt15' textAlign='right'>
                  {buttons.map((buttonProps: ButtonProps, key: number) => (
                    <Button key={key} {...buttonProps} />
                  ))}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Card.Content>
        </Card>
      </Grid.Column>
    </Grid.Row>
  );
};

export default Details;

import { ActionType, ApiEngine, CleanResponse, Question } from '../../model';
import { setAlert, setSuccess } from './alert';

const apiPath = 'question';

export const flushQuestions = () => async (dispatch: Function) => {
  dispatch({ type: ActionType.QUESTIONS_FLUSH, payload: [] });
  dispatch({ type: ActionType.QUESTION_PROJECT_FLUSH, payload: [] });
};

export const fetchQuestions =
  (search?: string, isQsBuilder?: boolean) => async (dispatch: Function, _store: Function, api: ApiEngine) => {
    try {
      const query = { search };
      if (isQsBuilder) {
        Object.assign(query, { questionnaire: isQsBuilder });
      }
      const data: CleanResponse = await api.get(apiPath, null, query);
      dispatch({ type: ActionType.QUESTIONS_FETCH, payload: data });
      // setSuccess(data.message);
      return;
    } catch (err) {
      dispatch(setAlert(err));
    }
  };

export const addQuestions = (payload: Question) => async (dispatch: Function, _store: Function, api: ApiEngine) => {
  try {
    const res: CleanResponse = await api.post(apiPath, payload);
    dispatch({ type: ActionType.QUESTIONS_ADD, payload: res.data });
    setSuccess(res.message);
    return dispatch(fetchQuestions());
  } catch (err) {
    dispatch(setAlert(err));
  }
};

export const updateQuestions = (payload: Question) => async (dispatch: Function, _store: Function, api: ApiEngine) => {
  try {
    const res: CleanResponse = await api.put(apiPath, payload);
    dispatch({ type: ActionType.QUESTIONS_PUT, payload: res.data });
    setSuccess(res.message);
    return dispatch(fetchQuestions());
  } catch (err) {
    dispatch(setAlert(err));
  }
};

export const removeQuestions =
  (payload: { id: number; isQuesDel: boolean }) => async (dispatch: Function, _store: Function, api: ApiEngine) => {
    try {
      const res: CleanResponse = await api.delete(apiPath, payload);
      dispatch({ type: ActionType.QUESTIONS_DEL, payload: res.data });
      setSuccess(res.message);
      return dispatch(fetchQuestions());
    } catch (err) {
      dispatch(setAlert(err));
    }
  };

export const removeLangQuestion =
  (langQuestionId: number) => async (dispatch: Function, _store: Function, api: ApiEngine) => {
    try {
      const payload = { langQuestionId };
      const res: CleanResponse = await api.delete(apiPath, payload);
      dispatch({ type: ActionType.QUESTIONS_LANG_DEL, payload: res.data });
      setSuccess(res.message);
      return dispatch(fetchQuestions());
    } catch (err) {
      dispatch(setAlert(err));
    }
  };

export const getQuesInProject = (id: number) => async (dispatch: Function, _store: Function, api: ApiEngine) => {
  try {
    const data = await api.get(`${apiPath}/project-list`, null, { id });
    return dispatch({ type: ActionType.QUESTION_PROJECT_LIST, payload: data });
  } catch (err) {
    dispatch(setAlert(err));
  }
};

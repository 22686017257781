import React from 'react';
import { Card, Grid, GridView, Paging } from '../Inputs';
import { GridViewProps, PGProps, T } from '../../model';
import { initFalsy } from '../../constants';

interface GenericListProps {
  gridProps: GridViewProps<T>;
  pgProps: PGProps;
}

const List: React.FC<GenericListProps> = (props) => {
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={16}>
          <Card fluid={!initFalsy}>
            <Card.Content>
              <GridView {...props.gridProps} />

              <Paging {...props.pgProps} />
            </Card.Content>
          </Card>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default List;

import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { Button, Card, DropZone, Grid, GridView } from '../../components/Inputs';
import { useActions } from '../../redux/actions';
import * as rostersActions from '../../redux/actions/rosters';
import { AfterFileDrop, StoreT, T } from '../../model';
import { Path } from '../../routes';
import { va } from '../../utils/arrayUtils';
import { initFalsy } from '../../constants';

type RosterGridProps = { headers: string[]; mainTitle: string; rosters: T[] };

const RosterGrid = ({ headers, mainTitle, rosters }: RosterGridProps) => {
  return (
    <Grid.Row>
      {va(rosters) && (
        <Grid.Column className='mt10' width={16}>
          <h1 className='mainTitle'>
            {mainTitle} <span className='ml5'>({rosters.length})</span>
          </h1>
        </Grid.Column>
      )}
      {va(rosters) && (
        <Card>
          <Card.Content>
            <Grid.Column width={16}>
              <GridView headers={headers} list={rosters} />
            </Grid.Column>
          </Card.Content>
        </Card>
      )}
    </Grid.Row>
  );
};

const Roster = () => {
  const hst = useHistory();
  const loc = useLocation();
  const projectD: T = loc.state;
  const rAction = useActions(rostersActions);
  const [rosterUploading, setRosterUploading] = React.useState(initFalsy);
  const [rawRoster, setRawRoster] = React.useState({} as AfterFileDrop);
  const [rosterProcessing, setRosterProcessing] = React.useState(initFalsy);
  const { providers, centers, departments, languages } = useSelector((state: StoreT) => state.rosters);
  const rosterGridData = [
    { mainTitle: 'Language', roster: languages },
    { mainTitle: 'Providers', roster: providers },
    { mainTitle: 'Sites', roster: centers },
    { mainTitle: 'Departments', roster: departments },
  ];
  const afterDrop = async (data: AfterFileDrop, onlyParse: boolean = !initFalsy) => {
    // uploading begins
    if (onlyParse) {
      setRawRoster(data);
    } else {
      setRosterProcessing(!onlyParse);
    }
    setRosterUploading(!initFalsy);
    await rAction.uploadRosters(projectD.projectId, data.result, onlyParse, data.file);
    setRosterUploading(initFalsy);

    if (!onlyParse) {
      setRosterProcessing(onlyParse);
      cancel();
    }
  };
  const cancel = () => rAction.flushRosters();
  const confirmUpload = () => afterDrop(rawRoster, initFalsy);
  const parseHeader = (rosters: T[]) => (va(rosters) && rosters[0] ? Object.keys(rosters[0]) : []);
  const renderButtons = (
    <>
      <Button.Group className='float-right mt20'>
        <Button content='Cancel' className='mr10' disabled={rosterProcessing} onClick={cancel} />
        <Button content='Confirm' loading={rosterProcessing} onClick={confirmUpload} primary={!initFalsy} />
      </Button.Group>
    </>
  );

  if (!projectD) {
    hst.push(Path.Projects);
    return;
  }

  return (
    <Grid>
      {(va(centers) || va(departments) || va(providers) || va(languages)) && renderButtons}

      {(!va(centers) || !va(departments) || !va(providers) || !va(languages)) && (
        <Grid.Row className='headerTop'>
          <Grid.Column width={16}>
            <h1 className='mainTitle'>
              Select Roster for <strong>{projectD?.name}</strong>
            </h1>
          </Grid.Column>
          <Grid.Column width={16}>
            <DropZone afterDrop={afterDrop} loading={rosterUploading} />
          </Grid.Column>
        </Grid.Row>
      )}

      {rosterGridData.map((r, key) => (
        <RosterGrid headers={parseHeader(r.roster)} key={key} mainTitle={r.mainTitle} rosters={r.roster} />
      ))}

      {(va(centers) || va(departments) || va(providers) || va(languages)) && (
        <Grid.Row>
          <Grid.Column className='mt20 mb10' computer={16} mobile={16} tablet={16}>
            {renderButtons}
          </Grid.Column>
        </Grid.Row>
      )}
    </Grid>
  );
};

export default Roster;

import React from 'react';
import { PublicLayout } from './index';
import { LogInForm } from '../../components';
import { FormWrap, Model } from '../../components/Inputs';
import { useActions } from '../../redux/actions';
import * as Actions from '../../redux/actions/auth';
import { FormikValues, T } from '../../model';
import { validate } from '../../utils/validation';
import { COOKIE_NAME, initFalsy } from '../../constants';
import { VerifyOtp } from '../../components/Accounts/2fa';
import { getMyIp } from '../../utils/common';
import { getCookie } from '../../utils/storage';
import { vo } from '../../utils/objectUtils';

const LoginPage = () => {
  const authActions = useActions(Actions);
  const [ipAdd, setIpAdd] = React.useState('');
  const [isOpenVOtp, setVOtpModal] = React.useState(initFalsy);
  const [disable, setDisabled] = React.useState(initFalsy);
  const [getRemAcc, setRemAcc] = React.useState(null);
  const checkCookie = () => {
    const user: T = getCookie(COOKIE_NAME);
    user ? setRemAcc(user) : setRemAcc(null);
  };
  const requiredFields = ['email', 'password'];
  const signInForm = {
    displayName: 'signInForm',
    initialValues: { email: '', password: '' },
    onSubmit: async (values: FormikValues, { resetForm }: FormikValues) => {
      try {
        const payload = { auth: values, cookieStatus: getRemAcc, deviceIpAdd: ipAdd };
        await authActions
          .SignIn(payload)
          .then((res: T) => {
            if (vo(res.user) && !res.accessToken) {
              setDisabled(!initFalsy);
              setVOtpModal(!initFalsy);
            }
          })
          .catch((err: T) => console.error(err));
      } catch (err) {
        resetForm();
      }
    },
    children: LogInForm,
    validate: (values: FormikValues) => validate(requiredFields, values),
  };
  const verifyOtpProps = { setDisabled };
  const closePopup = () => {
    setVOtpModal(initFalsy);
  };
  const modelContent = (
    <>
      <VerifyOtp {...verifyOtpProps} />
    </>
  );
  const modelProps: T = {
    content: modelContent,
    header: 'Verification Code',
    initialize: disable,
    closeIcon: !initFalsy,
    onClose: closePopup,
    size: 'tiny',
    contentScrolling: !initFalsy,
  };

  const fse = () => {
    const fetchIpAdd = async () => {
      const deviceIpAdd = await getMyIp();
      setIpAdd(deviceIpAdd);
    };

    checkCookie();
    fetchIpAdd()
      .then()
      .catch((err) => console.error(err));
  };

  React.useEffect(fse, []);

  return (
    <>
      <PublicLayout>
        <FormWrap {...signInForm} />
      </PublicLayout>

      {isOpenVOtp && <Model {...modelProps} />}
    </>
  );
};

export default LoginPage;

import * as React from 'react';
import { SyntheticEvent } from 'react';
import PropTypes from 'prop-types';
import { Grid, Icon, Pagination, Select } from '../../components/Inputs';
import { PaginationProps, PGProps, SelectValue } from '../../model';
import { DEFAULT_PAGE_SIZES, DEFAULT_RECORDS_PER_PAGE, initFalsy, ONE } from '../../constants';
import { numArray } from '../../utils/arrayUtils';

enum PZ {
  F = 'First Page',
  L = 'Last Page',
  N = 'Next Page',
  P = 'Previous Page',
}

const Paging: React.FC<PGProps> = (props: PGProps) => {
  const { activePage, navigate, pageSize, setPageChange, setPageSize, totalRecords } = props;
  const pageSizeOptions = DEFAULT_PAGE_SIZES.map((val) => ({ text: val.toString(), value: val }));
  const totalPages: number = Math.ceil(totalRecords / pageSize);
  const goToPageOptions = numArray(totalPages).map((val) => ({ text: (val + ONE).toString(), value: val + ONE }));
  const aLotOfPages = totalPages > 3;

  const handlePageSizeChange = (value: SelectValue) => {
    setPageSize(value);
    setPageChange(ONE);
    navigate(ONE, value);
  };
  const handleGoToPageChange = (value: SelectValue) => {
    setPageChange(value);
    navigate(value, pageSize);
  };
  const handlePaginationChange = (_e: SyntheticEvent, { activePage }: PaginationProps) => {
    setPageChange(activePage);
    navigate(activePage, pageSize);
  };

  const Arrow = (isLeft: boolean) => (isLeft ? <Icon name='arrow left' /> : <Icon name='arrow right' />);
  const activeOne = activePage === ONE;
  const activeTotal = activePage === totalPages;
  const firstPageButton = { title: PZ.F, content: Arrow(!initFalsy), icon: !initFalsy, disabled: activeOne };
  const lastPageButton = { title: PZ.L, content: Arrow(initFalsy), icon: !initFalsy, disabled: activeTotal };
  const prevPageButton = { title: PZ.P, content: 'Prev', disabled: activeOne };
  const nextPageButton = { title: PZ.N, content: 'Next', disabled: activeTotal };
  const ellipsisButton = aLotOfPages ? { content: <Icon name='ellipsis horizontal' />, icon: !initFalsy } : null;

  const paginationProps = {
    activePage,
    className: 'float-right',
    ellipsisItem: ellipsisButton,
    firstItem: firstPageButton,
    lastItem: lastPageButton,
    nextItem: nextPageButton,
    onPageChange: handlePaginationChange,
    prevItem: prevPageButton,
    size: 'mini',
    totalPages,
  };
  const sizeSelectionProps = {
    className: 'ml15 minwauto',
    clearable: initFalsy,
    name: 'pageSize',
    onChange: handlePageSizeChange,
    options: pageSizeOptions,
    placeholder: 'Select Page Size',
    scrolling: !initFalsy,
    search: !initFalsy,
    selection: !initFalsy,
    title: 'Change Page Size',
    upward: !initFalsy,
    value: pageSize,
  };
  const goToPageProps = {
    className: 'ml15 minwauto',
    clearable: initFalsy,
    name: 'pageSize',
    onChange: handleGoToPageChange,
    options: goToPageOptions,
    placeholder: 'Go to Page',
    scrolling: !initFalsy,
    search: !initFalsy,
    selection: !initFalsy,
    title: 'Go to Page',
    upward: !initFalsy,
    value: activePage,
  };

  return (
    <Grid.Row>
      <Grid.Column mobile={16} tablet={16} computer={16} textAlign='right'>
        <Pagination {...paginationProps} />
        <div className='rowCount float-right mt15 ml10'>
          <Select {...sizeSelectionProps} />
        </div>
        {aLotOfPages && (
          <div className='rowCount float-right mt15 ml10'>
            <label className='mt10'>{sizeSelectionProps.title}</label>
          </div>
        )}
        {aLotOfPages && (
          <div className='rowCount float-right mt15 ml10'>
            <Select {...goToPageProps} />
          </div>
        )}
        {aLotOfPages && (
          <div className='rowCount float-right mt15'>
            <label className='mt10'>{goToPageProps.title}</label>
          </div>
        )}
      </Grid.Column>
    </Grid.Row>
  );
};

Paging.defaultProps = {
  activePage: ONE,
  pageSize: DEFAULT_RECORDS_PER_PAGE,
  totalRecords: 0,
};

Paging.propTypes = {
  activePage: PropTypes.number.isRequired,
  navigate: PropTypes.func.isRequired,
  pageSize: PropTypes.number.isRequired,
  setPageChange: PropTypes.func.isRequired,
  setPageSize: PropTypes.func.isRequired,
  totalRecords: PropTypes.number.isRequired,
};

export default Paging;

import React from 'react';
import { SUR as Surveyor } from '../../components/Surveyors';
import { initFalsy } from '../../constants';

const AllSurveyors = () => {
  return (
    <>
      <Surveyor isPM={!initFalsy} />
    </>
  );
};

export default AllSurveyors;

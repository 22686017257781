import { ActionType, ApiEngine } from '../../model';
import { flushAlert, setAlert, setSuccess } from './alert';
import { initFalsy } from '../../constants';

/**
 * Send Token on Forgot Password
 * @param email
 */
export const sendTokenPwdForgot = (email: string) => async (dispatch: Function, _store: Function, api: ApiEngine) => {
  try {
    const { message } = await api.post('account/password-forgot', { email });
    dispatch({ type: ActionType.FORGOT_PASSWORD, payload: {} });
    dispatch(setSuccess(message));
    return message;
  } catch (err) {
    dispatch(setAlert(err, initFalsy));
    dispatch(flushAlert());
    throw err;
  }
};

/**
 * Send Token on Forgot Password
 * @param token
 * @param isInvite [default is false]
 */
export const validateToken =
  (token: string, isInvite: boolean = initFalsy) =>
  async (dispatch: Function, _store: Function, api: ApiEngine) => {
    try {
      const validTokenInfo = await api.post('account/password-token-verify', { token, isInvite });
      dispatch({ type: ActionType.FORGOT_PASSWORD, payload: validTokenInfo });
      return validTokenInfo;
    } catch (err) {
      dispatch(setAlert(err, initFalsy));
      dispatch(flushAlert());
      throw err;
    }
  };

/**
 * Send Token on Forgot Password
 * @param payload {email, token, password}
 */
export const resetPwd =
  (payload: { email: string; token: string; password: string; isInvite?: boolean }) =>
  async (dispatch: Function, _store: Function, api: ApiEngine) => {
    try {
      const { message } = await api.post('account/password-reset', payload);
      dispatch({ type: ActionType.RESET_PASSWORD, payload: {} });
      dispatch(setSuccess(message));
      return message;
    } catch (err) {
      dispatch(setAlert(err, initFalsy));
      dispatch(flushAlert());
      throw err;
    }
  };

/**
 * Flush User Accounts
 */
export const flushAccounts = () => async (dispatch: Function) => {
  try {
    return dispatch({ type: ActionType.FLUSH_ACC_STORE, payload: {} });
  } catch (err) {
    dispatch({ type: ActionType.ALERT, payload: err });
  }
};

import { ActionType, ApiEngine, CleanResponse, Client, ClientsContacts, ClientsList } from '../../model';
import { setAlert, setSuccess } from './alert';
import { formatAsPhone } from '../../utils/validation';
import { initFalsy } from '../../constants';

const clientApiPath = 'clients';

/**
 * Flush Clients Store
 */
export const flushClients = () => async (dispatch: Function) => {
  try {
    return dispatch({ type: ActionType.ADMIN_CLIENTS_FLUSH, payload: null });
  } catch (err) {
    dispatch(setAlert(err));
  }
};

/**
 * Fetch Clients
 * @param search
 * @param skip
 * @param limit
 * @param orderBy
 * @param orderDir
 */
export const fetchClients =
  (search: string, skip: number, limit: number, orderBy: string, orderDir: string) =>
  async (dispatch: Function, _store: Function, api: ApiEngine) => {
    try {
      const query = { skip, limit, orderBy, orderDir, search, isClient: !initFalsy };
      const clients = await api.get(clientApiPath, null, query);

      return dispatch({ type: ActionType.ADMIN_PULL_CLIENTS, payload: clients });
    } catch (err) {
      dispatch(setAlert(err));
    }
  };

/**
 * Fetch Contacts List Search
 * @param id
 * @param search
 */
export const findContacts = (id: number, search: string) => (dispatch: Function, _store: Function, api: ApiEngine) => {
  try {
    const apiPath = `${clientApiPath}/contact/${id}`;
    const query = { search };
    return api.get(apiPath, null, query);
  } catch (err) {
    dispatch(setAlert(err));
  }
};

/**
 * Insert new Client with Contacts
 * @param payload
 */
export const addClient = (payload: Client) => async (dispatch: Function, _store: Function, api: ApiEngine) => {
  try {
    const clientRes: CleanResponse = await api.post(clientApiPath, payload);
    dispatch(setSuccess(clientRes.message));
    return dispatch({ type: ActionType.ADMIN_CLIENTS_ADD, payload: null });
  } catch (err) {
    dispatch(setAlert(err));
  }
};

/**
 * Flush Client Info Store
 */
export const flushClientInfo = () => async (dispatch: Function) => {
  try {
    return dispatch({ type: ActionType.ADMIN_CLIENT_INFO_FLUSH, payload: {} });
  } catch (err) {
    dispatch(setAlert(err));
  }
};

/**
 * Fetch By ClientID
 * @param id
 */
export const pullClientInfo = (id: number) => async (dispatch: Function, _store: Function, api: ApiEngine) => {
  try {
    if (!id) {
      return;
    }
    const clientInfoResp = await api.get(`${clientApiPath}/${id}`);
    const { contacts } = clientInfoResp;
    const contactsList = contacts.map((data: ClientsContacts) => {
      const { phoneNumber } = data;
      const phNumberFormat = formatAsPhone(phoneNumber);
      return { ...data, phoneNumber: phNumberFormat !== null ? phNumberFormat : phoneNumber };
    });
    const clientInfo = Object.assign(clientInfoResp, { contacts: contactsList });
    dispatch({ type: ActionType.ADMIN_CLIENT_INFO, payload: clientInfo });
    return clientInfo;
  } catch (err) {
    dispatch(setAlert(err));
  }
};

/**
 * Remove By ClientID
 * @param id
 */
export const removeClient =
  (id: number, isDeactivated?: boolean) => async (dispatch: Function, store: Function, api: ApiEngine) => {
    try {
      const response: CleanResponse = await api.delete(clientApiPath, { id, isDeactivated });
      const cl: ClientsList = store().clients;
      dispatch({ type: ActionType.ADMIN_DEL_CLIENT, payload: response });
      const fi = cl.rows && cl.rows.findIndex((i) => i.id === id);
      if (isDeactivated !== undefined) {
        const updDelDate = cl?.rows[fi];
        !!updDelDate &&
          (isDeactivated ? (updDelDate.deletedAt = new Date().toString()) : (updDelDate.deletedAt = null));
        dispatch({ type: ActionType.PULL_PROJECTS, payload: cl });
      } else {
        if (fi >= 0) {
          cl.rows.splice(fi, 1);
          cl.count--;
          dispatch({ type: ActionType.ADMIN_PULL_CLIENTS, payload: cl });
        }
      }

      dispatch(setSuccess(response.message));
    } catch (err) {
      dispatch(setAlert(err));
    }
  };

/**
 * Remove Client Contact By ID
 * @param contactId
 */
export const removeContact = (contactId: number) => async (dispatch: Function, _store: Function, api: ApiEngine) => {
  try {
    const response: CleanResponse = await api.delete(`${clientApiPath}/contact`, { id: contactId });
    dispatch({ type: ActionType.ADMIN_DEL_CLIENT_CONTACT, payload: response });
    dispatch(setSuccess(response.message));
  } catch (err) {
    dispatch(setAlert(err));
  }
};

/**
 * Update Client
 * @param payload
 */
export const updateClient =
  (payload: Partial<Client>) => async (dispatch: Function, _store: Function, api: ApiEngine) => {
    try {
      const response: CleanResponse = await api.put(clientApiPath, payload);
      dispatch({ type: ActionType.ADMIN_PUT_CLIENT, payload: response });
      dispatch(setSuccess(response.message));
      return payload;
    } catch (err) {
      dispatch(setAlert(err));
    }
  };

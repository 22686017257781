import * as React from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { Alerter, Notfound, PrvRt } from './components';
import { default as ROUTES } from './routes';
import { AppRouteProps, PrivateRouteProps, RouteCP, T } from './model';
import { filterAuthRoute } from './utils/common';
import { initFalsy } from './constants';

const AppRoutes = (props: AppRouteProps) => {
  const { auth } = props;
  const { authUrl, isAuthentic, authRole } = filterAuthRoute(auth);
  const defPvtRouteProps: PrivateRouteProps = {
    authRole,
    isAuthenticated: isAuthentic,
    authenticationPath: authUrl,
  };
  const renderPublic = (props: RouteCP, Component: React.FC<RouteCP>) => {
    const { authUrl, isAuthentic } = filterAuthRoute(auth);
    return isAuthentic ? <Redirect to={authUrl} /> : <Component {...props} />;
  };
  const withParent = (P: T, C: T) => (P ? <P>{React.createElement(C)}</P> : React.createElement(C));

  return (
    <BrowserRouter>
      <Alerter />
      <Switch>
        {ROUTES.map(({ allowAllRoles, component, exact, isPublic, parent, path, role }, key) =>
          isPublic ? (
            <Route exact={!initFalsy} key={key} path={path} render={(rp: RouteCP) => renderPublic(rp, component)} />
          ) : (
            <PrvRt
              {...defPvtRouteProps}
              allowAllRoles={allowAllRoles}
              exact={exact}
              key={key}
              routeRole={role as number}
              path={path}
              component={() => withParent(parent, component)}
            />
          ),
        )}
        <Route path='*'>
          <Notfound />
        </Route>
      </Switch>
    </BrowserRouter>
  );
};

const mapStateToProps = (state: AppRouteProps) => {
  return {
    // alert: state.alert,
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(AppRoutes);

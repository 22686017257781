import { Account, Action, ActionType } from '../../model';
import createReducer from './createReducer';

const initialAccounts: Partial<Account> = {};
export const accounts = createReducer(initialAccounts, {
  [ActionType.FORGOT_PASSWORD](_store: Function, action: Action<Account>) {
    return action.payload;
  },
  [ActionType.TOKEN_VERIFY](_store: Function, action: Action<Account>) {
    return action.payload;
  },
  [ActionType.RESET_PASSWORD](_store: Function, action: Action<Account>) {
    return action.payload;
  },
});

import React from 'react';
import { Link } from 'react-router-dom';
import { LoginBG } from './index';
import { Button, Form, FormFieldGroup, FormFieldType, Grid } from './Inputs';
import { hasError } from '../utils/validation';
import { initFalsy } from '../constants';
import { T } from '../model';

const LogIn: React.FC = (props: T) => {
  const { errors, handleChange, handleSubmit, isSubmitting, values } = props;
  const commonFPs = { autoComplete: 'off', onChange: handleChange };
  const loginForm = [
    {
      ...commonFPs,
      error: hasError(errors.email),
      name: 'email',
      placeholder: 'Email Address',
      type: FormFieldType.Email,
      value: values.email,
    },
    {
      ...commonFPs,
      error: hasError(errors.password),
      name: 'password',
      placeholder: 'Password',
      type: FormFieldType.Password,
      value: values.password,
    },
  ];
  const submitProps = {
    className: 'float-left',
    content: 'Login',
    disabled: isSubmitting,
    loading: isSubmitting,
    primary: !initFalsy,
    type: 'submit',
  };

  return (
    <Grid>
      <Grid.Row>
        <LoginBG />
        <Grid.Column mobile={16} tablet={16} computer={8}>
          <div className='loginForm text-center text-white'>
            <h2 className='mb-0 f24'>LOGIN</h2>
            <span className='w-100 f20 mb-2'>Hello! Let's Get Started.</span>
            <Form onSubmit={handleSubmit}>
              <Grid columns='equal'>
                <Grid.Row>
                  {loginForm.map((f, k) => (
                    <Grid.Column key={k} mobile={16}>
                      <FormFieldGroup fields={f} />
                    </Grid.Column>
                  ))}
                  <Grid.Column mobile={16}>
                    <Button {...submitProps} />
                    <Link to='/forgot-password' className='float-right forgotText'>
                      Forgot Password?
                    </Link>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          </div>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default LogIn;

import React from 'react';
import { Button, Card, Dropdown, FieldArray, Form, Grid, Select, TextBox, TextField } from '../../Inputs';
import { ButtonProps, Client, FieldArrayRenderProps, FieldProps, FormProps, SelectValue, T } from '../../../model';
import { hasError } from '../../../utils/validation';
import { enumAsAO, va } from '../../../utils/arrayUtils';
import { vo } from '../../../utils/objectUtils';
import { addClientsForm, CLIENT_CONTACT_TYPE, initFalsy, STATE } from '../../../constants';
import { stringify } from '../../../utils/stringUtils';

interface ExtraProps {
  action: { id: number; value: string; deletedAt?: string | null };
  clientInfo?: Client;
  doEdit: Function;
  onCancel?: Function;
  onContactRemoval?: Function;
}

interface AddFormProps extends FormProps {
  extraProps: ExtraProps;
}

const AddClient = (formProps: AddFormProps) => {
  const { errors, handleChange, setFieldValue, handleSubmit, isSubmitting, values, extraProps } = formProps;
  const { action, clientInfo, doEdit, onCancel, onContactRemoval }: ExtraProps = extraProps;
  const { value, deletedAt } = action;
  const isViewOnly = value === 'view';
  const contactTypes = enumAsAO(CLIENT_CONTACT_TYPE);
  const [stateId, setState]: [T, Function] = React.useState(clientInfo ? clientInfo.stateId : null);
  const { clients, contacts } = addClientsForm;
  let formTitle = 'Create New Client';
  let submitButtonCaption = 'Add Client';
  switch (value) {
    case 'edit':
      formTitle = 'Edit Client Details';
      submitButtonCaption = 'Save';
      break;
    case 'view':
      formTitle = 'View Client Details';
      submitButtonCaption = 'Add Project';
      break;
  }
  const addFormJSON = clients.map((field: FieldProps) => ({
    ...field,
    autoComplete: 'off',
    disabled: isViewOnly,
    error: hasError(errors[field.name]),
    onChange: handleChange,
    value: values[field.name],
  }));
  const submitButton: ButtonProps = {
    content: submitButtonCaption,
    className: 'ml10 mb15',
    loading: isSubmitting,
    primary: !initFalsy,
    type: 'submit',
  };
  const cancelButton: ButtonProps = {
    content: 'Cancel',
    onClick: onCancel,
    className: 'mb15',
    secondary: !initFalsy,
  };
  const cirIBProps: ButtonProps = {
    className: 'float-right mt-5',
    circular: !initFalsy,
    type: 'button',
    compact: !initFalsy,
  };
  const addFormButtons = deletedAt ? [cancelButton] : [cancelButton, submitButton];

  const callEdit = () => {
    return clientInfo ? doEdit(clientInfo.id) : null;
  };
  const selectState = (value: SelectValue) => {
    setState(value);
    if (typeof handleChange === 'function') {
      setFieldValue('stateId', value);
    }
  };
  const renderAddContactsErr = (errorKey: string, i: number, key: string) => {
    const faErrFields: T = errors[errorKey];

    if (!va(faErrFields)) {
      return initFalsy;
    }

    return vo(faErrFields[i]) ? faErrFields[i][key] : '';
  };
  const renderAddContacts = (fps: FieldArrayRenderProps) => (
    <>
      {va(values.contacts) &&
        values.contacts.map((_c: T, i: number) => {
          const removeFux = () => {
            if (_c.id && _c.clientId && typeof onContactRemoval === 'function') {
              onContactRemoval(_c.id);
            }
            fps.remove(i);
          };
          return (
            <React.Fragment key={i}>
              <Grid.Column mobile={16} tablet={16} computer={16}>
                <h3 className='w100 subHeaderInner'>
                  <span>{CLIENT_CONTACT_TYPE[_c.typeId]}</span>
                  {i !== 0 && !isViewOnly && (
                    <Button {...cirIBProps} color='red' icon='trash alternate' onClick={() => removeFux()} />
                  )}
                </h3>
              </Grid.Column>
              {contacts.map((a, ix) => {
                const name = `contacts.${i}.${a.name}`;
                const error = renderAddContactsErr('contacts', i, a.name);
                const tfProps: T = { ...a, name, autoComplete: 'off', error, disabled: isViewOnly };
                return (
                  <Grid.Column key={ix} mobile={16} tablet={16} computer={8}>
                    <TextField {...tfProps} />
                  </Grid.Column>
                );
              })}
            </React.Fragment>
          );
        })}
      <Grid.Column mobile={16} tablet={16} computer={16}>
        {!isViewOnly && (
          <h3 className='w100 subHeaderInner'>
            <span>Add Contact</span>
            <Button primary={!initFalsy} {...cirIBProps}>
              <Dropdown icon='plus' compact={!initFalsy}>
                <Dropdown.Menu className='contactMenu'>
                  {contactTypes.map((opt, ix) => (
                    <Dropdown.Item content={opt.text} key={ix} onClick={() => fps.push({ typeId: opt.value })} />
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </Button>
          </h3>
        )}
      </Grid.Column>
    </>
  );

  const stateSelectProps = {
    disabled: isViewOnly,
    hasFirstEmptyOpt: !initFalsy,
    label: 'Select State',
    onChange: selectState,
    options: enumAsAO(STATE),
    placeholder: 'Select State',
    scrolling: !initFalsy,
    search: !initFalsy,
    selection: !initFalsy,
    value: stateId,
  };

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column mobile={16} tablet={16} computer={10}>
          <Card fluid={!initFalsy} className='mb20'>
            <Card.Header>
              <h2 className='subTitle'>{formTitle}</h2>
              {isViewOnly && !deletedAt && (
                <Button className='asAnchor float-right' content='Edit' icon='edit' onClick={callEdit} />
              )}
            </Card.Header>
            <Card.Content className='pb0'>
              <Form onSubmit={handleSubmit}>
                <Grid>
                  <Grid.Row>
                    <Grid.Column mobile={16} tablet={16} computer={16}>
                      <h3 className='w100 subHeaderInner'>
                        <span>Client Details</span>
                      </h3>
                    </Grid.Column>

                    {addFormJSON.map((field: FieldProps, key: number) => {
                      const asSelect = field.name === 'stateId';
                      return (
                        <Grid.Column key={key} mobile={16} tablet={16} computer={8}>
                          {asSelect && <Select name='stateId' {...stateSelectProps} />}
                          {!asSelect && <TextBox {...field} />}
                        </Grid.Column>
                      );
                    })}

                    <FieldArray name='contacts' render={renderAddContacts} />

                    <Grid.Column width={16} textAlign='right'>
                      {addFormButtons.map((buttonProps: ButtonProps, key: number) => (
                        <Button key={key} {...buttonProps} />
                      ))}
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Form>
            </Card.Content>
          </Card>
        </Grid.Column>
        <Grid.Column mobile={16} tablet={16} computer={8}>
          <pre className='invisible'>{stringify({ ...{}, values })}</pre>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default AddClient;

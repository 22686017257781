import React from 'react';
import { Button, Card, ChkBox, Form, FormFieldGroup, FormFieldType, Grid } from '../../Inputs';
import { hasError } from '../../../utils/validation';
import { DEFAULT_VALUE, initFalsy } from '../../../constants';
import { T } from '../../../model';

const VerifyOtpForm = (props: T) => {
  const {
    chkBoxes,
    errors,
    handleChange,
    handleSubmit,
    isSubmitting,
    isShowResendBtn,
    loading,
    minutes,
    resSendOtp,
    seconds,
    values,
  } = props;
  const commonFPs = { autoComplete: 'off', onChange: handleChange };
  const otpVerificationForm = [
    {
      ...commonFPs,
      error: hasError(errors.otp),
      name: 'otp',
      placeholder: 'Enter Your 6 Digit',
      type: FormFieldType.Number,
      value: values.otp,
    },
  ];
  const submitProps = {
    className: 'float-left',
    content: 'Verify',
    disabled: isSubmitting,
    loading: loading,
    primary: !initFalsy,
    type: 'submit',
  };
  const resendButtProps = {
    className: `float-right resend-otp ${isShowResendBtn ? '' : 'invisible'}`,
    content: 'Resend Verification Code',
    onClick: resSendOtp,
    disabled: !isShowResendBtn,
  };

  return (
    <>
      <Card>
        <Grid>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={16} computer={16}>
              <div className='loginForm text-center text-white'>
                <h2 className='mb-0 f24'>Enter The Verification Code</h2>
                <span>
                  <h5>
                    Your code will expire in:&nbsp;
                    {!(minutes === DEFAULT_VALUE && seconds === DEFAULT_VALUE) && (
                      <>
                        {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                      </>
                    )}
                  </h5>
                </span>
                <Form onSubmit={handleSubmit}>
                  <Grid columns='equal'>
                    <Grid.Row>
                      {otpVerificationForm.map((f, k) => (
                        <Grid.Column key={k} mobile={16}>
                          <FormFieldGroup fields={f} />
                        </Grid.Column>
                      ))}
                      <Grid.Column mobile={16}>
                        <Button {...submitProps} />
                        <Button {...resendButtProps} />
                      </Grid.Column>
                      <Grid.Column mobile={16}>
                        {chkBoxes?.map((chkProps: T, _idx: number) => (
                          <Form.Field className='ui form text-left' key={_idx}>
                            <ChkBox {...chkProps} />
                          </Form.Field>
                        ))}
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Form>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Card>
    </>
  );
};

export default VerifyOtpForm;

import React, { SyntheticEvent } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Loader } from '../../components';
import { List } from '../../components/Generic';
import { BtnSwitch, Grid, ProgressGoals, Search } from '../../components/Inputs';
import { ButtonProps, Project, SearchProps, ST, StoreT, T } from '../../model';
import { useActions } from '../../redux/actions';
import * as assignmentsActions from '../../redux/actions/assignments';
import * as projectActions from '../../redux/actions/projects';
import { DEFAULT_RECORDS_PER_PAGE, GoalsHeader, initFalsy, PROJECT_STATUS, ROLES } from '../../constants';
import { Path } from '../../routes';
import { va } from '../../utils/arrayUtils';
import { getSkipLimit, joinAsFullName, isValidSearch } from '../../utils/common';
import { usDateFormat } from '../../utils/dateUtils';
import { sleep } from '../../utils/objectUtils';

const PA = () => {
  const initPageNo = 1;
  const hist = useHistory();
  const assignA = useActions(assignmentsActions);
  const projectA = useActions(projectActions);
  const user = useSelector((state: StoreT) => state.auth.user);
  const sortKeys = ['name', '', '', '', 'statusId', 'timezone', 'managerId'];
  const [isWeeklySelected, setWeekly] = React.useState(!initFalsy);
  const [limit, setPageSize] = React.useState(DEFAULT_RECORDS_PER_PAGE);
  const [activePage, setPageChange] = React.useState(initPageNo);
  const [loader, isLoading] = React.useState(initFalsy);
  const [searchVal, onSearch]: [T, Function] = React.useState('');
  const gHeader = isWeeklySelected
    ? `${GoalsHeader.Weekly} / ${GoalsHeader.WeeklyTotal}`
    : `${GoalsHeader.RP} / ${GoalsHeader.RPTotal}`;
  const headers = ['Project Name', 'Started On', 'Project Deadline', gHeader, 'Status', 'Timezone', 'Project Manager'];
  const onSelect = async (p: Partial<Project>) => {
    isLoading(!initFalsy);
    const projectId = p.id;
    await projectA.getProjectInfo(projectId);
    await assignA.fetchAssignments(projectId);
    isLoading(initFalsy);
    hist.push(`${ROLES['CIMR Manager'] !== user.role ? Path.OPSAssignments : Path.CIMRAssignments}/${projectId}`);
  };
  const onChangeType = (isWeekly: boolean) => {
    const skip = getSkipLimit(activePage, limit);
    fetchProjects(skip, limit, sortKeys[0], 'ASC', isWeekly).then();
    setWeekly(isWeekly);
  };
  const fetchProjects = async (
    skip = 0,
    lim = DEFAULT_RECORDS_PER_PAGE,
    sortOn = 'name',
    sortBy = 'ASC',
    isWeekly = !initFalsy,
  ) => {
    isLoading(!initFalsy);
    await projectA.fetchProjects(
      skip,
      lim,
      sortOn,
      sortBy,
      undefined,
      undefined,
      initFalsy,
      isWeekly,
      undefined,
      searchVal,
    );
    isLoading(initFalsy);
  };
  const navFux = (activePage: number, gridSize: number) => {
    const skip = getSkipLimit(activePage, gridSize);
    fetchProjects(skip, gridSize).then();
  };
  const onSort = (cc: string, direction: ST) => {
    const skip = getSkipLimit(activePage, limit);
    fetchProjects(skip, limit, cc, direction).then();
  };
  const firstRunEffect = () => {
    const skip = getSkipLimit(activePage, limit);
    fetchProjects(skip, limit).then();

    return () => projectA.flushProjects();
  };
  const reportButtProps: ButtonProps[] = [
    { active: isWeeklySelected === !initFalsy, content: 'Current Week', onClick: () => onChangeType(!initFalsy) },
    { as: 'div', className: 'or', key: 'or' },
    { active: isWeeklySelected === initFalsy, content: 'Reporting Period', onClick: () => onChangeType(initFalsy) },
  ];
  const { rows, count } = useSelector((state: StoreT) => state.projects) || {};
  const projects = va(rows)
    ? rows.map((project) => {
        const { deadline, id, isLocked, name, startDate, statusId, timezone, user } = project;
        const deadlineCST = usDateFormat(deadline);
        const startedOn = usDateFormat(startDate as string);
        return {
          _id: id,
          _lock: isLocked,
          name,
          deadlineCST,
          startedOn,
          progress: (
            <ProgressGoals compGoals={project.compGoals} reqGoals={project.reqGoals} surveysRP={project.surveysRP} />
          ),
          status: PROJECT_STATUS[statusId],
          timezone,
          managerId: joinAsFullName(user?.firstName, user?.lastName),
        };
      })
    : [];
  const gridProps = { headers, list: projects, onSelect, onSort, sortKeys };
  const pgProps = { activePage, navigate: navFux, pageSize: limit, setPageChange, setPageSize, totalRecords: count };
  const onSearchChange = (_e: SyntheticEvent, data: SearchProps) => {
    if (isValidSearch(data.value as string, loader)) {
      return sleep(0);
    }

    onSearch(data.value);
  };
  const searchProps = {
    loading: loader,
    onSearchChange: onSearchChange,
    placeholder: 'Search Project...',
    showNoResults: initFalsy,
  };
  React.useEffect(firstRunEffect, [searchVal]);

  return (
    <>
      {loader && <Loader />}

      <Grid>
        <Grid.Row className='headerTop'>
          <Grid.Column width={16}>
            <h1 className='mainTitle'>Project Assignments</h1>
            <BtnSwitch buttonProps={reportButtProps} />
            <Search {...searchProps} />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={16}>
            <List gridProps={gridProps} pgProps={pgProps} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};

export default PA;

import * as React from 'react';
import { useSelector } from 'react-redux';
import { Footer, Header, LeftMenu, Wrapper } from '../../components';
import { NAV } from '../../constants';
import { RC, StoreT } from '../../model';
import { ParentPath } from '../../routes';
import { useActions } from '../../redux/actions';
import * as appActions from '../../redux/actions/nav';
import * as authActions from '../../redux/actions/auth';

const OPDashboard: React.FC = (props: RC) => {
  const { isExpanded } = useSelector((state: StoreT) => state.nav),
    { SetLeftNav, GetLeftNav } = useActions(appActions),
    { Load } = useActions(authActions),
    styles: React.CSSProperties = {
      position: 'relative',
      overflow: 'auto',
      transition: 'all .15s',
      padding: '88px 20px 0',
      marginLeft: `${isExpanded ? 300 : 75}px`,
    },
    toggleFunc = () => SetLeftNav(isExpanded),
    pageLifeCycle = () => {
      GetLeftNav();
      Load();
    };

  React.useEffect(pageLifeCycle, [isExpanded]);

  return (
    <Wrapper>
      <Header expanded={isExpanded} />

      <LeftMenu navItems={NAV.OPS} parentLink={ParentPath.OPS} expanded={isExpanded} onToggle={toggleFunc} />

      <main className='print-it' style={styles}>
        {props && props.children}
      </main>

      <Footer />
    </Wrapper>
  );
};

export default OPDashboard;

import React from 'react';
import { connect, useSelector } from 'react-redux';
import {
  Account,
  CallLog,
  CallLogList,
  CLUPayload,
  FormikValues,
  IconProps,
  MiniAccount,
  ProjectsList,
  ST,
  StoreT,
  T,
} from '../../model';
import { Button, Card, FormWrap, GenModelProps, Grid, GridView, Icon, Model, Paging } from '../Inputs';
import { Loader } from '../index';
import { CLR, Filters } from './index';
import {
  CST_TIMEZONE,
  DEFAULT_RECORDS_PER_PAGE,
  initFalsy,
  ROLES,
  SurCallResponse,
  SurveyStatus,
} from '../../constants';
import { useActions } from '../../redux/actions';
import * as callActions from '../../redux/actions/calls';
import * as projectActions from '../../redux/actions/projects';
import * as userAccActions from '../../redux/actions/users';
import { va } from '../../utils/arrayUtils';
import { getSkipLimit, joinAsFullName } from '../../utils/common';
import { startFrom, toEnd, usDateTime } from '../../utils/dateUtils';
import { vo } from '../../utils/objectUtils';
import { Path } from '../../routes';

interface CLDProps {
  callLogs: CallLogList;
  isManager?: boolean;
  isOPS?: boolean;
  projects: ProjectsList;
  surveyors: Array<MiniAccount>;
  user: Account;
}

type FU = (
  skip?: number,
  lim?: number,
  sortOn?: string | undefined,
  sortBy?: ST,
  surveyorId?: number,
  searchPhone?: string,
  responseType?: number,
  startDate?: string,
  endDate?: string,
  projectId?: number,
  isComplete?: boolean,
) => Promise<T>;

type Filter = {
  startDate?: string;
  endDate?: string;
  isComplete: boolean;
  projectId?: number;
  surveyorId?: number;
  searchPhone?: string;
  responseType?: number;
};

const CallLogGrid = (props: CLDProps) => {
  const initPageNo = 1;
  const { callLogs, user, isOPS } = props;
  const surveyCompText = 'Survey Complete';
  const callLogGridHeaders = [
    'Call Date/Time',
    'Survey Date/Time',
    'Cell Phone',
    'Home Phone',
    'Call Status',
    'Survey Status',
    'Project',
    'Survey Language',
    'View Survey',
  ];
  const sortKeys = [
    'surveyedOn',
    'surveyCompletedOn',
    'phoneCell',
    'phoneHome',
    'result',
    'isPartial',
    'project.name',
    'surveyLanguage.name',
    '',
  ];
  const initSort: { column: string; direction: ST } = { column: 'surveyedOn', direction: 'DESC' };
  const initFilter: Filter = { startDate: '', endDate: '', isComplete: initFalsy, searchPhone: '' };
  const cat = useActions(callActions);
  const pro = useActions(projectActions);
  const userAction = useActions(userAccActions);
  const callInfo: CallLog = useSelector((state: StoreT) => state.callLog);
  const [exporting, setExporting] = React.useState(initFalsy);
  const [loading, setLoading] = React.useState(initFalsy);
  const [editing, setEditing] = React.useState(initFalsy);
  const [limit, setPageSize] = React.useState(DEFAULT_RECORDS_PER_PAGE);
  const [activePage, setPageChange] = React.useState(initPageNo);
  const [sortVars, setSortVars] = React.useState(initSort);
  const [filter, setFilter] = React.useState(initFilter);
  const onAction = async (id: number) => {
    setLoading(!initFalsy);
    setEditing(!initFalsy);
    await cat.fetchCallLogInfo(id);
    setLoading(initFalsy);
  };
  const fromDate = startFrom(isOPS);
  const onCallCSVExport = async (values: T) => {
    const { endDate, isComplete, projectId, responseType, searchPhone, startDate, surveyorId } = values;
    const exactEndDate = endDate ? toEnd(endDate) : '';
    const isOPS = !!props.isOPS;
    const isManager = !isOPS ? !!props.isManager : initFalsy;
    const svId =
      user.role === ROLES.Surveyor || user.role === ROLES['CIMR Surveyor'] || user.role === ROLES['Keller Surveyor']
        ? user.id
        : surveyorId;
    setExporting(!initFalsy);
    await cat.fetchClLogCSV(
      isManager,
      !isOPS ? user.id : '',
      svId,
      searchPhone,
      startDate,
      exactEndDate,
      projectId,
      responseType,
      isComplete,
    );
    setExporting(initFalsy);
  };
  const onResponseCancel = async () => {
    await cat.flushCallLogInfo();
    setEditing(initFalsy);
  };
  const onReset = async () => {
    await onResponseCancel();
    setFilter(initFilter);
    setPageChange(initPageNo);
  };
  const onSubmit = async (values: FormikValues, { resetForm }: FormikValues) => {
    try {
      setSortVars(initSort);
      const { endDate, isComplete, projectId, responseType, surveyorId, searchPhone, startDate } = values;

      setFilter(values as Filter);

      await fetchCallLog(
        0,
        limit,
        undefined,
        undefined,
        surveyorId,
        searchPhone,
        responseType,
        startDate,
        endDate,
        projectId,
        isComplete,
      );
    } catch (err) {
      resetForm();
    }
  };
  const fetchCallLog: FU = async (
    skip = 0,
    limit = DEFAULT_RECORDS_PER_PAGE,
    sortOn = initSort.column,
    sortBy = initSort.direction,
    surveyorId,
    searchPhone,
    responseType,
    startDate,
    endDate,
    projectId,
    isComplete,
  ) => {
    setLoading(!initFalsy);
    const exactEndDate = endDate ? toEnd(endDate) : '';
    const isOPS = !!props.isOPS;
    const isManager = !isOPS ? !!props.isManager : initFalsy;
    const svId =
      user.role === ROLES.Surveyor || user.role === ROLES['CIMR Surveyor'] || user.role === ROLES['Keller Surveyor']
        ? user.id
        : surveyorId;
    const type = user.role === ROLES['CIMR Manager'] ? ROLES['CIMR Surveyor'] : undefined;
    await cat.fetchClLog(
      skip,
      limit,
      sortOn,
      sortBy,
      isManager,
      !isOPS ? user.id : '',
      svId,
      searchPhone,
      responseType,
      startDate ? startDate : fromDate,
      exactEndDate,
      projectId,
      isComplete,
      type,
    );
    setLoading(initFalsy);
  };
  const navFux = async (activePage: number, gridSize: number) => {
    const { endDate, isComplete, projectId, responseType, surveyorId, searchPhone, startDate } = filter;
    const skip = getSkipLimit(activePage, gridSize);

    await fetchCallLog(
      skip,
      gridSize,
      sortVars.column,
      sortVars.direction,
      surveyorId,
      searchPhone,
      responseType,
      startDate,
      endDate,
      projectId,
      isComplete,
    );
  };
  const onUpdate = async (updCallLogPayload: CLUPayload) => {
    await cat.putSurCallLog(updCallLogPayload);
    const { endDate, isComplete, projectId, responseType, surveyorId, searchPhone, startDate } = filter;
    const skip = getSkipLimit(activePage, limit);

    await fetchCallLog(
      skip,
      limit,
      sortVars.column,
      sortVars.direction,
      surveyorId,
      searchPhone,
      responseType,
      startDate,
      endDate,
      projectId,
      isComplete,
    );
    setEditing(initFalsy);
  };
  const onSort = async (column: string, direction: ST) => {
    const { surveyorId, searchPhone, responseType, startDate, endDate, projectId, isComplete } = filter;
    const skip = getSkipLimit(activePage, limit);

    setSortVars({ column, direction });
    await fetchCallLog(
      skip,
      limit,
      column,
      direction,
      surveyorId,
      searchPhone,
      responseType,
      startDate,
      endDate,
      projectId,
      isComplete,
    );
  };
  const lyfFstEvent = () => {
    const [projectSortOn, projectSortBy] = ['name', 'ASC'];
    const fetchProjects = props.isManager
      ? pro.fetchProjects(
          0,
          Number.MAX_SAFE_INTEGER,
          projectSortOn,
          projectSortBy,
          undefined,
          isOPS ? undefined : user.id,
          !initFalsy,
        )
      : pro.fetchSurveyorProjects(0, Number.MAX_SAFE_INTEGER, projectSortOn, projectSortBy, user.id);
    const type = user.role === ROLES['CIMR Manager'] ? ROLES['CIMR Surveyor'] : undefined;
    const fetchSurveyors = userAction.fetchSvInfo(!isOPS ? user.id : undefined, type);
    fetchCallLog().then(fetchProjects).then(fetchSurveyors);

    return () => {
      pro.flushProjects();
      cat.flushCallLogs();
      userAction.flushSurveyUserList();
    };
  };
  const actionProps: IconProps = { name: 'eye', title: 'View Details' };
  const qsLinkButtProps = (id: number) => {
    const qsUrl = `${Path.QAASSvGuidelines}/${id}`;
    return {
      as: 'a',
      className: 'asAnchor',
      href: qsUrl,
      icon: 'external alternate',
      onClick: (_e: React.SyntheticEvent) => {
        _e.preventDefault();
        window.open(qsUrl);
      },
    };
  };
  const pdfLinkButtProps = (id: number) => {
    //const path:string = (user.role === ROLES.Surveyor || user.role === ROLES['CIMR Surveyor']) ?  `${Path.SurveyorCallLog}`: `${Path.QACallLog}`
    const qsUrl = `${Path.QACallLog}-download/${id}`;
    return {
      as: 'a',
      className: 'asAnchor',
      href: qsUrl,
      icon: 'print',
      onClick: (_e: React.SyntheticEvent) => {
        _e.preventDefault();
        window.open(qsUrl);
      },
    };
  };
  const logs = va(callLogs.rows)
    ? callLogs.rows.map((log: CallLog) => {
        const {
          hasSurveys,
          id,
          isPartial,
          phoneCell,
          phoneHome,
          project,
          result,
          surveyLanguage,
          surveyedOn,
          surveyCompletedOn,
          user,
        } = log;
        const dnd = SurCallResponse['Do Not Call'];
        const dndClass = dnd === result ? 'red' : '';
        const surveyor = props.isManager ? { surveyor: joinAsFullName(user?.firstName, user?.lastName) } : {};
        const resultText = result === SurCallResponse['Agreed to Survey'] ? surveyCompText : SurCallResponse[result];
        const surveyStatus = isPartial
          ? !!hasSurveys
            ? SurveyStatus[SurveyStatus.Partial]
            : ''
          : SurveyStatus[SurveyStatus.Completed];

        return {
          ...surveyor,
          dt: usDateTime(surveyedOn, CST_TIMEZONE),
          sdt: surveyCompletedOn && usDateTime(surveyCompletedOn, CST_TIMEZONE),
          phoneCell,
          phoneHome,
          callResult: <div className={dndClass}>{resultText}</div>,
          isPartial: surveyStatus,
          project: project?.name,
          language: surveyLanguage.name,
          action: (
            <>
              <Icon className='hand-pointer' onClick={() => onAction(id as number)} {...actionProps} />
              {props.user.role === ROLES.Quality && (
                <React.Fragment>
                  <Button {...qsLinkButtProps(project.id as number)} />
                  <Button {...pdfLinkButtProps(id as number)} />
                </React.Fragment>
              )}
            </>
          ),
        };
      })
    : [];
  const CLRProps = {
    callInfo,
    onCancel: onResponseCancel,
    onUpdate,
    users: user,
  };
  const modelContent = (
    <>
      {vo(callInfo) ? (
        <CLR {...CLRProps} />
      ) : (
        <div style={{ height: '100px' }}>
          <Loader />
        </div>
      )}
    </>
  );
  const filtersFormConfig = {
    children: Filters,
    displayName: 'call-log-filter',
    exporting,
    initialValues: {
      startDate: fromDate,
      endDate: '',
      projectId: '',
      searchPhone: '',
      isComplete: initFalsy,
      surveyorId: '',
      responseType: '',
    },
    isManager: props.isManager,
    onReset,
    onSubmit,
    onCallCSVExport,
    projects: props.projects,
    surveyors: props.surveyors,
  };
  const gridProps = {
    headers: callLogGridHeaders,
    list: logs,
    onSort,
    sortKeys: sortKeys,
  };
  const modelProps: GenModelProps = {
    centered: !initFalsy,
    closeOnDimmerClick: initFalsy,
    closeOnEscape: initFalsy,
    content: modelContent,
    hasClose: initFalsy,
    initialize: editing,
    onCancel: () => {
      setEditing(initFalsy);
    },
    scrolling: !initFalsy,
    size: 'fullscreen',
  };
  const pagingProps = {
    totalRecords: callLogs.count,
    navigate: navFux,
    activePage: activePage,
    pageSize: limit,
    setPageSize,
    setPageChange,
  };

  if (props.isManager) {
    callLogGridHeaders.unshift('Surveyor');
    sortKeys.unshift('');
  }

  React.useEffect(lyfFstEvent, []);

  return (
    <Grid>
      {loading && <Loader />}

      <Grid.Row className='headerTop'>
        <Grid.Column width={16}>
          <h1 className='mainTitle'>Call Log</h1>
        </Grid.Column>
      </Grid.Row>

      {<FormWrap {...filtersFormConfig} />}

      {editing && <Model {...modelProps} />}

      <Grid.Row>
        <Grid.Column width={16}>
          <Card fluid={!initFalsy}>
            <Card.Content>
              <GridView {...gridProps} />
              <Paging {...pagingProps} />
            </Card.Content>
          </Card>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

const mp = (store: StoreT) => ({
  callLogs: store.callLogs,
  projects: store.projects,
  surveyors: store.surveyorInfo,
  user: store.auth.user,
});

export default connect(mp)(CallLogGrid);

import React from 'react';
import { AccountAddForm as AddForm } from '../Forms';
import { FormWrap } from '../Inputs';
import { useActions } from '../../redux/actions';
import * as userAccActions from '../../redux/actions/users';
import { validate } from '../../utils/validation';
import { initFalsy, ROLES, ROLES as UserRoles } from '../../constants';
import { FormikValues, SelectValue, T } from '../../model';
import * as languagesActions from '../../redux/actions/languages';

type AddAccount = {
  onCancel: Function;
  userRoles: Array<string | number>;
};
type V = SelectValue;
type RoleValue = T | keyof typeof UserRoles; // as TS7015: indexing Enum as 'number' gives unexpected error.

const AddAccount: React.FC<AddAccount> = (props: AddAccount) => {
  const { userRoles, onCancel } = props,
    [curRole, setRole]: [V, Function] = React.useState('Role'),
    role: RoleValue = UserRoles[curRole],
    userActions = useActions(userAccActions),
    langAction = useActions(languagesActions),
    defaultReqFields = ['firstName', 'lastName', 'email'],
    requiredFields =
      role === ROLES.Surveyor || role === ROLES['CIMR Surveyor'] || role === ROLES['Keller Surveyor']
        ? defaultReqFields.concat(['cid', 'langIds'])
        : defaultReqFields,
    addUserFormConfig = {
      displayName: 'addUserForm',
      initialValues: { firstName: '', lastName: '', email: '', phoneNumber: '', vonageExt: '', cid: '' },
      onSubmit: async (values: FormikValues, { resetForm }: FormikValues) => {
        try {
          const formValues = { ...values, role };
          const userUpdateResult = await userActions.addUser(formValues);
          if (userUpdateResult !== 400) {
            onCancel();
          }
        } catch (err) {
          resetForm();
        }
      },
      children: AddForm,
      validate: (values: FormikValues) => validate(requiredFields, values),
      extraProps: { onCancel, setRole, role, curRole, userRoles },
    },
    lyf = () => {
      langAction.fetchLanguages(undefined, undefined, undefined, undefined, initFalsy);
      return () => langAction.flushLanguages();
    };

  React.useEffect(lyf, []);
  return <FormWrap {...addUserFormConfig} />;
};

export default AddAccount;

import createReducer from './createReducer';
import { Action, ActionType, Answer } from '../../model';

const initAns: Answer[] = [];
export const answers = createReducer(initAns, {
  [ActionType.ANSWERS_FETCH](_store: Function, action: Action<Answer[]>) {
    return action.payload;
  },

  [ActionType.ANSWERS_FLUSH](_store: Function, action: Action<void>) {
    return action.payload;
  },
});

import { useHistory } from 'react-router-dom';
import * as React from 'react';
import { checkIfProfilePath } from '../utils/common';
import * as pkg from '../../package.json';

const Footer: React.FC = () => {
  const hist = useHistory();
  const loc = hist.location;
  const isProfilePath = checkIfProfilePath(loc.pathname);
  return (
    <footer className='float-right'>
      {isProfilePath && (
        <p className='mb-0'>
          {pkg.description} Version@{pkg.version}
        </p>
      )}
    </footer>
  );
};

export default Footer;

import React from 'react';
import { useParams } from 'react-router';
import { connect } from 'react-redux';
import { QATree, TreeProps } from './index';
import { MiniLoader, PreBlock } from '../index';
import { CallResponse } from '../Calls';
import { Button, Card, ChkBox, Divider, Form, FormWrap, Grid, Icon, Select } from '../Inputs';
import {
  BN,
  ChkProps,
  FormikValues,
  IconProps,
  Language as LangMod,
  QsLogic,
  QuestionnaireItem,
  SelectValue,
  StoreT,
  Survey,
  T,
} from '../../model';
import { useActions } from '../../redux/actions';
import * as callActions from '../../redux/actions/calls';
import * as languagesActions from '../../redux/actions/languages';
import * as navActions from '../../redux/actions/nav';
import * as questionnaireActions from '../../redux/actions/questionnaire';
import { AnswerType, initFalsy, Language, NA, QSF } from '../../constants';
import { diff, va } from '../../utils/arrayUtils';
import { isFunction } from '../../utils/common';
import { piz } from '../../utils/numUtils';
import { validate as validateRequiredFields } from '../../utils/validation';
import { removeObjValue } from '../../utils/objectUtils';
import { stringify, uuid } from '../../utils/stringUtils';

type QuestionnaireProps = {
  addLogicScroll?: boolean;
  agreedToSurvey?: boolean;
  allowScroll?: boolean;
  callId?: number;
  callResponseProps?: T;
  captureResponse?: () => Promise<void>;
  chkBoxes?: ChkProps[];
  isDeleted?: boolean;
  isSvProject?: boolean;
  languageId?: number;
  languages: Array<LangMod>;
  loading?: boolean;
  logic: QsLogic[];
  onAddComment?: Function;
  pipeItems?: { label: string; value: T }[];
  projectId?: number;
  ps?: boolean;
  qar?: boolean;
  questionnaire: QuestionnaireItem[];
  setLanguage?: Function;
  surveyCallId?: number;
  surveyLangId?: number;
  surveyorId?: number;
  surveyResult?: BN;
  surveys?: Survey[];
};

const Questionnaire: React.FC<QuestionnaireProps> = (props) => {
  const initTruthy = !initFalsy;
  const initialArr: T = [];
  const initialValues: T = {};
  const listScrollProps: T = {
    style: { height: '530px', overflowY: 'auto', overflowX: 'hidden', scrollBehavior: 'smooth' },
  };
  const {
    addLogicScroll,
    agreedToSurvey,
    allowScroll,
    callId,
    callResponseProps,
    captureResponse,
    chkBoxes,
    isDeleted,
    isSvProject,
    languageId,
    languages,
    logic,
    onAddComment,
    pipeItems,
    ps,
    projectId, // via Call Log
    // qar,
    questionnaire,
    surveyCallId,
    surveyorId,
    surveys,
    surveyResult,
    surveyLangId = Language.English,
    setLanguage,
  } = props;
  const { id } = useParams();
  const lan = useActions(languagesActions);
  const nav = useActions(navActions);
  const qre = useActions(questionnaireActions);
  const cal = useActions(callActions);
  const [loading, setLoading] = React.useState(!initTruthy);
  const [langLoading, setLangLoading] = React.useState(!initTruthy);
  const [items, loadQuestionnaire] = React.useState(initialArr as Partial<QuestionnaireItem>[]);
  const [itemsToRemove, setItemsToRemove] = React.useState(initialArr as number[]);
  const [requiredFields, setRequiredFields] = React.useState(initialArr as string[]);
  const [over, onOver] = React.useState(!initTruthy as BN);
  const [isDebug, setIsDebug] = React.useState(!initTruthy);
  const [scrollProps, setScrollProps] = React.useState(listScrollProps);
  const langOpts = languages.map((l) => ({ text: l.name, value: l.id }));
  const requiredTypes = [AnswerType.Single, AnswerType.Multiple];

  const fetchQ = async (languageId?: number) => {
    const pid = projectId || id;
    return isDeleted
      ? qre.fetchQsNLogic(pid, undefined, isDeleted, isSvProject, languageId)
      : qre.fetchQsNLogic(pid, undefined, undefined, undefined, languageId);
  };
  const reload = async (languageId?: number, firstRun?: boolean) => {
    return fetchQ(languageId)
      .then((data) => {
        loadQuestionnaire(data as T);
        if (firstRun && va(data)) {
          const firstSequenceItem = data[0];
          const firstSequence = firstSequenceItem.sequence;
          onOver(firstSequence);
        }
      })
      .finally(() => setLoading(!initTruthy));
  };
  const firstRun = () => {
    setLoading(initTruthy);
    reload(languageId, initTruthy)
      .then(() => {
        if (languageId) {
          setLanguage && isFunction(setLanguage) && setLanguage(languageId);
        }

        if (surveyorId) {
          lan.fetchSurveyorLanguages(surveyorId);
        } else {
          lan.fetchLanguages(undefined, undefined, undefined, undefined, !initTruthy);
        }
      })
      .finally(() => setLoading(!initTruthy));

    return () => {
      qre.flushQuestionnaire();
      lan.flushLanguages();
    };
  };
  const filterIVs = (i: Survey) => {
    const { sequence, ansId, openEnded } = i;
    const fn = `${QSF}${sequence}`;
    if (Object.keys(initialValues).includes(fn)) {
      const fnVal = initialValues[fn];
      const isAr = Array.isArray(fnVal);
      const fVal = isAr ? fnVal : [fnVal];
      fVal.push(ansId || openEnded);
      Object.assign(initialValues, { [fn]: fVal });
    } else {
      Object.assign(initialValues, { [fn]: ansId || openEnded });
    }
  };
  const onScrollToDiv = async (sequence: number) => {
    const elemId = `qs-tree-row-${sequence}`;
    const topPage: HTMLElement = document.getElementById(elemId) as HTMLElement;
    if (topPage) {
      const optns: T = { behavior: 'smooth', block: 'center', inline: 'nearest' };
      topPage.scrollIntoView(optns);
    }
  };
  const validate = (values: FormikValues) => {
    const newValues = removeObjValue(values, NA);
    const requiredSeq: Partial<QuestionnaireItem>[] = va(items)
      ? items.filter((i) => requiredTypes.includes(i.type as number) && !itemsToRemove.includes(i.sequence as number))
      : [];
    const requiredFields: string[] = requiredSeq.map((i) => `${QSF}${i.sequence}`);
    const vErrs = validateRequiredFields(requiredFields, newValues);
    setRequiredFields(Object.keys(vErrs).map((v) => `Q${v?.split(QSF)[1]}`));
    return ps === initFalsy ? vErrs : {};
  };
  const onSubmit = async (values: FormikValues, { resetForm }: FormikValues) => {
    try {
      setLoading(initTruthy);
      const fieldName = QSF;
      const isUpdateAllowed = !!projectId;
      const _uuid = uuid(50);
      const uniqueId = va(surveys) ? (surveys && surveys[0].uniqueId ? surveys[0].uniqueId : _uuid) : _uuid;

      // converting form values to survey_objects
      let _tmpPayload = Object.keys(values).map((v) => {
        const sequence = piz(v.split(fieldName)[1]);
        const q = (questionnaire.find((q) => q.sequence === sequence) || {}) as QuestionnaireItem;
        return { ansId: values[v], quesId: q.quesId as number, sequence };
      });

      // marking logically hidden questions as n/a
      itemsToRemove.forEach((sequence) => {
        const q = (questionnaire.find((q) => q.sequence === sequence) || {}) as QuestionnaireItem;
        if (requiredTypes.includes(q.type as number)) {
          const hiddenQues = _tmpPayload.filter((i) => i.quesId === q.quesId);
          _tmpPayload = diff(_tmpPayload, hiddenQues);
          _tmpPayload.push({ ansId: NA, quesId: q.quesId as number, sequence });
        }
      });

      // final payload_object
      let payload: Partial<Survey>[] = initialArr;

      // parsing single_multiple_open_ended_answers
      _tmpPayload.forEach((a) => {
        const isOpenEnded = typeof a.ansId === 'string';
        const projectId = piz(id);
        if (Array.isArray(a.ansId)) {
          const sd = a.ansId.filter((i) => i !== NA).map((ansId: T) => ({ ...a, uniqueId, ansId, callId, projectId }));
          payload = payload.concat(sd);
        } else {
          const openEnded = isOpenEnded ? a.ansId : null;
          const ansId = isOpenEnded ? null : a.ansId;
          payload.push({ ...a, uniqueId, ansId, callId, openEnded, projectId });
        }
      });

      // if partially surveyed all un-answered marked as n/a
      if (props.ps) {
        questionnaire
          .filter((iq) => requiredTypes.includes(iq.type as number))
          .forEach(({ projectId, quesId, sequence }) => {
            const isAnswered = payload.find((p) => p.sequence === sequence);
            if (!isAnswered) {
              payload.push({ uniqueId, ansId: undefined, callId, openEnded: NA, projectId, quesId, sequence });
            }
          });
      }

      // allowing updating surveys from call_log
      if (callId && projectId) {
        payload = payload.map((p) => {
          const { ansId, quesId, openEnded, sequence }: Partial<Survey> = p;
          const isOE = !!openEnded;
          const sur = (surveys as Survey[]).find((i) => {
            return (
              (isOE ? i.openEnded === openEnded : i.ansId === ansId) && i.quesId === quesId && i.sequence === sequence
            );
          });
          if (!!ansId && (ansId as number).toString() === NA) {
            Object.assign(p, { ansId: null, openEnded: NA });
          }
          return { ...p, uniqueId, callId: surveyCallId, projectId, id: sur?.id };
        });
      }
      // submit
      await cal.saveCallSurvey(payload, isUpdateAllowed);
      await (isFunction(captureResponse) && captureResponse && captureResponse());
      setLoading(!initTruthy);
      resetForm();
    } catch (e) {
      setLoading(!initTruthy);
      resetForm();
    }
  };
  const onPrint = async () => {
    setScrollProps({});
    await nav.SetLeftNav(!initFalsy);
    window.print();
    setScrollProps(listScrollProps);
  };
  const onLanguageChange = async (value: SelectValue) => {
    setLangLoading(initTruthy);
    await reload(value);
    setLangLoading(!initTruthy);
    return setLanguage && isFunction(setLanguage) && setLanguage(value);
  };
  const hasQuestionnaire = va(items);
  // const isCallSurvey = !!callId;
  const isCallSurvey = true;
  const printIconProps: IconProps = {
    className: 'hand-pointer',
    name: 'print',
    onClick: onPrint,
    size: 'large',
  };
  const selProps = {
    className: 'float-right',
    clearable: !initTruthy,
    loading: langLoading,
    name: 'langId',
    onChange: onLanguageChange,
    options: langOpts,
    placeholder: 'Select Languages',
    value: surveyLangId,
  };
  const subButtProps = {
    className: 'float-right',
    content: !!projectId ? 'Save' : 'Submit Survey',
    primary: initTruthy,
  };
  const formProps = {
    allowScroll,
    displayName: 'qv_frm',
    initialValues,
    isCallSurvey,
    items,
    itemsToRemove,
    langId: surveyLangId,
    logic,
    onAddComment,
    onOver,
    onScrollToDiv,
    onSubmit,
    over,
    pipeItems,
    setItemsToRemove,
    surveys,
    validateOnMount: initTruthy,
    validate,
  };

  surveys?.forEach(filterIVs);

  if (!(va(langOpts) && langOpts.findIndex((i) => i.value === Language.English) > -1)) {
    langOpts.push({ text: Language[Language.English], value: Language.English });
  }

  React.useEffect(firstRun, initialArr);

  return (
    <Grid.Row className={addLogicScroll ? 'questionnaireList qs-v' : 'qs-v'} id='qs-v'>
      <Grid.Column computer={16} mobile={16} tablet={16}>
        <Card fluid={initTruthy}>
          <Card.Header className='no-print'>
            <Grid>
              <Grid.Row>
                <Grid.Column width={15}>
                  <h2 className='subTitle'>Questionnaire</h2>
                </Grid.Column>
                {!addLogicScroll && (
                  <Grid.Column width={1}>
                    <Icon {...printIconProps} />
                  </Grid.Column>
                )}
              </Grid.Row>
            </Grid>
          </Card.Header>
          <Card.Content className='no-print'>
            <Grid>
              <Grid.Row>
                <Grid.Column width={16}>
                  <div className='float-right' style={{ width: '200px' }}>
                    <Select {...selProps} />
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Card.Content>
          <Card.Content>
            <FormWrap {...formProps}>
              {(props: TreeProps) => {
                const isDisabled =
                  (!(props.isValid || ps === initTruthy) && agreedToSurvey === initTruthy) || props.isSubmitting;
                const onMouseOver = async () => {
                  if (props && props.validateForm) {
                    await props.validateForm();
                  }
                };
                const debugChk: ChkProps = {
                  checked: isDebug,
                  label: 'SHOW QUESTIONS THAT ARE REQUIRED',
                  name: 'qDebug',
                  onChange: () => setIsDebug(!isDebug),
                };
                return (
                  <Form onSubmit={props.handleSubmit} onMouseOver={onMouseOver}>
                    <Grid>
                      {callId && !surveyResult && (
                        <Grid.Row className='no-print'>
                          <Grid.Column width={16}>
                            <CallResponse {...callResponseProps} />
                          </Grid.Column>
                        </Grid.Row>
                      )}
                      {loading && (
                        <Grid.Row className='no-print' style={{ height: '200px' }}>
                          <Grid.Column width={16}>
                            <MiniLoader content='Loading Questionnaire...' />
                          </Grid.Column>
                        </Grid.Row>
                      )}
                      {!loading && (
                        <Grid.Row className={isCallSurvey && agreedToSurvey ? 'qs-tree' : ''}>
                          <Grid.Column width={16}>
                            <QATree {...props} scrollProps={scrollProps} />
                            <Divider />
                          </Grid.Column>
                        </Grid.Row>
                      )}
                      {!loading && callId && !hasQuestionnaire && (
                        <Grid.Row className='no-print'>
                          <Grid.Column className='text-center' width={16}>
                            No questionnaire found...Please try later or try choosing English...
                          </Grid.Column>
                        </Grid.Row>
                      )}
                      {!langLoading && hasQuestionnaire && agreedToSurvey === initTruthy && (
                        <Grid.Row className='no-print mt20'>
                          <hr className='mb10' />
                          {chkBoxes?.map((chkProps, idx) => (
                            <Grid.Column key={idx} width={3}>
                              <Form.Field className='ui form'>
                                <ChkBox {...chkProps} />
                              </Form.Field>
                            </Grid.Column>
                          ))}
                          <Grid.Column width={4}>
                            <Button {...subButtProps} disabled={isDisabled} loading={props.isSubmitting} />
                          </Grid.Column>
                          {!!projectId && !ps && (
                            <Grid.Column width={16}>
                              <Form.Field className='ui form'>
                                <ChkBox {...debugChk} />
                              </Form.Field>

                              {isDebug && <PreBlock>{stringify({ requiredFields, surveyLangId })}</PreBlock>}
                            </Grid.Column>
                          )}
                        </Grid.Row>
                      )}
                    </Grid>
                  </Form>
                );
              }}
            </FormWrap>
          </Card.Content>
        </Card>
      </Grid.Column>
    </Grid.Row>
  );
};

const MapProps = (state: StoreT) => {
  return {
    languages: state.languages,
    logic: state.questionnaireLogic,
    questionnaire: state.questionnaire,
  };
};

export default connect(MapProps)(Questionnaire);

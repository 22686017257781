import React from 'react';
import { T } from '../../model';

export interface CardProps {
  id: string;
  content?: string;
  moveCard: (id: string, to: number) => void;
  findCard: (id: string) => { index: number };
  onComplete?: Function;
}

export interface ContainerProps {
  items: ItemProps[];
  onComplete?: Function;
}

export interface ItemProps {
  id: number;
  content?: T;
}

export const ItemTypes = {
  CARD: 'card',
  QUESTION: 'question',
  RESPONSE: 'response',
};

export type REF = React.RefObject<T> | React.ReactElement<T, string | React.JSXElementConstructor<T>> | Element | null;

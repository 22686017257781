import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { useActions } from '../../redux/actions';
import { getSkipLimit, joinAsFullName } from '../../utils/common';
import { CST_TIMEZONE, DEFAULT_RECORDS_PER_PAGE, initFalsy } from '../../constants';
import * as escalationActions from '../../redux/actions/escalations';
import * as projectActions from '../../redux/actions/projects';
import * as userAction from '../../redux/actions/users';
import { Path } from '../../routes';
import { Filters } from './index';
import { Loader } from '../index';
import { Card, FormWrap, Grid, GridView, Icon, IconProps, Paging } from '../Inputs';
import {
  Account,
  Escalation,
  EscalationList,
  FormikValues,
  MiniAccount,
  ProjectsList,
  ST,
  StoreT,
  T,
} from '../../model';
import { va } from '../../utils/arrayUtils';
import { startFrom, toEnd, usDateTime } from '../../utils/dateUtils';

interface CLDProps {
  escalations: EscalationList;
  isOPS?: boolean;
  projects: ProjectsList;
  surveyors: Array<MiniAccount>;
  user: Account;
}

type GetEsc = (
  skip?: number,
  lim?: number,
  sortOn?: string,
  sortBy?: ST,
  projectId?: number,
  surveyorId?: number,
  startDate?: string,
  endDate?: string,
  status?: boolean,
) => Promise<T>;

type Filter = {
  startDate?: string;
  endDate?: string;
  status: boolean;
  projectId?: number;
  surveyorId?: number;
};

enum EResolution {
  Resolved = 'Resolved',
  Unresolved = 'Unresolved',
}

const PMEscalations = (props: CLDProps) => {
  const initPageNo = 1;
  const hist = useHistory();
  const esc = useActions(escalationActions);
  const pro = useActions(projectActions);
  const usa = useActions(userAction);
  const { surveyors, isOPS, escalations, projects, user } = props;
  const fromDate = startFrom(isOPS);
  const initFilter: Filter = { startDate: '', endDate: '', status: initFalsy };
  const initSort: { column: string; direction: ST } = { column: 'surveyedOn', direction: 'DESC' };
  const [activePage, setPageChange] = React.useState(initPageNo);
  const [loading, setLoading] = React.useState(initFalsy);
  const [filter, setFilter] = React.useState(initFilter);
  const [limit, setPageSize] = React.useState(DEFAULT_RECORDS_PER_PAGE);
  const [sortVars, setSortVars] = React.useState(initSort);
  const escalationHeaders = ['Date/Time', 'Status', 'Project Name', 'Surveyor Name', 'Escalation Notes'];
  const sortKeys = ['surveyedOn', 'status', 'projectId', 'surveyorId'];
  const fetchEscalation: GetEsc = async (
    skip = 0,
    limit = DEFAULT_RECORDS_PER_PAGE,
    sortOn = 'surveyedOn',
    sortBy = 'DESC',
    projectId,
    surveyorId,
    startDate,
    endDate,
    resolveStatus,
  ) => {
    setLoading(!initFalsy);
    const status = !!resolveStatus ? resolveStatus : initFalsy;
    const exactEndDate = endDate ? toEnd(endDate) : '';
    await esc.fetchAllEscalation(
      skip,
      limit,
      sortOn,
      sortBy,
      surveyorId,
      startDate ? startDate : fromDate,
      exactEndDate,
      projectId,
      status,
      isOPS ? undefined : user.id,
    );
    setLoading(initFalsy);
  };
  const navFux = (activePage: number, gridSize: number) => {
    const { endDate, status, projectId, surveyorId, startDate } = filter,
      skip = getSkipLimit(activePage, gridSize);

    fetchEscalation(
      skip,
      gridSize,
      sortVars.column,
      sortVars.direction,
      projectId,
      surveyorId,
      startDate,
      endDate,
      status,
    ).then();
  };
  const onView = async (id: number) => {
    const path = isOPS ? `${Path.OPSEscalationsDetail}/${id}` : `${Path.PMEscalationsDetail}/${id}`;
    hist.push(path, id);
  };
  const onSort = (column: string, direction: ST) => {
    const { surveyorId, startDate, endDate, projectId, status } = filter,
      skip = getSkipLimit(activePage, limit);

    setSortVars({ column, direction });
    fetchEscalation(skip, limit, column, direction, projectId, surveyorId, startDate, endDate, status).then();
  };
  const lyfFstEvent = () => {
    fetchEscalation().then(() => {
      const [sortOn, sortBy] = ['name', 'ASC'];
      const fetchProjects = pro.fetchProjects(
        0,
        Number.MAX_SAFE_INTEGER,
        sortOn,
        sortBy,
        undefined,
        isOPS ? undefined : user.id,
        !initFalsy,
      );
      const getSurveyors = usa.fetchSvInfo(isOPS ? undefined : user.id);
      const proms = [fetchProjects, getSurveyors];

      return Promise.all(proms);
    });

    return () => {
      esc.flushEscalations();
      pro.flushProjects();
      usa.flushSurveyor();
    };
  };
  const actionProps: IconProps = { name: 'eye', title: 'View Details' };
  const list = va(escalations.rows)
    ? escalations.rows.map(({ id, log, project, status, surveyor }: Escalation) => {
        return {
          date: usDateTime(log.surveyedOn, CST_TIMEZONE),
          status: status ? EResolution.Resolved : EResolution.Unresolved,
          project: project?.name,
          surveyorName: joinAsFullName(surveyor.firstName, surveyor.lastName),
          action: (
            <>
              <Icon className='hand-pointer' onClick={() => onView(id as number)} {...actionProps} />
            </>
          ),
        };
      })
    : [];
  const gridProps = {
    headers: escalationHeaders,
    list,
    onSort,
    sortKeys,
  };
  const pagingProps = {
    activePage,
    navigate: navFux,
    pageSize: limit,
    setPageChange,
    setPageSize,
    totalRecords: escalations.count,
  };
  const filtersFormConfig = {
    children: Filters,
    displayName: 'escalations-filter',
    initialValues: { startDate: fromDate, endDate: '', status: initFalsy, projectId: '', surveyorId: '' },
    onSubmit: async (values: FormikValues, { resetForm }: FormikValues) => {
      try {
        setSortVars(initSort);
        const { endDate, status, projectId, surveyorId, startDate } = values;
        setFilter(values as Filter);
        const skip = getSkipLimit(activePage, limit);
        await fetchEscalation(
          skip,
          limit,
          sortVars.column,
          sortVars.direction,
          projectId,
          surveyorId,
          startDate,
          endDate,
          status,
        );
      } catch (err) {
        resetForm();
      }
    },
    projects,
    surveyors,
  };

  React.useEffect(lyfFstEvent, []);

  return (
    <Grid>
      {loading && <Loader />}

      <Grid.Row className='headerTop'>
        <Grid.Column width={16}>
          <h1 className='mainTitle'>Escalations</h1>
        </Grid.Column>
      </Grid.Row>

      {<FormWrap {...filtersFormConfig} />}

      <Grid.Row>
        <Grid.Column width={16}>
          <Card fluid={!initFalsy}>
            <Card.Content>
              <GridView {...gridProps} />
              <Paging {...pagingProps} />
            </Card.Content>
          </Card>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

const mp = (store: StoreT) => ({
  escalations: store.escalations,
  projects: store.projects,
  surveyors: store.surveyorInfo,
  user: store.auth.user,
});

export default connect(mp)(PMEscalations);

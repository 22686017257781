import React, { SyntheticEvent } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Path } from '../../routes';
import { Grid, Icon, ProgressGoals, Search } from '../Inputs';
import { List } from '../Generic';
import { Loader } from '../index';
import { IconProps, SearchProps, ST, StoreT, T } from '../../model';
import { CST_TIMEZONE, initFalsy } from '../../constants';
import { useActions } from '../../redux/actions';
import * as commentActions from '../../redux/actions/comments';
import { va } from '../../utils/arrayUtils';
import { getSkipLimit, isValidSearch } from '../../utils/common';
import { usDateTime } from '../../utils/dateUtils';
import { sleep } from '../../utils/objectUtils';

type Props = { isManager?: boolean; isOPS?: boolean };

const CCProjects: React.FC<Props> = ({ isManager, isOPS }) => {
  const initPageNo = 1;
  const initCommentsGridSize = 5;
  const [limit, setPageSize] = React.useState(initCommentsGridSize);
  const [activePage, setPageChange] = React.useState(initPageNo);
  const [loader, isLoading] = React.useState(initFalsy);
  const [searchVal, onSearch]: [T, Function] = React.useState('');
  const sortKeys = ['name', '', 'oldestUncleanedSurveyedOn'];
  const headers = ['Project Name', 'Cleaning Progress', 'Oldest Uncleaned', 'Clean Comments'];
  const { rows, count } = useSelector((state: StoreT) => state.projects) || {};
  const { id } = useSelector((state: StoreT) => state.auth.user);
  const hist = useHistory();
  const _commentAction = useActions(commentActions);
  const fetchProjects = async (skip = 0, lim = initCommentsGridSize, sortOn = sortKeys[0], sortBy = 'ASC') => {
    isLoading(!initFalsy);
    await _commentAction.fetchCommentProjects(skip, lim, sortOn, sortBy, isManager ? id : undefined, searchVal);
    isLoading(initFalsy);
  };
  const onSort = (cc: string, direction: ST) => {
    const skip = getSkipLimit(activePage, limit);
    fetchProjects(skip, limit, cc, direction).then();
  };
  const navigate = (activePage: number, gridSize: number) => {
    const skip = getSkipLimit(activePage, gridSize);
    fetchProjects(skip, gridSize).then();
  };
  const firstRun = () => {
    const skip = getSkipLimit(activePage, limit);
    fetchProjects(skip, limit, sortKeys[0]).then();

    return () => _commentAction.flushCommentProjects();
  };
  const onSearchChange = (_e: SyntheticEvent, data: SearchProps) => {
    if (isValidSearch(data.value as string, loader)) {
      return sleep(0);
    }

    onSearch(data.value);
  };
  const searchProps = {
    loading: loader,
    onSearchChange: onSearchChange,
    placeholder: 'Search Project...',
    showNoResults: initFalsy,
  };
  const goCC = (projectId: number) => {
    const [path, state] = [isOPS ? Path.OPcc : isManager ? Path.PMcc : Path.CCCC, { projectId }];
    hist.push(path, state);
  };
  const projectAction: IconProps = { className: 'hand-pointer', name: 'comments', title: 'Comment Cleaning' };
  const projectsList = va(rows)
    ? rows.map((p: T) => {
        const { cleaned, isLocked, oldestUncleanedSurveyedOn, name, total } = p;
        return {
          _id: p.id,
          _lock: isLocked,
          name,
          cp: <ProgressGoals compGoals={cleaned} reqGoals={total} />,
          // srp: uncleanedOpportunity,
          oun: oldestUncleanedSurveyedOn ? usDateTime(oldestUncleanedSurveyedOn, CST_TIMEZONE) : '-',
          Action: <Icon onClick={() => goCC(p.id)} {...projectAction} />,
        };
      })
    : [];
  const gridProps = {
    headers,
    list: projectsList,
    onSort: onSort,
    sortKeys,
  };
  const pgProps = {
    activePage,
    navigate,
    pageSize: limit,
    setPageChange: setPageChange,
    setPageSize: setPageSize,
    totalRecords: count,
  };

  React.useEffect(firstRun, [searchVal]);

  return (
    <>
      {loader && <Loader />}

      <Grid>
        <Grid.Row className='headerTop'>
          <Grid.Column width={16}>
            <h1 className='mainTitle'>Comment Cleaning Selection</h1>
            <Search {...searchProps} />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={16}>
            <List gridProps={gridProps} pgProps={pgProps} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};

export default CCProjects;

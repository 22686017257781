import React from 'react';
import { Grid } from './Inputs';
import LoginBGImg from '../styles/images/loginImg.png';

const LoginBG: React.FC = () => {
  return (
    <Grid.Column mobile={16} tablet={16} computer={8}>
      <img className='loginImg img-fluid' src={LoginBGImg} alt='cr-login-background' />
    </Grid.Column>
  );
};

export default LoginBG;

import React from 'react';
import Calendar from 'react-calendar';
import { Form, Icon, Popup } from './index';
import { DatePickerProps } from '../../model';
import { piz } from '../../utils/numUtils';
import { pez } from '../../utils/stringUtils';
import 'react-calendar/dist/Calendar.css';
import { initFalsy } from '../../constants';
import { isFunction } from '../../utils/common';

const dateParser = (value: string | Date, separator = '/') => {
  if (!value) {
    return {};
  }
  const newDate = new Date();
  const isDate = value && typeof value === 'string' && value.length > 10;
  const d = isDate ? new Date(value) : newDate;
  const splits = !isDate ? (value as string).split(separator) : [];
  const mnth = !isDate ? piz(splits[0]) - 1 : d.getMonth();
  const date = !isDate ? piz(splits[1]) || 1 : d.getDate();
  const year = !isDate ? piz(pez(splits[2], newDate.getFullYear().toString())) : d.getFullYear();
  const localDate = isDate ? d.toUSDateString() : null;
  const month = mnth === -1 ? 1 : mnth;

  return { date, month, year, localDate };
};
const DatePicker = (props: DatePickerProps) => {
  const { label, onClear, placeholder, setValue, value, ...custom } = props;
  const { date, month, year, localDate } = dateParser(value);
  const dt = (value && new Date(year as number, month as number, date as number)) || null;
  const [disable, setDisabled]: [boolean, Function] = React.useState(initFalsy);
  const className = custom.className ? `${custom.className} w100` : 'w100';
  const iconProps: {} = {
    icon: 'calendar alternate outline',
    iconPosition: 'left',
  };
  const isSVF = !!setValue && isFunction(setValue);
  const isCF = !!onClear && isFunction(onClear);

  const handleChange = (dt: Date | Date[]) => {
    if (isSVF) {
      setDisabled(!initFalsy);
      setValue((dt as Date).toISOString());
    }
  };

  const handleClear = () => {
    if (isSVF && isCF) {
      setValue('');
      (onClear as Function)();
    }
  };
  const handlePopup = () => {
    setDisabled(initFalsy);
  };

  const Inp = (
    <Form.Input
      {...custom}
      {...iconProps}
      autoComplete='off'
      className={className}
      label={label}
      onClick={handlePopup}
      placeholder={placeholder || label}
      value={!!value ? localDate : ''}
    />
  );
  const Cal = <Calendar value={dt as Date} onChange={handleChange} />;
  return (
    <div className='datePicker w100'>
      {!!dt && isCF && <Icon className='datePickerCloseIcon' name='close' onClick={() => handleClear()} />}
      <Popup disabled={disable} on='focus' trigger={Inp} content={Cal} />
    </div>
  );
};

export default DatePicker;

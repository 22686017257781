import { Account, ActionType, ApiEngine, CleanResponse, T, UsersList } from '../../model';
import { setAlert, setSuccess } from './alert';
import { Del, initFalsy, ROLES as UserRoles } from '../../constants';
import { downloadAsCSV, jsonToCsv } from '../../utils/common';
import { isBool } from '../../utils/boolUtils';

const apiPath = 'users';
const SKLT = 0;
const ST_EMP = '';

/**
 * Flush User Accounts
 */
export const flushUserAccounts = () => async (dispatch: Function) => {
  try {
    return dispatch({ type: ActionType.ADMIN_PULL_USERS_FLUSH, payload: [] });
  } catch (err) {
    dispatch(setAlert(err));
  }
};

export const flushUserInfo = () => async (dispatch: Function) => {
  try {
    return dispatch({ type: ActionType.USER_INFO_FLUSH, payload: {} });
  } catch (err) {
    dispatch(setAlert(err));
  }
};

const _fetchUsers =
  (
    skip: number,
    limit: number,
    orderBy: string,
    orderDir: string,
    role: number | number[],
    search: string,
    paginated: boolean = !initFalsy,
    isDeleted: boolean = initFalsy,
    status?: boolean,
  ) =>
  async (_dispatch: Function, _store: Function, api: ApiEngine) => {
    const query = { isDeleted, limit, orderBy, orderDir, paginated, role, search, skip, status };
    return api.get(apiPath, null, query);
  };

/**
 * Fetch User Accounts
 * @param skip
 * @param limit
 * @param orderBy
 * @param orderDir
 * @param role
 * @param search
 * @param paginated
 * @param isDeleted
 * @param status
 */
export const fetchUserAccounts =
  (
    skip: number,
    limit: number,
    orderBy: string,
    orderDir: string,
    role: number,
    search: string,
    paginated: boolean = !initFalsy,
    isDeleted: boolean = initFalsy,
    status?: boolean,
  ) =>
  async (dispatch: Function, _store: Function, _api: ApiEngine) => {
    try {
      dispatch({ type: ActionType.ADMIN_PULL_USERS_BEGIN, payload: {} });
      const userAccounts = await dispatch(
        _fetchUsers(skip, limit, orderBy, orderDir, role, search, paginated, isDeleted, status),
      );
      dispatch({ type: ActionType.ADMIN_PULL_USERS_SUCCESS, payload: userAccounts });
      return userAccounts;
    } catch (err) {
      dispatch(setAlert(err));
    }
  };

/**
 * Fetch Users
 * @param role
 * @param search
 */
export const fetchUsers = (role: number | number[], search: string) => async (dispatch: Function) => {
  const users = await dispatch(_fetchUsers(SKLT, SKLT, 'firstName', ST_EMP, role, search, initFalsy));
  dispatch({ type: ActionType.ADMIN_PULL_USERS_SUCCESS, payload: users });
};

/**
 * Insert User Account
 * @param payload
 */
export const addUser = (payload: Account) => async (dispatch: Function, _store: Function, api: ApiEngine) => {
  try {
    const userRes: CleanResponse = await api.post(apiPath, payload);
    dispatch({ type: ActionType.ADMIN_ADD_USER, payload: userRes });
    dispatch(setSuccess(userRes.message));
    return;
  } catch (err) {
    dispatch(setAlert(err));
    return err.statusCode;
  }
};

/**
 * Fetch By User Id
 * @param id
 */
export const pullUserInfo = (id: number) => async (dispatch: Function, _store: Function, api: ApiEngine) => {
  try {
    if (!id) {
      return;
    }
    const userInfo = await api.get(`${apiPath}/${id}`);
    dispatch({ type: ActionType.ADMIN_USER_INFO, payload: userInfo });
    return userInfo;
  } catch (err) {
    dispatch(setAlert(err));
  }
};

/**
 * Remove User By Id
 * @param id
 */
export const removeUser = (id: number) => async (dispatch: Function, store: Function, api: ApiEngine) => {
  try {
    const response: CleanResponse = await api.delete(apiPath, { id });
    const users: UsersList = store().users;
    dispatch({ type: ActionType.ADMIN_DEL_USER, payload: response });
    const fi = users.rows && users.rows.findIndex((i) => i.id === id);
    if (fi >= 0) {
      users.rows.splice(fi, 1);
      users.count--;
      dispatch({ type: ActionType.ADMIN_PULL_USERS_SUCCESS, payload: users });
    }

    dispatch(setSuccess(response.message));
  } catch (err) {
    dispatch(setAlert(err));
  }
};

/**
 * Update User Account
 * @param payload
 */
export const updateAccount =
  (payload: Partial<Account>) => async (dispatch: Function, _store: Function, api: ApiEngine) => {
    try {
      const { languages, ...data } = payload;
      const response: CleanResponse = await api.put(apiPath, data);
      dispatch({ type: ActionType.ADMIN_PUT_USER, payload: response });
      dispatch(setSuccess(response.message));
    } catch (err) {
      dispatch(setAlert(err));
      return err.statusCode;
    }
  };

/**
 * Re-invite
 * @param id
 */
export const reInvite = (id: number) => async (dispatch: Function, _store: Function, api: ApiEngine) => {
  try {
    const res: CleanResponse = await api.post(`${apiPath}/invite`, { id });

    dispatch(setSuccess(res.message));
    return dispatch({ type: ActionType.ADMIN_RE_INVITE, payload: {} });
  } catch (err) {
    dispatch(setAlert(err));
  }
};

/**
 * Fetch Survey UserList By Manager Id
 * @param skip
 * @param limit
 * @param orderBy
 * @param orderDir
 * @param id
 * @param isWeekly
 * @param type
 * @param search
 */
export const getSurveyUserList =
  (
    skip: number,
    limit: number,
    orderBy: string,
    orderDir: string,
    id: number | undefined,
    isWeekly: boolean,
    type: number,
    search: string,
  ) =>
  async (dispatch: Function, _store: Function, api: ApiEngine) => {
    try {
      const q = { skip, limit, orderBy, orderDir, isWeekly, managerId: id, search, type };
      const users = await api.get(`${apiPath}/surveyors`, null, q);
      dispatch({ type: ActionType.PM_SURVEYORS_FETCH, payload: users });
      return users;
    } catch (e) {
      dispatch(setAlert(e));
    }
  };

/**
 * Flush Survey UserList
 */
export const flushSurveyUserList = () => async (dispatch: Function) => {
  return dispatch(flushSurveyor());
};

/**
 * Get Account Management Data For Create CSV File
 * @param role
 * @param search
 * @param userType
 */
export const exportAccCSV = (role: number, search: string, userType: null | boolean) => async (dispatch: Function) => {
  try {
    const status: T = isBool(userType) !== initFalsy ? userType : undefined;
    const accounts = await dispatch(
      _fetchUsers(SKLT, SKLT, ST_EMP, ST_EMP, role, search, initFalsy, initFalsy, status),
    );
    const fileTitle = `${apiPath}-${new Date().valueOf()}`;
    const headers = [
      'FirstName',
      'LastName',
      'Email',
      'Role',
      'Contact Number',
      'Vonage Extension',
      'Contractor ID',
      'Status',
    ];
    const processASCSV = (json: T[]) => {
      return json.map((s) => {
        const { cid, email, firstName, isDeleted, lastName, phoneNumber, role, vonageExt } = s;
        const roleVal = UserRoles[role];

        return {
          [headers[0]]: firstName,
          [headers[1]]: lastName,
          [headers[2]]: email,
          [headers[3]]: roleVal,
          [headers[4]]: phoneNumber,
          [headers[5]]: vonageExt,
          [headers[6]]: cid,
          [headers[7]]: isDeleted ? Del.DE : Del.AC,
        };
      });
    };
    const formattedRoster = processASCSV(accounts);
    const csv = jsonToCsv(formattedRoster);

    downloadAsCSV(fileTitle, csv);

    return dispatch({ type: ActionType.ACC_CSV_EXPORT });
  } catch (err) {
    dispatch(setAlert(err));
  }
};

/**
 * Deactivate user
 * @returns Message
 */
export const deactivateUser =
  (payload: { id: number; status: boolean }) => async (dispatch: Function, _store: Function, api: ApiEngine) => {
    try {
      const response: CleanResponse = await api.put(`${apiPath}/status`, payload);
      dispatch({ type: ActionType.ADMIN_USER_DEACTIVATE, payload: response });
      dispatch(setSuccess(response.message));
      return;
    } catch (err) {
      dispatch(setAlert(err));
      return err.statusCode;
    }
  };

/**
 * Flush Surveyor
 */
export const flushSurveyor = () => (dispatch: Function) => {
  try {
    dispatch({ type: ActionType.FLUSH_SURVEYOR, payload: null });
    dispatch({ type: ActionType.FLUSH_SURVEYOR_INFO, payload: null });
    return;
  } catch (err) {
    dispatch(setAlert(err));
  }
};

/**
 * Fetch External User
 */
export const fetchExtUsers =
  (skip: number, limit: number, orderBy: string, orderDir: string) =>
  async (dispatch: Function, _store: Function, api: ApiEngine) => {
    try {
      const query = { skip, limit, orderBy, orderDir };
      const resp = await api.get(`${apiPath}/external-user`, null, query);
      dispatch({ type: ActionType.ADMIN_FETCH_EXT_USER, payload: resp });

      return resp;
    } catch (err) {
      dispatch(setAlert(err));
    }
  };

/**
 * Fetch Surveyor Info Only
 * @returns Surveyor Info
 */
export const fetchSvInfo =
  (id?: number, type?: number) => async (dispatch: Function, _store: Function, api: ApiEngine) => {
    try {
      const q = { managerId: id, type };
      const users = await api.get(`${apiPath}/surveyors-info`, null, q);
      dispatch({ type: ActionType.PM_SURVEYORS_INFO_FETCH, payload: users });
      return users;
    } catch (e) {
      dispatch(setAlert(e));
    }
  };

/**
 * Flush External User
 */
export const flushExtUser = () => (dispatch: Function) => {
  return dispatch({ type: ActionType.ADMIN_FLUSH_EXT_USER, payload: null });
};

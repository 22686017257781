const DEFAULT_MIN_WIDTH = 1300;

/**
 * detect device by navigators user agent
 * */
export const detectViaAgent = () => {
  const userAgent = window.navigator && window.navigator.userAgent;
  return (
    userAgent.match(/Android/i) ||
    userAgent.match(/webOS/i) ||
    userAgent.match(/iPhone/i) ||
    userAgent.match(/iPad/i) ||
    userAgent.match(/iPod/i) ||
    userAgent.match(/BlackBerry/i) ||
    userAgent.match(/Windows Phone/i)
  );
};

/**
 * detect device by page width
 * */
const detectViaPageWidth = () => {
  return window.innerWidth <= DEFAULT_MIN_WIDTH;
};

const isMobileDevice = detectViaAgent() || detectViaPageWidth();

export default isMobileDevice;

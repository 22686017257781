import * as React from 'react';
import { Icon, Table } from '../Inputs';
import { initFalsy, SortDir } from '../../constants';
import { GridViewProps, SortDirection, T } from '../../model';
import { numArray, va } from '../../utils/arrayUtils';
import { isFunction } from '../../utils/common';

type S = { column: string | null; direction: SortDirection };

enum DIR {
  ASC = 'ascending',
  DESC = 'descending',
}

const GridView: React.FC<T> = (props: T & GridViewProps<T>) => {
  const { activeSortKey, sortKeys } = props;
  const initSortState: S = {
    column: va(sortKeys) ? (activeSortKey ? activeSortKey : sortKeys[0]) : undefined,
    direction: undefined,
  };
  const initRowSelection: Array<boolean> = numArray(props.list.length).map(() => initFalsy);
  const [sortVar, setSortVars]: [S, Function] = React.useState(initSortState);
  const [isRowSelected, selectRow]: [Array<boolean>, Function] = React.useState(initRowSelection);
  const _selectable = !!props.onSelect;
  const noRows = !va(props.list);
  const hasFooterRows = va(props.footer);
  const onRowSelect = (index: number, id: number, dataRow: T) => {
    initRowSelection.splice(index, 1, !initFalsy);
    selectRow(initRowSelection);
    if (typeof props.onSelect === 'function') {
      props.onSelect({ ...dataRow, id });
    }
  };
  const handleSort = (clickedColumn: string) => {
    const st: S =
      sortVar.column !== clickedColumn
        ? { column: clickedColumn, direction: DIR.ASC }
        : { column: clickedColumn, direction: sortVar.direction === DIR.ASC ? DIR.DESC : DIR.ASC };

    setSortVars(st);

    if (isFunction(props.onSort)) {
      props.onSort(clickedColumn, st.direction === DIR.ASC ? SortDir.DESC : SortDir.ASC);
    }
  };
  const headerCss = (head: string, wcKey: string) => `${sortVar.column === head ? 'active' : ''} ${wcKey ? wcKey : ''}`;
  const iconCss = (head: string) => {
    const headingMatch = sortVar.column === head;
    const sameDirection = sortVar.direction === DIR.ASC;
    const isMatch = headingMatch && sameDirection;
    return `ml15 sort alphabet ${isMatch ? 'down' : 'up'} ${headingMatch ? 'orange' : ''}`;
  };
  return (
    <div className='frozen no-more-tables'>
      <Table className='crossTable' stackable={!initFalsy} sortable={!initFalsy}>
        <Table.Header>
          <Table.Row>
            {props.headers.map((headerContent: string, n: number) => {
              const wcKey = va(props.widthCss) ? props.widthCss[n] : undefined;
              const width = va(props.widths) ? props.widths[n] : undefined;
              const sortKey = va(sortKeys) ? sortKeys[n] : undefined;
              const sortProps = sortKey ? { onClick: () => handleSort(sortKey) } : {};
              return (
                <Table.HeaderCell key={n} className={headerCss(sortKey, wcKey)} width={width} {...sortProps}>
                  {headerContent}
                  {sortKey && <Icon className={iconCss(sortKey)} />}
                </Table.HeaderCell>
              );
            })}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {!noRows &&
            props.list.map((listRow: T, idx: number) => {
              const { _id, _lock, _warn, ..._row } = listRow,
                className =
                  _selectable || _lock || _warn
                    ? `${_selectable ? 'hand-pointer ' : ''}${_lock ? 'rowLocked ' : ''}${_warn ? 'error' : ''}`
                    : '',
                rowProps = Object.assign(
                  { className },
                  _selectable ? { active: isRowSelected[idx], onClick: () => onRowSelect(idx, _id, _row) } : {},
                );
              return (
                <Table.Row key={idx} {...rowProps}>
                  {Object.values(_row).map((dataValue: T, ix: number) => (
                    <Table.Cell data-heading={props.headers[ix]} key={ix}>
                      {dataValue}
                    </Table.Cell>
                  ))}
                </Table.Row>
              );
            })}
          {noRows && (
            <Table.Row>
              <Table.Cell className='text-center' colSpan={props.headers.length} data-heading='Doh!'>
                No records found
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
        {hasFooterRows && (
          <Table.Footer>
            {props.footer.map((row: T, key: number) => (
              <Table.Row key={key}>
                {Object.values(row).map((dataValue: T, ix: number) => (
                  <Table.Cell data-heading={props.headers[ix]} key={ix}>
                    {dataValue}
                  </Table.Cell>
                ))}
              </Table.Row>
            ))}
          </Table.Footer>
        )}
      </Table>
    </div>
  );
};

export default GridView;

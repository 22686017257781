import { Action, ActionType, Alert } from '../../model';
import createReducer from './createReducer';

const initialAlerts: Alert = { successMsg: '', message: '', statusCode: 200 };
export const alert = createReducer<Alert>(initialAlerts, {
  [ActionType.ALERT](_store: Alert, action: Action<Alert>) {
    return action.payload;
  },
  [ActionType.MESSAGE](_store: Alert, action: Action<Alert>) {
    return action.payload;
  },
});

import React from 'react';
import { connect } from 'react-redux';
import { useActions } from '../../redux/actions';
import { Loader } from '../../components';
import { Button, Card, Chart, FormWrap, GenModelProps, Grid, GridView, Model } from '../../components/Inputs';
import { Account, FormikValues, MiniAccount, ProjectsList, ST, StoreT, SurveyorEarning, T } from '../../model';
import { aMaxVal, byKey, va } from '../../utils/arrayUtils';
import { asCurrency, currencyWithoutFrac } from '../../utils/numUtils';
import { BAR_PRIMARY_COLOR, Frequency, initFalsy, LINE_COLOR, ROLES } from '../../constants';
import { ERD, Filters } from './index';
import * as projectActions from '../../redux/actions/projects';
import * as surveyorErAction from '../../redux/actions/surveyorEarnings';
import * as userAccActions from '../../redux/actions/users';
import { startFrom, toEnd } from '../../utils/dateUtils';

interface EarningProps {
  earnings: SurveyorEarning[];
  chartErData: SurveyorEarning[];
  isPM?: boolean;
  isOP?: boolean;
  isSurveyor?: boolean;
  projects: ProjectsList;
  surveyors: Array<MiniAccount>;
  user: Account;
}

type FSE = (
  startDate?: string,
  endDate?: string,
  projectId?: number,
  surveyorId?: number,
  weekly?: boolean,
  monthly?: boolean,
) => Promise<void>;

type WED = (
  weeklyEarningData: string | undefined,
  erSurveyorId?: number | undefined,
  mangerId?: number | undefined,
) => Promise<void>;
type SE = { earnings: string; weekOf: string; surveys: number };
type Filter = { startDate?: string; endDate?: string; projectId?: number; surveyorId?: number; earningsFreq?: number };

const Earnings = (props: EarningProps) => {
  let [totSurvey, totEarning] = [0, 0];
  const { earnings, chartErData, isPM, isOP, isSurveyor, projects, surveyors, user } = props;
  const fromDate = startFrom(isOP);
  const initFilter: Filter = { startDate: '', endDate: '', earningsFreq: Frequency.Daily };
  const [loader, isLoading] = React.useState(initFalsy);
  const [filter, setFilter] = React.useState(initFilter);
  const [disable, setDisabled] = React.useState(initFalsy);
  const [weeklyFilter, setWeeklyFilter] = React.useState(initFilter);
  const [weeklyERData, setWeeklyERData] = React.useState('');
  const [getSortData, setSortData] = React.useState({ column: 'weekOf', direction: 'ASC' });
  const [defaultDate, setStartDate] = React.useState(fromDate);
  const isWeekly = filter.earningsFreq === Frequency.Weekly;
  const isMonthly = filter.earningsFreq === Frequency.Monthly;
  const axisBottomLabel = `${isWeekly ? 'Week' : isMonthly ? 'Month' : 'Day'} Of`;
  const axisLeftLabel = `${isWeekly ? 'Weekly' : isMonthly ? 'Monthly' : 'Daily'} Earnings`;
  const indexBy = 'weekOf';
  const keys = ['earnings'];
  const legendLabels = initFalsy ? ['Total Earnings'] : ['Total Earnings', 'Surveys Completed'];
  const proAction = useActions(projectActions);
  const userAction = useActions(userAccActions);
  const surveyorAction = useActions(surveyorErAction);
  const headers = [axisBottomLabel, 'Surveys Completed', axisLeftLabel];
  const sortKeys = ['weekOf', 'surveys', '', 'earnings'];
  const widthCss = ['w20', 'w40', 'w40'];
  const erSurveyorId = user.role === ROLES.Surveyor ? user.id : undefined;
  const isManagerId = isPM ? user.id : undefined;
  const handlePopup = () => {
    setDisabled(!initFalsy);
  };
  const handleClosePop = () => {
    surveyorAction.earningsPayStubFlush();
    setDisabled(initFalsy);
  };
  const onSort = (column: string, direction: ST) => {
    setSortData({ column, direction });
  };
  const fetchSurveyorEarning: FSE = async (startDate, endDate, projectId, surveyorId, weekly, monthly) => {
    isLoading(!initFalsy);
    const exactStartDate = !!startDate ? startDate : defaultDate;
    const exactEndDate = endDate ? toEnd(endDate) : '';
    await surveyorAction.getSurveyorEarnings(
      exactStartDate,
      exactEndDate,
      projectId,
      isPM ? user.id : undefined,
      isSurveyor ? user.id : surveyorId,
      weekly,
      monthly,
    );
    isLoading(initFalsy);
  };
  const fse = () => {
    const [sortOn, sortBy] = ['name', 'ASC'];
    const fetchProjects = isSurveyor
      ? proAction.fetchSurveyorProjects(0, Number.MAX_SAFE_INTEGER, sortOn, sortBy, user.id)
      : proAction.fetchProjects(
          0,
          Number.MAX_SAFE_INTEGER,
          sortOn,
          sortBy,
          undefined,
          isPM ? user.id : undefined,
          !initFalsy,
        );

    const fetchSurveyors = user.role !== ROLES.Surveyor && userAction.fetchSvInfo(isPM ? user.id : undefined);

    fetchSurveyorEarning().then(fetchProjects).then(fetchSurveyors);

    return () => {
      proAction.flushProjects();
      surveyorAction.flushSurveyorEarningData();
      userAction.flushSurveyUserList();
      userAction.flushSurveyUserList();
    };
  };
  const fetchWeeklyProjectsDet: WED = async (
    weeklyEarningData = undefined,
    surveyorId = erSurveyorId,
    managerId = isManagerId,
  ) => {
    const localDate = !!weeklyEarningData ? new Date(weeklyEarningData).toISOString() : undefined;
    await surveyorAction.fetchWeeklyProjectsDet(localDate, surveyorId, managerId, weeklyFilter);
  };
  const clickHandler = async ({ weekOf }: SE) => {
    handlePopup();
    setWeeklyERData(weekOf);
    await fetchWeeklyProjectsDet(weekOf, undefined, undefined);
  };
  const filtersFormConfig = {
    children: Filters,
    displayName: 'surveyor-earnings-filter',
    initialValues: { startDate: '', endDate: '', projectId: '', surveyorId: '', earningsFreq: Frequency.Weekly },
    isManager: isPM ? isPM : isOP,
    isSurveyor: isSurveyor,
    projects: projects,
    surveyors: surveyors,
    fromDate,
    defaultDate,
    setStartDate,
    onSubmit: async (values: FormikValues, { resetForm }: FormikValues) => {
      try {
        const { startDate, endDate, projectId, surveyorId, earningsFreq } = values;
        setFilter(values as Filter);
        setWeeklyFilter(values as Filter);
        await fetchSurveyorEarning(
          startDate,
          endDate,
          projectId,
          surveyorId,
          earningsFreq === Frequency.Weekly,
          earningsFreq === Frequency.Monthly,
        );
      } catch (err) {
        resetForm();
      }
    },
  };
  const modelActions = (
    <>
      <Button content='Cancel' onClick={handleClosePop} />
    </>
  );
  const modelContent = (
    <React.Fragment>
      <ERD currWeekOf={weeklyERData} />
    </React.Fragment>
  );
  const { column, direction } = getSortData;
  const list: SE[] = (
    va(earnings)
      ? earnings?.sort(byKey(column, direction === 'ASC', !initFalsy)).map((se: SurveyorEarning) => {
          const { earnings, surveys, weekOf } = se;
          totSurvey += surveys;
          totEarning += earnings;
          return {
            weekOf: weekOf,
            surveys: surveys,
            earnings: asCurrency(earnings),
          };
        })
      : []
  ) as SE[];
  const totalRow = va(list)
    ? [
        {
          weekOf: 'Total',
          surveys: <b>{totSurvey}</b>,
          earnings: <b>{asCurrency(totEarning)}</b>,
        },
      ]
    : [];
  const maxValue =
    chartErData.length > 0
      ? aMaxVal(chartErData.map((se: T) => se.earnings).concat(chartErData.map((se: T) => se.surveys))) + 10
      : 1000;
  const chartProps = {
    ...props,
    availableColors: [BAR_PRIMARY_COLOR, LINE_COLOR],
    axisBottomLabel,
    axisLeftLabel: `${axisLeftLabel} / Surveys Completed`,
    height: '400px',
    includeLine: !initFalsy,
    indexBy,
    keys,
    legendLabels,
    maxValue,
    lineValueLabel: 'Number of Surveys',
  };
  const chartData =
    chartErData.length > 0
      ? va(chartErData)
        ? chartErData.sort(byKey(column, direction === 'DESC', !initFalsy)).map((e) => ({
            ...e,
            lineValue: e.surveys,
            earnings: e.earnings,
            earningsLabel: 'Earnings',
            earningsValue: currencyWithoutFrac(e.earnings),
          }))
        : []
      : [];
  const gridProps = { footer: totalRow, headers, list, onSelect: clickHandler, onSort, sortKeys, widthCss };
  const modelProps: GenModelProps = {
    actions: modelActions,
    content: modelContent,
    header: 'Earning Details',
    initialize: disable,
    onCancel: handleClosePop,
    size: 'fullscreen',
  };

  React.useEffect(fse, []);

  return (
    <>
      {loader && <Loader />}

      <Grid>
        <Grid.Row className='headerTop'>
          <Grid.Column width={16}>
            <h1 className='mainTitle'>Surveyor Earnings</h1>
          </Grid.Column>
        </Grid.Row>
        <FormWrap {...filtersFormConfig} />
        <Grid.Row>
          <Grid.Column width={16}>
            <Card fluid>
              <Card.Content>
                <Chart data={chartData} {...chartProps} />
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            <Card fluid>
              <Card.Content>
                <GridView {...gridProps} />
                <Model {...modelProps} />
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};
const ReduxStoreMapper = (store: StoreT) => ({
  earnings: store.surveyorEarnings,
  chartErData: store.chartEarnings,
  projects: store.projects,
  surveyors: store.surveyorInfo,
  user: store.auth.user,
});

export default connect(ReduxStoreMapper)(Earnings);

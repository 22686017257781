import * as React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import App from './App';
import EB from './EB';
import Loader from './components/Loader';
import configureStore from './redux/configure';

const { persistStore, store } = configureStore();

const AppRedux: React.FC = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={<Loader />} persistor={persistStore}>
        <EB>
          <App />
        </EB>
      </PersistGate>
    </Provider>
  );
};

export default AppRedux;

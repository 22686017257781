import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useActions } from '../../redux/actions';
import { Account, FormikValues, StoreT } from '../../model';
import { FormWrap, Grid } from '../Inputs';
import CreteResetPwdForm from './ConfirmPwd';
import { Loader, LoginBG } from '../../components';
import { pwdRequirements, pwdValidations } from '../../utils/validation';
import { joinAsFullName } from '../../utils/common';
import { sleep } from '../../utils/objectUtils';
import * as accActions from '../../redux/actions/accounts';

const ResetPassword: React.FC = () => {
  const { resetToken } = useParams();
  const hist = useHistory();
  const redirectToLogin = () => hist.push('/');
  const accountActions = useActions(accActions);
  const accountInfo: Account | undefined = useSelector((state: StoreT) => state.accounts);
  const email: string | undefined = accountInfo && accountInfo.email;
  const confirmPwdKey = 'confirmPwd';
  const requiredFields = ['password', confirmPwdKey];
  const initialValues: { password: string; confirmPwd: string } = { password: '', confirmPwd: '' };
  const rcpForm = {
    displayName: 'reset-create-pwd-frm',
    initialValues,
    children: CreteResetPwdForm,
    onSubmit: async (values: FormikValues, { resetForm }: FormikValues) => {
      try {
        const payload = {
          token: resetToken,
          password: values.password,
          email,
        };
        await accountActions.resetPwd(payload);
        redirectToLogin();
      } catch (err) {
        resetForm();
      }
    },
    validate: (values: FormikValues) => pwdValidations(requiredFields, confirmPwdKey, pwdRequirements, values),
  };

  const resPassLC = () => {
    const initFetch = async () => {
      await sleep(50);
      await accountActions.validateToken(resetToken);
    };

    initFetch().catch(() => redirectToLogin());

    return () => accountActions.flushAccounts();
  };
  React.useEffect(resPassLC, [resetToken]);

  return (
    <Grid>
      <Grid.Row>
        <LoginBG />
        <Grid.Column mobile={16} tablet={16} computer={8}>
          <div className='loginForm reset text-center text-white'>
            <h2 className='mb-0 f24'>RESET PASSWORD</h2>
            {!(accountInfo && accountInfo.id) && (
              <Loader color='light'>
                <p className='text-info'>Loading...</p>
              </Loader>
            )}
            {accountInfo && accountInfo.id && (
              <p className='f18'>
                Hello
                <span className='highLight'> {joinAsFullName(accountInfo.firstName, accountInfo.lastName)}! </span>
                Let's Get Stated.
              </p>
            )}
            <FormWrap {...rcpForm} />
          </div>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default ResetPassword;

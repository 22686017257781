import React from 'react';
import { DndProvider } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { detectViaAgent } from '../../utils/deviceUtils';

const DndWrapper: React.FC = ({ children }) => {
  return <DndProvider backend={detectViaAgent() ? TouchBackend : HTML5Backend}>{children && children}</DndProvider>;
};

export default DndWrapper;

import { ROLES } from '../../constants';
import { ActionType, ApiEngine, CleanResponse } from '../../model';
import { setAlert, setSuccess } from './alert';

const apiPath = 'guidelines';

/**
 * Fetch Project Uploaded Pdf File
 * @param projectId
 * @returns uploaded Pdf Url
 */
export const fetchSurveyorGuidelines =
  (projectId: number) => async (dispatch: Function, store: Function, api: ApiEngine) => {
    try {
      const q = { projectId };
      const user = store().auth.user;
      const managerId = user.role === ROLES['Project Manager'] ? user.id : undefined;
      const surveyorId = user.role === ROLES.Surveyor || user.role === ROLES['CIMR Surveyor'] ? user.id : undefined;
      if (managerId) {
        Object.assign(q, { managerId });
      } else if (surveyorId) {
        Object.assign(q, { surveyorId });
      }
      const res = await api.get(apiPath, null, q);
      dispatch({ type: ActionType.FETCH_SV_GUIDELINE, payload: res.data });
      return res.data;
    } catch (err) {
      dispatch(setAlert(err));
    }
  };

/**
 * Upload Project Uploaded Pdf File
 * @param projectId
 * @param fileName
 * @param fileBuffer
 */
export const saveSurveyorGuidelines =
  (projectId: number, fileName: string, fileBuffer: object) =>
  async (dispatch: Function, _store: Function, api: ApiEngine) => {
    try {
      const query = { fileName, projectId };
      const res: CleanResponse = await api.post(apiPath, fileBuffer, query);
      dispatch({ type: ActionType.SAVE_SV_GUIDELINE, payload: res.data });
      dispatch(setSuccess(res.message));
      return res.data;
    } catch (err) {
      dispatch(setAlert(err));
    }
  };

/**
 * Fetch Surveyor Guideline Confirm Status
 * @param projectId
 * @returns uploaded Pdf Url
 */
export const fetchSvGuidelineStatus =
  (projectId: number, surveyorId: number) => async (dispatch: Function, _store: Function, api: ApiEngine) => {
    try {
      const q = { projectId, surveyorId };
      const res = await api.get(`${apiPath}/status`, null, q);
      dispatch({ type: ActionType.FETCH_SV_GUIDELINE_STATUS, payload: res.data });
      return res.data;
    } catch (err) {
      dispatch(setAlert(err));
    }
  };

/**
 *
 * @param projectId
 * @param surveyorId
 * @returns
 */
export const updateSvGuidelineStatus =
  (projectId: number, surveyorId: number) => async (dispatch: Function, _store: Function, api: ApiEngine) => {
    try {
      const payload = { projectId, surveyorId };
      await api.put(`${apiPath}/status`, payload);
    } catch (err) {
      dispatch(setAlert(err));
    }
  };

import React from 'react';
import { connect } from 'react-redux';
import { Grid, Card, GridView } from '../../components/Inputs';
import { StoreT, T, weeklyEarningData } from '../../model';
import { va } from '../../utils/arrayUtils';
import { asCurrency, pfz, piz } from '../../utils/numUtils';
import { Language } from '../../constants';

interface EarningsDProp {
  weeklyEarningDet: weeklyEarningData[];
  currWeekOf: string;
}

type SECardProps = { title: string; name: string };
type SEBlockProp = { seData: T; currWeekOf: string };

const SECARD: React.FC<SECardProps> = ({ title, name }) => {
  return (
    <Card fluid>
      <Card.Content>
        <Grid.Column className='earning-contact-detail'>
          <label>{title}</label>
          <b>{name}</b>
        </Grid.Column>
      </Card.Content>
    </Card>
  );
};

const SEBlock: React.FC<SEBlockProp> = (props) => {
  let [totSurvey, totalWeeklyEr] = [0, 0];
  let TWE = 0;
  const { seData, currWeekOf } = props,
    headers = ['Project Acronym', 'Survey Lang', 'QTY', 'Unit Price', 'Total Weekly Earnings', 'Total Earnings'],
    weekOfDate = new Date(currWeekOf).addDays().toISODateString(),
    inVoiceDate = new Date(weekOfDate).toMMDDYY(),
    list = va(seData.project)
      ? seData?.project.map((wed: T) => {
          const { languageId, projectAcronym, QTY, totalEarnings, unitPrice } = wed;
          const surveyLang = Language[languageId];
          totSurvey += piz(QTY);
          TWE = totalEarnings;
          totalWeeklyEr += pfz(TWE);
          return {
            projectAcronym,
            surveyLang,
            QTY,
            unitPrice: asCurrency(unitPrice),
            totalweeklyEarnings: `${asCurrency(TWE)}`,
            totalEarnings: `${asCurrency(TWE)}`,
          };
        })
      : [],
    totalRow = va(list)
      ? [
          {
            projectAcronym: '',
            surveyLang: '',
            QTY: <b>Total QTY (Completed Survey)</b>,
            unitPrice: '',
            totalweeklyEarnings: <b>Total Per Survey Amount</b>,
            totalEarnings: <b>Total Earnings</b>,
          },
          {
            projectAcronym: '',
            surveyLang: '',
            QTY: <b>{totSurvey}</b>,
            unitPrice: '',
            totalweeklyEarnings: <b>{asCurrency(totalWeeklyEr)}</b>,
            totalEarnings: <b>{asCurrency(totalWeeklyEr)}</b>,
          },
        ]
      : [];
  return (
    <Grid className='earning-details-tab' columns='five'>
      <Grid.Row className='pt10'>
        <Grid.Column>
          <SECARD title='SUBCONTRACTOR' name={seData.surveyorName} />
        </Grid.Column>
        <Grid.Column>
          <SECARD title='INVOICE DATE' name={inVoiceDate} />
        </Grid.Column>
        <Grid.Column>
          <SECARD title='INVOICE ID' name={`${seData.surveyorCid}_${inVoiceDate}`} />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          <Card fluid>
            <Card.Content>
              <GridView footer={totalRow} headers={headers} list={list} />
            </Card.Content>
          </Card>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

const EarningsDetails: React.FC<EarningsDProp> = (props) => {
  const { weeklyEarningDet, currWeekOf } = props,
    weeklyData = weeklyEarningDet !== null ? weeklyEarningDet : [];
  return (
    <>
      {va(weeklyData) ? (
        weeklyData.map((wed: object) => <SEBlock seData={wed} currWeekOf={currWeekOf} />)
      ) : (
        <div>
          <p className='center-force'>No record found.</p>
        </div>
      )}
    </>
  );
};

const ReduxStoreMapper = (store: StoreT) => ({
  weeklyEarningDet: store.weeklyEarnings,
});

export default connect(ReduxStoreMapper)(EarningsDetails);

import React from 'react';
import { ButtonProps, FieldArrayRenderProps, FormikValues, FormProps, ProjectLanguageList } from '../../model';
import { enumAsAO, va } from '../../utils/arrayUtils';
import { initFalsy, LanguageSamplingParams } from '../../constants';
import { Button, Card, FieldArray, Form, FormField, FormFieldGroup, FormFieldType, FormWrap, Grid } from '../Inputs';

type LSPProps = { languages: ProjectLanguageList[]; onUpdate: Function; updating: boolean };

interface AddFormProps extends FormProps {
  updating: boolean;
}

const LanguageSamplingForm = ({ handleSubmit, isSubmitting, updating, values }: AddFormProps) => {
  const opts = enumAsAO(LanguageSamplingParams);
  const fields = [
    { color: 'black', readOnly: !initFalsy, type: FormFieldType.Text },
    { options: opts, placeholder: 'Language Sampling...', type: FormFieldType.DropDown },
    { placeholder: 'Allowed Samples', type: FormFieldType.Number },
  ];
  const submitButtonProps: ButtonProps = {
    content: 'Update',
    loading: isSubmitting || updating,
    primary: !initFalsy,
  };
  const renderLangSampling = (_fps: FieldArrayRenderProps) => (
    <>
      {va(values.languages) &&
        values.languages.map((_c: ProjectLanguageList, i: number) => {
          const isOpen = _c.projectLanguage?.samplingId === LanguageSamplingParams.Open;
          const isClosed = _c.projectLanguage?.samplingId === LanguageSamplingParams["Don't"];
          const isSet = _c.projectLanguage?.samplingId === LanguageSamplingParams.Set;
          const iconButtons: ButtonProps = Object.assign(
            { className: 'mt10' },
            {
              // color: isOpen ? 'green' : (isClosed ? 'red' : ''),
              icon: isOpen ? 'check' : isClosed ? 'close' : '',
            },
          );
          return (
            <Grid.Row key={i}>
              <Grid.Column className='mt15' computer={4} tablet={4} mobile={16}>
                <b>{_c.name}</b>
              </Grid.Column>
              {(isOpen || isClosed) && (
                <Grid.Column computer={4} tablet={4} mobile={6}>
                  <Button circular={!initFalsy} type='button' {...iconButtons} />
                </Grid.Column>
              )}
              {isSet && (
                <Grid.Column computer={isSet ? 4 : 4} tablet={4} mobile={6}>
                  <FormFieldGroup fields={{ ...fields[2], name: `languages.${i}.projectLanguage.allowedSamples` }} />
                </Grid.Column>
              )}
              <Grid.Column floated='right' computer={isSet ? 8 : 8} tablet={8} mobile={10}>
                <FormField {...fields[1]} name={`languages.${i}.projectLanguage.samplingId`} />
              </Grid.Column>
            </Grid.Row>
          );
        })}
    </>
  );

  return (
    <Form onSubmit={handleSubmit}>
      <Grid>
        <FieldArray name='langSampParams' render={renderLangSampling} />
        <Grid.Row>
          <Grid.Column mobile={16} tablet={16} computer={16} textAlign='right'>
            <Button {...submitButtonProps} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Form>
  );
};

const LSP: React.FC<LSPProps> = ({ languages, onUpdate, updating }: LSPProps) => {
  const langFrmConfig = {
    displayName: 'language_form',
    initialValues: { languages },
    onSubmit: async (values: FormikValues, { resetForm }: FormikValues) => {
      try {
        const payload = values.languages.map(
          ({ projectLanguage: { allowedSamples, id, samplingId } }: ProjectLanguageList) => ({
            allowedSamples,
            id,
            samplingId,
          }),
        );

        await onUpdate(payload);
      } catch (err) {
        resetForm();
      }
    },
    children: LanguageSamplingForm,
    updating,
  };
  return (
    <Card fluid={!initFalsy} className='mb20'>
      <Card.Header>
        <h2 className='subTitle'>Language Sampling</h2>
      </Card.Header>
      <Card.Content>
        {va(languages) && <FormWrap {...langFrmConfig} />}
        {!va(languages) && <span>No Data Found</span>}
      </Card.Content>
    </Card>
  );
};

export default LSP;

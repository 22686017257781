import React from 'react';
import { ButtonProps } from '../../model';
import { Button } from './index';

interface Props {
  buttonProps: ButtonProps[];
}

const BtnSwitch: React.FC<Props> = ({ buttonProps }) => {
  const renderFux = (btProps: ButtonProps, key: number) => {
    const defType = 'div';
    const _btProps = { ...btProps, key };
    return btProps.as === defType ? React.createElement(defType, _btProps) : React.createElement(Button, _btProps);
  };
  return <Button.Group className='btnSwitch'>{buttonProps.map(renderFux)}</Button.Group>;
};

export default BtnSwitch;

import { ActionType, ApiEngine, CleanResponse, PutLanguageSampling } from '../../model';
import { setAlert, setSuccess } from './alert';

const apiPath = 'language';

/**
 * Flush Languages
 */
export const flushLanguages = () => async (dispatch: Function) => {
  try {
    return dispatch({ type: ActionType.FLUSH_LANGUAGES, payload: [] });
  } catch (err) {
    dispatch(setAlert(err));
  }
};

/**
 * Fetch Languages
 * @param skip
 * @param limit
 * @param orderBy
 * @param orderDir
 * @param paginated
 */
export const fetchLanguages =
  (skip: number, limit: number, orderBy: string, orderDir: string, paginated: boolean) =>
  async (dispatch: Function, _store: Function, api: ApiEngine) => {
    try {
      const query = { skip, limit, orderBy, orderDir, paginated };
      const languages = await api.get(apiPath, null, query);
      dispatch({ type: ActionType.FETCH_LANGUAGES, payload: languages });

      return languages;
    } catch (err) {
      dispatch(setAlert(err));
    }
  };

/**
 * Insert Language
 * @param name
 */
export const addLanguage = (name: string) => async (dispatch: Function, _store: Function, api: ApiEngine) => {
  try {
    const payload = { name };
    const langRes: CleanResponse = await api.post(apiPath, payload);
    dispatch(setSuccess(langRes.message));
    return dispatch({ type: ActionType.ADD_LANGUAGES, payload: null });
  } catch (err) {
    dispatch(setAlert(err));
  }
};

/**
 * Delete Language
 * @param langId
 */
export const delLanguages = (langId: number) => async (dispatch: Function, _store: Function, api: ApiEngine) => {
  try {
    const payload = { langId };
    const langRes: CleanResponse = await api.delete(apiPath, payload);
    dispatch(setSuccess(langRes.message));
    return dispatch({ type: ActionType.DEL_LANGUAGES, payload: null });
  } catch (err) {
    dispatch(setAlert(err));
  }
};

/**
 * Fetch Project Languages
 * @param projectId
 */
export const fetchProjectLanguages =
  (projectId: number) => async (dispatch: Function, _store: Function, api: ApiEngine) => {
    try {
      const query = { projectId };
      const languages = await api.get(apiPath, null, query);
      dispatch({ type: ActionType.FETCH_LANGUAGES, payload: languages });

      return languages;
    } catch (err) {
      dispatch(setAlert(err));
    }
  };

/**
 * Fetch Surveyor Languages
 * @param surveyorId
 */
export const fetchSurveyorLanguages =
  (surveyorId: number) => async (dispatch: Function, _store: Function, api: ApiEngine) => {
    try {
      const query = { surveyorId };
      const languages = await api.get(apiPath, null, query);
      dispatch({ type: ActionType.FETCH_LANGUAGES, payload: languages });

      return languages;
    } catch (err) {
      dispatch(setAlert(err));
    }
  };

/**
 * Update Project Languages Samplings
 * @param payload
 */
export const updLangSampling =
  (payload: PutLanguageSampling) => async (dispatch: Function, _store: Function, api: ApiEngine) => {
    try {
      const res: CleanResponse = await api.put(apiPath, payload);
      dispatch({ type: ActionType.PM_SAMPLING_LANGUAGES, payload: res });
      return dispatch(setSuccess(res.message));
    } catch (err) {
      dispatch(setAlert(err));
    }
  };

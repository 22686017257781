import React from 'react';
import { Button, ChkBox, Confirm, FormField, FormFieldType, Grid, Icon, List, RadioGroup, TextMarkup } from '../Inputs';
import {
  Answer,
  BN,
  ButtonProps,
  ChkProps,
  FormProps,
  IconProps,
  LangAnswers,
  NU,
  QsLogic,
  Question,
  reOrderProps,
  SelectValue,
  T,
} from '../../model';
import { AnswerType, CONFIRM_MESSAGES, initFalsy, Language, NA, QSF, RETURN_KEY_CODE } from '../../constants';
import { isFunction, noop } from '../../utils/common';
import { va } from '../../utils/arrayUtils';
import { flc, pez, stringify } from '../../utils/stringUtils';
import { piz } from '../../utils/numUtils';

type BranchProps = {
  ansId?: number[];
  answer?: Answer[];
  projectId?: number;
  quesId?: number;
  question?: Question;
  sequence?: number;
  type?: number;
  quesSequence?: number;
};

export enum MiniType {
  S = 1,
  M,
  OE,
  SC,
}

type ATV = {
  lang?: LangAnswers;
  qId: number;
  rPosition: number;
  text?: T;
  title: string;
  value: number;
  isAns: string;
};
type LV = { label: string; value: string };

export interface TreeProps extends Partial<FormProps> {
  allowScroll?: boolean;
  cancelEdit?: Function;
  editTypeId?: BN;
  isAdd?: boolean;
  isBuilder?: boolean;
  isCallSurvey?: boolean;
  isEdit?: boolean;
  isViewer?: boolean;
  items: Partial<BranchProps>[];
  itemsToRemove?: number[];
  langId?: number;
  logic?: QsLogic[];
  onAnswerDel?: Function;
  onChange?: Function;
  onDel?: Function;
  onEditAnswer?: Function;
  onOver?: Function;
  onScrollToDiv?: Function;
  over?: BN;
  pipeItems?: LV[];
  reOrderResponse?: Function;
  setEditType?: Function;
  setItemsToRemove?: Function;
  updateQA?: Function;
  scrollProps?: T;
}

const QATree = (treeProps: TreeProps) => {
  const __qsItemsToRemove: number[] = [];
  const {
    allowScroll,
    cancelEdit,
    editTypeId,
    errors,
    isAdd,
    isBuilder,
    isCallSurvey,
    isEdit,
    items,
    itemsToRemove,
    langId,
    logic,
    onAnswerDel,
    onChange,
    onDel,
    onEditAnswer,
    onOver,
    onScrollToDiv,
    over,
    pipeItems,
    reOrderResponse,
    setEditType,
    setFieldValue,
    setItemsToRemove,
    updateQA,
    scrollProps,
    values = {},
  } = treeProps;
  const commonCls = 'float-right hand-pointer mb10';
  const cancelIconProps: IconProps = { className: `${commonCls} red`, name: 'close', size: 'large', title: 'Cancel' };
  const delIconProps: IconProps = { className: `${commonCls} mr0`, name: 'trash' };
  const editIconProps: IconProps = { className: `${commonCls} mr10`, name: 'edit' };
  const upIconProps: IconProps = { className: `${commonCls} mr10`, name: 'arrow up' };
  const updateIconProps: IconProps = {
    className: `${commonCls} green mr10`,
    name: 'check',
    size: 'large',
    title: 'Update',
  };
  const downIconProps: IconProps = { className: `${commonCls} mr10`, name: 'arrow down' };
  const [editing, isEditing] = React.useState(initFalsy as BN);
  const [deleting, isDeleting] = React.useState(initFalsy);
  const [delQOpen, showDelQConfirm] = React.useState(initFalsy as BN);
  const [delAOpen, showDelAConfirm] = React.useState(initFalsy as BN);
  const [selectedAnswer, setSelectedAnswer] = React.useState({});
  const [active, setActive] = React.useState(initFalsy as BN);
  const voidFux = () => noop;
  const onDelete = async (sequenceId?: number) => {
    isDeleting(!initFalsy);
    await (onDel && isFunction(onDel) ? onDel(sequenceId) : voidFux);
    isDeleting(initFalsy);
    showDelQConfirm(initFalsy);
  };
  const onAnsDelete = async () => {
    isDeleting(!initFalsy);
    await (onAnswerDel && isFunction(onAnswerDel) ? onAnswerDel(selectedAnswer) : voidFux);
    isDeleting(initFalsy);
    showDelAConfirm(initFalsy);
  };
  const confirmAnsDel = (projectId: number, ansId: number, quesId: number, sequence: number) => {
    setSelectedAnswer({ projectId, ansId, quesId, sequence });
    showDelAConfirm(ansId);
  };
  const reOrder = (isInc: boolean, reOrderData: reOrderProps) => {
    const { rPosition, value } = reOrderData;
    const reOrderPayload = { isInc, rPosition, value };
    return reOrderResponse && isFunction(reOrderResponse) ? reOrderResponse(reOrderPayload) : voidFux;
  };
  const ansContent = (
    index: number,
    aTVObj: ATV,
    sequence: number,
    projectId: number,
    quesId: number,
    isE: boolean,
    value: T,
  ) => {
    const { title, rPosition } = aTVObj;
    const ansId = aTVObj.value;
    const style = {
      background: '#ebeff1',
      borderRadius: '4px',
      float: 'left' as 'left',
      marginBottom: '10px',
      padding: '2px 5px',
    };
    const delIconProps: IconProps = {
      className: 'ml5 mr0 cursorPointer',
      name: 'close',
      onClick: () => confirmAnsDel(projectId, ansId, quesId, sequence),
    };
    const reOrderPayload = { rPosition, value };
    const leftIconProps: IconProps = {
      className: 'ml5 mr0 cursorPointer',
      name: 'arrow left',
      onClick: () => reOrder(initFalsy, reOrderPayload),
    };
    const rightIconProps: IconProps = {
      className: 'ml5 mr0 cursorPointer',
      name: 'arrow right',
      onClick: () => reOrder(!initFalsy, reOrderPayload),
    };
    const isValid = !!value[index].qId && value[index].rPosition;
    const onlyOne = va(value) && value.filter((a: T) => a.qId && a.rPosition).length === 1;
    return isE || isEdit ? (
      <span style={style}>
        {title}
        <Icon {...delIconProps} disabled={onlyOne} />
        {isValid && (
          <>
            <Icon {...leftIconProps} disabled={index === 0} />
            <Icon {...rightIconProps} disabled={index === value.length - 1} />
          </>
        )}
      </span>
    ) : (
      title
    );
  };
  const captureNextSequence = (sequence: number, removedItems?: number[]) => {
    if (!va(removedItems)) {
      removedItems = itemsToRemove as number[];
    }
    const listOfAvailableQs = items.filter((i) => !removedItems?.includes(i.sequence as number));
    const seqList = listOfAvailableQs.map((s) => s.sequence as number);
    const lastSequence = seqList[seqList.length - 1];
    const nextItemIdx = seqList.findIndex((s) => s === sequence);
    if (nextItemIdx === -1) {
      let result: number = 0;
      if (!sequence) {
        result = seqList[0];
      } else if (sequence < lastSequence) {
        const sequences = listOfAvailableQs.filter((s: T) => s.sequence >= sequence);
        const qsItem = sequences[0];
        for (let i = 0; i < listOfAvailableQs.length; i++) {
          if (listOfAvailableQs[i].sequence === qsItem.sequence) {
            result = listOfAvailableQs[i].sequence as number;
            break;
          }
        }
      } else {
        result = lastSequence;
      }
      return result;
    } else {
      const nextItem = listOfAvailableQs[nextItemIdx + 1] as BranchProps;
      return sequence < lastSequence ? (nextItem.sequence as number) : lastSequence;
    }
  };
  const validSequence = (sequence: number, removedItems: number[]) => {
    const listOfAvailableQs = items.filter((i) => !removedItems?.includes(i.sequence as number));
    const nextItemIdx = listOfAvailableQs.findIndex((s) => s.sequence === sequence);
    return nextItemIdx > -1;
  };
  const listItemCss = (sequence: number) => {
    const activeQues = over as number;
    let lsItemClasses = 'page-Break qs-row ';
    lsItemClasses +=
      allowScroll && isCallSurvey
        ? sequence === activeQues
          ? 'qs-row-active'
          : sequence === active
            ? 'qs-row-active'
            : sequence < activeQues
              ? 'qs-row-display'
              : 'qs-row-overlay'
        : '';
    return lsItemClasses;
  };
  const usePrevious = <T extends unknown>(value: T): T | undefined => {
    const scu = React.useRef<T>();
    React.useEffect(() => {
      scu.current = value;
    });
    return scu.current;
  };
  const confirmButton: ButtonProps = {
    color: 'red',
    content: 'Yes, Delete',
    loading: deleting,
    primary: initFalsy,
    type: 'button',
  };
  const confirmAProps = {
    content: CONFIRM_MESSAGES.RESP,
    header: 'Delete Response',
    confirmButton: <Button {...confirmButton} onClick={onAnsDelete} />,
    open: !!delAOpen,
    onCancel: () => showDelAConfirm(initFalsy),
  };
  const confirmQsProps = {
    content: CONFIRM_MESSAGES.QUES_ITEM,
    header: 'Delete Questionnaire Item',
    confirmButton: <Button {...confirmButton} onClick={() => onDelete(delQOpen as number)} />,
    open: !!delQOpen,
    onCancel: () => showDelQConfirm(initFalsy),
  };
  const prevPropsValue = usePrevious(itemsToRemove) || {};

  const onHighlight = (sequence: number) => {
    if (sequence !== (over as number)) {
      setActive(sequence);
    }
  };
  const checkAns = (name: string, type?: number, seq?: number) => {
    const questionnaireLogic = (logic as QsLogic[])?.filter((i) => !!i.answerId && i.questionId === seq) || [],
      eligibleAnswers = values[name],
      hasMultipleAnswers = va(eligibleAnswers) && (AnswerType.Multiple === type || AnswerType.Single === type),
      matchingLogic = questionnaireLogic.filter((i) =>
        hasMultipleAnswers ? eligibleAnswers.includes(i.answerId) : i.answerId === eligibleAnswers,
      );

    if (va(matchingLogic)) {
      matchingLogic.map((a) => __qsItemsToRemove.push(a.affectedQuestionId as number));
    }
  };
  const onCancel = async () => {
    isEditing(initFalsy);
    await (cancelEdit && isFunction(cancelEdit) ? cancelEdit() : voidFux);
  };
  const onUpdate = async (branchProps: BranchProps) => {
    const { projectId, quesId, sequence, type } = branchProps;
    isDeleting(!initFalsy);
    if (branchProps?.type !== AnswerType.Scripted) {
      const questionnaires = branchProps?.answer?.map((qa) => ({
        ansId: qa.id,
        projectId,
        quesId,
        rPosition: qa.rPosition || branchProps?.answer?.length,
        sequence,
        type,
      }));
      await (updateQA && isFunction(updateQA) ? updateQA(questionnaires) : voidFux);
    }

    isDeleting(initFalsy);
    showDelAConfirm(initFalsy);
  };
  const scrollToTarget = (sequence: NU) => {
    if (allowScroll && isCallSurvey) {
      if (!va(items)) {
        return;
      }
      const o = piz(over);
      const s = piz(sequence);
      const isPrevious = s < o;
      const ns = captureNextSequence(s);
      if (!isPrevious) {
        onScrollToDiv && onScrollToDiv(ns as number);
        onOver && onOver(ns);
      }
      setActive(initFalsy);
    }
  };
  const findAnsText = (ans: LangAnswers[], langId: number = Language.English) => {
    const engAns = pez(ans?.find((i) => i.langId === Language.English)?.ansText);
    return pez(ans?.find((j) => j.langId === langId)?.ansText, engAns);
  };
  const renderAnswer = (props: Partial<BranchProps>, isBuilder: boolean | undefined) => {
    const { answer, sequence, type, projectId, quesId } = props;
    const aTVs: ATV[] =
      answer?.map((ans) => ({
        value: ans.id,
        qId: ans.qId,
        title: ans.title,
        rPosition: ans.rPosition,
        isAns: ans?.isAns,
        text: { children: <TextMarkup qText={findAnsText(ans.langAnswers, langId)} /> },
        lang: ans.langAnswers?.find((a) => a.langId === langId),
      })) || [];

    if (type === AnswerType.Scripted) {
      return;
    }

    let CMP;
    const name = `${QSF}${sequence}`;
    if (!isBuilder) {
      switch (type) {
        case AnswerType.Single:
          const rProps = {
            className: 'questionnaire-chk-box',
            name,
            options: aTVs,
            onChange: (val: SelectValue) => {
              setFieldValue && setFieldValue(name, val);
              scrollToTarget(sequence);
            },
            value: values[name] !== NA ? values[name] : undefined,
          };
          CMP = <RadioGroup {...rProps} />;
          break;
        case AnswerType.Multiple:
          CMP = (
            <Grid>
              <Grid.Row>
                {aTVs.map(({ text, value }, i) => {
                  const vn = values[name] !== NA ? values[name] : undefined;
                  const val = (va(vn) ? vn : !!vn ? [vn] : []) || [];
                  const tfProps = {
                    checked: val.includes(value),
                    label: text,
                    name,
                    onChange: (val: SelectValue) => {
                      const vn = values[name];
                      const existingVal = (va(vn) ? vn : !!vn && !Array.isArray(vn) ? [vn] : []) || [];
                      const set = new Set(existingVal);
                      if (set.has(val)) {
                        set.delete(val);
                      } else {
                        set.add(val);
                      }
                      setFieldValue && setFieldValue(name, Array.from(set));
                    },
                    value,
                  };
                  return (
                    <Grid.Column key={i} width={4}>
                      <ChkBox className='questionnaire-chk-box' {...tfProps} />
                    </Grid.Column>
                  );
                })}
              </Grid.Row>
            </Grid>
          );
          break;
        case AnswerType['Open Ended']:
          const onKeyDown = (_e: T) => {
            if (_e.keyCode === RETURN_KEY_CODE) {
              _e.preventDefault();
            }
          };
          const txtAreaProps = {
            name,
            onKeyDown,
            placeholder: 'Add open-ended response here',
            spellCheck: !initFalsy,
            type: FormFieldType.TextArea,
          };
          CMP = <FormField {...txtAreaProps} />;
          break;
      }
    }
    return (
      <Grid.Row className='qs-row-a'>
        <Grid.Column width={16}>{CMP}</Grid.Column>
        {isBuilder &&
          aTVs?.map((aTVObj, key) => {
            const isSelEdit = editing === sequence;
            const { isAns } = aTVObj;
            const content = ansContent(
              key,
              aTVObj,
              sequence as number,
              projectId as number,
              quesId as number,
              isSelEdit,
              aTVs,
            );
            return (
              <Grid.Column
                className={isAns ? 'ans-denied' : 'ans-ui-content'}
                computer={5}
                key={key}
                mobile={16}
                tablet={8}
              >
                <List.Description className='mb10' content={content} />
              </Grid.Column>
            );
          })}
      </Grid.Row>
    );
  };
  const findQuesText = (q: Question, langId: number = Language.English) => {
    const eng = pez(q.langQuestions?.find((a) => a.langId === Language.English)?.quesText);
    return pez(q.langQuestions?.find((a) => a.langId === langId)?.quesText, eng);
  };
  const parseQuesText = (question: Question) => {
    const leftAnc = '<<',
      rightAnc = '>>',
      anc = '***',
      globalFlag = 'gi',
      lbl_no_name = flc('NO NAME'),
      lbl_dos = flc('Date Of Service'),
      // ancRegexPattern = '\\*{3}(.*?)\\*{3}',
      // ancRegex = new RegExp(ancRegexPattern, globalFlag),
      noMatch = (st: string) => {
        // return isCallSurvey ? ancSt.replace(new RegExp(ancRegex, globalFlag), '') : ancSt;
        return st.replaceAll(leftAnc, anc).replaceAll(rightAnc, anc);
      },
      findValue = (m: string) => {
        const fieldName = m.replaceAll(leftAnc, '').replaceAll(rightAnc, '');
        const fieldValue = (pipeItems as LV[])?.find((i) => flc(i.label) === flc(fieldName))?.value as string;
        const dem = !!fieldValue ? noMatch(m) : '';
        const isDos = flc(fieldName) === lbl_dos;
        const isNoName = flc(fieldValue) === lbl_no_name;

        return isDos ? new Date(pez(fieldValue)).toLongDateString() : isNoName ? '' : pez(fieldValue, dem);
      },
      rosterHeaders = va(pipeItems) ? (pipeItems?.map((l) => `<<${l.label}>>`).join('|') as string) : '',
      findAndReplaceLabels = va(pipeItems) ? new RegExp(rosterHeaders, globalFlag) : '',
      ques = findQuesText(question, langId).replace(/DOS/g, 'Date Of Service');

    return va(pipeItems) ? noMatch(ques.replace(findAndReplaceLabels, findValue)) : noMatch(ques);
  };
  const typeSwitchProps: ChkProps = {
    checked: !!editTypeId,
    labelInLeft: !initFalsy,
    name: 'ques-type',
    onChange: () => setEditType && setEditType(!editTypeId),
    toggle: !initFalsy,
  };
  const firstLoad = () => {
    const viaRosterLogic = (logic || [])?.filter((i) => !i.answerId) || [];
    viaRosterLogic.forEach((l) => {
      const matchFound = (pipeItems as LV[])?.find(
        (p) => flc(p.label) === flc(l.rosterField) && flc(p.value) === flc(l.rosterValue),
      );
      if (matchFound) {
        __qsItemsToRemove.push(l.affectedQuestionId as number);
      }
    });

    if (stringify(prevPropsValue || {}) !== stringify(__qsItemsToRemove) && va(items)) {
      setItemsToRemove && setItemsToRemove(__qsItemsToRemove);
      if (va(__qsItemsToRemove)) {
        const isValS = validSequence(over as number, __qsItemsToRemove);
        if (!isValS) {
          const aSeq = captureNextSequence(over as number, __qsItemsToRemove);
          onOver && onOver(aSeq);
        }
      }
    }
  };

  React.useEffect(firstLoad, [__qsItemsToRemove]);

  return (
    <List {...(allowScroll && scrollProps)}>
      {va(items)
        ? items.map((branchProps, idx) => {
            const { question, sequence, projectId, type, quesSequence } = branchProps;
            const fieldName = `${QSF}${sequence}`;
            const isRequired = (errors || {})[fieldName];
            const isAllowedEdit =
              branchProps.type === AnswerType['Open Ended'] || branchProps.type === AnswerType.Scripted;
            const onEdit = async () => {
              isEditing(sequence || !initFalsy);
              await (onEditAnswer && isFunction(onEditAnswer) ? onEditAnswer(branchProps) : voidFux);
            };
            const onUpDown = (isInc?: boolean) => {
              return onChange && isFunction(onChange) ? onChange(projectId, sequence, isInc) : voidFux;
            };
            const reqCls = isRequired ? 'red' : '';
            const firstIcon = idx === 0;
            const lastIcon = idx === items.length - 1;
            const qsText = isBuilder ? question?.title : parseQuesText(question as Question);
            const isDelQues = question?.isQuestion;
            const liProps = {
              className: listItemCss(sequence as number),
              id: `qs-tree-row-${sequence}`,
            };

            checkAns(fieldName, type, sequence);

            return (
              <React.Fragment key={idx}>
                {!(itemsToRemove || []).includes(sequence as number) && (
                  <List.Item {...liProps}>
                    <List.Content>
                      <Grid className={isDelQues ? 'ques-denied' : 'ques-ui-grid'}>
                        <Grid.Row className='qs-row-q hand-pointer' onClick={() => onHighlight(sequence as number)}>
                          <Grid.Column mobile={16}>
                            <hr />
                            <p className='float-left'>
                              <sup className='invisible'>{`(${sequence}) `}</sup>
                              <b className={reqCls}>
                                {!!quesSequence && type !== AnswerType.Scripted && `Q${quesSequence}. `}
                              </b>
                              {isBuilder && qsText}
                              {!isBuilder && <TextMarkup qText={qsText} />}
                            </p>
                            <div className='clearFix' />
                            {isBuilder && <small className='txtGray'>{question && findQuesText(question)}</small>}
                            <div className='float-right'>
                              {isBuilder && editing !== sequence && (
                                <>
                                  {!isEdit && (
                                    <Icon {...delIconProps} onClick={() => showDelQConfirm(sequence || !initFalsy)} />
                                  )}
                                  {!isAdd && !isDelQues && (
                                    <>
                                      <Icon {...editIconProps} disabled={isAllowedEdit} onClick={onEdit} />
                                      <Icon
                                        {...downIconProps}
                                        disabled={lastIcon}
                                        onClick={() => onUpDown(!initFalsy)}
                                      />
                                      <Icon {...upIconProps} disabled={firstIcon} onClick={() => onUpDown(initFalsy)} />
                                      <b className='mr10 mb10 iconColor'>{MiniType[type as number]}</b>
                                    </>
                                  )}
                                </>
                              )}
                              {!!allowScroll && type !== AnswerType.Single && !lastIcon && (
                                <Icon size='large' {...downIconProps} onClick={() => scrollToTarget(sequence)} />
                              )}
                              {(editing === sequence || isEdit) && (
                                <span className='mb10 mr10 mt10'>
                                  <ChkBox
                                    {...typeSwitchProps}
                                    label={type === AnswerType.Single ? 'Single' : 'Multiple'}
                                  />
                                  <Icon {...cancelIconProps} onClick={() => onCancel()} />
                                  <Icon {...updateIconProps} onClick={() => onUpdate(branchProps)} />
                                </span>
                              )}
                            </div>
                          </Grid.Column>
                        </Grid.Row>
                        {renderAnswer(branchProps, isBuilder)}
                      </Grid>
                    </List.Content>
                  </List.Item>
                )}
              </React.Fragment>
            );
          })
        : []}
      {delQOpen && <Confirm {...confirmQsProps} />}
      {delAOpen && <Confirm {...confirmAProps} />}
    </List>
  );
};

export default QATree;

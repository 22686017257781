import React from 'react';
import { useHistory } from 'react-router-dom';
import { CCConstants } from './CCStructure';
import { Button, ChkBox, Form, Icon, Select } from '../Inputs';
import {
  Account,
  ButtonProps,
  FormProps,
  IconProps,
  NS,
  NU,
  Project,
  ProjectsList,
  Questionnaire,
  SelectValue,
} from '../../model';
import { initFalsy } from '../../constants';
import { va } from '../../utils/arrayUtils';
import isMobileDevice from '../../utils/deviceUtils';
import { joinAsFullName } from '../../utils/common';
import { Label } from 'semantic-ui-react';

type O = { [key: string]: string | number };
type TV = { text: string; value: NU };

interface Props extends FormProps {
  clearEditable: Function;
  hasRows: boolean;
  isLoading: Function;
  managerId: number;
  managers: Array<Account>;
  onCCFlush: Function;
  onUnClean: Function;
  pid: number;
  questions: Array<Questionnaire>;
  projects: ProjectsList;
  quesId: number;
  setQuesId: Function;
  setPM: Function;
  setProject: Function;
  setUseLambda: Function;
  useLambda: boolean;
}

const Filters: React.FC<Props> = (props) => {
  const strEmpty = '';
  const initState = [{ key: -1, value: '', text: '' }];
  const {
    clearEditable,
    handleSubmit,
    hasRows,
    isLoading,
    isSubmitting,
    managerId,
    managers,
    onCCFlush,
    onUnClean,
    questions,
    pid,
    projects,
    quesId,
    setQuesId,
    setPM,
    setProject,
    setUseLambda,
    useLambda,
  } = props;
  const hist = useHistory();
  const [pmOpts, setPMOpts]: [O[], Function] = React.useState(initState);
  const [proOpts, setProOpts]: [O[], Function] = React.useState(initState);
  const [isPmDisabled, setPmDisabled] = React.useState(initFalsy);
  const [lastQuesList, setLastQuesList] = React.useState([] as TV[]);
  const [lastQuesSel, setLastQuesSel] = React.useState(strEmpty as NS);
  const mapProjectOpts = (rows: Project[]) => {
    if (!va(rows)) {
      return [];
    }
    return rows.map((p) => ({ text: p.name, value: p.id }));
  };
  const mapPMOpts = (rows: Account[]) => {
    if (!va(rows)) {
      return [];
    }
    return rows.map((p) => ({ text: joinAsFullName(p.firstName, p.lastName), value: p.id }));
  };
  const mapOpenQues: (questions: Questionnaire[]) => TV[] = (questions) => {
    return questions.map((qs) => {
      return { text: qs.question?.title as string, value: qs.question?.id };
    });
  };
  const clearProcess = () => {
    setPmDisabled(initFalsy);
    setProject(strEmpty);
    setPM(strEmpty);
    clearEditable(CCConstants.WNsm);
    clearEditable(CCConstants.PWNsm);
    clearEditable(CCConstants.NWNsm);
    clearEditable(CCConstants.PWONsm);
    clearEditable(CCConstants.NWONsm);
  };
  const initProcess = () => {
    const _proOpts = mapProjectOpts(projects?.rows as Project[]);
    const _managerOpts = mapPMOpts(managers);
    if (va(questions)) {
      setLastQuesList(mapOpenQues(questions));
    }
    setPMOpts(_managerOpts);
    setProOpts(_proOpts);
  };
  const onPmChange = (value: SelectValue) => {
    clearProcess();
    if (!va(projects?.rows) || !value) {
      return;
    }
    isLoading(!initFalsy);
    setPM(value as number);
    const _proOpts = mapProjectOpts(projects.rows.filter((i) => i.managerId === value));
    setProOpts(_proOpts);
    isLoading(initFalsy);
  };
  const onQuesChange = (value: SelectValue) => {
    isLoading(!initFalsy);
    setQuesId(value as number);
    setLastQuesSel(value as number);
    isLoading(initFalsy);
  };
  const onProjectChange = (value: SelectValue) => {
    clearProcess();
    if (!va(projects?.rows) || !value) {
      return;
    }
    isLoading(!initFalsy);
    setPmDisabled(!initFalsy);
    setProject(value as number);
    const foundProject = projects?.rows.find((i) => i.id === value) as Project;
    setPM(foundProject.managerId);
    isLoading(initFalsy);
  };
  const onReset = async () => {
    await onUnClean();
    await onCCFlush();
    setQuesId(strEmpty);
    clearProcess();
  };
  const onGoBack = async () => {
    await onUnClean();
    hist.goBack();
  };
  const firstRun = () => {
    initProcess();

    return clearProcess;
  };
  const projectProps = {
    cols: { computer: 8, tablet: 8 },
    clearable: initFalsy,
    hasFirstEmptyOpt: !initFalsy,
    onChange: onProjectChange,
    options: proOpts,
    placeholder: 'Select Project',
    value: pid,
  };
  const pmProps = {
    cols: { computer: 8, tablet: 8 },
    clearable: initFalsy,
    disabled: isPmDisabled,
    hasFirstEmptyOpt: !initFalsy,
    onChange: onPmChange,
    options: pmOpts,
    placeholder: 'Select Project Manager',
    value: managerId,
  };
  const openEndedQuesProps = {
    cols: { computer: 8, tablet: 8 },
    clearable: !initFalsy,
    hasFirstEmptyOpt: !initFalsy,
    onChange: onQuesChange,
    options: lastQuesList,
    placeholder: 'Select Open-ended Question',
    value: quesId,
  };
  const selectProps = [projectProps, pmProps, openEndedQuesProps];
  const submitButton = {
    className: 'mt10',
    content: 'Filter',
    disabled: isSubmitting,
    loading: isSubmitting,
    primary: !initFalsy,
    type: 'submit',
  };
  const cancelButton = {
    content: 'Reset',
    className: `mt10 ${isMobileDevice ? '' : 'ml10'}`,
    onClick: onReset,
    secondary: !initFalsy,
    type: 'button',
  };
  const buttons = [submitButton, cancelButton];
  const chkProps = {
    checked: useLambda,
    className: 'invisible mt10',
    label: 'Using Lambda Server',
    onChange: () => setUseLambda(!useLambda),
    toggle: !initFalsy,
  };
  const goBackProps: IconProps = {
    className: 'float-right hand-pointer mb10',
    name: 'undo',
    onClick: onGoBack,
    title: 'Return to Previous Page',
  };
  const lastSelectedQuestion = lastQuesList.find((lq) => lq.value === lastQuesSel);

  React.useEffect(firstRun, [questions]);

  return (
    <Form className='filters' onSubmit={handleSubmit}>
      <Icon {...goBackProps} />

      {selectProps.map((selProp, idx) => (
        <Select key={idx} {...selProp} />
      ))}
      {buttons.map((btProps: ButtonProps, idx) => (
        <Button key={idx} {...btProps} />
      ))}
      {<ChkBox {...chkProps} />}

      {!hasRows && !quesId && lastSelectedQuestion && (
        <div className='field'>
          <Label color='orange' content='Last Selected Question' ribbon={!initFalsy} />
          {lastSelectedQuestion?.text}
        </div>
      )}
    </Form>
  );
};

export default Filters;

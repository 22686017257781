import createReducer from './createReducer';
import { Action, ActionType, Question, T } from '../../model';

const initQs: Question[] = [];
export const questions = createReducer(initQs, {
  [ActionType.QUESTIONS_FETCH](_store: Function, action: Action<Question[]>) {
    return action.payload;
  },

  [ActionType.QUESTIONS_FLUSH](_store: Function, action: Action<T>) {
    return action.payload;
  },
});

export const quesProjectList = createReducer(initQs, {
  [ActionType.QUESTION_PROJECT_LIST](_store: Function, action: Action<T>) {
    return action.payload;
  },

  [ActionType.QUESTIONS_FLUSH](_store: Function, action: Action<T>) {
    return action.payload;
  },
});

import { Action, ActionType } from '../../model';
import createReducer from './createReducer';

const initState: {} = {};

export const comments = createReducer(initState, {
  [ActionType.COMMENTS_FETCH](_store: Function, action: Action<any>) {
    return action.payload;
  },

  [ActionType.COMMENTS_CLEAN](_store: Function, action: Action<void>) {
    return action.payload;
  },

  [ActionType.COMMENTS_FLUSH](_store: Function, action: Action<void>) {
    return action.payload;
  },
});

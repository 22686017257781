import { ActionType, Alert, Call, CallLog, CleanResponse, CLUPayload, Dispatcher, Survey, T } from '../../model';
import { setAlert, setSuccess } from './alert';
import { va } from '../../utils/arrayUtils';
import { downloadAsCSV, joinAsFullName, jsonToCsv, optOutSecretKey } from '../../utils/common';
import { del, get, save } from '../../utils/storage';
import { piz } from '../../utils/numUtils';
import { CST_TIMEZONE, initFalsy, OPT_OUT_AGREE_KEY, SurCallResponse, SurveyStatus } from '../../constants';
import { usDateTime } from '../../utils/dateUtils';

const apiPath = 'calls';
const apiPathCallLock = 'call-lock';
const apiPathCallLog = `${apiPath}-log`;
const apiPathCallProgress = `${apiPath}-progress`;
const apiPathCallStats = 'call-stats';
const apiPathCallSurvey = 'call-survey';

type FCI = (projectId: number, surveyorId: number) => Dispatcher;
type FSCH = (projectId: number, userId: number, isWeekly: boolean, isManager: boolean) => Dispatcher;
type FSCL = (
  skip?: number,
  limit?: number,
  orderBy?: string,
  orderDir?: string,
  isManager?: boolean,
  userId?: number,
  surveyorId?: number,
  searchPhone?: string,
  responseType?: string,
  from?: string | undefined,
  to?: string,
  projectId?: number,
  isComplete?: boolean,
  type?: number | undefined,
) => Dispatcher;

type PSCL = (updPayload: CLUPayload) => Dispatcher;
type FCS = (
  from: string,
  to: string,
  projectId: number,
  managerId: number,
  surveyorId: number,
  weekly: boolean,
  monthly: boolean,
  type: number | undefined,
) => Dispatcher;

/**
 * Flush Surveyor Earnings
 */
export const flushSurveyorEarnings: () => Dispatcher = () => async (dispatch) => {
  try {
    dispatch({ type: ActionType.FLUSH_SURVEYOR_EARNINGS, payload: [] });
    dispatch({ type: ActionType.FLUSH_CHARTS_EARNINGS, payload: [] });
    return;
  } catch (err) {
    dispatch(setAlert(err));
  }
};

/**
 * Flush Call Stats
 */
export const flushCallStats: () => Dispatcher = () => async (dispatch) => {
  try {
    dispatch({ type: ActionType.FLUSH_CALL_STATS, payload: [] });
    dispatch({ type: ActionType.FLUSH_CHART_CALL_STATS, payload: [] });
    return;
  } catch (err) {
    dispatch(setAlert(err));
  }
};

/**
 * Flush Calls
 */
export const flushCallInfo: () => Dispatcher = () => async (dispatch, _store, _api) => {
  try {
    dispatch({ type: ActionType.PM_SAMPLING_FLUSH, payload: [] });
    dispatch({ type: ActionType.OPS_ASSIGNMENTS_FLUSH, payload: {} });
    return dispatch({ type: ActionType.FLUSH_CALLS_CHARTS, payload: {} });
  } catch (err) {
    dispatch(setAlert(err));
  }
};

/**
 * Flush Call Logs
 */
export const flushCallLogs: () => Dispatcher = () => async (dispatch, _store, _api) => {
  try {
    return dispatch({ type: ActionType.FLUSH_CALL_LOGS, payload: {} });
  } catch (err) {
    dispatch(setAlert(err));
  }
};

/**
 * Flush Call Log Info
 */
export const flushCallLogInfo: () => Dispatcher = () => async (dispatch, _store, _api) => {
  try {
    return dispatch({ type: ActionType.FLUSH_CALL_LOG_INFO, payload: {} });
  } catch (err) {
    dispatch(setAlert(err));
  }
};

/**
 * Fetch Call Info
 * @param projectId
 * @param surveyorId
 */
export const fetchCallInfo: FCI = (projectId, surveyorId) => async (dispatch, _store, api) => {
  try {
    const payload = { projectId, surveyorId };
    const data = await api.post(apiPath, payload);
    const { samplingParameters, assignedGoals, ...callInfo } = data;
    dispatch({ type: ActionType.SURVEYOR_FETCH_CALL_INFO, payload: callInfo });
    // dispatch({type: ActionType.OPS_ASSIGNMENTS_LIST, payload: assignedGoals});
    dispatch({ type: ActionType.PM_SAMPLING_FETCH, payload: samplingParameters });
    dispatch({ type: ActionType.SURVEYOR_CALL_LOCK, payload: {} });
    const optOutKey = optOutSecretKey(data.projectId as string, data.callId);
    save(optOutKey, new Date().toISOString());

    return data;
  } catch (err) {
    dispatch(setAlert(err));
    throw err;
  }
};

/**
 * un-lock call
 */
export const unLockCall: () => Dispatcher = () => async (dispatch, store, api) => {
  try {
    const {
      visitCall: { projectId, callId },
    } = store();
    const optOutKey = optOutSecretKey(projectId, callId);
    const optOutAgreeKey = optOutSecretKey(projectId, callId, OPT_OUT_AGREE_KEY);
    const getOptOut = get(optOutKey);
    if (getOptOut) {
      const optOutAgreeRes = get(optOutAgreeKey);
      const result = !!optOutAgreeRes ? piz(optOutAgreeRes) : optOutAgreeRes;
      const payload = { callId, projectId, result };

      await api.put(apiPathCallLock, payload);
      dispatch({ type: ActionType.SURVEYOR_CALL_UN_LOCK, payload: {} });
    }

    del(optOutKey);
    del(optOutAgreeKey);
    dispatch(flushCallInfo());
    return;
  } catch (err) {
    dispatch(setAlert(err));
  }
};

/**
 * Save Survey Call Response/Status (Update Call)
 * @param data
 * @param isDisplayMsg
 */
export const surveyCallResponse: (data: Call, isDisplayMsg: boolean) => Dispatcher =
  (data, isDisplayMsg) => async (dispatch, _store, api) => {
    try {
      const surveyCallRes: CleanResponse = await api.put(apiPath, data);
      dispatch({ type: ActionType.SURVEYOR_CALL_RESPONSE });
      isDisplayMsg && dispatch(setSuccess(surveyCallRes.message));
      return;
    } catch (err) {
      dispatch(setAlert(err));
    }
  };

/**
 * Fetch Call History
 * @param projectId
 * @param userId
 * @param isWeekly
 * @param isManager
 */
export const fetchSurCallHistory: FSCH = (projectId, userId, isWeekly, isManager) => async (dispatch, _store, api) => {
  try {
    const query = Object.assign({ isWeekly, projectId }, isManager ? { managerId: userId } : { surveyorId: userId });
    const data = await api.get(apiPathCallProgress, null, query);
    dispatch({ type: ActionType.FETCH_CALLS_CHARTS, payload: data });
    return data;
  } catch (err) {
    dispatch(setAlert(err));
  }
};

/**
 * Fetch Call Log Details
 * @param callLogId
 */
export const fetchCallLogInfo: (callLogId: number) => Dispatcher = (callLogId) => async (dispatch, _store, api) => {
  try {
    const payload = await api.get(`${apiPathCallLog}/${callLogId}`);
    dispatch({ type: ActionType.FETCH_CALL_LOG_INFO, payload });
    return payload;
  } catch (err) {
    dispatch(setAlert(err));
  }
};

/**
 * Fetch Call History
 * @param skip
 * @param limit
 * @param orderBy
 * @param orderDir
 * @param isManager
 * @param userId
 * @param surveyorId
 * @param searchPhone
 * @param responseType
 * @param from
 * @param to
 * @param projectId
 * @param isComplete
 * @param type
 */
export const fetchClLog: FSCL =
  (
    skip,
    limit,
    orderBy,
    orderDir,
    isManager,
    userId,
    surveyorId,
    searchPhone,
    responseType,
    from,
    to,
    projectId,
    isComplete,
    type,
  ) =>
  async (dispatch, _store, api) => {
    try {
      const [managerId] = [isManager ? userId : null];
      const query = {
        from,
        isComplete,
        limit,
        managerId,
        orderBy,
        orderDir,
        projectId,
        responseType,
        searchPhone,
        skip,
        surveyorId,
        to,
        type,
        tz: Intl.DateTimeFormat().resolvedOptions().timeZone || '',
      };
      const data = await api.get(apiPathCallLog, null, query);
      dispatch({ type: ActionType.FETCH_CALL_LOGS, payload: data });
      return data;
    } catch (err) {
      dispatch(setAlert(err));
    }
  };

/**
 * Update CallLog Details
 * @param data
 */
export const putSurCallLog: PSCL = (data) => async (dispatch, _store, api) => {
  try {
    const res = await api.put(apiPathCallLog, data);
    return dispatch({ type: ActionType.PUT_CALL_LOG_INFO, payload: res });
  } catch (err) {
    dispatch(setAlert(err));
  }
};

/**
 * Fetch Call Stats
 * @param from
 * @param to
 * @param projectId
 * @param managerId
 * @param surveyorId
 * @param weekly
 * @param monthly
 * @param type
 */
export const fetchCallStats: FCS =
  (from, to, projectId, managerId, surveyorId, weekly, monthly, type) => async (dispatch, _store, api) => {
    try {
      const q = { from, managerId, monthly, projectId, surveyorId, to, type, weekly };
      const callStats = await api.get(apiPathCallStats, null, q);
      dispatch({ type: ActionType.CALL_STATS, payload: callStats.callStatsData });
      dispatch({ type: ActionType.CHART_CALL_STATS, payload: callStats.chartData });
      return;
    } catch (err) {
      dispatch(setAlert(err));
    }
  };

/**
 * Save Call Survey Data
 * @param survey
 * @param isPut
 */
export const saveCallSurvey: (survey: Survey, isPut: boolean) => Dispatcher =
  (survey, isPut) => async (dispatch, _store, api) => {
    try {
      if (va(survey)) {
        const data = { survey, isPut };
        const payload = await api.post(apiPathCallSurvey, data);
        return dispatch({ type: ActionType.SAVE_CALL_SURVEY, payload });
      }
    } catch (err) {
      dispatch(setAlert(err));
    }
  };

/**
 * Export Call Logs CSV
 */
export const fetchClLogCSV: FSCL =
  (isManager, userId, surveyorId, searchPhone, from, to, projectId, responseType, isComplete) =>
  async (dispatch, _store, api) => {
    try {
      const [managerId] = [isManager ? userId : null];
      const query = { from, isComplete, managerId, projectId, searchPhone, responseType, surveyorId, to };
      const { data, message } = await api.get(`${apiPathCallLog}/export`, null, query);
      const fileTitle = `${apiPathCallLog}-${new Date().toUSDateString('', !initFalsy)}`;
      if (!va(data)) {
        return dispatch(setAlert({ message } as Alert));
      }

      const headers = [
        'Project Name',
        'Surveyor',
        'Survey Language',
        'Cell Phone',
        'Home Phone',
        'Call Status',
        'Survey Status',
        'Call Date/Time',
        'Survey Date/Time',
      ];
      const processASCSV = (json: CallLog[]) => {
        return json.map((i: CallLog) => {
          const surveyCompText = 'Survey Complete';
          const {
            hasSurveys,
            isPartial,
            phoneCell,
            phoneHome,
            project,
            result,
            surveyLanguage,
            surveyedOn,
            surveyCompletedOn,
            user,
          } = i;
          const resultText = result === SurCallResponse['Agreed to Survey'] ? surveyCompText : SurCallResponse[result];
          const surveyor = joinAsFullName(user?.firstName, user?.lastName);
          const surveyStatus = isPartial
            ? !!hasSurveys
              ? SurveyStatus[SurveyStatus.Partial]
              : ''
            : SurveyStatus[SurveyStatus.Completed];
          return {
            [headers[0]]: project?.name,
            [headers[1]]: surveyor,
            [headers[2]]: surveyLanguage.name,
            [headers[3]]: phoneCell,
            [headers[4]]: phoneHome,
            [headers[5]]: resultText,
            [headers[6]]: surveyStatus,
            [headers[7]]: surveyedOn ? usDateTime(surveyedOn, CST_TIMEZONE) : '',
            [headers[8]]: surveyCompletedOn ? usDateTime(surveyCompletedOn, CST_TIMEZONE) : '',
          };
        });
      };
      const formattedRoster: T = processASCSV(data);
      const csv = jsonToCsv(formattedRoster);
      downloadAsCSV(fileTitle, csv);
      dispatch(setSuccess(message));
    } catch (err) {
      dispatch(setAlert(err));
    }
  };

import { array, date, mixed, number, object, string } from './validations';
import {
  AnswerType,
  EMAIL_VALIDATE_REGEX,
  PWD_TEST1_REGEX,
  PWD_TEST2_REGEX,
  PWD_TEST3_REGEX,
  PWD_TEST4_REGEX,
  QSF,
} from '../constants';
import { FormErrors, FormikValues, PwdR, QuestionnaireItem, T } from '../model';
import { va } from './arrayUtils';

const REQUIRED = 'Required';
const EMAIL = 'Invalid email address';
const ATLEAST = (char: number) => `At least ${char} characters`;

export const pwdRequirements = [
  { key: 1, value: 'At Least One Letter', hasError: true },
  { key: 2, value: 'At Least One Capital Letter', hasError: true },
  { key: 3, value: 'At Least One Number', hasError: true },
  { key: 4, value: 'At least 8 characters long', hasError: true },
];

const validateRequired = (fieldList: string[], values: FormikValues) => {
  const errors: FormErrors = {};

  fieldList.forEach((field) => {
    const fieldType = typeof values[field];
    switch (fieldType) {
      case 'object':
        if (!va(values[field])) {
          errors[field] = REQUIRED;
        }
        break;
      default:
        if (!values[field]) {
          errors[field] = REQUIRED;
        }
        break;
    }
  });

  return errors;
};

const validateEmail = (values: FormikValues) => {
  const errors: FormErrors = {};

  if (values.email && !EMAIL_VALIDATE_REGEX.test(values.email)) {
    errors.email = EMAIL;
  }

  return errors;
};

// content of type any as hasError can have multiple error entry points Formik Errors
export const hasError = (content: T) => (!!content ? { content, pointing: 'below' } : false);

/**
 * Common form validation, checks for required fields and email
 * @param requiredFields
 * @param values
 */
export const validate = (requiredFields: string[], values: FormikValues) => {
  const errors: FormErrors = {};
  Object.assign(errors, validateRequired(requiredFields, values));

  Object.assign(errors, validateEmail(values));

  return errors;
};

/**
 * Icon validator for forms with password & confirm password keys
 * @param requiredFields
 * @param confirmPwdKey
 * @param requirements
 * @param values
 */
export const pwdValidations = (
  requiredFields: string[],
  confirmPwdKey: string,
  requirements: PwdR[],
  values: FormikValues,
) => {
  const errors: T = {};
  Object.assign(errors, validateRequired(requiredFields, values));

  if (values.password && values[confirmPwdKey] && values.password !== values[confirmPwdKey]) {
    errors[confirmPwdKey] = 'Password & confirm password do not match.';
  }

  requirements.forEach((reqTest) => {
    reqTest.hasError = false;
    const pwdRegex: T = { PWD_TEST1_REGEX, PWD_TEST2_REGEX, PWD_TEST3_REGEX, PWD_TEST4_REGEX };
    const pattern = `PWD_TEST${reqTest.key}_REGEX`;
    const regExp = new RegExp(pwdRegex[pattern]);
    if (values.password && !regExp.test(values.password)) {
      errors['password'] = reqTest.value;
      reqTest.hasError = true;
    }
    if (values[confirmPwdKey] && !regExp.test(values[confirmPwdKey])) {
      errors[confirmPwdKey] = reqTest.value;
      reqTest.hasError = true;
    }
  });

  return errors;
};

/**
 * Formatting as US Phone Number
 * @param phone
 */
export const asPhone = (phone: string) => {
  const phoneRegex = /(\d{3})(\d{3})(\d{4})/;
  const returnAs = '($1) $2-$3';
  return phone?.replace(phoneRegex, returnAs);
};

/**
 * US phone number format
 * @param number
 */
export const formatAsPhone = (number: string) => {
  const cleaned = ('' + number).replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = match[1] ? '+1 ' : '';
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return null;
};

export const clientAddSchema = object.shape({
  name: string.required(REQUIRED),
  abbr: string.required(REQUIRED),
  stateId: string.required(REQUIRED),
  contacts: array
    .of(
      object.shape({
        firstName: string.min(2, ATLEAST(2)).required(REQUIRED),
        lastName: string.min(2, ATLEAST(2)).required(REQUIRED),
        email: string.email(EMAIL).required(REQUIRED),
      }),
    )
    .required(REQUIRED)
    .min(1, ATLEAST(1)),
});

export const projectAddSchema = object.shape({
  name: string.required(REQUIRED),
  typeId: number.required(REQUIRED),
  statusId: string.required(REQUIRED),
  managerId: string.required(REQUIRED),
  reportFreqId: string.required(REQUIRED),
  startDate: date.required(REQUIRED),
  contacts: array.min(0).of(
    object.shape({
      firstName: string.min(2, ATLEAST(2)).required(REQUIRED),
      lastName: string.min(2, ATLEAST(2)).required(REQUIRED),
      email: string.email(EMAIL).required(REQUIRED),
    }),
  ),
  billableRate: number.required(REQUIRED),
  paymentRate: number.required(REQUIRED),
});

const qaTitleSchema = {
  title: string.required(REQUIRED).min(2, ATLEAST(2)),
};

export const questionSchema = object.shape({
  ...qaTitleSchema,
  category: string.required(REQUIRED).min(2, ATLEAST(2)).nullable(),
  langQuestions: array
    .of(
      object.shape({
        langId: string.required(REQUIRED),
        quesText: string.required(REQUIRED),
      }),
    )
    .required(REQUIRED)
    .min(1, ATLEAST(1)),
});

export const answerSchema = object.shape({
  ...qaTitleSchema,
  langAnswers: array
    .of(
      object.shape({
        langId: string.required(REQUIRED),
        ansText: string.required(REQUIRED),
      }),
    )
    .required(REQUIRED)
    .min(1, ATLEAST(1)),
});

export const questionnaireSchema = (qas: Partial<QuestionnaireItem>[]) => {
  const valType = (typ: AnswerType) => {
    switch (typ) {
      case AnswerType.Single:
        return number.required(REQUIRED);
      case AnswerType.Multiple:
        return mixed.required(REQUIRED);
      // array.of(mixed.required(REQUIRED))
      // .min(1)
      // .required(REQUIRED);
    }
  };
  const arrAsObj = (arr: T[]) => arr.reduce((ac, a) => ({ ...ac, [`${QSF}${a.sequence}`]: valType(a.type) }), {});
  return object.shape(arrAsObj(qas));
};

import React from 'react';
import { useParams } from 'react-router-dom';
import { PublicLayout } from './index';
import { ForgotPassword, ResetPassword } from '../../components/Accounts';

const ForgotPassPage: React.FC = () => {
  const { resetToken } = useParams();

  return (
    <PublicLayout>
      {resetToken && <ResetPassword />}
      {!resetToken && <ForgotPassword />}
    </PublicLayout>
  );
};

export default ForgotPassPage;

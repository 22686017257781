import request from 'superagent';
import { IRequestParams, T } from './model';
import { SESSION_AUTH_KEY } from './constants';
import { get } from './utils/storage';
import { pez } from './utils/stringUtils';

const concatAuthHeader = (authToken: string): string => {
  return authToken ? 'Bearer ' + authToken : '';
};

export abstract class ApiService {
  protected async executeRequest<S>(params: IRequestParams) {
    let authorization: string = '';
    authorization = concatAuthHeader(pez(get(SESSION_AUTH_KEY) as string));

    return new Promise<S>((resolve, reject) => {
      let req: request.Request = request(params.method, params.url).set('Content-Type', 'application/json');

      const queryParameters = params.queryParameters;
      if (queryParameters) {
        req.query(queryParameters);
      }

      if (authorization) {
        req.set('Authorization', authorization);
      }

      if (params.body) {
        req.send(params.body);
      }

      req.end((error: T, response: request.Response) => {
        if (error) {
          console.log(`[API] Error in ${params.method} ${params.url}:`, error.message, error.code);

          if (queryParameters) {
            console.log(`[API] Query parameters:`, queryParameters);
          }

          if (params.body) {
            console.log(`[API] Body:`, params.body);
            req.send(params.body);
          }

          console.log(`[API] Authorization: ${authorization ? 'Set' : 'Not set'}`);

          // Check for specific error types
          if (error.message.includes('CORS')) {
            console.log(`[API] Potential CORS issue`);
          }
          if (error.response) {
            console.log(`[API] Response status:`, error.response.status);
            console.log(`[API] Response body:`, error.response.body);
          }
          if (error.code === 'ECONNABORTED') {
            console.log(`[API] Request was aborted or terminated`);
            reject(new Error('Request was aborted or terminated'));
          } else if (error.code === 'ETIMEDOUT') {
            console.log(`[API] Request timed out`);
            reject(new Error('Request timed out'));
          } else {
            reject(error);
          }
          return;
        }
        if (!response.ok) {
          console.log(`[API] Response error: Status ${response.status}`);
          console.log(`[API] Response body:`, response.body);
          reject(Object.assign({}, response.body, { code: Number(response.status) }));
        } else {
          const res = response.body || response.text;
          resolve(res);
        }
      });
    });
  }
  protected async executeRequestWithRetry<S>(params: IRequestParams, retries = 3) {
    for (let attempt = 1; attempt <= retries; attempt++) {
      try {
        return await this.executeRequest<S>(params);
      } catch (error) {
        if (attempt === retries) throw error;
        console.log(`Retry attempt ${attempt} failed:`, error);
        await new Promise((resolve) => setTimeout(resolve, 1000 * attempt)); // Exponential backoff
      }
    }
  }
}

export class Api extends ApiService {
  baseApiUrl: string;

  constructor(url: string) {
    super();
    this.baseApiUrl = url;
  }

  public async get(url: string, data: T, query: T) {
    const requestParams: IRequestParams = {
      method: 'GET',
      url: `${this.baseApiUrl}/${url}`,
      body: data,
      queryParameters: query,
    };
    return this.executeRequest(requestParams);
  }

  public async post(url: string, data?: T, query?: T) {
    const requestParams: IRequestParams = {
      method: 'POST',
      url: `${this.baseApiUrl}/${url}`,
      body: data,
      queryParameters: query,
    };
    return this.executeRequest(requestParams);
  }

  public async put(url: string, data: T, query: T) {
    const requestParams: IRequestParams = {
      method: 'PUT',
      url: `${this.baseApiUrl}/${url}`,
      body: data,
      queryParameters: query,
    };
    return this.executeRequest(requestParams);
  }

  public async patch(url: string, data: T, query: T) {
    const requestParams: IRequestParams = {
      method: 'PATCH',
      url: `${this.baseApiUrl}/${url}`,
      body: data,
      queryParameters: query,
    };
    return this.executeRequest(requestParams);
  }

  public async delete(url: string, data?: T) {
    const requestParams: IRequestParams = {
      method: 'DELETE',
      url: `${this.baseApiUrl}/${url}`,
      body: data,
    };
    return this.executeRequest(requestParams);
  }
}

import React from 'react';
import { useHistory } from 'react-router';
import { QuestionnaireBuilderMain, QuestionnaireLogic } from './index';
import { Path } from '../../routes';
import { initFalsy } from '../../constants';
import { ButtonProps } from '../../model';
import { useActions } from '../../redux/actions';
import * as questionnaireActions from '../../redux/actions/questionnaire';

interface QLBProps {
  isOps?: boolean;
}

const QuestionnaireLogicBuilder: React.FC<QLBProps> = ({ isOps }) => {
  const initPG = 0,
    hist = useHistory(),
    [pg, setPG] = React.useState(initPG),
    Pages = [QuestionnaireBuilderMain, QuestionnaireLogic],
    isLastPage = pg === Pages.length - 1,
    qre = useActions(questionnaireActions),
    backToList = () => {
      qre.flushQuestionnaire();
      hist.push(isOps ? Path.OPQuestionnaireList : Path.PMQuestionnaireList);
    },
    onBackOrCancel = () => {
      if (pg === 0) {
        backToList();
      }

      const newPg = pg - 1;

      setPG(newPg);
    },
    onNext = () => {
      if (isLastPage) {
        setPG(initPG);
        backToList();
      } else {
        const newPg = pg + 1;
        setPG(newPg);
      }
    },
    backButton: ButtonProps = {
      className: 'float-right',
      content: isLastPage ? 'Return to Builder' : 'Return to List',
      icon: 'angle double left',
      labelPosition: 'left',
      onClick: onBackOrCancel,
      type: 'button',
    },
    goBackProps: ButtonProps = {
      circular: !initFalsy,
      className: 'float-right',
      icon: 'undo',
      onClick: backToList,
      primary: !initFalsy,
      title: 'Cancel and Go Back',
    },
    nextButton: ButtonProps = {
      className: 'ml15 float-right',
      content: isLastPage ? 'Finish' : 'Next',
      icon: 'angle double right',
      labelPosition: 'right',
      onClick: onNext,
      primary: !initFalsy,
      type: 'button',
    },
    buttons = [nextButton, backButton],
    pageProps = { buttons, goBackProps, isOps };

  return <>{React.createElement(Pages[pg], pageProps)}</>;
};

export default QuestionnaireLogicBuilder;

import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { Path } from '../../routes';
import { Button, Card, Grid, TextBox } from '../Inputs';
import { CallDetail, CallSurComments } from '../Generic';
import { ButtonProps, CallLog, Escalation, ProjectContact, RC, StoreT, T } from '../../model';
import { useActions } from '../../redux/actions';
import { Questionnaire } from '../Questionnaire';
import * as callActions from '../../redux/actions/calls';
import * as escalationActions from '../../redux/actions/escalations';
import { Loader } from '../index';
import {
  initFalsy,
  Language,
  PatientType,
  PRJ_CONTACT_TYPE,
  PROJECT_TYPE,
  REPORTING_FREQ,
  RosterKeys,
} from '../../constants';
import { asPhone } from '../../utils/validation';
import { joinAsFullName } from '../../utils/common';
import { pez } from '../../utils/stringUtils';
import { piz } from '../../utils/numUtils';
import { vo } from '../../utils/objectUtils';
import { uniq, va } from '../../utils/arrayUtils';

interface ESD {
  isOPS?: boolean;
}

type ParserFux = ({ field, value }: { field: string; value: string }) => { label: string; value: string };

const PMEscalationDetails = (props: ESD) => {
  const callInfo: CallLog = useSelector((state: StoreT) => state.callLog);
  const escalationInfo: Escalation = useSelector((state: StoreT) => state.escalationInfo);
  const { log, notes, project, status, surveyor } = escalationInfo;
  const hist = useHistory();
  const { id } = useParams();
  const cat = useActions(callActions);
  const esc = useActions(escalationActions);
  const [loading, setLoading] = React.useState(initFalsy);
  const [selLanguage, setLanguage] = React.useState(Language.English);
  const { isOPS } = props;
  const backToGrid = () => {
    hist.push(isOPS ? Path.OPSEscalations : Path.PMEscalations);
  };
  const onResolve = async () => {
    const payload = { callHisId: log.id, id: escalationInfo.id };
    await esc.updateEscalationStatus(payload);
    backToGrid();
  };
  const getContactsDetails = () => {
    const style = bottomOrange,
      types = uniq(contacts.map((c) => c.typeId)).sort(),
      finalConArr: { label: string; style?: T; value: string }[] = [];

    types.forEach((type) => {
      const contactType: string = PRJ_CONTACT_TYPE[type as number];
      const pContacts = contacts.filter((i) => i.typeId === type);
      pContacts.forEach((primaryContact) => {
        finalConArr.push({ label: contactType, value: primaryContact && primaryContact.firstName });
        finalConArr.push({
          label: `${contactType} Number`,
          value: primaryContact && (primaryContact.phoneNumber as string),
        });
        finalConArr.push({
          label: `${contactType} Email`,
          style: style,
          value: primaryContact && primaryContact.email,
        });
      });
    });
    return finalConArr;
  };
  const loadEscalationDetail = async () => {
    setLoading(!initFalsy);
    const escalation = await esc.fetchEscalationById(piz(id));
    if (escalation.log && escalation.log.id) {
      await cat.fetchCallLogInfo(escalation.log.id);
    }
    setLoading(initFalsy);
  };
  const fieldValueParser: ParserFux = ({ field, value }) => ({ label: field, value });
  const firRun = () => {
    loadEscalationDetail().then();

    return () => esc.flushEscalation();
  };
  const resolveButton: ButtonProps = Object.assign({
    className: '',
    color: !status ? '' : 'green',
    content: !status ? 'Resolve' : 'Resolved',
    floated: 'right',
    onClick: onResolve,
    primary: !status,
  });
  const cancelButton = {
    content: 'Go Back',
    className: 'float-right mr20',
    onClick: backToGrid,
    secondary: !initFalsy,
  };
  const buttons = [resolveButton, cancelButton];
  const bottomOrange = { borderBottom: '1px solid orange' };
  const contacts = va(project?.contacts) ? (project.contacts as ProjectContact[]) : [];
  const listItems = vo(escalationInfo.log)
    ? [
        { label: RosterKeys.PatientName, value: joinAsFullName(log.patientFirstName, log.patientLastName) },
        { label: RosterKeys.CellPhone, value: asPhone(log.phoneCell) },
        { label: RosterKeys.HomePhone, value: asPhone(log.phoneHome) },
        { label: 'Date Of Service', value: new Date(log.dos).toUSDateString() },
        { label: RosterKeys.Age, value: log.age },
        { label: RosterKeys.Sex, value: log.gender },
        { label: RosterKeys.ProviderName, value: log.providerName },
        { label: RosterKeys.Center, value: log.siteName },
        { label: RosterKeys.Department, value: log.departmentName },
        { label: RosterKeys.GuarantorName, value: log.guarantor },
        { label: RosterKeys.Language, value: log.language?.name },
        { label: RosterKeys.CHPW, value: log.chpw },
        { label: RosterKeys.PatientType, value: PatientType[log.typeId] },
        { label: RosterKeys.ProviderType, value: log.providerType },
        { label: RosterKeys.PayerType, value: log.payerType },
        { label: RosterKeys.Ethnicity, value: log.ethnicity },
        { label: RosterKeys.Race, value: log.race },
        {
          label: 'Language',
          value: log.surveyLanguageId ? Language[log.surveyLanguageId] || Language[log.languageId] : Language.English,
        },
      ]
    : [];
  const proDetails = [
    { label: 'Client Name', value: project?.client?.name },
    { label: 'Project Type', value: PROJECT_TYPE[project?.typeId] },
    { label: 'Reporting Period', style: bottomOrange, value: REPORTING_FREQ[project?.reportFreqId] },
  ].concat(getContactsDetails());
  const commonReadOnlyProps = { disabled: !initFalsy, readOnly: !initFalsy };
  const escComments = [{ label: 'Notes', placeholder: 'Escalation Notes', value: notes }];
  const callComments = vo(callInfo)
    ? [
        { ...commonReadOnlyProps, label: 'Positive', placeholder: 'Positive ', value: callInfo.positive },
        { ...commonReadOnlyProps, label: 'Negative', placeholder: 'Negative', value: callInfo.negative },
      ]
    : [];
  const callDetailProps = {
    listItems,
    listMainTitle: pez(project?.name),
    listSubTitle: `Surveyor Name: ${surveyor && joinAsFullName(surveyor.firstName, surveyor.lastName)}` as RC,
  };
  const pipeFields = (va(callInfo?.call?.visit?.visitInfo) ? callInfo?.call?.visit?.visitInfo : []) as T[];
  const questionnaireProps = {
    agreedToSurvey: initFalsy,
    callId: callInfo.id,
    languageId: callInfo.languageId,
    pipeItems: listItems.concat(pipeFields.map(fieldValueParser)),
    projectId: callInfo.projectId,
    setLanguage,
    surveyLangId: selLanguage,
    surveyCallId: callInfo?.callId,
    surveys: callInfo?.call?.survey,
  };

  React.useEffect(firRun, []);

  return (
    <Grid>
      {loading && <Loader />}

      <Grid.Row className='headerTop'>
        <Grid.Column width={16}>
          <h1 className='mainTitle'>Escalations Details</h1>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column computer={6} tablet={6} mobile={16}>
          <Grid>
            <Grid.Row>
              <Grid.Column width={16}>
                <CallDetail {...callDetailProps} />
                <CallSurComments header='Comments' items={callComments} />
              </Grid.Column>
              <Grid.Column width={16}>
                <Card className='mb20 disabledList' fluid={!initFalsy}>
                  <Card.Content>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column width={16}>
                          <h3 className='w100 subHeaderInner'>
                            <span>More Details</span>
                          </h3>
                        </Grid.Column>
                        {proDetails.map((f, k) => (
                          <Grid.Column key={k} computer={16} tablet={16} mobile={16}>
                            <TextBox disabled={!initFalsy} {...f} />
                          </Grid.Column>
                        ))}
                      </Grid.Row>
                    </Grid>
                  </Card.Content>
                </Card>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Grid.Column>

        <Grid.Column computer={10} tablet={12} mobile={16}>
          <Card fluid={initFalsy}>
            <Card.Content>
              <h3 className='subHeaderInner'>
                <span>Escalation Notes</span>
              </h3>
              <Grid>
                <Grid.Row>
                  <Grid.Column width={16}>
                    <CallSurComments items={escComments} />
                  </Grid.Column>
                  <Grid.Column width={16}>
                    {buttons.map((bp, idx) => (
                      <Button key={idx} {...bp} />
                    ))}
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Card.Content>
          </Card>
          <Grid.Column computer={10} tablet={12} mobile={16}>
            <Card fluid={initFalsy}>
              <Card.Content>
                {vo(callInfo) && !!callInfo.projectId && <Questionnaire {...questionnaireProps} />}
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default PMEscalationDetails;

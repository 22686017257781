import * as React from 'react';
import { useSelector } from 'react-redux';
import { Footer, Header, LeftMenu, Wrapper } from '../../components';
import { useActions } from '../../redux/actions';
import * as appActions from '../../redux/actions/nav';
import { ParentPath } from '../../routes';
import { NAV, ROLES, ShortRoles } from '../../constants';
import { RC, StoreT } from '../../model';
import * as authActions from '../../redux/actions/auth';

type P = { mainHeading?: string };
const CommonDashboard: React.FC<P & RC> = (props: P & RC) => {
  const { isExpanded } = useSelector((state: StoreT) => state.nav),
    { role } = useSelector((state: StoreT) => state.auth.user),
    roleVal = ROLES[role],
    roleShortName = ShortRoles[roleVal],
    { SetLeftNav, GetLeftNav } = useActions(appActions),
    { Load } = useActions(authActions),
    styles: React.CSSProperties = {
      position: 'relative',
      overflow: 'auto',
      transition: 'all .15s',
      padding: '88px 20px 0',
      marginLeft: `${isExpanded ? 300 : 75}px`,
    },
    toggleFunc = () => SetLeftNav(isExpanded),
    pageLifeCycle = () => {
      GetLeftNav();
      Load();
    };

  React.useEffect(pageLifeCycle, [isExpanded]);

  return (
    <Wrapper>
      <Header expanded={isExpanded} />

      <LeftMenu
        navItems={NAV[roleShortName]}
        parentLink={ParentPath[roleShortName as keyof typeof ParentPath]}
        expanded={isExpanded}
        onToggle={toggleFunc}
      />

      <main className='mainWrap' style={styles}>
        {props && props.mainHeading && <h1 className='mainTitle'>{props.mainHeading}</h1>}
        {props && props.children}
      </main>

      <Footer />
    </Wrapper>
  );
};

export default CommonDashboard;

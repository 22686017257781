import React, { useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { CardProps, ContainerProps, ItemProps, ItemTypes, REF } from './types';
import { noop } from '../../utils/common';
import { va } from '../../utils/arrayUtils';
import { DndWrapper } from './index';
import { initFalsy } from '../../constants';
import { T } from '../../model';

const DragNDrop: React.FC<ContainerProps> = (props) => {
  return (
    <DndWrapper>
      <DNDContainer items={props.items} onComplete={props.onComplete || noop} />
    </DndWrapper>
  );
};

const DNDContainer: React.FC<ContainerProps> = (props) => {
  const [cards, setCards] = useState([] as ItemProps[]);
  React.useEffect(() => {
    if (va(props.items)) {
      setCards(props.items);
    }
  }, [props.items]);

  const [, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect: (monitor) => {
      const onComp = props.onComplete || noop;
      if (monitor.didDrop()) {
        const ids = cards.map((i) => i.id);
        onComp(ids);
      }
    },
  });

  const moveCard = (id: string, atIndex: number) => {
    const { card, index } = findCard(id);
    // remove last index
    cards.splice(index, 1);
    // add at new index
    cards.splice(atIndex, 0, card);
    setCards([]);
    setCards(cards);
  };

  const findCard = (id: string) => {
    const card = cards.filter((c: T) => `${c.id}` === id)[0];
    return {
      card,
      index: cards.indexOf(card),
    };
  };

  return (
    <div ref={drop}>
      {cards.map((card: ItemProps, idx: number) => (
        <Card key={idx} id={`${card.id}`} content={card.content} moveCard={moveCard} findCard={findCard} />
      ))}
    </div>
  );
};

const Card: React.FC<CardProps> = ({ id, content, findCard, moveCard }) => {
  const cardStyle = {
    border: '1px dashed gray',
    padding: '10px',
    backgroundColor: 'white',
    cursor: 'move',
  };

  const originalIndex = findCard(id).index;
  const [{ isDragging }, drag] = useDrag({
    item: { type: ItemTypes.CARD, id, originalIndex },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (_dropResult, monitor) => {
      const { id: droppedId, originalIndex } = monitor.getItem();
      const didDrop = monitor.didDrop();
      if (!didDrop) {
        moveCard(droppedId, originalIndex);
      }
    },
  });
  const [, drop] = useDrop({
    accept: ItemTypes.CARD,
    canDrop: () => initFalsy,
    hover({ id: draggedId }: T) {
      if (draggedId !== id) {
        const { index: overIndex } = findCard(id);
        moveCard(draggedId, overIndex);
      }
    },
  });

  const opacity = isDragging ? 0.4 : 1;
  const border = isDragging ? '1px dashed gray !important' : '1px dotted #96989D !important';

  return (
    <div className='bbl' ref={(node: REF) => drag(drop(node))} style={{ ...cardStyle, opacity, border }}>
      {content}
    </div>
  );
};

export default DragNDrop;

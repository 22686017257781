import { Action, ActionType, SurveyorEarning } from '../../model';
import createReducer from './createReducer';

export const surveyorEarnings = createReducer([], {
  [ActionType.SURVEYOR_EARNINGS](_store: Function, action: Action<SurveyorEarning>) {
    return action.payload;
  },

  [ActionType.FLUSH_SURVEYOR_EARNINGS](_store: Function, action: Action<void>) {
    return action.payload;
  },
});

import React from 'react';
import { connect, useSelector } from 'react-redux';
import {
  Account,
  CallLog,
  CallLogList,
  CLUPayload,
  MiniAccount,
  ProjectsList,
  QuestionnaireItem,
  StoreT,
  T,
} from '../../model';
import { Card, Grid } from '../../components/Inputs';
import { CLR } from '../../components/CallLog';
import { initFalsy } from '../../constants';
import { useActions } from '../../redux/actions';
import * as callActions from '../../redux/actions/calls';
import { useParams } from 'react-router';
import { Loader } from '../../components';

interface CLDProps {
  callLogs: CallLogList;
  isManager?: boolean;
  isOPS?: boolean;
  projects: ProjectsList;
  surveyors: Array<MiniAccount>;
  user: Account;
}

const CallLogDownload = (props: CLDProps) => {
  const { user } = props;
  const { id }: T = useParams();
  console.log('id line no 55', id);
  const callLogGridHeaders = [
    'Call Date/Time',
    'Survey Date/Time',
    'Cell Phone',
    'Home Phone',
    'Call Status',
    'Survey Status',
    'Project',
    'Survey Language',
    'View Survey',
  ];
  const sortKeys = [
    'surveyedOn',
    'surveyCompletedOn',
    'phoneCell',
    'phoneHome',
    'result',
    'isPartial',
    'project.name',
    'surveyLanguage.name',
    '',
  ];
  const cat = useActions(callActions);

  const callInfo: CallLog = useSelector((state: StoreT) => state.callLog);
  const questionnaire: T = useSelector((state: StoreT) => state.questionnaire as QuestionnaireItem[]);
  const [loading, setLoading] = React.useState(initFalsy);

  const onResponseCancel = async () => {
    await cat.flushCallLogInfo();
  };

  const onUpdate = async (updCallLogPayload: CLUPayload) => {
    await cat.putSurCallLog(updCallLogPayload);
  };

  const fetchCallLogs = async (id: T) => {
    setLoading(!initFalsy);
    await cat.fetchCallLogInfo(id);
    setLoading(initFalsy);
  };

  const getCallLogs = () => {
    fetchCallLogs(id).then(() => {});
  };

  const downloadQuestionnaire = () => {
    if (questionnaire && questionnaire.length > 0) {
      setTimeout(() => {
        window.print();
        window.close();
      }, 1500);
    }
  };

  const CLRProps = {
    callInfo,
    onCancel: onResponseCancel,
    onUpdate,
    users: user,
  };

  if (props.isManager) {
    callLogGridHeaders.unshift('Surveyor');
    sortKeys.unshift('');
  }

  React.useEffect(getCallLogs, [id]);
  React.useEffect(downloadQuestionnaire, [questionnaire]);

  return (
    <Grid>
      {loading && <Loader />}
      <Grid.Row>
        <Grid.Column width={16}>
          <Card fluid={!initFalsy}>
            <CLR {...CLRProps} />
          </Card>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

const mp = (store: StoreT) => ({
  callLogs: store.callLogs,
  projects: store.projects,
  surveyors: store.surveyorInfo,
  user: store.auth.user,
});

export default connect(mp)(CallLogDownload);

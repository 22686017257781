export enum ROLES {
  Admin,
  'Project Manager',
  Operations,
  Surveyor,
  Quality,
  Finance,
  'Comment Cleaner',
  'CIMR Surveyor',
  'CIMR Manager',
  'Keller Surveyor',
}

export enum CLIENT_CONTACT_TYPE {
  'Billing Contact' = 2,
  'IT Contact' = 1,
  'Primary Contact' = 0,
  'Reporting Contact' = 3,
}

export enum PRJ_CONTACT_TYPE {
  'Billing Contact' = 2,
  'IT Contact' = 1,
  'Primary Contact' = 0,
  'Reporting Contact' = 3,
  'SR Contact' = 4,
}

export enum PROJECT_TYPE {
  'Patient Satisfaction Experience Survey' = 1,
  'CG CAHPS Survey',
  'Pharmacy Experience Survey',
  'Market Research Survey',
  'Health Plan Survey',
  'ER Survey',
  'Urgent Care Survey',
  'Special Program Survey',
  'Provider Survey',
  'Nutrition Survey',
  'School Health Survey',
  'Fee Perception Survey',
  'Communication Survey',
  'Employee Engagement Survey',
  'Provider Engagement Survey',
  Other,
}

export enum PROJECT_STATUS {
  'Not Started' = 1,
  'In Progress',
  'Final Counts',
  'Send To Client',
  Paused,
  Completed,
}

export enum ASSIGNMENT_TYPE {
  Assigned,
  Coverage,
}

export enum PROJECT_MODIFIERS {
  Language,
  Site,
  'Service Line',
  'Survey Completion Status',
}

export enum MODIFIER_TABLE_TYPE {
  'Project Build',
  Payment,
}

export enum REPORTING_FREQ {
  Monthly = 1,
  BiMonthly = 2,
  Quarterly = 3,
  SemiAnnually = 6,
  Annually = 12,
}

export enum COLLABORATIVE_GROUP {
  CHPW,
  CPG,
  ORPCA,
}

export enum PatientType {
  Established = 1,
  New,
}

export enum GoalsHeader {
  Weekly = 'Week-to-Date Surveys Completed',
  WeeklyTotal = 'Current Week Minimum Surveys',
  RP = 'Report-Period-to-Date Surveys Completed',
  RPTotal = 'Current Report-Period Minimum Surveys',
}

export enum Language {
  English = 1,
  Spanish,
  Albanian,
  Arabic,
  Cantonese,
  'Cape Verdean',
  'Haitian Creole',
  Korean,
  Mandarin,
  Portuguese,
  Russian,
  Vietnamese,
}

export enum SurCallResponse {
  'Agreed to Survey' = 1,
  'No Answer or Voicemail Recording' = 4,
  'Do Not Call' = 2,
  'Respondent Refused' = 3,
  'Respondent Unavailable' = 5,
  'Scheduled Another Time to Contact' = 6,
  'Incorrect Roster Information' = 7,
}

export enum SurveyStatus {
  Completed,
  Partial,
}

export enum Frequency {
  Daily = 1,
  Weekly = 7,
  Monthly = 30,
}

export enum SamplingParams {
  Provider,
  Site,
  Department,
  Language = 999,
}

export enum LanguageSamplingParams {
  Open = 1,
  "Don't",
  Set,
}

export enum AnswerType {
  Single = 1,
  Multiple,
  'Open Ended',
  Scripted,
}

export enum RosterKeys {
  Age = 'Age',
  CellPhone = 'Cell Phone',
  Center = 'Site',
  CHPW = 'CHPW',
  Department = 'Department',
  DOB = 'DOB',
  DOS = 'DOS',
  Ethnicity = 'Ethnicity',
  GuarantorName = 'Guarantor',
  HomePhone = 'Home Phone',
  Language = 'Language',
  MRN = 'MRN',
  PatientName = 'Patient Name',
  PatientType = 'Patient Type',
  PayerType = 'Payer Type',
  ProviderName = 'Provider Name',
  ProviderType = 'Provider Type',
  Race = 'Race',
  Sex = 'Sex',
}

export enum ProjectIcons {
  Details = 'det',
  Sampling = 'sam',
  RosterUpload = 'upl',
  RosterExport = 'r_exp',
  QSurveyExport = 's_exp',
  AdditionalInfo = 'inf',
  PdfFileUpload = 'pdfupl',
}

export enum SortDir {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum RosterStatus {
  Ready = 1,
  Processing,
  Errored,
  Removed,
}

export enum Del {
  AC = 'Active',
  DE = 'Deactive',
  DA = 'Delete Account',
  DACTC = 'Deactivate Client',
  DACTP = 'Deactivate Project',
  DACTU = 'Deactivate Account',
  DC = 'Delete Client',
  DP = 'Delete Project',
  RACTC = 'Reactivate Client',
  RACTP = 'Reactivate Project',
  RACTU = 'Reactivate Account',
  YD = 'Yes, Delete',
  YDA = 'Yes, Deactivate',
  YRA = 'Yes, Reactivate',
}

export enum JsEvents {
  BeforeUnload = 'beforeunload',
  Click = 'click',
  KeyPress = 'keypress',
  Load = 'load',
  MouseDown = 'mousedown',
  MouseMove = 'mousemove',
  MouseUp = 'mouseup',
  Scroll = 'scroll',
  Wheel = 'wheel',
}

export enum StatusCode {
  BadRequest = 400,
  Unauthorized = 401,
  NotFound = 404,
  TooManyRequest = 429,
}

export enum CCStatus {
  NotCleaned,
  Cleaned = 1,
  Processing,
}

export enum CommentType {
  OE,
  P,
  N,
}

export enum PLSort {
  LatestRosterUpload = 'lastRosterUpload',
  OldestUncleanedSurveyedOn = 'oldestUncleanedSurveyedOn',
  UndefinedSamplingParameters = 'undefinedProviders',
  UnresolvedEscalations = 'uncleanedEscalation',
}

import React from 'react';
import { connect } from 'react-redux';
import { DraggableListItem } from './QAList';
import { Loader } from '../../components';
import { Button, Confirm, List, Modal, Popup } from '../Inputs';
import { ItemTypes } from '../DnD';
import { BN, IconProps, LangQuestions, QuesProject, QusList, StoreT } from '../../model';
import { getQuestionnaireViewerRoute, isFunction } from '../../utils/common';
import { CONFIRM_MESSAGES, initFalsy, ROLES } from '../../constants';
import { va } from '../../utils/arrayUtils';
import { useActions } from '../../redux/actions';
import * as questActions from '../../redux/actions/questions';

type QListProps = {
  onAddQ?: () => void;
  onDel?: (id: BN, isQ: boolean) => Promise<void>;
  onEditQ?: Function;
  questions: QusList;
  quesProject: Array<QuesProject>;
};

const QuestionsList = ({ onAddQ, onDel, onEditQ, questions, quesProject }: QListProps) => {
  const onlyList = !(isFunction(onAddQ) && isFunction(onDel) && isFunction(onEditQ));
  const que = useActions(questActions);
  const [del, setDel]: [BN, Function] = React.useState(initFalsy);
  const [isDeact, setDeactStatus] = React.useState(initFalsy);
  const [isDelLoad, setDelLoad] = React.useState(initFalsy);
  const [isDeleting, setIsDeleting] = React.useState(initFalsy);
  const confirmButtonProps = {
    content: isDeact ? 'Yes, Reactivate' : 'Yes, Deactivate',
    loading: isDeleting,
    primary: isDeact ? !initFalsy : initFalsy,
    type: 'button',
    className: 'red',
  };
  const content = () => {
    return (
      <Modal.Content>
        {isDelLoad && <Loader />}

        {va(quesProject) ? (
          <>
            <p>{isDeact ? CONFIRM_MESSAGES.REACTIVE_QUESTION : CONFIRM_MESSAGES.QUESTION}</p>
            <p>Question is in use on following Questionnaires: </p>
            <ul>
              {quesProject.map((i: QuesProject) => {
                const qsUrl = getQuestionnaireViewerRoute(ROLES.Operations, i.id);
                const qsIconProps: IconProps = {
                  as: 'a',
                  className: 'asAnchor',
                  href: qsUrl,
                  icon: 'external',
                  target: '_blank',
                  title: 'Open in new window',
                };
                return (
                  <li>
                    {i.name} <Button {...qsIconProps} />
                  </li>
                );
              })}
            </ul>
          </>
        ) : (
          <p>{isDeact ? CONFIRM_MESSAGES.REACTIVE_QUESTION : CONFIRM_MESSAGES.QUESTION}</p>
        )}
      </Modal.Content>
    );
  };
  const delConfirmProps = {
    content: content(),
    header: isDeact ? 'Reactivate Question' : 'Deactivate Question',
    confirmButton: <Button {...confirmButtonProps} />,
    open: !!del,
    onCancel: () => setDel(initFalsy),
    onConfirm: async () => {
      setIsDeleting(!initFalsy);
      if (!onlyList && onDel) {
        await onDel(del, !initFalsy);
      }
      setIsDeleting(initFalsy);
      setDel(initFalsy);
    },
  };
  const onQuesDel = async (id: number, isDeleted?: string) => {
    const isQuesDel = isDeleted ? !initFalsy : initFalsy;
    setDel({ id, isQuesDel });
    setDeactStatus(isQuesDel);
    setDelLoad(!initFalsy);
    await que.getQuesInProject(id);
    setDelLoad(initFalsy);
  };

  return (
    <>
      <List className='listStyle listWithAction mb15' relaxed={!onlyList}>
        {va(questions) ? (
          questions.map((q) => {
            const index = q.langQuestions.findIndex((lq: LangQuestions) => lq.language.name === 'English'),
              qText = index > -1 ? q.langQuestions[index].quesText : '',
              QbmBlock = (
                <List.Item
                  className={q.isQuestion ? 'ques-denied' : 'hand-pointer'}
                  key={q.id}
                  onClick={() => (onEditQ ? onEditQ(q) : {})}
                >
                  <DraggableListItem onlyList={onlyList} qa={q} onEdit={onEditQ} type={ItemTypes.QUESTION} />
                  <List.Content floated='right'>
                    {!onlyList && <strong className='mr15'>{q.category}</strong>}

                    {!onlyList && <List.Icon className='mr10 hand-pointer' name='edit' />}
                    {!onlyList && (
                      <List.Icon
                        className='ml0 mr0 hand-pointer'
                        name={q.isQuestion ? 'undo alternate' : 'trash alternate outline'}
                        onClick={() => onQuesDel(q.id, q.isQuestion)}
                      />
                    )}
                  </List.Content>
                </List.Item>
              );

            return (
              <div key={q.id}>
                <div className='clearfix' />
                {qText && onlyList ? (
                  <Popup position='top left' size='mini' basic content={qText} trigger={QbmBlock} />
                ) : (
                  QbmBlock
                )}
              </div>
            );
          })
        ) : (
          <p className='center-force'>No record found.</p>
        )}
      </List>

      {del && <Confirm {...delConfirmProps} />}

      {!onlyList && <Button content='Add New Question' primary={!initFalsy} onClick={onAddQ} />}
    </>
  );
};

const mapStore = (store: StoreT) => ({
  questions: store.questions,
  quesProject: store.quesProjectList,
});

export default connect(mapStore)(QuestionsList);

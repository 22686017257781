import React, { SyntheticEvent } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import {
  Button,
  Card,
  Confirm,
  Grid,
  GridView,
  Icon,
  Item,
  ProgressGoals,
  Search,
  TextBox,
} from '../../components/Inputs';
import { Loader } from '../../components';
import { CONFIRM_MESSAGES, initFalsy, PRJ_CONTACT_TYPE, PROJECT_TYPE, REPORTING_FREQ, ROLES } from '../../constants';
import { Account, BN, Project, ProjectContact, SearchProps, StoreT, T } from '../../model';
import { useActions } from '../../redux/actions';
import * as assignActions from '../../redux/actions/assignments';
import * as projectActions from '../../redux/actions/projects';
import * as userActions from '../../redux/actions/users';
import { uniq, va } from '../../utils/arrayUtils';
import { isValidSearch, joinAsFullName } from '../../utils/common';
import { sleep, vo } from '../../utils/objectUtils';
import { pez } from '../../utils/stringUtils';
import { ProjectName } from '../../components/Projects';

const Assignments = () => {
  const assignAC = useActions(assignActions),
    opsAC = useActions(projectActions),
    userA = useActions(userActions),
    user = useSelector((state: StoreT) => state.auth.user),
    { id }: T = useParams(),
    projectInfo: Project = useSelector((state: StoreT) => state.projectInfo),
    assignments = useSelector((state: StoreT) => state.assignments),
    [ssInp, setSSVal]: [string, Function] = React.useState(''),
    [loader, isLoading] = React.useState(initFalsy),
    [miniLoader, isMiniLoading] = React.useState(initFalsy),
    [delOpen, showDelConfirm]: [BN, Function] = React.useState(initFalsy),
    [deleting, isDeleting] = React.useState(initFalsy),
    users = useSelector((state: StoreT) => state.users as Array<Account>),
    contacts = va(projectInfo.contacts) ? (projectInfo.contacts as ProjectContact[]) : [],
    getContactsDetails = () => {
      const style = { borderBottom: '1px solid orange' },
        types = uniq(contacts.map((c) => c.typeId)).sort(),
        finalConArr: { label: string; style?: T; value: string }[] = [];

      types.forEach((type) => {
        const contactType: string = PRJ_CONTACT_TYPE[type as number];
        const pContacts = contacts.filter((i) => i.typeId === type);
        pContacts.forEach((primaryContact) => {
          finalConArr.push({ label: contactType, value: primaryContact && primaryContact.firstName });
          finalConArr.push({
            label: `${contactType} Number`,
            value: primaryContact && (primaryContact.phoneNumber as string),
          });
          finalConArr.push({
            label: `${contactType} Email`,
            style: style,
            value: primaryContact && primaryContact.email,
          });
        });
      });
      return finalConArr;
    },
    detFields = [
      { label: 'Client Name', value: projectInfo?.client?.name },
      { label: 'Project Type', value: PROJECT_TYPE[projectInfo.typeId] },
      { label: 'Reporting Period', value: REPORTING_FREQ[projectInfo.reportFreqId] },
    ].concat(getContactsDetails()),
    searchSurveyors = async (searchInp: string) => {
      isMiniLoading(!initFalsy);
      const role =
        user.role === ROLES['CIMR Manager']
          ? [ROLES['CIMR Surveyor']]
          : [ROLES.Surveyor, ROLES['CIMR Surveyor'], ROLES['Keller Surveyor']];
      await userA.fetchUsers(role, searchInp);
      isMiniLoading(initFalsy);
    },
    performDelOperation = async (id: BN) => {
      isDeleting(!initFalsy);
      await assignAC.deleteAssignment(id as number);
      isDeleting(initFalsy);
      showDelConfirm(initFalsy);
    },
    resultRenderer = ({ firstName, lastName, email, phoneNumber }: T) => {
      return (
        <Item content={`${joinAsFullName(firstName, lastName)}, Email: ${pez(email)} Phone: ${pez(phoneNumber)}`} />
      );
    },
    onSearchChange = async (_e: SyntheticEvent, data: SearchProps) => {
      setSSVal(data.value as string);

      if (isValidSearch(data.value as string, loader)) {
        return sleep(0);
      }

      await searchSurveyors(pez(data.value));
    },
    onResultSelect = async (_e: SyntheticEvent, surveyor: Account) => {
      isLoading(!initFalsy);
      // assign assignment
      const payload = {
        managerId: projectInfo.managerId,
        projectId: id,
        surveyorId: surveyor.id,
      };
      await assignAC.assignProjectSurveyor(payload);
      await assignAC.fetchAssignments(id);
      setSSVal('');
      isLoading(initFalsy);
      _e.preventDefault();
    },
    cfmProps = {
      confirmButton: <Button color='red' content='Yes, Delete' loading={deleting} primary={initFalsy} type='button' />,
      content: CONFIRM_MESSAGES.RE_ASSIGN_SUV_PRJ,
      header: 'Delete Surveyor',
      onCancel: () => showDelConfirm(initFalsy),
      onConfirm: () => performDelOperation(delOpen),
      open: !!delOpen,
    },
    searchProps = {
      className: 'w100',
      fluid: !initFalsy,
      loading: miniLoader,
      minCharacters: 2,
      noResultsMessage: 'No such surveyor.',
      onSearchChange,
      placeholder: 'Surveyors...',
      resultRenderer,
      results: users,
      value: ssInp,
    },
    surveyors = va(assignments)
      ? assignments.map(({ id, surveyorCompGoals, surveyorGoals, surveyor }) => {
          return {
            sn: joinAsFullName(surveyor?.firstName, surveyor?.lastName),
            se: surveyor?.email,
            progress: <ProgressGoals compGoals={surveyorCompGoals} reqGoals={surveyorGoals} />,
            Action: (
              <Icon className='hand-pointer' name='trash alternate' title='Delete' onClick={() => showDelConfirm(id)} />
            ),
          };
        })
      : [],
    lyf = () => {
      if (!vo(projectInfo) && id) {
        isLoading(!initFalsy);
        sleep().then(() => {
          const apis = [assignAC.fetchAssignments(id), opsAC.getProjectInfo(id), setSSVal('')];
          Promise.all(apis).then(() => isLoading(initFalsy));
        });
      }

      return () => assignAC.flushAssignments();
    };

  React.useEffect(lyf, []);

  return (
    <>
      {loader && <Loader />}
      {delOpen && <Confirm {...cfmProps} />}
      <Grid>
        <Grid.Row className='headerTop'>
          <Grid.Column width={16}>
            <h1 className='mainTitle'>Assignments</h1>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column computer={6} tablet={16} mobile={16}>
            <Card fluid={!initFalsy} className='mb20 disabledList'>
              <Card.Header>
                <h2 className='subTitle'>
                  <ProjectName name={projectInfo.name} isLocked={projectInfo.isLocked} />
                </h2>
              </Card.Header>
              <Card.Content>
                <Grid>
                  <Grid.Row>
                    <Grid.Column computer={16} tablet={16} mobile={16}>
                      <h3 className='w100 subHeaderInner'>
                        <span>Details</span>
                      </h3>
                    </Grid.Column>
                    {detFields.map((f, k) => (
                      <Grid.Column key={k} computer={16} tablet={16} mobile={16}>
                        <TextBox disabled={!initFalsy} {...f} />
                      </Grid.Column>
                    ))}
                  </Grid.Row>
                </Grid>
              </Card.Content>
            </Card>
          </Grid.Column>
          <Grid.Column computer={10} tablet={16} mobile={16}>
            <Card fluid={!initFalsy} className='mb20'>
              <Card.Header>
                <h2 className='subTitle'>Details</h2>
              </Card.Header>
              <Card.Content>
                <Grid>
                  <Grid.Row>
                    <Grid.Column computer={8} tablet={16} mobile={16}>
                      <Card fluid={!initFalsy}>
                        <Card.Content>
                          <Search {...searchProps} onResultSelect={(e, d) => onResultSelect(e, d.result)} />
                        </Card.Content>
                      </Card>
                    </Grid.Column>
                    <Grid.Column computer={16} tablet={16} mobile={16}>
                      <GridView list={surveyors} headers={['Surveyor', 'Email', 'Weekly Goal', 'Action']} />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};

export default Assignments;

import React from 'react';
import { Progress } from './index';
import { surveyGoals } from '../../utils/common';
import { fixedFloat, piz } from '../../utils/numUtils';
import { pez } from '../../utils/stringUtils';
import { initFalsy } from '../../constants';
import { T } from '../../model';

type NU = number | undefined;
type ProgressGoalProps = { compGoals: NU; reqGoals: NU; title?: string; surveysRP?: T };

const ProgressGoals: React.FC<ProgressGoalProps> = ({ compGoals, reqGoals, surveysRP, title }) => {
  const _completedGoals = surveysRP || compGoals;
  const percentProgress = fixedFloat((piz(_completedGoals) / piz(reqGoals)) * 100, 1);
  const hovTitle = pez(title, percentProgress.toString());

  return (
    <div title={hovTitle}>
      <Progress className='mb0' indicating={!initFalsy} percent={percentProgress} size='tiny' />
      {surveyGoals(_completedGoals, reqGoals)}
    </div>
  );
};

export default ProgressGoals;

import * as React from 'react';
import { initFalsy } from './constants';
import { T } from './model';
import Notfound from './components/Notfound';

type ST = { hasError: boolean };

class ErrorBoundary extends React.Component<T, ST> {
  constructor(props: T) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: T) {
    // Update state so the next render will show the fallback UI.
    console.error(error);
    return { hasError: !initFalsy };
  }

  componentDidCatch(error: T, errorInfo: T) {
    // You can also log the error to an error reporting service
    console.info('===> ', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <>
          <Notfound isGeneralError={!initFalsy} />
        </>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;

import React from 'react';
import { OtpToCIMRManager, VerifyOtpForm } from './index';
import { FormWrap } from '../../Inputs';
import { useActions } from '../../../redux/actions';
import * as Actions from '../../../redux/actions/auth';
import { ChkProps, FormikValues, StoreT, T, User } from '../../../model';
import { validate } from '../../../utils/validation';
import {
  COOKIE_EXPIRE_DAYS,
  COOKIE_NAME,
  FOUR,
  initFalsy,
  initialMinute,
  initialSeconds,
  ONE,
  ONE_SEC,
  ROLES,
  StatusCode,
  ZERO,
} from '../../../constants';
import { connect } from 'react-redux';
import { va } from '../../../utils/arrayUtils';
import { getCookie, setCookie } from '../../../utils/storage';
import { cfl } from '../../../utils/stringUtils';

interface userProps {
  authData: User;
  setDisabled: Function;
}

const VerifyOtp = (props: userProps) => {
  const { setDisabled, authData } = props;
  const { user } = authData;
  const authActions = useActions(Actions);
  const otpReqFields: T = ['otp'];
  const cimrManReqFields = ['phNumber'];
  const [loading, isLoading] = React.useState(initFalsy);
  const [minutes, setMinutes] = React.useState(initialMinute);
  const [seconds, setSeconds] = React.useState(initialSeconds);
  const [selCimrPhoneNumber, setCimrPhoneNumber] = React.useState(null);
  const [isShowResendBtn, setShowResendBtn] = React.useState(initFalsy);
  const [getRemAcc, setRemAcc] = React.useState(initFalsy);
  const { id, firstName, lastName, email, cimrList, phoneNumber, role } = user;
  const setRememberAcc = (val: boolean) => setRemAcc(val);
  const name = { name: `${cfl(firstName!)} ${cfl(lastName!)}` };
  const chkBoxes: ChkProps[] = [
    {
      className: 'pt10 text-left',
      checked: getRemAcc,
      label: 'Remember this account',
      name: 'getRemAcc',
      onChange: () => setRememberAcc(!getRemAcc),
    },
  ];
  const resSendOtp = async () => {
    const payload = {
      id: id,
      phoneNumber: selCimrPhoneNumber ? selCimrPhoneNumber : phoneNumber,
    };
    role === ROLES['CIMR Surveyor'] && Object.assign(payload, name);
    await authActions.resendOtp(payload);
    setMinutes(initialMinute);
    setSeconds(initialSeconds);
    setShowResendBtn(initFalsy);
  };
  const resetAuthOtp = async () => await authActions.resetOtpStatus(id);
  const verifyAuthOtpForm = {
    displayName: 'verify-auth-otp-form',
    initialValues: { otp: null },
    onSubmit: async (values: FormikValues, { resetForm }: FormikValues) => {
      try {
        isLoading(!initFalsy);
        const payload = { email, id, otp: values.otp };
        const response = await authActions.verifyValidOtp(payload);
        if (response === StatusCode.TooManyRequest) {
          await resetAuthOtp();
          setDisabled(initFalsy);
        }

        if (response !== StatusCode.NotFound) {
          let cookieArr = [];
          const user: T = getCookie(COOKIE_NAME);
          if (user) {
            const cookieVal = JSON.parse(user);
            cookieArr.push(...cookieVal, btoa(email));
          } else {
            cookieArr.push(btoa(email));
          }
          getRemAcc && setCookie(COOKIE_NAME, cookieArr, COOKIE_EXPIRE_DAYS);
        }
      } catch (err) {
        resetForm();
      } finally {
        setCimrPhoneNumber(null);
        isLoading(initFalsy);
      }
    },
    chkBoxes,
    loading,
    children: VerifyOtpForm,
    isShowResendBtn,
    minutes,
    resSendOtp,
    seconds,
    validate: (values: FormikValues) => validate(otpReqFields, values),
  };
  const sendOtpToCimr = {
    displayName: 'Send Verification Code To Cimr Manager',
    initialValues: { phNumber: null },
    onSubmit: async (values: FormikValues, { resetForm }: FormikValues) => {
      try {
        isLoading(!initFalsy);
        const payload = {
          id,
          phoneNumber: values.phNumber,
        };
        role === ROLES['CIMR Surveyor'] && Object.assign(payload, name);
        await authActions.resendOtp(payload, !initFalsy).then(() => {
          isLoading(initFalsy);
        });
      } catch (err) {
        resetForm();
      } finally {
        setMinutes(initialMinute);
        setSeconds(initialSeconds);
      }
    },
    loading,
    children: OtpToCIMRManager,
    cimrList,
    selCimrPhoneNumber,
    setCimrPhoneNumber,
    validate: (values: FormikValues) => validate(cimrManReqFields, values),
  };

  React.useEffect(() => {
    if (!va(cimrList)) {
      const myInterval = setInterval(async () => {
        if (seconds > ZERO) {
          setSeconds(seconds - ONE);
        }

        if (seconds === ZERO) {
          if (minutes === ZERO) {
            setDisabled(initFalsy);
            resetAuthOtp().then();
          } else {
            setMinutes(minutes - ONE);
            setSeconds(initialSeconds);
          }

          if (minutes === FOUR) {
            setShowResendBtn(!initFalsy);
          }
        }
      }, ONE_SEC);

      return () => {
        clearInterval(myInterval);
      };
    }
  });

  return <>{!va(user.cimrList) ? <FormWrap {...verifyAuthOtpForm} /> : <FormWrap {...sendOtpToCimr} />}</>;
};

const mapToProps = (state: StoreT) => {
  return {
    authData: state.auth,
  };
};

export default connect(mapToProps)(VerifyOtp);

import React from 'react';
import { DatePicker, Form, Search, useField } from './index';
import { FormFieldGroupProps, FormFieldProps, T } from '../../model';
import { va } from '../../utils/arrayUtils';
import { noop } from '../../utils/common';
import { initFalsy } from '../../constants';

export enum FormFieldType {
  CheckBox = 'chk',
  DatePicker = 'date',
  DropDown = 'select',
  Email = 'email',
  Number = 'number',
  Password = 'password',
  Radio = 'radio',
  Search = 'search',
  Select = 'select',
  Text = 'text',
  TextArea = 'textArea',
}

export const FormFieldGroup: React.FC<FormFieldGroupProps> = (props: FormFieldGroupProps) => (
  <Form.Group widths='equal' {...props.misc}>
    {props.fields &&
      (va(props.fields) ? (
        props.fields?.map((o: FormFieldProps, key: number) => <FormField key={key} {...o} />)
      ) : (
        <FormField {...(props.fields as FormFieldProps)} />
      ))}
    {props.children}
  </Form.Group>
);

export const FormField: React.FC<FormFieldProps> = (props: FormFieldProps) => {
  const { disabled, label, loading, name, onChange, onSearchChange, options, placeholder, type, ...rest } = props;
  const [field, meta, helper] = useField(props.name);

  const _val = field.value;
  const _err = meta.error;
  const _label = label || '';
  const _placeholder = placeholder || label;
  const _options = options || [];
  if (_options.findIndex((i: { key: number }) => i.key === -1) === -1) {
    _options.unshift({ key: -1, value: '', text: '' });
  }

  const ErrComponent = !!_err && { content: _err, pointing: 'below' };
  const isTruthy = !initFalsy;
  const commonProps = {
    disabled,
    error: ErrComponent,
    label: _label,
    name,
    onBlur: field.onBlur || noop,
    onChange: field.onChange || noop,
    placeholder: _placeholder,
    value: _val,
  };
  const selProps = {
    clearable: isTruthy,
    fluid: isTruthy,
    scrolling: isTruthy,
    search: isTruthy,
    selection: isTruthy,
  };

  const handleSearch = (_e: T, data: T) => {
    if (onSearchChange && typeof onSearchChange === 'function') {
      onSearchChange(data.value || data.searchQuery);
      _e.preventDefault();
    }
  };

  switch (props.type) {
    case FormFieldType.Text:
    case FormFieldType.Email:
    case FormFieldType.Password:
    case FormFieldType.Number:
      return <Form.Input {...commonProps} loading={props.loading} type={props.type} {...rest} />;

    case FormFieldType.TextArea:
      return <Form.TextArea {...commonProps} {...rest} />;

    case FormFieldType.Search:
      return <Search {...commonProps} {...rest} />;

    case FormFieldType.Radio:
      return <Form.Radio {...commonProps} onClick={(_e, { checked }) => helper.setTouched(!!checked)} {...rest} />;

    case FormFieldType.CheckBox:
      return (
        <Form.Checkbox
          {...commonProps}
          checked={!!_val}
          onClick={(_e, { checked }) => helper.setTouched(!!checked)}
          onChange={(_e, { checked }) => helper.setValue(checked)}
          {...rest}
          value={''}
        />
      );

    case FormFieldType.Select:
    case FormFieldType.DropDown:
      return (
        <Form.Select
          {...commonProps}
          {...selProps}
          loading={loading}
          onBlur={(_e, data) => {
            helper.setTouched(!!data.value);
          }}
          onChange={(_e, { value }) => helper.setValue(value)}
          onSearchChange={handleSearch}
          options={_options}
          {...rest}
        />
      );

    case FormFieldType.DatePicker:
      return <DatePicker {...commonProps} setValue={(value) => helper.setValue(value)} {...rest} />;

    default:
      return null;
  }
};

export default FormField;

import React from 'react';
import { useParams } from 'react-router';
import { Loader } from '../../components';
import { Grid } from '../../components/Inputs';
import { Guidelines } from '../../components/Guidelines';
import { useActions } from '../../redux/actions';
import * as svGuidelinesAction from '../../redux/actions/svGuidelines';
import { initFalsy } from '../../constants';
import { isValidURL } from '../../utils/stringUtils';

const SG = () => {
  const strEmpty = '',
    { id } = useParams(),
    [loading, setLoading] = React.useState(initFalsy),
    [isAssignedQues, setIsAssignedQues] = React.useState(initFalsy),
    [url, setUrl] = React.useState(strEmpty),
    svGuideline = useActions(svGuidelinesAction),
    processUrl = async (_url: string) => {
      setUrl(_url);
      setLoading(initFalsy);
    },
    fetchGuidelines = async () => {
      setLoading(!initFalsy);
      svGuideline.fetchSurveyorGuidelines(id).then((res: any) => {
        if (res) {
          const validUrl = isValidURL(res);
          return processUrl(validUrl ? res : '');
        } else {
          setLoading(!initFalsy);
          setIsAssignedQues(!initFalsy);
        }
      });
    },
    firstRun = () => {
      fetchGuidelines().then();
    };

  React.useEffect(firstRun, []);

  return (
    <>
      {loading && <Loader />}

      {!isAssignedQues && (
        <Grid>
          <Grid.Row>
            <Grid.Column className='mt20 mb10' width={16}>
              <Guidelines url={url} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}
    </>
  );
};

export default SG;

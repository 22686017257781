import * as React from 'react';
import * as ReactDOM from 'react-dom';
import AppRedux from './AppRedux';
import './styles/css/final.scss';
// import registerServiceWorker, {unregister} from './registerServiceWorker';
import registerServiceWorker from './registerServiceWorker';

const rootEl = document.getElementById('root');
ReactDOM.render(<AppRedux />, rootEl);

// comment in for PWA with service worker in production mode
registerServiceWorker();

// unregister();

import { ActionType, Alert, ApiEngine, CleanResponse, SurveyPayload, T } from '../../model';
import { setAlert, setSuccess } from './alert';
import { downloadAsCSV, jsonToCsv } from '../../utils/common';
import { vo } from '../../utils/objectUtils';
import { prName, processASCSV } from '../../utils/surveyCsv';
import { DEFAULT_ERROR, initFalsy } from '../../constants';

const apiPath = 'survey-export';
const apiPath2 = 'survey';

/**
 * Export questionnaire data as a CSV or excel file
 * @param payload
 * @param doosraWay
 */
export const surveyCsvExport =
  (payload: SurveyPayload, doosraWay: boolean) => async (dispatch: Function, _store: Function, api: ApiEngine) => {
    try {
      const date = new Date().toUSDateString('', !initFalsy, !initFalsy, '');
      const { from, to, projectId, surveyorId, languageId, name } = payload;
      const q = { from, to, projectId, surveyorId, languageId };
      const { data, message }: CleanResponse = await api.get(doosraWay ? apiPath2 : apiPath, null, q);
      const fileTitle = `${apiPath}-${prName(name)}-${date}`;

      if (!vo(data)) {
        return dispatch(setAlert({ message } as Alert));
      }

      // if (!doosraWay) {
      const formattedSurveyData: T = processASCSV(data);
      downloadAsCSV(fileTitle, jsonToCsv(formattedSurveyData, !initFalsy));
      // } else {
      //   downloadAsCSV(fileTitle, jsonToCsv(data, !initFalsy));
      // }

      dispatch({ type: ActionType.QUESTIONNAIRE_CSV_EXPORT });
      return dispatch(setSuccess(message));
    } catch (err) {
      console.error(err);
      const alert = { statusCode: 0 };
      const message = DEFAULT_ERROR;
      const errPayload = err && err === 'No Data Found' ? { ...alert, message: err } : { ...alert, message };
      dispatch(setAlert(errPayload));
    }
  };

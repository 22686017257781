import { Action, ActionType, DepartmentList, ProviderList, Sampling, SiteList, T } from '../../model';
import createReducer from './createReducer';

const initSamplingParams: Array<Sampling> = [];
const initDepartment: Partial<DepartmentList> = {};
const initProvider: Partial<ProviderList> = {};
const initSite: Partial<SiteList> = {};

export const samplingParams = createReducer(initSamplingParams, {
  [ActionType.PM_SAMPLING_FETCH](_store: Function, action: Action<Sampling[]>) {
    return action.payload;
  },
  [ActionType.PM_SAMPLING_ADD](_store: Function, action: Action<Sampling[]>) {
    return action.payload;
  },
  [ActionType.PM_SAMPLING_UPDATE](_store: Function, action: Action<Sampling[]>) {
    return action.payload;
  },
  [ActionType.PM_SAMPLING_DEL](_store: Function, action: Action<Sampling[]>) {
    return action.payload;
  },

  [ActionType.PM_SAMPLING_FLUSH](_store: Function, action: Action<T>) {
    return action.payload;
  },
});

export const departments = createReducer(initDepartment, {
  [ActionType.PM_DEPARTMENTS](_store: Function, action: Action<DepartmentList>) {
    return action.payload;
  },

  [ActionType.PM_DEPARTMENTS_FLUSH](_store: Function, action: Action<void>) {
    return action.payload;
  },
});

export const providers = createReducer(initProvider, {
  [ActionType.PM_PROVIDERS](_store: Function, action: Action<ProviderList>) {
    return action.payload;
  },

  [ActionType.PM_PROVIDERS_FLUSH](_store: Function, action: Action<void>) {
    return action.payload;
  },
});

export const sites = createReducer(initSite, {
  [ActionType.PM_SITES](_store: Function, action: Action<SiteList>) {
    return action.payload;
  },

  [ActionType.PM_SITES_FLUSH](_store: Function, action: Action<void>) {
    return action.payload;
  },
});

import { ActionType, Alert } from '../../model';
import { sleep } from '../../utils/objectUtils';
import { DEFAULT_ALERT_SHOW_TIME, DEFAULT_ERROR, initFalsy } from '../../constants';
import { LogOut } from './auth';

enum StatusCode {
  '4xx' = 400,
  '5xx' = 500,
}
enum StatusMessage {
  ERR_SESSION_EXPIRED = 'Session Expired',
}

export const setAlert =
  (payload: Alert, autoFlush: boolean = !initFalsy) =>
  async (dispatch: Function) => {
    if (autoFlush) {
      dispatch(flushAlert());
    }

    if (payload.statusCode >= StatusCode['4xx'] && payload.message === StatusMessage.ERR_SESSION_EXPIRED) {
      dispatch(LogOut());
    }

    if (payload.statusCode >= StatusCode['5xx'] || !payload.statusCode) {
      const message = `${payload.message}; ${DEFAULT_ERROR}`;
      return dispatch({ type: ActionType.ALERT, payload: { ...payload, message } });
    }

    return dispatch({ type: ActionType.ALERT, payload });
  };

export const setSuccess =
  (successMsg: string, autoFlush: boolean = !initFalsy) =>
  async (dispatch: Function) => {
    if (autoFlush) {
      dispatch(flushAlert());
    }
    return dispatch({ type: ActionType.MESSAGE, payload: { successMsg } });
  };

export const flushAlert = () => async (dispatch: Function) => {
  sleep(DEFAULT_ALERT_SHOW_TIME).then(() => dispatch({ type: ActionType.ALERT, payload: {} }));
};

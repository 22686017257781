import { applyMiddleware, createStore, Middleware } from 'redux';
import {
  getStoredState as _getStoredState,
  PersistConfig,
  persistReducer,
  persistStore as _persistStore,
} from 'redux-persist';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { createBrowserHistory } from 'history';
import * as localF from 'localforage';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from './reducers';
import { ActionType, RootState, StoreT, T } from '../model';
import { Api } from '../api';
import { __NODE_ENV__, API_URL, initFalsy, SESSION_AUTH_KEY } from '../constants';
import { get } from '../utils/storage';
import { vo } from '../utils/objectUtils';

type ActionCreatorType = { type: string | number; payload?: Extract<StoreT, T> };
const actionTypeEnumToString = (action: ActionCreatorType): T =>
  action && !!action.type && typeof action.type === 'number' && ActionType[action.type]
    ? { type: ActionType[action.type], payload: action.payload }
    : action;

const migrate: (state: T) => Promise<T> = (state) => {
  const _state = vo(state) ? { ...state, auth: { ...state.auth, accessToken: get(SESSION_AUTH_KEY) } } : {};
  // if (__NODE_ENV__ === 'development') {
  //   console.info(state?.auth, _state?.auth);
  // }
  return Promise.resolve(_state);
};
const persistConfig: PersistConfig<RootState> = {
  debug: __NODE_ENV__ === 'development',
  key: 'cr-root',
  migrate,
  serialize: !initFalsy,
  storage: localF,
  timeout: 0,
  version: 0.1,
  whitelist: ['auth', 'nav'],
};

const logger: Middleware = createLogger({ actionTransformer: actionTypeEnumToString, collapsed: !initFalsy });
const composeEnhancers = composeWithDevTools({ actionSanitizer: actionTypeEnumToString });

const history = createBrowserHistory();
const isDevelopment = process.env.NODE_ENV === 'development';
const initialState: T = {};
const api: Api = new Api(API_URL);

const middleware = [thunk.withExtraArgument(api)];

if (isDevelopment) {
  middleware.push(logger);
}

const enhancedDevMW = isDevelopment ? composeEnhancers(applyMiddleware(...middleware)) : applyMiddleware(...middleware);

const persistedReducer = persistReducer(persistConfig, rootReducer());

export default () => {
  const store = createStore(persistedReducer, initialState, enhancedDevMW);
  const persistStore = _persistStore(store);
  const getStoredState = _getStoredState(persistConfig);
  return { store, persistStore, getStoredState };
};

export { history };

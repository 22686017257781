import React from 'react';
import { Button, Card, Form, FormFieldGroup, FormFieldType, Grid, Modal } from '../../Inputs';
import { initFalsy } from '../../../constants';
import { SelectValue, T } from '../../../model';
import { va } from '../../../utils/arrayUtils';

const verifyOtpForm = (props: T) => {
  const { handleSubmit, isSubmitting, loading, cimrList, selCimrPhoneNumber, setCimrPhoneNumber } = props;
  const cimrManOpts = va(cimrList)
    ? cimrList.map((p: T) => ({ text: `${p.firstName} ${p.lastName}`, value: p.phoneNumber }))
    : [];
  const filterFields = [
    {
      className: '',
      cols: { computer: 4, tablet: 8 },
      defaultValue: selCimrPhoneNumber,
      fluid: initFalsy,
      label: 'CIMR Manager',
      name: 'phNumber',
      onChange: (value: SelectValue) => setCimrPhoneNumber(value),
      options: cimrManOpts,
      placeholder: 'Select CIMR Manager',
      type: FormFieldType.DropDown,
    },
  ];
  const submitProps = {
    className: 'text-center',
    content: 'Submit',
    disabled: isSubmitting,
    loading: loading,
    primary: !initFalsy,
    type: 'submit',
  };

  return (
    <Modal.Content>
      <Card>
        <Grid>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={16} computer={16}>
              <div className='loginForm text-center text-white'>
                <h2 className='mb-0 f24'>Select CIMR Manager</h2>
                <Form onSubmit={handleSubmit}>
                  <Grid columns='equal'>
                    <Grid.Row>
                      {filterFields.map(({ cols, ...f }, k) => (
                        <Grid.Column key={k} mobile={16}>
                          <FormFieldGroup fields={f} />
                        </Grid.Column>
                      ))}
                      <Grid.Column mobile={16}>
                        <Button {...submitProps} />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Form>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Card>
    </Modal.Content>
  );
};

export default verifyOtpForm;

import React from 'react';
import { useHistory, useParams } from 'react-router';
import { connect } from 'react-redux';
import { Loader } from '../../components';
import { ViewEditAccount } from '../Admin';
import { CallsHistoryChart } from '../../components/Calls';
import { ProjectGoals, ProjectName } from '../../components/Projects';
import {
  BtnSwitch,
  Button,
  Card,
  ChkBox,
  Confirm,
  DatePicker,
  GenModelProps,
  Grid,
  GridView,
  Icon,
  Model,
  Paging,
  ProgressGoals,
  TextBox,
} from '../../components/Inputs';
import {
  CONFIRM_MESSAGES,
  DEFAULT_RECORDS_PER_PAGE,
  initFalsy,
  MODIFIER_TABLE_TYPE,
  PRJ_CONTACT_TYPE,
  PROJECT_MODIFIERS,
  PROJECT_STATUS,
  PROJECT_TYPE,
  REPORTING_FREQ,
  RosterStatus,
  SortDir,
  StatusCode,
} from '../../constants';
import {
  Account,
  Assignment,
  ButtonProps,
  ChartData,
  IconProps,
  LabelField,
  Modifier,
  Project,
  ProjectContact,
  RosterHistory,
  RosterHistoryList,
  ST,
  StoreT,
  SurList,
  T,
} from '../../model';
import { Path } from '../../routes';
import { useActions } from '../../redux/actions';
import * as clActions from '../../redux/actions/calls';
import * as projectActions from '../../redux/actions/projects';
import * as rosterAction from '../../redux/actions/rosters';
import * as assignActions from '../../redux/actions/assignments';
import * as userAccActions from '../../redux/actions/users';
import { aMaxVal, aSort, uniq, va } from '../../utils/arrayUtils';
import { getSkipLimit, joinAsFullName, surveyMaxValue } from '../../utils/common';
import { firstDayOfWeek } from '../../utils/dateUtils';
import { asCurrency, piz } from '../../utils/numUtils';
import { vo } from '../../utils/objectUtils';
import { pez } from '../../utils/stringUtils';

interface AHP {
  assignmentId?: number;
  isAdd: boolean;
  projectId: T;
  weekGoals?: number;
  surveyorId?: number;
}

interface ProjectDetailProps {
  assignments: Assignment[];
  callsHistory: ChartData[];
  isManager: boolean;
  pInfo: Project;
  roster_history: RosterHistoryList;
  user: Account;
}

const Details: React.FC<ProjectDetailProps> = (props: ProjectDetailProps) => {
  const { assignments, callsHistory, isManager, pInfo, roster_history, user } = props;
  const initPageNo = 1;
  const { id }: T = useParams();
  const hist = useHistory();
  const proAction = useActions(projectActions);
  const callActions = useActions(clActions);
  const rosterA = useActions(rosterAction);
  const assignAC = useActions(assignActions);
  const userAction = useActions(userAccActions);
  const [loader, isLoading] = React.useState(initFalsy);
  const [isWeeklySelected, setWeekly] = React.useState(!initFalsy);
  const [isPGDetailLoader, setPGDetailLoader] = React.useState(initFalsy);
  const [isShowPMPopup, setShowPMPopup] = React.useState(initFalsy);
  const [limit, setPageSize] = React.useState(DEFAULT_RECORDS_PER_PAGE);
  const [activePage, setPageChange] = React.useState(initPageNo);
  const [initPD, setPD]: [boolean, Function] = React.useState(initFalsy);
  const [lock, setIsLockStatus]: [boolean, Function] = React.useState(pInfo.isLocked || initFalsy);
  const [disable, setDisabled] = React.useState(initFalsy);
  const [aHistPayload, setAHPayload]: [T, Function] = React.useState(null);
  const rosterHistoryHeaders = ['Date Uploaded', 'Number of encounters', 'Date Range', 'Status', 'Action'];
  const surveyorHeaders = ['Surveyor', 'Email', 'Completed / Weekly', 'Action'];
  const contacts = va(pInfo.contacts) ? (pInfo.contacts as ProjectContact[]) : [];
  const [dlDate, setDeadlineDate] = React.useState('');
  const [getRPeriod, setRPeriod] = React.useState('');
  const [startDate, setStartDate] = React.useState('');
  const [isAssignedQues, setIsAssignedQues] = React.useState(initFalsy);
  const [chartHeight, setChartHeight] = React.useState(0);

  const onSetStartDate = (val: string) => {
    const newDeadline = new Date(val).addMonths(pInfo.reportFreqId).toISOString();
    setStartDate(val);
    setDeadlineDate(newDeadline);
  };
  const currWeek = firstDayOfWeek();
  const managerId = isManager ? user.id : undefined;
  const [rows, setRows] = React.useState([] as SurList);
  const sortKeys = ['name', 'email'];

  // @ts-ignore
  // noinspection TypeScriptValidateTypes
  const modifiers = va(pInfo.modifiers)
    ? (pInfo.modifiers?.filter((a: Modifier) => a.modifierId === MODIFIER_TABLE_TYPE.Payment) as Modifier[])
    : [];
  const completedSurvey = va(callsHistory) ? aMaxVal(callsHistory.map((c) => c.cSurveys)) : 0;
  const pmName = joinAsFullName(pInfo?.user?.firstName, pInfo?.user?.lastName);
  const getContactsDetails = () => {
    const style = { borderBottom: '1px solid orange' },
      types = uniq(contacts.map((c) => c.typeId)).sort(),
      finalConArr: { label: string; style?: T; value: string }[] = [];

    types.forEach((type) => {
      const contactType: string = PRJ_CONTACT_TYPE[type as number];
      const pContacts = contacts.filter((i) => i.typeId === type);
      pContacts.forEach((primaryContact) => {
        finalConArr.push({
          label: contactType,
          value: primaryContact && joinAsFullName(primaryContact.firstName, primaryContact.lastName),
        });
        finalConArr.push({
          label: `${contactType} Number`,
          value: primaryContact && (primaryContact.phoneNumber as string),
        });
        finalConArr.push({
          label: `${contactType} Email`,
          value: primaryContact && primaryContact.email,
        });
        finalConArr.push({
          label: `${contactType} Job Title`,
          style: style,
          value: primaryContact && (primaryContact.jobTitle as string),
        });
      });
    });
    return finalConArr;
  };
  const getModifiers = () => {
    const modFinalArr: { label: string; value: string }[] = [];
    modifiers.forEach((m) => {
      const { details, rate, typeId }: Modifier = m;
      const modifierType: string = PROJECT_MODIFIERS[typeId];
      modFinalArr.push({ label: `${modifierType} [${details}]`, value: asCurrency(rate) });
    });
    return modFinalArr;
  };
  const proDetails = [
    { label: 'Client Name', value: pInfo?.client?.name },
    { label: 'Project Type', value: PROJECT_TYPE[pInfo.typeId] },
    { label: 'Reporting Period', value: REPORTING_FREQ[pInfo.reportFreqId] },
  ].concat(getContactsDetails());
  const proSetting = [
    {
      label: 'Reporting Period',
      name: 'reportFreqId',
      children: <span>{REPORTING_FREQ[pInfo.reportFreqId]}</span>,
    },
    {
      label: 'Project Type',
      name: 'typeId',
      children: <span>{PROJECT_TYPE[pInfo.typeId]}</span>,
    },
  ];
  const sd = !!startDate ? startDate : pInfo.startDate;
  const fields = [
    {
      component: DatePicker,
      label: 'Project Start',
      name: 'start',
      value: sd,
      setValue: onSetStartDate,
    },
    {
      component: DatePicker,
      label: 'Project Deadline',
      name: 'deadline',
      value: !!dlDate ? dlDate : pInfo.deadline,
      setValue: setDeadlineDate,
    },
    {
      className: 'w100',
      component: TextBox,
      label: 'Sampling Weeks',
      name: 'reportWeeks',
      type: 'number',
      value: !!getRPeriod ? getRPeriod : pInfo.reportWeeks,
      onChange: (e: T) => {
        const _rpValue = e.target.value;
        if (!piz(_rpValue) || _rpValue < 0 || _rpValue > 99) {
          return;
        }
        setRPeriod(_rpValue);
      },
    },
  ];
  const handlePopup = async (isAdd: boolean, weekGoals?: number, assignmentId?: number, surveyorId?: number) => {
    setPGDetailLoader(!initFalsy);
    const payload: AHP = { assignmentId, isAdd, projectId: id, weekGoals, surveyorId };
    setAHPayload(payload);
    setPGDetailLoader(initFalsy);
    setDisabled(!initFalsy);
  };
  const handleClosePop = async () => {
    await assignAC.flushAssignmentsHistory();
    await assignAC.flushAssignmentHistWeeklyCount();
    setAHPayload(null);
    setDisabled(initFalsy);
    const managerId = isManager ? user.id : undefined;
    const assignment = await assignAC.fetchAssignments(id, managerId);
    refreshList('', SortDir.ASC, assignment).then();
  };
  const onShowPM = async () => {
    isLoading(!initFalsy);
    await userAction.pullUserInfo(pInfo?.managerId);
    setShowPMPopup(!initFalsy);
    isLoading(initFalsy);
  };
  const onHidePMPopup = () => {
    setShowPMPopup(initFalsy);
  };
  const labelFields: LabelField[] = [
    {
      as: 'a',
      label: 'Project Manager',
      onClick: onShowPM,
      value: pmName,
      title: 'Project Manager Contact Information',
    },
    { label: 'Time Zone', value: pez(pInfo.timezone as string) },
    { label: 'Status', value: PROJECT_STATUS[pInfo.statusId] },
    { label: `Current ${isWeeklySelected ? 'Week' : 'Report'} Count`, value: completedSurvey },
    { label: 'Payment Rate', value: asCurrency(pInfo.paymentRate) },
    { label: 'Billable Payment Rate', value: asCurrency(pInfo.billableRate) },
  ].concat(getModifiers());
  const navFux = (activePage: number, gridSize: number) => {
    const skip = getSkipLimit(activePage, gridSize);
    rosterA.fetchRosters(id, skip, gridSize).then();
  };
  const confirmUndoRoster = (o: RosterHistory) => {
    setPD({ id: o.id, projectId: o.projectId });
  };
  const onChangeType = (isWeekly: boolean) => {
    isLoading(!initFalsy);
    const managerId = isManager ? user.id : undefined;
    callActions.fetchSurCallHistory(id, managerId, isWeekly, !initFalsy);
    setWeekly(isWeekly);
    isLoading(initFalsy);
  };
  const onSort = async (column: string, direction: ST) => {
    await refreshList(column, direction);
  };
  const refreshList = async (cc?: string, direction?: string, surveyors: SurList = assignments) => {
    isLoading(!initFalsy);
    const _sortKey = `${direction === SortDir.DESC ? '-' : ''}${cc}`;
    const _rows = Object.assign([], surveyors).sort(aSort(_sortKey));
    setRows(_rows);
    isLoading(initFalsy);
  };
  const fetchAssignment = async () => {
    const assignment = await assignAC.fetchAssignments(id, managerId);
    refreshList('', SortDir.ASC, assignment).then();
  };
  const goToSampling = () => {
    const path = `${isManager ? Path.SamplingParams : Path.OPSSamplingParams}/${pInfo.id}`;
    hist.push(path, pInfo);
  };
  const updatePRDate = async () => {
    const payload = {
      id,
      startDate: sd,
      deadline: dlDate ? dlDate : pInfo.deadline,
      reportWeeks: piz(getRPeriod),
    };
    await proAction.updateProjectDate(payload);
  };
  const toggleLockProject = async () => {
    isLoading(!initFalsy);
    setIsLockStatus(!lock);
    const payload = { id, isLocked: !lock };
    await proAction.updateProjectStatus(payload);
    isLoading(initFalsy);
  };
  const doUndo = async (getPDData: T) => {
    isLoading(!initFalsy);
    const skip = getSkipLimit(activePage, limit);
    await rosterA.undoRosters(getPDData.id, getPDData.projectId);
    await rosterA.fetchRosters(id, skip, limit);
    isLoading(initFalsy);
    setPD(initFalsy);
  };
  const projectLockProps = {
    checked: lock,
    labelInLeft: !initFalsy,
    label: 'Project Lock',
    name: 'project-status',
    onChange: toggleLockProject,
    toggle: !initFalsy,
  };
  const goToSamplingParams: ButtonProps = {
    className: 'mb15 ml10',
    content: 'Sampling Parameters',
    floated: 'right',
    onClick: goToSampling,
    primary: !initFalsy,
  };
  const updateButton: ButtonProps = {
    className: 'mb15 ml10',
    content: 'Update',
    floated: 'right',
    onClick: updatePRDate,
    primary: !initFalsy,
  };
  const confirmButtProps: ButtonProps = {
    color: 'red',
    content: 'Confirm',
    loading: loader,
    onClick: () => doUndo(initPD),
    primary: initFalsy,
    type: 'button',
  };
  const confirmButton = (
    <>
      <Button {...confirmButtProps} />
    </>
  );
  const surveyors = va(rows)
    ? rows.map((assignment) => {
        const { assignmentHistory, id, surveyor, surveyorCompGoals, surveyorGoals, surveyorId } = assignment;
        const isAdd = !vo(assignmentHistory);
        const unlatchPopup: IconProps = {
          onClick: () => handlePopup(isAdd, surveyorGoals, id, surveyorId),
          title: surveyorGoals ? 'View Records' : 'Add Weekly Goals',
        };
        return {
          _warn: isAdd,
          sn: joinAsFullName(surveyor?.firstName, surveyor?.lastName),
          se: surveyor?.email,
          progress: <ProgressGoals compGoals={surveyorCompGoals} reqGoals={surveyorGoals} />,
          action: <Icon className='hand-pointer' name='eye' {...unlatchPopup} />,
        };
      })
    : [];
  const rosterHistory = va(roster_history.rows)
    ? roster_history.rows.map((history) => {
        const { isUndoable, rangeEndDate, rangeStartDate, status, totalEnc, uploadedOn } = history;
        const undoableProps: IconProps = !isUndoable
          ? { color: 'red' }
          : {
              onClick: () => confirmUndoRoster(history),
              title: 'Undo Roster',
            };
        const hasErr = status === RosterStatus.Errored;
        const isReady = status === RosterStatus.Ready && uploadedOn && rangeEndDate && rangeStartDate;
        const readyIconProps: IconProps = {
          loading: status === RosterStatus.Processing,
          name: isReady ? 'checkmark' : hasErr ? 'exclamation' : 'refresh',
          title: RosterStatus[status],
        };
        return {
          uploadedOn: uploadedOn && new Date(uploadedOn).toUSDateString(),
          totalEnc,
          range: (
            <>
              {rangeStartDate &&
                rangeEndDate &&
                `${new Date(rangeStartDate).toUSDateString()} - ${new Date(rangeEndDate).toUSDateString()}`}
            </>
          ),
          status: (
            <>
              {status !== RosterStatus.Removed && <Icon {...readyIconProps} />}
              {RosterStatus[status]}
            </>
          ),
          action: (
            <>
              {status !== RosterStatus.Processing && <Icon className='hand-pointer' name='trash' {...undoableProps} />}
            </>
          ),
        };
      })
    : [];
  const confirmProps = {
    content: CONFIRM_MESSAGES.UNDO_ROSTER,
    header: 'Undo Roster',
    confirmButton,
    open: vo(initPD as {}),
    onCancel: () => setPD(initFalsy),
    onConfirm: () => doUndo(initPD),
  };
  const pmModelContentProps = {
    action: 'view',
    id: pInfo?.user?.id,
    isModel: !initFalsy,
    onCancel: onHidePMPopup,
  };
  const pmModelProps: GenModelProps = {
    content: <ViewEditAccount {...pmModelContentProps} />,
    initialize: isShowPMPopup,
    onCancel: onHidePMPopup,
    size: 'small',
  };
  const ahPGModelActions = (
    <>
      <Button content='Cancel' onClick={handleClosePop} />
    </>
  );
  const ahPGModelContent = (
    <React.Fragment>
      <ProjectGoals aHistPayload={aHistPayload} isLoading={isPGDetailLoader} setLoader={setPGDetailLoader} />
    </React.Fragment>
  );
  const reportButtProps: ButtonProps[] = [
    { active: isWeeklySelected === !initFalsy, content: 'Current Week', onClick: () => onChangeType(!initFalsy) },
    { as: 'div', className: 'or', key: 'or' },
    { active: isWeeklySelected === initFalsy, content: 'Reporting Period', onClick: () => onChangeType(initFalsy) },
  ];
  const ahPGModelProps: GenModelProps = {
    actions: ahPGModelActions,
    content: ahPGModelContent,
    header: 'Assignment History',
    initialize: disable,
    onCancel: handleClosePop,
    size: 'fullscreen',
  };
  const gridProps = { headers: surveyorHeaders, list: surveyors, onSort, sortKeys };
  const firstRun = () => {
    const skip = getSkipLimit(activePage, limit);
    isLoading(!initFalsy);
    proAction
      .getProjectInfo(id)
      .then((p: Project) => {
        if (p.statusCode === StatusCode.Unauthorized) {
          isLoading(!initFalsy);
          setIsAssignedQues(!initFalsy);
        } else {
          setIsLockStatus(p.isLocked);
          return rosterA
            .fetchRosters(id, skip, DEFAULT_RECORDS_PER_PAGE)
            .then(() => callActions.fetchSurCallHistory(id, managerId, isWeeklySelected, !initFalsy))
            .then(() => fetchAssignment())
            .then(() => isLoading(initFalsy));
        }
      })
      .then(() => {
        const clientHeight = window.document.getElementById('pdc')?.parentElement?.clientHeight || 0;
        const chartHeight = clientHeight - (20 / 100) * clientHeight;
        setChartHeight(chartHeight);
      })
      .catch(() => {
        setIsAssignedQues(!initFalsy);
      });
    return () => {
      proAction.flushProjectInfo();
      assignAC.flushAssignments();
      assignAC.flushAssignmentsHistory();
      callActions.flushCallInfo();
    };
  };

  React.useEffect(firstRun, []);

  return (
    <>
      {loader && <Loader />}

      {initPD && <Confirm {...confirmProps} />}

      {!isAssignedQues && (
        <Grid>
          <Grid.Row className='headerTop'>
            <Grid.Column width={16}>
              <h1 className='mainTitle'>
                Project Details&nbsp;
                <ProjectName name={pInfo.name} isLocked={pInfo.isLocked} />
              </h1>
              <BtnSwitch buttonProps={reportButtProps} />
              <div className='btnToggle'>
                <ChkBox {...projectLockProps} />
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row stretched>
            <CallsHistoryChart
              callsHistory={callsHistory}
              height={chartHeight}
              isWeeklySelected={isWeeklySelected}
              maxValue={surveyMaxValue(callsHistory)}
              proDetails={labelFields}
            />
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16}>
              <Grid>
                <Grid.Row>
                  <Grid.Column computer={6} tablet={12} mobile={16}>
                    <Card fluid={!initFalsy} className='mb20 disabledList'>
                      <Card.Content>
                        <Grid.Row>
                          <Grid.Column width={16}>
                            <h3 className='w100 subHeaderInner'>
                              <span>Contact Details</span>
                            </h3>
                          </Grid.Column>
                          {proDetails.map((f, k) => (
                            <Grid.Column key={k} computer={16} tablet={16} mobile={16}>
                              <TextBox disabled={!initFalsy} {...f} />
                            </Grid.Column>
                          ))}
                        </Grid.Row>
                      </Card.Content>
                    </Card>
                  </Grid.Column>
                  <Grid.Column computer={10} tablet={16} mobile={16}>
                    <Card fluid={!initFalsy}>
                      <Card.Content className='pb0'>
                        <Grid.Row>
                          <Grid.Column width={16}>
                            <h3 className='w100 subHeaderInner'>
                              <span>Settings</span>
                            </h3>
                          </Grid.Column>
                          <Grid.Column width={16}>
                            <Grid>
                              <Grid.Row>
                                <Grid.Column computer={8} mobile={16} tablet={16}>
                                  <label>{proSetting[0].label}</label>
                                  <span>{REPORTING_FREQ[pInfo.reportFreqId]}</span>
                                </Grid.Column>
                                <Grid.Column computer={8} mobile={16} tablet={16}>
                                  <label>{proSetting[1].label}</label>
                                  <span>{PROJECT_TYPE[pInfo.typeId]}</span>
                                </Grid.Column>
                                <hr />
                                {fields.map(({ component, ...fProps }: T, i) => {
                                  return (
                                    <Grid.Column key={i} computer={8} mobile={16} tablet={16}>
                                      {React.createElement(component, fProps)}
                                    </Grid.Column>
                                  );
                                })}
                              </Grid.Row>
                            </Grid>
                          </Grid.Column>
                          <hr />
                          <Grid.Column width={16}>
                            <Button {...goToSamplingParams} />
                            <Button {...updateButton} />
                          </Grid.Column>
                        </Grid.Row>
                      </Card.Content>
                    </Card>
                    <Card fluid={!initFalsy}>
                      <Card.Content className='pb0'>
                        <Grid.Row>
                          <Grid.Column width={16}>
                            <h3 className='w100 subHeaderInner'>
                              <span>Project Goals {`- (${currWeek})`}</span>
                            </h3>
                          </Grid.Column>
                          <Grid.Column computer={16} tablet={16} mobile={16}>
                            <GridView {...gridProps} />
                            <Model {...ahPGModelProps} />
                          </Grid.Column>
                        </Grid.Row>
                      </Card.Content>
                    </Card>
                    <Card fluid={!initFalsy}>
                      <Card.Content>
                        <Grid.Row>
                          <Grid.Column width={16}>
                            <h3 className='w100 subHeaderInner'>
                              <span>Roster History</span>
                            </h3>
                          </Grid.Column>
                          <Grid.Column width={16}>
                            <GridView headers={rosterHistoryHeaders} list={rosterHistory} />
                            <Paging
                              totalRecords={roster_history.count}
                              navigate={navFux}
                              activePage={activePage}
                              pageSize={limit}
                              setPageSize={setPageSize}
                              setPageChange={setPageChange}
                            />
                          </Grid.Column>
                        </Grid.Row>
                      </Card.Content>
                    </Card>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}

      {isShowPMPopup && <Model {...pmModelProps} />}
    </>
  );
};

const mapToProps = (state: StoreT) => {
  return {
    assignments: state.assignments,
    callsHistory: state.callsHistory as T,
    pInfo: state.projectInfo,
    roster_history: state.roster_history,
    user: state.auth.user,
  };
};

export default connect(mapToProps)(Details);

import { ActionType, ApiEngine, CleanResponse, Dispatcher } from '../../model';
import { setAlert, setSuccess } from './alert';

type ESC = (projectId: number, from: string, to: string) => Dispatcher;

const rosterAPI = 'roster';

export const uploadRosters =
  (projectId: number, buffer: object, onlyParse: boolean, file: string) =>
  async (dispatch: Function, _store: Function, api: ApiEngine) => {
    try {
      const query = { onlyParse, file };
      const { message, rows } = await api.post(`roster/${projectId}`, buffer, query);

      dispatch({ type: ActionType.UPLOAD_ROSTERS, payload: rows });
      dispatch(setSuccess(message));
      return;
    } catch (err) {
      dispatch(setAlert(err));
    }
  };

export const flushRosters = () => async (dispatch: Function) => {
  dispatch({ type: ActionType.FLUSH_ROSTERS, payload: [] });
};

export const fetchRosters =
  (projectId: number, skip: number, limit: number) => async (dispatch: Function, _store: Function, api: ApiEngine) => {
    try {
      const query = { skip, limit, projectId };
      const data = await api.get(rosterAPI, null, query);

      return dispatch({ type: ActionType.FETCH_ROSTERS, payload: data });
    } catch (err) {
      dispatch(setAlert(err));
    }
  };

export const undoRosters =
  (id: number, projectId: number) => async (dispatch: Function, _store: Function, api: ApiEngine) => {
    try {
      const query = { id, projectId };
      const data: CleanResponse = await api.put(rosterAPI, query);
      dispatch({ type: ActionType.UNDO_ROSTERS, payload: _store().roster_history });
      setSuccess(data.message);
      return;
    } catch (err) {
      dispatch(setAlert(err));
    }
  };

/**
 * Get Calls as Roster, Export
 * @param projectId
 * @param from
 * @param to
 */
export const exportRosterCallLogs: ESC = (projectId, from, to) => async (dispatch, _s, api) => {
  try {
    const rosterExportApiPath = 'calls';
    const query = { projectId, from, to };
    const surveyCallsDetails = await api.get(rosterExportApiPath, null, query);

    dispatch({ type: ActionType.SURVEYOR_EXPORT_CSV_SUCCESS, payload: surveyCallsDetails });
    dispatch(setSuccess('Export Successful!'));

    return surveyCallsDetails;
  } catch (err) {
    dispatch(setAlert(err));
  }
};

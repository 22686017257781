import React from 'react';
import { Grid, RadioGroup } from '../Inputs';
import { SelectValue, T } from '../../model';
import { va } from '../../utils/arrayUtils';

interface CRP {
  buttonFields: T[];
  captureResponse: (result: number) => T;
  className?: string;
  initResult?: number;
}

const CallResponses: React.FC<CRP> = (props: CRP) => {
  const irv = props.initResult || 0;
  return (
    <Grid.Row className={props.className}>
      <Grid.Column>
        {va(props.buttonFields) && (
          <RadioGroup
            className='questionnaire-chk-box'
            labelCss='mt10 mb20'
            label='Select Call Disposition Code'
            onChange={(val: SelectValue) => props.captureResponse(val)}
            options={props.buttonFields}
            defaultValue={irv}
          />
        )}
      </Grid.Column>
    </Grid.Row>
  );
};

export default CallResponses;

import { ConfirmInvitePage, ForgotPassword, LoginPage } from './pages/Accounts';
import { AdminDashboard, Clients, ExternalUser, Languages, Projects, UserAccounts } from './pages/Admin';
import { CC as CommentCleaner, CCDash, CCP } from './pages/CommentCleaner';
import { CCMSDash } from './pages/CIMR';
import { CCSD } from './pages/CCSurveyor';
import { KSD } from './pages/KellerSurveyor';
import { AccManagement, CommonDash, MyProfile } from './pages/Common';
import {
  PMCallLog,
  PMCallQ,
  PMCC,
  PMCCP,
  PMDash,
  PMEs,
  PMEscalationsDetail,
  PMProjects,
  PMQ,
  PMQb,
  PMQl,
  PMSampling,
  PMSerR,
  PMStats,
  PMSurveyExport,
  PMSurveyors,
  PMSvEarnings,
  PMSvPrj,
  PMSvPrjDet,
  ProjectDetail,
} from './pages/PM';
import {
  AllSurveyors,
  Assignments,
  CallQuality,
  MyProjects,
  OPDash,
  OPQ,
  OPQb,
  OPQl,
  OpsCL,
  OPSProjectDetail,
  OPSSampling,
  OPSurveyExport,
  QBM,
  PA,
  Stats,
  SurveyorEarnings,
  OpSerR,
  OPCCP,
  OPCC,
  OPEscalationsDetail,
  OPEs,
} from './pages/OPS';
import { QACLog, QADash, QAQuality } from './pages/QA';
import {
  SEarnings,
  SG,
  SPCall,
  SPDetails,
  SProjects,
  SStats,
  SurCL,
  SurCQ,
  SurDash,
  SurQsV,
  CLDwn,
} from './pages/Surveyor';
import { AdditionalInfo, Guidelines, RosterExport, RosterImport } from './components/Projects';

import { RouteProps } from './model';
import { initFalsy, ROLES } from './constants';
import { SRDetails } from './components/ServiceRecoveries';

export enum ParentPath {
  Admin = '/admin',
  CC = '/cc',
  CCSurveyor = '/ccs',
  CIMR = '/cimr',
  OPS = '/ops',
  PM = '/project-manager',
  QA = '/qa',
  Surveyor = '/surveyor',
  KellerSurveyor = '/keller',
}

export enum Path {
  Home = '/',
  Forgot = '/forgot-password',
  Reset = '/reset-password/:resetToken',
  Create = '/create-password/:resetToken',

  MyProfile = '/my-profile',
  AdminAccounts = '/admin/account-management',
  AdminClients = '/admin/client-management',
  Languages = '/admin/languages',
  AdminProjects = '/admin/project-list',
  AdminProjectAddEdit = '/admin/projects/:id',
  AdminProjectView = '/admin/projects/:id/:mode',
  AdminDash = '/admin/dashboard',
  AdminExternalUser = '/admin/external-login-attempts',

  PMDash = '/project-manager/dashboard',
  MySurveyorPID = '/project-manager/my-surveyors/projects/:id',
  MySurveyorProjects = '/project-manager/my-surveyors/projects',
  MySurveyors = '/project-manager/my-surveyors',
  MySurveyorsID = '/project-manager/my-surveyors/:uid/:pid',
  PMCallLog = '/project-manager/call-log',
  PMCallQuality = '/project-manager/call-quality',
  PMcc = '/project-manager/comment-cleaning',
  PMCCProjects = '/project-manager/comment-cleaning-selection',
  PMAccManagement = '/project-manager/user-directory',
  PMEscalations = '/project-manager/escalations',
  PMEscalationsDetail = '/project-manager/escalation-detail',
  PMEscalationsDetailID = '/project-manager/escalation-detail/:id',
  PMQsAddEdit = '/project-manager/questionnaires/:id',
  PMQuestionnaireList = '/project-manager/questionnaires',
  PMQuestionnairePid = '/project-manager/questionnaire-viewer/:id',
  PMQuestionnaireViewer = '/project-manager/questionnaire-viewer',
  PMServiceRecoveries = '/project-manager/client-report-exports',
  PMStats = '/project-manager/surveyor-call-stats',
  PMSurveyExportPid = '/project-manager/survey-export/:id',
  PMSurveyorEarnings = '/project-manager/surveyor-earnings',
  PMSurveyorGuidelines = '/project-manager/surveyor-guidelines',
  PMSurveyorGuidelinesPID = '/project-manager/surveyor-guidelines/:id',
  ProjectAddInfoID = '/project-manager/info/:id',
  ProjectAdditionalInfo = '/project-manager/info',
  ProjectDetail = '/project-manager/detail',
  ProjectDetailID = '/project-manager/detail/:id',
  Projects = '/project-manager/my-projects',
  Rosters = '/project-manager/rosters',
  SamplingParams = '/project-manager/sampling',
  SamplingParamsID = '/project-manager/sampling/:id',
  PMRosterExport = '/project-manager/roster-export',
  PMSurveyExport = '/project-manager/survey-export',
  ServiceRecoveriesPID = '/project-manager/client-report-exports/:id',

  OPSDash = '/ops/dashboard',
  OPQuestionnaireAddEdit = '/ops/questionnaires/:id',
  OPQuestionnaireList = '/ops/questionnaires',
  OPQuestionnairePid = '/ops/questionnaire-viewer/:id',
  OPQuestionnaireViewer = '/ops/questionnaire-viewer',
  OPcc = '/ops/comment-cleaning',
  OPCCProjects = '/ops/comment-cleaning-selection',
  OPAccManagement = '/ops/user-directory',
  OPServiceRecoveries = '/ops/client-report-exports',
  OPServiceRecoveriesPID = '/ops/client-report-exports/:id',
  OPSAllSurveyors = '/ops/all-surveyors',
  OPSAssignments = '/ops/assignments',
  OPSCallLog = '/ops/call-log',
  OPSCallQuality = '/ops/call-quality',
  OPSProjectAssignment = '/ops/assignments/:id',
  OPSProjects = '/ops/my-projects',
  OPSProjectDetail = '/ops/detail',
  OPSProjectDetailID = '/ops/detail/:id',
  OPSSamplingParams = '/ops/sampling',
  OPSSamplingParamsID = '/ops/sampling/:id',
  OPSQuesBankManager = '/ops/question-bank-manager',
  OPSStats = '/ops/surveyor-call-stats',
  OPSEscalations = '/ops/escalations',
  OPSEscalationsDetail = '/ops/escalation-detail',
  OPSEscalationsDetailID = '/ops/escalation-detail/:id',
  OPSSurveyorEarnings = '/ops/surveyor-earnings',
  OPSSurveyorPID = '/ops/my-surveyors/projects/:id',
  OPSSurveyorProjects = '/ops/my-surveyors/projects',
  OPSSurveyors = '/ops/my-surveyors',
  OPSSurveyorsID = '/ops/my-surveyors/:uid/:pid',
  OPSRosterExport = '/ops/roster-export',
  OPSurveyExport = '/ops/survey-export',
  OPSurveyExportPid = '/ops/survey-export/:id',
  OPSAddInfo = '/ops/info',
  OPSAddInfoID = '/ops/info/:id',
  OPSSvGuidelines = '/ops/surveyor-guidelines',
  OPSSvGuidelinesPID = '/ops/surveyor-guidelines/:id',
  OPSRosters = '/ops/rosters',

  QADash = '/qa/dashboard',
  QACallLog = '/qa/call-log',
  QACallLogDownload = '/qa/call-log-download/:id',
  QACallQuality = '/qa/call-quality',
  QAAccManagement = '/qa/user-directory',
  QAASSvGuidelines = '/qa/surveyor-guidelines',
  QAASSvGuidelinesPID = '/qa/surveyor-guidelines/:id',

  CCDash = '/cc/dashboard',
  CCAccManagement = '/cc/user-directory',
  CCCC = '/cc/comment-cleaning',
  CCProjects = '/cc/comment-cleaning-selection',

  SurveyorCallLog = '/surveyor/call-log',
  //SurveyorCallLogDownload = '/surveyor/call-log-download/:id',
  SurveyorCallQuality = '/surveyor/call-quality',
  SurveyorEarnings = '/surveyor/surveyor-earnings',
  SurveyorGuidelines = '/surveyor/surveyor-guidelines',
  SurveyorGuidelinesPID = '/surveyor/surveyor-guidelines/:id',
  // SurveyorProjectCall = '/surveyor/project-call',
  SurveyorProjectCallID = '/surveyor/project-call/:id',
  SurveyorProjectDetails = '/surveyor/my-projects/:id',
  SurveyorProjects = '/surveyor/my-projects',
  SurveyorProjectSurCallID = '/surveyor/survey-call/:id',
  SurveyorProjectSurvey = '/surveyor/survey-call',
  SurveyorQuestionnairePid = '/surveyor/questionnaire-viewer/:id',
  SurveyorQuestionnaireViewer = '/surveyor/questionnaire-viewer',
  SurveyorStats = '/surveyor/surveyor-call-stats',

  CCSCallLog = '/ccs/call-log', //d
  CCSCallQuality = '/ccs/call-quality',
  CCSGuidelines = '/ccs/surveyor-guidelines', //d
  CCSGuidelinesPID = '/ccs/surveyor-guidelines/:id', //d
  CCSProDet = '/ccs/my-projects/:id', //d
  CCSProjectCallID = '/ccs/project-call/:id',
  CCSProjects = '/ccs/my-projects', //d
  CCSProjectSurvey = '/ccs/survey-call',
  CCSQsViewer = '/ccs/questionnaire-viewer', //d
  CCSQsVPid = '/ccs/questionnaire-viewer/:id', //d
  CCSSurCallID = '/ccs/survey-call/:id',
  CCSurveyorStats = '/ccs/surveyor-call-stats', //d

  CIMRDash = '/cimr/dashboard',
  CIMRAllSurveyors = '/cimr/all-surveyors',
  CIMRAssignments = '/cimr/assignments',
  CIMRCallLog = '/cimr/call-log',
  CIMRCallQuality = '/cimr/call-quality',
  CIMRProjectAssignment = '/cimr/assignments/:id',
  CIMRStats = '/cimr/surveyor-call-stats',
  CIMRViewer = '/cimr/questionnaire-viewer',
  CIMRVPid = '/cimr/questionnaire-viewer/:id',
  CIMRSurveyorPID = '/cimr/my-surveyors/projects/:id',
  CIMRSurveyorProjects = '/cimr/my-surveyors/projects',
  CIMRSurveyors = '/cimr/my-surveyors',
  CIMRSurveyorsID = '/cimr/my-surveyors/:uid/:pid',
  CIMRAccManagement = '/cimr/user-directory',

  KellerProjects = '/keller/my-projects',
  KellerCallLog = '/keller/call-log',
  KellerSurveyorStats = '/keller/surveyor-call-stats',
  KellerSurveyorGuidelines = '/keller/surveyor-guidelines',
  KellerSurveyorGuidelinesPID = '/keller/surveyor-guidelines/:id',
  KellerSurveyorProDet = '/keller/my-projects/:id',
  KellerSurveyorQsViewer = '/keller/questionnaire-viewer',
  KellerSurveyorQsVPid = '/keller/questionnaire-viewer/:id',
  KellerSurveyorProjectSurvey = '/keller/survey-call',
  KellerSurveyorSurCallID = '/keller/survey-call/:id',
  KellerSurveyorEarnings = '/keller/surveyor-earnings',
}

const pmProps = { parent: PMDash, role: ROLES['Project Manager'] };
const opsProps = { parent: OPDash, role: ROLES.Operations };
const ccSurProps = { parent: CCSD, role: ROLES['CIMR Surveyor'] };
const surProps = { parent: SurDash, role: ROLES.Surveyor };
const cimrProps = { parent: CCMSDash, role: ROLES['CIMR Manager'] };
const kellerSurProps = { parent: KSD, role: ROLES['Keller Surveyor'] };

const ROUTES: RouteProps<Path>[] = [
  { component: LoginPage, isPublic: !initFalsy, path: Path.Home },
  { component: ForgotPassword, isPublic: !initFalsy, path: Path.Forgot },
  { component: ForgotPassword, isPublic: !initFalsy, path: Path.Reset },
  { component: ConfirmInvitePage, isPublic: !initFalsy, path: Path.Create },
  { component: MyProfile, path: Path.MyProfile, allowAllRoles: !initFalsy, parent: CommonDash },

  { component: AdminDashboard, path: Path.AdminDash, role: ROLES.Admin },
  { component: UserAccounts, path: Path.AdminAccounts, role: ROLES.Admin },
  { component: Clients, path: Path.AdminClients, role: ROLES.Admin },
  { component: Languages, path: Path.Languages, parent: AdminDashboard, role: ROLES.Admin },
  { component: Projects, exact: !initFalsy, path: Path.AdminProjects, role: ROLES.Admin },
  { component: Projects, exact: !initFalsy, path: Path.AdminProjectAddEdit, role: ROLES.Admin },
  { component: Projects, exact: !initFalsy, path: Path.AdminProjectView, role: ROLES.Admin },
  {
    component: ExternalUser,
    parent: AdminDashboard,
    exact: !initFalsy,
    path: Path.AdminExternalUser,
    role: ROLES.Admin,
  },

  { component: PMDash, path: Path.PMDash, role: ROLES['Project Manager'] },
  { component: PMProjects, path: Path.Projects, ...pmProps },
  { component: RosterImport, path: Path.Rosters, ...pmProps },
  { component: RosterExport, path: Path.PMRosterExport, ...pmProps },
  { component: ProjectDetail, path: Path.ProjectDetailID, ...pmProps },
  { component: AdditionalInfo, path: Path.ProjectAddInfoID, ...pmProps },
  { component: PMSampling, path: Path.SamplingParamsID, ...pmProps },
  { component: PMCallLog, path: Path.PMCallLog, ...pmProps },
  { component: PMSurveyors, exact: !initFalsy, path: Path.MySurveyors, ...pmProps },
  { component: PMSvPrj, path: Path.MySurveyorPID, ...pmProps },
  { component: PMSvPrjDet, path: Path.MySurveyorsID, ...pmProps },
  { component: AccManagement, path: Path.PMAccManagement, ...pmProps },
  { component: PMCallQ, path: Path.PMCallQuality, ...pmProps },
  { component: PMCC, path: Path.PMcc, ...pmProps },
  { component: PMCCP, path: Path.PMCCProjects, ...pmProps },
  { component: PMEs, path: Path.PMEscalations, ...pmProps },
  { component: PMEscalationsDetail, path: Path.PMEscalationsDetailID, ...pmProps },
  { component: PMQ, exact: !initFalsy, path: Path.PMQuestionnairePid, ...pmProps },
  { component: PMQb, exact: !initFalsy, path: Path.PMQsAddEdit, ...pmProps },
  { component: PMQl, exact: !initFalsy, path: Path.PMQuestionnaireList, ...pmProps },
  { component: PMSerR, exact: !initFalsy, path: Path.PMServiceRecoveries, ...pmProps },
  { component: PMStats, path: Path.PMStats, ...pmProps },
  { component: PMSvEarnings, path: Path.PMSurveyorEarnings, ...pmProps },
  { component: PMSurveyExport, path: Path.PMSurveyExportPid, ...pmProps },
  { component: Guidelines, path: Path.PMSurveyorGuidelinesPID, ...pmProps },
  { component: SRDetails, exact: !initFalsy, path: Path.ServiceRecoveriesPID, ...pmProps },

  { component: OPDash, path: Path.OPSDash, role: ROLES.Operations },
  { component: AllSurveyors, path: Path.OPSAllSurveyors, ...opsProps },
  { component: Assignments, exact: !initFalsy, path: Path.OPSProjectAssignment, ...opsProps },
  { component: CallQuality, path: Path.OPSCallQuality, ...opsProps },
  { component: PA, exact: !initFalsy, path: Path.OPSAssignments, ...opsProps },
  { component: MyProjects, path: Path.OPSProjects, ...opsProps },
  { component: OPSProjectDetail, path: Path.OPSProjectDetailID, ...opsProps },
  { component: OPSSampling, path: Path.OPSSamplingParamsID, ...opsProps },
  { component: RosterExport, path: Path.OPSRosterExport, ...opsProps },
  { component: AdditionalInfo, path: Path.OPSAddInfoID, ...opsProps },
  { component: Guidelines, path: Path.OPSSvGuidelinesPID, ...opsProps },
  { component: RosterImport, path: Path.OPSRosters, ...opsProps },
  { component: OPCC, path: Path.OPcc, ...opsProps },
  { component: OPCCP, path: Path.OPCCProjects, ...opsProps },
  { component: OpSerR, exact: !initFalsy, path: Path.OPServiceRecoveries, ...opsProps },
  { component: SRDetails, exact: !initFalsy, path: Path.OPServiceRecoveriesPID, ...opsProps },
  { component: AccManagement, path: Path.OPAccManagement, ...opsProps },
  { component: OPQ, exact: !initFalsy, path: Path.OPQuestionnairePid, ...opsProps },
  { component: OPQb, exact: !initFalsy, path: Path.OPQuestionnaireAddEdit, ...opsProps },
  { component: OPQl, exact: !initFalsy, path: Path.OPQuestionnaireList, ...opsProps },
  { component: OpsCL, path: Path.OPSCallLog, ...opsProps },
  { component: PMSvPrj, path: Path.OPSSurveyorPID, ...opsProps },
  { component: QBM, path: Path.OPSQuesBankManager, ...opsProps },
  { component: Stats, path: Path.OPSStats, ...opsProps },
  { component: OPEs, path: Path.OPSEscalations, ...opsProps },
  { component: OPEscalationsDetail, path: Path.OPSEscalationsDetailID, ...opsProps },
  { component: SurveyorEarnings, path: Path.OPSSurveyorEarnings, ...opsProps },
  { component: OPSurveyExport, path: Path.OPSurveyExportPid, ...opsProps },
  { component: PMSvPrjDet, exact: !initFalsy, path: Path.OPSSurveyorsID, ...opsProps },

  { component: QADash, path: Path.QADash, role: ROLES.Quality },
  { component: QAQuality, path: Path.QACallQuality, parent: QADash, role: ROLES.Quality },
  { component: AccManagement, path: Path.QAAccManagement, parent: QADash, role: ROLES.Quality },
  { component: QACLog, path: Path.QACallLog, parent: QADash, role: ROLES.Quality },
  { component: CLDwn, path: Path.QACallLogDownload, parent: QADash, role: ROLES.Quality },
  { component: Guidelines, path: Path.QAASSvGuidelinesPID, parent: QADash, role: ROLES.Quality },

  { component: CCDash, path: Path.CCDash, role: ROLES['Comment Cleaner'] },
  { component: CommentCleaner, path: Path.CCCC, parent: CCDash, role: ROLES['Comment Cleaner'] },
  { component: CCP, path: Path.CCProjects, parent: CCDash, role: ROLES['Comment Cleaner'] },
  { component: AccManagement, path: Path.CCAccManagement, parent: CCDash, role: ROLES['Comment Cleaner'] },

  { component: SProjects, exact: !initFalsy, path: Path.SurveyorProjects, ...surProps },
  { component: SPDetails, exact: !initFalsy, path: Path.SurveyorProjectDetails, ...surProps },
  { component: SPCall, path: Path.SurveyorProjectCallID, ...surProps },
  { component: SPCall, path: Path.SurveyorProjectSurCallID, ...surProps },
  { component: SurCL, path: Path.SurveyorCallLog, ...surProps },
  /*{component: SurCID, path: Path.SurveyorCallLogDownload, ...surProps},*/
  { component: SurCQ, path: Path.SurveyorCallQuality, ...surProps },
  { component: SEarnings, path: Path.SurveyorEarnings, ...surProps },
  { component: SG, path: Path.SurveyorGuidelinesPID, ...surProps },
  { component: SStats, path: Path.SurveyorStats, ...surProps },
  { component: SurQsV, exact: !initFalsy, path: Path.SurveyorQuestionnairePid, ...surProps },

  { component: SPCall, path: Path.CCSProjectCallID, ...ccSurProps },
  { component: SPCall, path: Path.CCSSurCallID, ...ccSurProps },
  { component: SPDetails, exact: !initFalsy, path: Path.CCSProDet, ...ccSurProps },
  { component: SProjects, exact: !initFalsy, path: Path.CCSProjects, ...ccSurProps },
  { component: SG, path: Path.CCSGuidelinesPID, ...ccSurProps },
  { component: SurCL, path: Path.CCSCallLog, ...ccSurProps },
  { component: SurCQ, path: Path.CCSCallQuality, ...ccSurProps },
  { component: SurQsV, exact: !initFalsy, path: Path.CCSQsVPid, ...ccSurProps },
  { component: SStats, path: Path.CCSurveyorStats, ...ccSurProps },

  { component: CCMSDash, path: Path.CIMRDash, role: ROLES['CIMR Manager'] },
  { component: AllSurveyors, path: Path.CIMRAllSurveyors, ...cimrProps },
  { component: AccManagement, path: Path.CIMRAccManagement, ...cimrProps },
  { component: Assignments, exact: !initFalsy, path: Path.CIMRProjectAssignment, ...cimrProps },
  { component: SurQsV, exact: !initFalsy, path: Path.CIMRVPid, ...cimrProps },
  { component: CallQuality, path: Path.CIMRCallQuality, ...cimrProps },
  { component: PA, exact: !initFalsy, path: Path.CIMRAssignments, ...cimrProps },
  { component: OpsCL, path: Path.CIMRCallLog, ...cimrProps },
  { component: PMSvPrj, path: Path.CIMRSurveyorPID, ...cimrProps },
  { component: PMSvPrjDet, path: Path.CIMRSurveyorsID, ...cimrProps },
  { component: Stats, path: Path.CIMRStats, ...cimrProps },

  { component: SProjects, exact: !initFalsy, path: Path.KellerProjects, ...kellerSurProps },
  { component: SurCL, path: Path.KellerCallLog, ...kellerSurProps },
  { component: SStats, path: Path.KellerSurveyorStats, ...kellerSurProps },
  { component: SG, path: Path.KellerSurveyorGuidelinesPID, ...kellerSurProps },
  { component: SPDetails, exact: !initFalsy, path: Path.KellerSurveyorProDet, ...kellerSurProps },
  { component: SurQsV, exact: !initFalsy, path: Path.KellerSurveyorQsVPid, ...kellerSurProps },

  { component: SPCall, path: Path.KellerSurveyorSurCallID, ...kellerSurProps },
  { component: SEarnings, path: Path.KellerSurveyorEarnings, ...kellerSurProps },
];

export default ROUTES;

import './prototypes/date';
import { Frequency } from '../constants';
import { isValidNumber, pfz, piz } from './numUtils';
import { T } from '../model';

// UTILS
// PROJECT SPECIFIC

const MILLISECONDS_TO_DAYS = 8.64e7;
const MILLISECONDS_TO_MONTHS = 2.628e9;

export const parseDate = (dt: string) => {
  return dt && !isNaN(Date.parse(dt)) ? new Date(dt).toISODateString() : null;
};

/**
 * Deadline from start date & reporting freq months
 * @param startDate
 * @param reportingFreqMonths
 */
export const getDeadline = (startDate: string | Date, reportingFreqMonths: number) => {
  const dd = new Date(startDate).addMonths(reportingFreqMonths);
  return new Date(dd.getFullYear(), dd.getMonth() + 1, dd.lastDayOfMonth()).toISODateString();
};

export const newDate = (date?: Date) => {
  const d = date || new Date();
  const mm = piz(d.getMonth().toString().padStart(2, '0'));
  const dd = piz(d.getDate().toString().padStart(2, '0'));
  const yy = d.getFullYear();
  return new Date(yy, mm, dd);
};

/**
 * Add/Sub seconds and Get a new Date as ISO String
 * @param d
 * @param n
 */
type SST = (d: Date, n: number) => Date;
export const setSeconds: SST = (d, n) => new Date(new Date(d).setSeconds(new Date(d).getSeconds() + n));

/**
 * Add days to date
 * @param date
 * @param days
 */
export const addDays = (date: Date | string, days: number) => {
  return new Date(date).setDate(new Date(date).getDate() + days);
};

/**
 * Format date as MMM-DD-YYYY
 * @param dt
 */
export const formatDate = (dt: string | Date) => {
  return new Date(dt).toMMMDDYYYY();
};

/**
 * Format date as DAY, MMM DD YYYY
 * @param dt
 */
export const formatDateStr = (dt: string | Date) => {
  const d = new Date(dt);
  const ud = Date.UTC(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate());
  return new Date(ud).toUTCString().substring(0, 16);
};

/**
 * Days in-between two dates
 * @param one
 * @param another
 */
export const daysBetween = (one: Date, another: Date) => {
  return Math.round(Math.abs(+one - +another) / MILLISECONDS_TO_DAYS);
};

/**
 * Months in-between two dates
 * @param one
 * @param another
 */
export const monthsBetween = (one: Date, another: Date) => {
  return Math.round(Math.abs(+one - +another) / MILLISECONDS_TO_MONTHS);
};

/**
 * Hours to Time in mm:ss
 */
export const hourToTime = (hours: number) => {
  if (!isValidNumber(hours)) {
    return hours;
  }

  const actualHours = piz(hours);
  const remainingTime = hours - actualHours;
  const minutes = pfz(remainingTime) * 60;
  const min = Math.floor(Math.abs(minutes)).toString().padStart(2, '0');
  const sec = Math.floor((Math.abs(minutes) * 60) % 60)
    .toString()
    .padStart(2, '0');

  return [actualHours, min, sec].join(':');
};

/**
 * Seconds to Time in HH:mm:ss
 */
export const secondsToTime = (seconds: number) => {
  if (!isValidNumber(seconds)) {
    return seconds;
  }

  const ms = seconds * 1000;
  const locale = 'en-US';
  const opt = {
    timeZone: 'Etc/UTC',
    minute: '2-digit',
    second: '2-digit',
  };

  return new Date(ms).toLocaleTimeString(locale, opt);
};

/**
 * Convert Date And Time (Timezone) as MM/DD/YYYY hh:mm:ss
 * @param dt
 * @param tz
 */
export const usDateTime = (dt: string | Date, tz: string) => {
  const options: T = {
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    month: 'numeric',
    second: 'numeric',
    timeZone: tz,
    year: 'numeric',
  };
  const formatter = new Intl.DateTimeFormat([], options);
  const date = formatter.format(new Date(dt));
  return date.replace(/, /g, ' ');
};

/**
 * Get Last Month/Day/Year Of The Week
 */
export const firstDayOfWeek = (date?: string) => {
  const d = !!date ? new Date(date) : new Date();
  const day = d.getDay();
  const diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
  const nd = new Date(d.setDate(diff));
  const dd = nd.getDate();
  const mm = nd.getMonth() + 1;
  const yy = nd.getFullYear();
  return `${mm}/${dd}/${yy}`;
};

/**
 * USA Date format
 */
export const usDateFormat = (date: string) => new Date(date).toUSDateString();

/**
 * Check Diff B/W Two Dates Match Each Other
 */
export const getDiffTwoDates = (d1: string, d2: string) => {
  const date1 = new Date(d1);
  const date2 = new Date(d2);
  // anything involving '=' should use the '+' prefix
  // it will then compare the dates' millisecond values
  return +date1 <= +date2;
};

/**
 * Return Start (From) Date Of Filter
 * @param isOP
 */
export const startFrom = (isOP?: boolean) => {
  const toDay = newDate();
  return new Date(new Date().setDate(toDay.getDate() - (isOP ? Frequency.Daily : Frequency.Daily))).toISOString();
};

/**
 * Return End Date Of Filter
 * @param endDate
 */
export const toEnd = (endDate: Date | string) => setSeconds(new Date(addDays(endDate, 1)), -1).toISOString();

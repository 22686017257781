import createReducer from './createReducer';
import { Action, ActionType, T } from '../../model';

export const rosters = createReducer([], {
  [ActionType.UPLOAD_ROSTERS](_store: Function, action: Action<T>) {
    return action.payload;
  },

  [ActionType.FLUSH_ROSTERS](_store: Function, action: Action<T>) {
    return action.payload;
  },
});

export const roster_history = createReducer([], {
  [ActionType.FETCH_ROSTERS](_store: Function, action: Action<T>) {
    return action.payload;
  },

  [ActionType.UNDO_ROSTERS](_store: Function, action: Action<T>) {
    return action.payload;
  },

  [ActionType.FLUSH_ROSTERS](_store: Function, action: Action<T>) {
    return action.payload;
  },
});

import React from 'react';
import { useSelector } from 'react-redux';
import { Button, Card, Form, FormField, FormFieldType, Grid, TextBox } from '../../Inputs';
import { ButtonProps, FieldProps, FormProps, StoreT } from '../../../model';
import { editAccountForm, initFalsy, ROLES } from '../../../constants';
import { hasError } from '../../../utils/validation';

type ExtraProps = {
  onCancel: Function;
};

interface EFProps extends FormProps {
  extraProps: ExtraProps;
}

const EditForm = (props: EFProps) => {
  const Truthy = !initFalsy;
  const languageOptions = useSelector((state: StoreT) => state.languages) || [];
  const { errors, handleChange, handleSubmit, isSubmitting, values, extraProps }: EFProps = props;
  const { onCancel }: ExtraProps = extraProps;
  const selProps = {
    label: 'Languages',
    multiple: Truthy,
    name: 'langIds',
    options: languageOptions.map(({ id, name }) => ({ text: name, value: id })),
    placeholder: 'Select Languages',
    upward: Truthy,
  };
  const updateButton: ButtonProps = {
    content: 'Save',
    className: 'ml15',
    primary: Truthy,
    loading: isSubmitting,
    type: 'submit',
  };
  const cancelButton: ButtonProps = {
    content: 'Cancel',
    secondary: Truthy,
    onClick: onCancel,
  };
  const editFormJSON = editAccountForm.map((field: FieldProps) => {
    return field.name
      ? Object.assign(field, {
          onChange: handleChange,
          value: values[field.name],
          error: hasError(errors[field.name]),
          disabled: !Truthy,
        })
      : Object.assign(field, {
          disabled: isSubmitting,
        });
  });
  const editFormButtons: ButtonProps[] = [cancelButton, updateButton];

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column mobile={16} tablet={16} computer={10}>
          <Card fluid={Truthy}>
            <Card.Header>
              <h2 className='subTitle'>Edit Account</h2>
            </Card.Header>
            <Card.Content>
              <Form onSubmit={handleSubmit}>
                <Grid>
                  <Grid.Row>
                    {editFormJSON.map((field: FieldProps, key: number) => (
                      <Grid.Column mobile={16} tablet={16} computer={8} key={key}>
                        {<TextBox {...field} />}
                      </Grid.Column>
                    ))}
                    {(props.initialValues.role === ROLES.Surveyor ||
                      props.initialValues.role === ROLES['CIMR Surveyor'] ||
                      props.initialValues.role === ROLES['Keller Surveyor']) && (
                      <Grid.Column mobile={16} tablet={16} computer={8}>
                        <FormField type={FormFieldType.Select} {...selProps} />
                      </Grid.Column>
                    )}
                    <Grid.Column mobile={16} tablet={16} computer={16} className='mt15' textAlign='right'>
                      {editFormButtons.map((buttonProps: ButtonProps, key: number) => (
                        <Button key={key} {...buttonProps} />
                      ))}
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Form>
            </Card.Content>
          </Card>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default EditForm;

import React, { SyntheticEvent } from 'react';
import { useHistory, useParams } from 'react-router';
import { connect } from 'react-redux';
import { Button, Card, ChkBox, GenModelProps, Grid, GridView, Icon, Model, Paging, Search, Select } from '../Inputs';
import { Path } from '../../routes';
import { Loader } from '../index';
import {
  BN,
  ButtonProps,
  ChkProps,
  IconProps,
  Project,
  QAAll,
  QAListRowItem,
  QAProjects,
  QuestionnaireItem,
  QuestionnaireList,
  SearchProps,
  SelectValue,
  StoreT,
  T,
} from '../../model';
import { useActions } from '../../redux/actions';
import * as questionnaireActions from '../../redux/actions/questionnaire';
import { va } from '../../utils/arrayUtils';
import { sleep } from '../../utils/objectUtils';
import { DEFAULT_RECORDS_PER_PAGE, initFalsy } from '../../constants';
import { getSkipLimit, isValidSearch } from '../../utils/common';

interface QListProps {
  isOps?: boolean;
  qa: QuestionnaireList;
  questionnaire: QuestionnaireItem[];
}

const QuestionnairesList: React.FC<QListProps> = ({ isOps, qa, questionnaire }: QListProps) => {
  const initPageNo = 1;
  const { id } = useParams();
  const hist = useHistory();
  const isAdd = id === 'add';
  const [isLoading, setIsLoading] = React.useState(initFalsy);
  const [copyLoading, setCopyLoader] = React.useState(initFalsy);
  const [copying, setCopying] = React.useState(initFalsy as BN);
  const [selProjectId, setProjectId]: [T, Function] = React.useState(null);
  const [limit, setPageSize] = React.useState(DEFAULT_RECORDS_PER_PAGE);
  const [activePage, setPageChange] = React.useState(initPageNo);
  const [qsRows, setQSRows] = React.useState([] as QAListRowItem[]);
  const [qsCount, setQSCount] = React.useState(0);
  const [includeLogic, setIncludeLogic] = React.useState(initFalsy);
  const [availableProjects, setAvailableProjects]: [Project[], Function] = React.useState([]);
  const [allProjects, setAllProjects]: [{ id: number; name: string }[], Function] = React.useState([]);
  const [searchVal, onSearch]: [T, Function] = React.useState('');
  const qre = useActions(questionnaireActions);
  const proOpts = availableProjects.map((p) => ({ text: p.name, value: p.id }));
  const copyFromProject = allProjects.find((p) => p.id === copying)?.name;
  const copyToProject = allProjects.find((p) => p.id === selProjectId)?.name;
  const setProjectsAndPaginationVars = (all: QAAll[], available: Project[], { count, rows }: QuestionnaireList) => {
    setAllProjects(all);
    setAvailableProjects(available);
    setQSCount(count);
    const skip = getSkipLimit(activePage, limit);
    const _rows = rows.slice(skip, skip + limit);
    setQSRows(_rows);
  };
  const onAction = async (type: string, projectId: number) => {
    switch (type) {
      case 'eye':
        hist.push(`${isOps ? Path.OPQuestionnaireViewer : Path.PMQuestionnaireViewer}/${projectId}`);
        break;
      case 'edit':
        hist.push(`${isOps ? Path.OPQuestionnaireList : Path.PMQuestionnaireList}/${projectId}`);
        break;
      case 'copy':
        setCopying(projectId);
        break;
    }
  };
  const onAdd = () => {
    hist.push(`${isOps ? Path.OPQuestionnaireList : Path.PMQuestionnaireList}/add`);
  };
  const onCancel = () => {
    setProjectId(null);
    setCopying(initFalsy);
    setIncludeLogic(initFalsy);
  };
  const onCopy = async () => {
    setCopyLoader(!initFalsy);
    const qaRes: QAProjects = await qre.copyQuestionnaire(
      copying,
      selProjectId,
      copyFromProject,
      copyToProject,
      includeLogic,
    );
    const { all, available, qa } = qaRes;
    setProjectsAndPaginationVars(all, available, qa);
    setCopyLoader(initFalsy);
    // close
    onCancel();
  };
  const navFux = (activePage: number, gridSize: number) => {
    const skip = getSkipLimit(activePage, gridSize);
    const _rows = Object.assign([], qa.rows).slice(skip, skip + limit);
    setQSRows(_rows);
  };
  const fetchQuestionnaires = async () => {
    setIsLoading(!initFalsy);
    await qre.fetchProjectAvailable(searchVal).then(({ all, available, qa }: QAProjects) => {
      setProjectsAndPaginationVars(all, available, qa);
    });

    setIsLoading(initFalsy);
  };
  const firstRun = () => {
    fetchQuestionnaires().then();

    return () => qre.flushQuestionnaires();
  };
  const actionProps: IconProps[] = [
    { name: 'eye', title: 'Preview Questionnaire' },
    { name: 'edit', title: 'Edit Questionnaire' },
    { name: 'copy', title: 'Copy Questionnaire' },
  ];
  const addButtonProps = {
    circular: !initFalsy,
    className: 'float-right mt-5',
    icon: 'plus',
    onClick: onAdd,
    primary: !initFalsy,
  };
  const chkProps: ChkProps = {
    checked: includeLogic,
    label: 'Include Logic',
    onChange: () => setIncludeLogic(!includeLogic),
  };
  const copyButtonProps: ButtonProps = {
    content: 'Copy',
    disabled: !selProjectId,
    icon: 'copy',
    labelPosition: 'right',
    loading: copyLoading,
    onClick: onCopy,
    primary: !initFalsy,
  };
  const selectProps = {
    clearable: !initFalsy,
    hasFirstEmptyOpt: !initFalsy,
    label: 'Select Project',
    name: 'projects',
    options: proOpts || [],
    placeholder: 'Select Project',
  };
  const questionnairesList = va(qsRows)
    ? qsRows.map((r) => {
        const { project, projectId } = r;
        return {
          projectName: project.name,
          action: actionProps.map((actProps, _idx) => {
            const clickProps = { onClick: () => onAction(actProps.name as string, projectId) };
            return <Icon className='hand-pointer' key={_idx} {...actProps} {...clickProps} />;
          }),
        };
      })
    : [];
  const gridProps = { headers: ['Project Name', 'Action'], list: questionnairesList };
  const pagingProps = {
    totalRecords: qsCount,
    navigate: navFux,
    activePage: activePage,
    pageSize: limit,
    setPageSize,
    setPageChange,
  };
  const copyModelActions = (
    <>
      <Button {...copyButtonProps} />
      <Button content='Cancel' onClick={onCancel} />
    </>
  );
  const copyModelContent = (
    <Grid>
      <Grid.Row>
        <Grid.Column width={16}>
          <h3 className='w100 subHeaderInner'>
            <span>
              Copying Questionnaire from <b>{copyFromProject}</b> to Select Project <b>{copyToProject}</b>
            </span>
          </h3>
        </Grid.Column>
        <Grid.Column width={16}>
          <Select {...selectProps} onChange={(value: SelectValue) => setProjectId(value)} />
        </Grid.Column>
        <Grid.Column width={16}>
          <ChkBox {...chkProps} />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
  const copyModelProps: GenModelProps = {
    actions: copyModelActions,
    centered: !initFalsy,
    content: copyModelContent,
    contentScrolling: initFalsy,
    initialize: !!copying,
    onCancel: onCancel,
  };
  const onSearchChange = (_e: SyntheticEvent, data: SearchProps) => {
    if (isValidSearch(data.value as string, isLoading)) {
      return sleep(0);
    }

    onSearch(data.value);
  };
  const searchProps = {
    loading: isLoading,
    onSearchChange: onSearchChange,
    placeholder: 'Search Project...',
    showNoResults: initFalsy,
  };
  React.useEffect(firstRun, [activePage, limit, searchVal]);

  return (
    <>
      {isLoading && <Loader />}

      {!isAdd && !va(questionnaire) && (
        <Grid>
          <Grid.Row className='headerTop'>
            <Grid.Column width={8}>
              <h1 className='mainTitle'>Create New Questionnaire</h1>
              <Search {...searchProps} />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column computer={8} mobile={16} tablet={8}>
              <Card fluid={!initFalsy}>
                <Card.Content>
                  <h2 className='subHeaderInner'>
                    Add Questionnaire
                    <Button {...addButtonProps} />
                  </h2>
                  <GridView {...gridProps} />
                  <Paging {...pagingProps} />
                </Card.Content>
              </Card>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}

      {copying && <Model {...copyModelProps} />}
    </>
  );
};

const mapStateTProps = (state: StoreT) => {
  return {
    qa: state.questionnaireList,
    questionnaire: state.questionnaire,
  };
};

export default connect(mapStateTProps)(QuestionnairesList);

import React from 'react';
import { Icon } from './Inputs/index';
import { pez } from '../utils/stringUtils';
import { initFalsy } from '../constants';

interface PreProps {
  className?: string;
  title?: string;
  hideMe?: boolean;
}

const PreBlock: React.FC<PreProps> = (props) => {
  const [hideMe, setHideMe] = React.useState(props.hideMe || initFalsy);
  return (
    <div className={`pre-block ${pez(props.className)} ${hideMe ? 'invisible' : ''}`}>
      {props.hideMe !== initFalsy && (
        <Icon name='close' onClick={() => setHideMe(!initFalsy)} className='hand-pointer float-right' />
      )}
      {props.title && <div className='pre-block__title'>{props.title}</div>}
      <pre>{props.children}</pre>
    </div>
  );
};

export default PreBlock;

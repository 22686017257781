import * as React from 'react';
import { useSelector } from 'react-redux';
import { AccountEditForm as EditForm, AccountViewForm as ViewForm } from '../Forms';
import { Button, Confirm, FormWrap } from '../Inputs';
import { validate } from '../../utils/validation';
import * as userAccActions from '../../redux/actions/users';
import * as languagesActions from '../../redux/actions/languages';
import { useActions } from '../../redux/actions';
import { Account, BN, FormikValues, StoreT } from '../../model';
import { CONFIRM_MESSAGES, Del, initFalsy, ROLES } from '../../constants';

type ViewEditAccount = {
  action: string;
  doEdit?: Function;
  id?: number;
  isAdmin?: boolean;
  isModel?: boolean;
  onCancel: Function;
};

const ViewAccount: React.FC<ViewEditAccount> = (props: ViewEditAccount) => {
  const userAction = useActions(userAccActions);
  const langAction = useActions(languagesActions);
  const languageOptions = useSelector((state: StoreT) => state.languages) || [];
  const [delOpen, showDelConfirm]: [BN, Function] = React.useState(initFalsy);
  const [deleting, isDeleting]: [boolean, Function] = React.useState(initFalsy);
  const { action, doEdit, isAdmin, id, isModel, onCancel }: ViewEditAccount = props;
  const isView = action === 'view';
  const performDelOperation = async (id: BN) => {
    isDeleting(!initFalsy);
    const payload = { id, status: userInfo.isDeleted ? !initFalsy : initFalsy };
    await userAction.deactivateUser(payload);
    isDeleting(initFalsy);
    showDelConfirm(initFalsy);
    onCancel();
  };
  const userInfo = useSelector((state: StoreT) => state.userInfo);
  const {
    firstName = '',
    lastName = '',
    email = '',
    phoneNumber = '',
    role = 9,
    vonageExt = '',
    cid = '',
    languages = [],
  } = userInfo;
  const defaultReqFields = ['firstName', 'lastName', 'email'];
  const requiredFields =
    role === ROLES.Surveyor || role === ROLES['CIMR Surveyor'] || role === ROLES['Keller Surveyor']
      ? defaultReqFields.concat(['cid', 'langIds'])
      : defaultReqFields;
  const initLangIds = languageOptions
    .filter((a) => languages.map((a: string) => a.toLowerCase()).includes(a.name.toLowerCase()))
    .map((a) => a.id);
  const commonExtraProps = { id, isModel, onCancel };
  const addUserFormConfig = {
    displayName: isView ? 'viewUserForm' : 'editUserForm',
    initialValues: { firstName, lastName, email, phoneNumber, role, vonageExt, cid, langIds: initLangIds },
    onSubmit: async (values: FormikValues, { resetForm }: FormikValues) => {
      try {
        if (isView) {
          // delete
          showDelConfirm(id);
        } else {
          // do update
          const payload: Partial<Account> = { ...values, id };
          const userUpdateResult = await userAction.updateAccount(payload);
          if (userUpdateResult !== 400) {
            onCancel();
          }
        }
      } catch (err) {
        resetForm();
      }
    },
    children: isView ? ViewForm : EditForm,
    validate: (values: FormikValues) => validate(requiredFields, values),
    extraProps: isAdmin ? { ...commonExtraProps, doEdit } : { ...commonExtraProps },
  };
  const DelButton = <Button color='red' content={Del.YDA} loading={deleting} primary={initFalsy} type='button' />;
  const lyf = () => {
    return () => {
      userAction.flushUserInfo();
      langAction.flushLanguages();
    };
  };

  React.useEffect(lyf, []);

  return (
    <>
      <FormWrap {...addUserFormConfig} />
      {delOpen && (
        <Confirm
          content={CONFIRM_MESSAGES.USER_DEACTIVATE}
          header={Del.DACTU}
          confirmButton={DelButton}
          open={!!delOpen}
          onCancel={() => showDelConfirm(initFalsy)}
          onConfirm={() => performDelOperation(delOpen)}
        />
      )}
    </>
  );
};

export default ViewAccount;

import React from 'react';
import { Loader } from '../index';
import { Button, Card, DatePicker, Form, Grid, GridView } from '../Inputs';
import { CallsHistory, CallsRoster, T } from '../../model';
import { CST_TIMEZONE, Frequency, initFalsy, PatientType, SurCallResponse, SurveyStatus } from '../../constants';
import { va } from '../../utils/arrayUtils';
import { formatDate, newDate, toEnd, usDateTime } from '../../utils/dateUtils';
import { useLocation } from 'react-router';
import { downloadAsCSV, joinAsFullName, jsonToCsv } from '../../utils/common';
import { useActions } from '../../redux/actions';
import * as rosterActions from '../../redux/actions/rosters';

type _processASCSVFunc = (json: CallsHistory[]) => { [p: string]: string | number }[];

const RosterExport: React.FC = () => {
  const toDay = newDate();
  const loc = useLocation();
  const actions = useActions(rosterActions);
  const headers = [
    'DOB',
    'DOS',
    'Age',
    'Cell Phone',
    'Home Phone',
    'Ethnicity',
    'Patient First Name',
    'Last Name',
    'Payer Type',
    'Race',
    'Sex',
    'Provider Name',
    'Provider Required Surveys',
    'Provider Completed Surveys',
    'Provider Remaining Surveys',
    'Provider Total Encounters',
    'Provider Last Encounter',
    'Provider Eligible Encounters',
    'Department Name',
    'Department Required Surveys',
    'Department Completed Surveys',
    'Department Remaining Surveys',
    'Department Last Encounter',
    'Department Total Encounters',
    'Department Eligible Encounters',
    'Site Name',
    'Site Required Surveys',
    'Site Completed Surveys',
    'Site Remaining Surveys',
    'Site Last Encounter',
    'Total Site Encounters',
    'Site Eligible Encounters',
    'Language',
    'Project Name',
    'Purpose',
    'Result',
    'Type',
    'Surveyor Name',
    'Surveyed On',
    'Survey Completed On',
    'Surveyor Email',
    'Survey Status',
  ];
  const pd: T = loc.state;
  const [loader, isLoading] = React.useState(initFalsy);
  const [startDate, setStartDate] = React.useState(
    new Date(new Date().setDate(toDay.getDate() - Frequency.Weekly)).toISOString(),
  );
  const [endDate, setEndDate] = React.useState(toDay.toISOString());
  const [csvJSON, setCsvJSON]: [CallsHistory[], Function] = React.useState([]);
  const datesFilter = [
    { placeholder: 'From', name: 'fromDate', value: startDate, setValue: setStartDate },
    { placeholder: 'To', name: 'toDate', value: endDate, setValue: setEndDate },
  ];
  const processASCSV: _processASCSVFunc = (json: CallsHistory[]) => {
    return json.map((s) => {
      const {
        age,
        departmentEligibleEnc,
        departmentEnc,
        departmentLastEncounter,
        departmentName,
        departmentRem,
        departmentReq,
        departmentSurveys,
        dob,
        dos,
        ethnicity,
        gender,
        isPartial,
        language,
        patientFirstName,
        patientLastName,
        payerType,
        phoneCell,
        phoneHome,
        project,
        providerEligibleEnc,
        providerEnc,
        providerLastEncounter,
        providerName,
        providerRem,
        providerReq,
        providerSurveys,
        race,
        result,
        siteEligibleEnc,
        siteEnc,
        siteLastEncounter,
        siteName,
        siteRem,
        siteReq,
        siteSurveys,
        surveyedOn,
        surveyCompletedOn,
        typeId,
        user,
        visitPurpose,
      } = s;
      const surStatus = isPartial ? SurveyStatus[SurveyStatus.Partial] : SurveyStatus[SurveyStatus.Completed];
      return {
        [headers[0]]: formatDate(dob as string),
        [headers[1]]: formatDate(new Date(dos).toUSDateString()),
        [headers[2]]: age,
        [headers[3]]: phoneCell as string,
        [headers[4]]: phoneHome,
        [headers[5]]: ethnicity,
        [headers[6]]: patientFirstName,
        [headers[7]]: patientLastName,
        [headers[8]]: payerType,
        [headers[9]]: race,
        [headers[10]]: gender,
        [headers[11]]: providerName,
        [headers[12]]: providerReq,
        [headers[13]]: providerSurveys,
        [headers[14]]: providerRem,
        [headers[15]]: formatDate(providerLastEncounter),
        [headers[16]]: providerEnc,
        [headers[17]]: providerEligibleEnc,
        [headers[18]]: departmentName,
        [headers[19]]: departmentReq,
        [headers[20]]: departmentSurveys,
        [headers[21]]: departmentRem,
        [headers[22]]: formatDate(departmentLastEncounter),
        [headers[23]]: departmentEnc,
        [headers[24]]: departmentEligibleEnc,
        [headers[25]]: siteName,
        [headers[26]]: siteReq,
        [headers[27]]: siteSurveys,
        [headers[28]]: siteRem,
        [headers[29]]: formatDate(siteLastEncounter),
        [headers[30]]: siteEnc,
        [headers[31]]: siteEligibleEnc,
        [headers[32]]: language.name,
        [headers[33]]: project.name,
        [headers[34]]: visitPurpose,
        [headers[35]]: SurCallResponse[result],
        [headers[36]]: PatientType[typeId || 0],
        [headers[37]]: joinAsFullName(user.firstName, user.lastName),
        [headers[38]]: surveyedOn ? usDateTime(surveyedOn, CST_TIMEZONE) : '',
        [headers[39]]: surveyCompletedOn ? usDateTime(surveyCompletedOn, CST_TIMEZONE) : '',
        [headers[40]]: user.email,
        [headers[41]]: surStatus,
      };
    });
  };
  const fetchReport = async (projectId: number) => {
    const fileTitle = `survey-report-${new Date().valueOf()}`;
    isLoading(!initFalsy);
    const ed = toEnd(endDate);
    actions
      .exportRosterCallLogs(projectId, startDate, ed)
      .then((response: CallsHistory[]) => {
        const formattedRoster: CallsRoster[] = processASCSV(response);
        setCsvJSON(formattedRoster);
        Object.assign(headers, Object.keys(formattedRoster));
        const csv = jsonToCsv(formattedRoster);
        downloadAsCSV(fileTitle, csv);
        isLoading(initFalsy);
      })
      .catch((err: Error) => {
        console.error(err);
        isLoading(initFalsy);
      });
  };

  return (
    <>
      {loader && <Loader />}

      <Grid>
        <Grid.Row className='headerTop'>
          <Grid.Column width={16}>
            <h1 className='mainTitle'>Surveyors Called Roster Export for {pd.name}</h1>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={16}>
            <Card fluid>
              <Card.Content>
                <Form>
                  <Grid>
                    <Grid.Row>
                      {datesFilter.map((fProps: T, i) => {
                        return (
                          <Grid.Column key={i} mobile={16} tablet={16} computer={6}>
                            <DatePicker {...fProps} />
                          </Grid.Column>
                        );
                      })}
                      <Grid.Column mobile={16} tablet={16} computer={4} floated='right'>
                        <Button content={'Export Csv'} primary={!initFalsy} onClick={() => fetchReport(pd.projectId)} />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Form>
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid.Row>

        {va(csvJSON) && (
          <Grid.Row>
            <Grid.Column width={16}>
              <Card fluid>
                <Card.Content>
                  <GridView headers={headers} list={csvJSON} />
                </Card.Content>
              </Card>
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>
    </>
  );
};

export default RosterExport;

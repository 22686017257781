import React from 'react';
import { Grid } from '../../components/Inputs';
import { ProjectNameByID } from '../../components/Projects';
import { Questionnaire } from '../../components/Questionnaire';
import { initFalsy, Language } from '../../constants';

const PMQuestionnaire = () => {
  const [selLanguage, setLanguage] = React.useState(Language.English);
  const qProps = {
    isDeleted: !initFalsy,
    isSvProject: !initFalsy,
    setLanguage,
    surveyLangId: selLanguage,
  };
  return (
    <Grid>
      <Grid.Row className='headerTop no-print'>
        <Grid.Column width={16}>
          <ProjectNameByID />
        </Grid.Column>
      </Grid.Row>

      <Questionnaire {...qProps} />
    </Grid>
  );
};

export default PMQuestionnaire;

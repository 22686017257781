import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import SideNav from '@trendmicro/react-sidenav';
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import { Logo } from './index';
import { LeftDrawerInterface, NavItem } from '../model';
import { parseMenuAsUrl as pmu } from '../utils/common';
import { va } from '../utils/arrayUtils';
import { pez } from '../utils/stringUtils';

const LeftMenu: React.FC<LeftDrawerInterface> = (props: LeftDrawerInterface) => {
  const hist = useHistory();
  const loc = hist.location;

  // left-menu
  return (
    <SideNav className='nav-style no-print' onToggle={props.onToggle} expanded={props.expanded}>
      <div className='mainLogo' onClick={() => hist.push('/')}>
        <Logo />
      </div>
      <SideNav.Toggle className='float-right'>
        {props.expanded ? 'Collapse' : 'Expand'}
        <i className='fas fa-bars mb10 mt5' />
      </SideNav.Toggle>
      <SideNav.Nav>
        {va(props.navItems) ? (
          props.navItems.map((menuItem: NavItem, index: number) => {
            const { className, name, icon } = menuItem;
            const parentLink = props.parentLink || '';
            const navLink = `${parentLink}${pmu(name)}`;
            const isMatch = (link: string) => link === loc.pathname;
            return (
              <SideNav.NavItem
                className={`${isMatch(navLink) ? 'active' : ''} ${pez(className)}`}
                key={index}
                onClick={() => hist.push(navLink)}
              >
                <SideNav.NavIcon title={name}>
                  <i className={`fas ${icon || 'fa-user-cog'}`} style={{ fontSize: '1.75em' }} />
                </SideNav.NavIcon>
                <SideNav.NavText>
                  <Link to={navLink}>{name}</Link>
                </SideNav.NavText>
              </SideNav.NavItem>
            );
          })
        ) : (
          <div />
        )}
      </SideNav.Nav>
    </SideNav>
  );
};

export default LeftMenu;

import React from 'react';
import { QuestionnaireBuilder } from '../../components/Questionnaire';
import { initFalsy } from '../../constants';

const OPQb = () => {
  return (
    <>
      <QuestionnaireBuilder isOps={!initFalsy} />
    </>
  );
};

export default OPQb;

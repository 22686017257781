import React from 'react';
import { connect } from 'react-redux';
import { DraggableListItem } from './QAList';
import { Button, Confirm, List } from '../Inputs';
import { ItemTypes } from '../DnD';
import { AnsList, BN, StoreT } from '../../model';
import { isFunction } from '../../utils/common';
import { CONFIRM_MESSAGES, initFalsy } from '../../constants';

type RListProps = {
  onAddR?: () => void;
  onDel?: (id: BN, isQ: boolean) => Promise<void>;
  onEditR?: Function;
  answers: AnsList;
};

const ResponseList = ({ onAddR, onDel, onEditR, answers }: RListProps) => {
  const onlyList = !(isFunction(onAddR) && isFunction(onDel) && isFunction(onEditR)),
    [del, setDel]: [BN, Function] = React.useState(initFalsy),
    [isDeact, setDeactStatus] = React.useState(initFalsy),
    [isDeleting, setIsDeleting] = React.useState(initFalsy),
    confirmButtonProps = {
      content: isDeact ? 'Yes, Reactivate' : 'Yes, Deactivate',
      loading: isDeleting,
      primary: isDeact ? !initFalsy : initFalsy,
      type: 'button',
      className: 'red',
    },
    delConfirmProps = {
      content: isDeact ? CONFIRM_MESSAGES.REACTIVE_ANSWER : CONFIRM_MESSAGES.ANSWER,
      header: isDeact ? 'Reactivate Response' : 'Deactivate Response',
      confirmButton: <Button {...confirmButtonProps} />,
      open: !!del,
      onCancel: () => setDel(initFalsy),
      onConfirm: async () => {
        setIsDeleting(!initFalsy);
        if (!onlyList && onDel) {
          await onDel(del, initFalsy);
        }
        setIsDeleting(initFalsy);
        setDel(initFalsy);
      },
    };

  const onAnsDel = async (id: number, isDeleted?: string) => {
    const isAnsDel = isDeleted ? !initFalsy : initFalsy;
    setDel({ id, isAnsDel });
    setDeactStatus(isAnsDel);
  };

  return (
    <div>
      <List className='listStyle listWithAction mb15' relaxed={!onlyList}>
        {answers.map((q) => (
          <List.Item
            className={q.isAns ? 'ans-denied' : 'hand-pointer'}
            key={q.id}
            onClick={() => (onEditR ? onEditR(q) : {})}
          >
            <DraggableListItem onlyList={onlyList} qa={q} onEdit={onEditR} type={ItemTypes.RESPONSE} />
            <List.Content floated='right'>
              {!onlyList && <List.Icon className='mr10 hand-pointer' name='edit' />}
              {!onlyList && (
                <List.Icon
                  className='ml0 mr0 hand-pointer'
                  name={q.isAns ? 'undo alternate' : 'trash alternate outline'}
                  onClick={() => onAnsDel(q.id, q.isAns)}
                />
              )}
            </List.Content>
          </List.Item>
        ))}
      </List>

      {del && <Confirm {...delConfirmProps} />}

      {!onlyList && <Button content='Add New Answer' primary={!initFalsy} onClick={onAddR} />}
    </div>
  );
};

const mapStore = (store: StoreT) => ({
  answers: store.answers,
});

export default connect(mapStore)(ResponseList);

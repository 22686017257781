import React from 'react';
import { Button, Grid, Icon } from '../Inputs';
import { initFalsy } from '../../constants';

interface CLP {
  loading: boolean;
  onClick: () => Promise<void>;
}

const CallIcon: React.FC<CLP> = (props: CLP) => {
  const iTruthy = !initFalsy;
  return (
    <Grid.Row className='no-print'>
      <Grid.Column computer={16} tablet={16} mobile={16} textAlign='center'>
        <Icon circular={iTruthy} name='phone volume' size='huge' />
      </Grid.Column>
      <Grid.Column computer={16} tablet={16} mobile={16} textAlign='center' className='mt10'>
        <Button content='Start Call' loading={props.loading} onClick={props.onClick} primary={iTruthy} />
      </Grid.Column>
    </Grid.Row>
  );
};

export default CallIcon;

import { NS } from '../model';

type SFF = { name: string; label: string; type?: string; key?: NS };

const accountsForm: Array<SFF> = [
  {
    name: 'firstName',
    label: 'First Name',
  },
  {
    name: 'lastName',
    label: 'Last Name',
  },
  {
    name: 'email',
    label: 'Email Address',
    type: 'email',
  },
  {
    name: 'phoneNumber',
    label: 'Contact Number',
  },
  {
    name: 'vonageExt',
    label: 'Vonage Extension',
  },
  {
    name: 'cid',
    label: 'Contractor ID',
  },
];

const clientsForm: Array<SFF> = [
  {
    name: 'name',
    label: 'Client Name',
  },
  {
    name: 'abbr',
    label: 'Client Abbreviation',
  },
  {
    name: 'stateId',
    label: 'State',
  },
];
const clientContactsForm: Array<SFF & { isFA?: boolean }> = [
  {
    name: 'firstName',
    label: 'First Name',
  },
  {
    name: 'lastName',
    label: 'Last Name',
  },
  {
    name: 'jobTitle',
    label: 'Job Title',
  },
  {
    name: 'email',
    label: 'Email Address',
    type: 'email',
  },
  {
    name: 'phoneNumber',
    label: 'Contact Number',
  },
  {
    name: 'phoneExtension',
    label: 'Extension',
  },
];

export const addAccountFormJson: SFF[] = accountsForm.slice(0);

export const editAccountForm: SFF[] = accountsForm.slice(0);

export const viewAccountForm: SFF[] = accountsForm.slice(0);

type T = { clients: SFF[]; contacts: SFF[] };
export const addClientsForm: T = { clients: clientsForm.slice(0), contacts: clientContactsForm.slice(0) };

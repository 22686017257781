import {
  CST_TIMEZONE,
  Headers,
  initFalsy,
  NA,
  PatientType,
  SurCallResponse,
  SurveyStatus,
  VISIT_INFO_MATCH,
} from '../constants';
import { SurveyExport, T } from '../model';
import { va } from './arrayUtils';
import { joinAsFullName } from './common';
import { usDateTime } from './dateUtils';
import { fixedInt } from './numUtils';
import { vo } from './objectUtils';
import { cfl, flc, pez, plb } from './stringUtils';

type UO = { [K: string]: string };
const _ZERO = 0;

export const processASCSV = (json: SurveyExport) => {
  const final: T[] = [];
  const headers: string[] = Object.keys(Headers).filter((key) => isNaN(Number(key)));
  const { allSurveyQuestion, rosterParams, surveyData, surveyLanguage } = json;
  const qstId = va(surveyData) ? surveyData[_ZERO].qId : _ZERO;

  surveyLanguage.forEach((i: T) => {
    const surveyCsvData: UO = {};
    let [_currentQuestion, _currentSequence, multiAns, isResetArr, surveyVal]: [
      number,
      number,
      string[],
      boolean,
      T[],
    ] = [-1, -1, [], initFalsy, []];

    const {
      age,
      attempts,
      call,
      callId,
      chpw,
      departmentName,
      dob,
      dos,
      ethnicity,
      gender,
      guarantor,
      isPartial,
      isQARemoval,
      mrn,
      negative,
      negativeWNSM,
      negativeWoNSM,
      patientFirstName,
      patientFullName,
      patientLastName,
      payerType,
      phoneCell,
      phoneHome,
      positive,
      positiveWNSM,
      positiveWoNSM,
      project,
      providerName,
      providerType,
      race,
      result,
      siteName,
      surveyedOn,
      surveyCompletedOn,
      surveyLanguage,
      typeId,
      user,
      visitPurpose,
    } = i;

    const surveyObj = surveyData.filter((j) => j.callId === callId);
    va(surveyObj) && surveyVal.push(surveyObj);

    if (surveyObj.length === _ZERO && result === SurCallResponse['Agreed to Survey']) {
      const payload = [
        {
          ansText: '',
          answerTitle: '',
          callId,
          category: '',
          openEnded: NA,
          qId: qstId,
          quesId: _ZERO,
          sequence: _ZERO,
          uniqueId: i.id,
          withNSM: '',
          withoutNSM: '',
        },
      ];
      surveyVal.push(payload);
    }

    va(surveyVal[_ZERO]) &&
      surveyVal[_ZERO].forEach((k: T) => {
        let surveyAllQuestionsObj: UO = {};
        const { ansText, callId, openEnded, qId, quesId, sequence, uniqueId, withNSM, withoutNSM } = k;
        const isMultiAns = _currentSequence === sequence && _currentQuestion === quesId;

        if (isMultiAns) {
          multiAns.push(pez(ansText));
          isResetArr = !initFalsy;
        } else {
          if (isResetArr) {
            multiAns = [];
          }
          Object.assign(multiAns, [], [pez(ansText)]);
          _currentSequence = sequence;
          _currentQuestion = qId;
        }
        const openEndVal = openEnded !== NA ? plb(openEnded) : '';
        const fIdx = final.findIndex(
          (f) => f[headers[Headers.MRN]] === mrn && f[headers[Headers.UniqueId]] === uniqueId,
        );
        const answer = isMultiAns ? multiAns.filter((ma) => !!ma).join('; ') : pez(ansText, openEndVal);
        const allSvQues = allSurveyQuestion.filter((q: T) => q.id === k.qId);

        allSvQues.forEach((i: T) => {
          const surveyCsvResponse = allSvQues ? ansTextMapper(callId, i.title, answer, withNSM, withoutNSM) : {};
          Object.assign(surveyCsvData, surveyCsvResponse);
          const allQuesTs = allSurveyQuestion.map((i) => i.title) as string[];
          const quesList = factorQs(allQuesTs);
          Object.assign(surveyAllQuestionsObj, quesList);
        });

        const rosterParam = va(rosterParams) ? rosterData(rosterParams, call) : rosterParams;
        const rosterObj = Object.assign({}, ...rosterParam);

        if (fIdx === -1) {
          if (!vo(surveyAllQuestionsObj)) {
            return;
          }
          const rowItem = {
            ...surveyAllQuestionsObj,
            ...surveyCsvData,
            [headers[Headers['Positive Comments']]]: plb(positive),
            [headers[Headers['Positive Cleaned']]]: plb(positiveWoNSM),
            [headers[Headers['Positive NSM']]]: plb(positiveWNSM),
            [headers[Headers['Negative Comments']]]: plb(negative),
            [headers[Headers['Negative Cleaned']]]: plb(negativeWoNSM),
            [headers[Headers['Negative NSM']]]: plb(negativeWNSM),
            ...rosterObj,
            [headers[Headers.Name]]: joinAsFullName(patientFirstName, patientLastName),
            [headers[Headers['Patient Roster Name']]]: patientFullName,
            [headers[Headers['Patient Type']]]: PatientType[typeId],
            [headers[Headers.DOS]]: dos,
            [headers[Headers['Survey Language']]]: surveyLanguage.name,
            [headers[Headers['Project Name']]]: project.name,
            [headers[Headers['Provider Name']]]: providerName,
            [headers[Headers['Department Name']]]: departmentName,
            [headers[Headers['Site Name']]]: siteName,
            [headers[Headers['Surveyor Name']]]: joinAsFullName(user.firstName, user.lastName),
            [headers[Headers['Surveyed On']]]: surveyedOn ? usDateTime(surveyedOn, CST_TIMEZONE) : '',
            [headers[Headers['Survey Completed On']]]: surveyCompletedOn
              ? usDateTime(surveyCompletedOn, CST_TIMEZONE)
              : '',
            [headers[Headers.Result]]: SurCallResponse[result],
            [headers[Headers['Survey Status']]]: !!isPartial
              ? SurveyStatus[SurveyStatus.Partial]
              : SurveyStatus[SurveyStatus.Completed],
            [headers[Headers.MRN]]: mrn,
            [headers[Headers.DOB]]: dob,
            [headers[Headers.Age]]: fixedInt(age),
            [headers[Headers['Cell Phone']]]: phoneCell,
            [headers[Headers['Home Phone']]]: phoneHome,
            [headers[Headers['Payer Type']]]: payerType,
            [headers[Headers['Provider Type']]]: providerType,
            [headers[Headers['Guarantor']]]: guarantor,
            [headers[Headers['CHPW']]]: chpw,
            [headers[Headers.Ethnicity]]: ethnicity,
            [headers[Headers.Race]]: race,
            [headers[Headers.Sex]]: gender,
            [headers[Headers.Attempts]]: attempts && fixedInt(attempts),
            [headers[Headers['Surveyor Email']]]: user.email,
            [headers[Headers['UniqueId']]]: uniqueId,
            [headers[Headers['Visit Purpose']]]: visitPurpose,
            [headers[Headers['QA Removal']]]: isQARemoval ? 'Yes' : 'No',
          };
          final.push(rowItem);
        } else {
          final[fIdx] = Object.assign({}, final[fIdx], surveyCsvData);
        }
      });
  });
  return final;
};

const ansTextMapper = (_callId: number, quesTitle: string, ansText: string, _withNSM: string, _withoutNSM: string) => {
  const divTag = 'div';
  const text = document.createElement(divTag);
  text.innerHTML = ansText;
  const qText = text.textContent || text.innerText || '';
  return {
    [quesTitle]:
      quesTitle.includes('Cleaned') === !initFalsy
        ? _withoutNSM
        : quesTitle.includes('NSM') === !initFalsy
          ? _withNSM
          : qText,
  };
};

const factorQs = (questions: string[]) => {
  return questions.reduce((acc: T, curr) => {
    acc[curr] = '';
    return acc;
  }, {});
};

export const prName = (name: string) => {
  const projectName = flc(name);
  const splitter = ' ';
  return projectName.split(splitter)[_ZERO];
};

const rosterData = (rosterParams: string[], call: T) => {
  return rosterParams.map((rpi: string) => {
    const fieldName = cfl(rpi);
    const fieldData = { [fieldName]: '' };
    if (vo(call) && va(call.visit?.visitInfo)) {
      const foundVisitInfo = call.visit?.visitInfo.find((d: T) => flc(d.field) === flc(fieldName));
      if (foundVisitInfo && !foundVisitInfo?.field.includes(VISIT_INFO_MATCH)) {
        Object.assign(fieldData, { [fieldName]: foundVisitInfo.value });
      }
    }
    return fieldData;
  });
};

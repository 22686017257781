import React from 'react';
import { SurveyExport } from '../../components/Surveys';

const SurveyCsvExport = () => {
  return (
    <>
      <SurveyExport />
    </>
  );
};

export default SurveyCsvExport;

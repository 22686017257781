import React from 'react';
import { DragSourceMonitor, useDrag } from 'react-dnd';
import { RC, T } from '../../model';

interface DraggableProps {
  children?: RC;
  item: T;
  type: string;
}

const Draggable: React.FC<DraggableProps> = ({ children, item, type }: DraggableProps) => {
  const [{ isDragging }, drag] = useDrag({
    item: { type, ...item },
    collect: (monitor: DragSourceMonitor) => {
      return {
        isDragging: monitor.isDragging(),
      };
    },
  });

  return (
    <div ref={drag} className={isDragging ? 'dnd-chosen-card' : ''}>
      {children && (isDragging ? <div className='dnd-card'>{children}</div> : children)}
    </div>
  );
};

export default Draggable;

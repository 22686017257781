import React from 'react';
import { initFalsy } from '../../constants';
import { Form, SemanticTextArea, TextAreaProps } from './index';
import { T } from '../../model';

export interface TAProps extends TextAreaProps {
  custom?: T;
  disabled?: boolean;
  disabledCss?: boolean;
  label?: string;
  value: string;
}

const TxtArea: React.FC<TAProps> = (props) => {
  const { disabled, disabledCss, custom, label, value } = props;
  const disabledStyle = {
    background: '#FFFFFF',
    border: 0,
    color: '#262626',
    fontWeight: 'bolder !important',
    opacity: '0.9',
  };
  const textAreaProps = {
    className: 'textarea w100',
    disabled,
    rows: 5,
    spellCheck: !initFalsy,
    style: disabledCss ? disabledStyle : {},
    value,
  };

  return (
    <Form.Field className='ui form'>
      <label>{label}</label>
      <SemanticTextArea {...textAreaProps} {...custom} />
    </Form.Field>
  );
};

export default TxtArea;

import createReducer from './createReducer';
import { Action, ActionType, AssignmentHistory, T } from '../../model';

export const assignments = createReducer([], {
  [ActionType.OPS_ASSIGNMENTS_LIST](_store: Function, action: Action<T>) {
    return action.payload;
  },

  [ActionType.OPS_ASSIGNMENTS_FLUSH](_store: Function, action: Action<T>) {
    return action.payload;
  },
});

export const assignmentsHistory = createReducer([], {
  [ActionType.ASSIGNMENT_HISTORY_FETCH](_store: Function, action: Action<AssignmentHistory[]>) {
    return action.payload;
  },

  [ActionType.ASSIGNMENT_HISTORY_BULK_ADD](_store: Function, action: Action<AssignmentHistory[]>) {
    return action.payload;
  },

  [ActionType.ASSIGNMENT_HISTORY_FLUSH](_store: Function, action: Action<void>) {
    return action.payload;
  },
});

export const assignHistoryWeeklyData = createReducer(null, {
  [ActionType.ASSIGNMENT_HISTORY_WEEKLY_DATA](_store: Function, action: Action<AssignmentHistory[]>) {
    return action.payload;
  },

  [ActionType.ASSIGNMENT_HISTORY_WEEKLY_DATA_FLUSH](_store: Function, action: Action<void>) {
    return action.payload;
  },
});

import React from 'react';
import { Icon } from '../Inputs';

type proName = {
  name: string;
  isLocked?: boolean;
};

const ProjectName: React.FC<proName> = (props) => {
  const { name, isLocked } = props;
  return (
    <React.Fragment>
      {name}&nbsp;
      {isLocked && <Icon name='lock' title='Project is Locked' />}
    </React.Fragment>
  );
};

export default ProjectName;

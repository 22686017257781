import * as React from 'react';
import { useSelector } from 'react-redux';
import AdminDashboard from './AdminDashboard';
import { ClientForm as Add, ListClient as List } from '../../components/Admin';
import { Loader } from '../../components';
import { Button, Card, Grid, Paging, Search } from '../../components/Inputs';
import { IDValue, ButtonProps, SearchProps, ST, StoreT, T } from '../../model';
import { useActions } from '../../redux/actions';
import * as clientActions from '../../redux/actions/clients';
import { getSkipLimit, isValidSearch } from '../../utils/common';
import { sleep } from '../../utils/objectUtils';
import { DEFAULT_RECORDS_PER_PAGE, initFalsy } from '../../constants';

const ClientManagement: React.FC = () => {
  const initGridSize = DEFAULT_RECORDS_PER_PAGE,
    initPageNo = 1,
    initAction = {
      id: undefined,
      value: '',
    },
    [isAdding, onAddClient] = React.useState(initFalsy),
    [searching, onSearchLoad] = React.useState(initFalsy),
    [miniLoader, setLoader] = React.useState(initFalsy),
    [pageSize, setPageSize] = React.useState(initGridSize),
    [activePage, setPageChange] = React.useState(initPageNo),
    [state, setAction]: [IDValue, Function] = React.useState(initAction),
    clientA = useActions(clientActions),
    { rows, count } = useSelector((state: StoreT) => state.clients) || {},
    addBtnProps: ButtonProps = {
      icon: 'plus',
      labelPosition: 'left',
      className: 'float-right ml10 mb10',
      primary: !initFalsy,
    },
    searchProps = {
      className: 'float-right ml10 mb10',
      placeholder: 'Search...',
      showNoResults: initFalsy,
    },
    openForm = () => (state.id ? onAction({ ...initAction, value: 'flush' }) : onAddClient(!isAdding)),
    onAction = async (object: IDValue) => {
      const { id, value, isDeactivated } = object;
      setLoader(!initFalsy);
      switch (value) {
        case 'view':
        case 'edit':
          await clientA.pullClientInfo(id);
          break;
        case 'del':
          await clientA.removeClient(id, isDeactivated);
          Object.assign(object, initAction);
          break;
        case 'flush':
          Object.assign(object, initAction);
          await clientA.flushClientInfo();
          break;
        default:
          Object.assign(object, initAction);
          break;
      }
      setAction(object);
      setLoader(initFalsy);
    },
    doEdit = (id: number) => onAction({ id, value: 'edit' }),
    navigateFux = async (pNo: number, gridSize: number) => {
      const skip = getSkipLimit(pNo, gridSize);
      const orderBy = 'ASC',
        orderOn = 'name',
        search = undefined;
      await asyncPullClients(search, skip, pageSize, orderOn, orderBy).then();
    },
    onSearch = async (_e: T, data: SearchProps) => {
      if (isValidSearch(data.value as string, miniLoader)) {
        return sleep(0);
      }

      onSearchLoad(!initFalsy);

      const skip = getSkipLimit(activePage, pageSize);
      const orderBy = 'ASC',
        orderOn = 'name';

      asyncPullClients(data.value, skip, pageSize, orderOn, orderBy).then(() => onSearchLoad(initFalsy));
    },
    onSort = (cc: string, direction: ST) => {
      const skip = getSkipLimit(activePage, pageSize);
      const search = undefined;
      asyncPullClients(search, skip, pageSize, cc, direction).then();
    },
    asyncPullClients = async (
      search: string | undefined,
      skip: number,
      pageSize: number,
      orderOn: string,
      orderBy: string,
    ) => {
      await clientA.fetchClients(search, skip, pageSize, orderOn, orderBy);
    },
    pageLifeCycle = () => {
      // on mount
      const orderBy = 'ASC',
        orderOn = 'name',
        search = undefined;
      const skip = getSkipLimit(activePage, pageSize);
      asyncPullClients(search, skip, pageSize, orderOn, orderBy).then();

      // on dis mount
      return () => clientA.flushClients();
    },
    paginationProps = {
      activePage: activePage,
      navigate: navigateFux,
      pageSize: pageSize,
      setPageChange: setPageChange,
      setPageSize: setPageSize,
      totalRecords: count,
    };

  React.useEffect(pageLifeCycle, [pageSize, activePage, state, isAdding]);

  return (
    <AdminDashboard>
      {miniLoader && <Loader />}

      <Grid>
        <Grid.Row className='headerTop'>
          <Grid.Column width={16}>
            <h1 className='mainTitle'>Client Management</h1>
            <Search {...searchProps} loading={searching} onSearchChange={onSearch} />
            <Button {...addBtnProps} onClick={openForm} content='Add Client' />
          </Grid.Column>
        </Grid.Row>
      </Grid>

      {(isAdding || state.id) && <Add action={state} onCancel={openForm} doEdit={doEdit} />}

      {!state.id && (
        <Card fluid>
          <Card.Content>
            <List dataList={rows} onSort={onSort} onAction={onAction} />
            <Paging {...paginationProps} />
          </Card.Content>
        </Card>
      )}
    </AdminDashboard>
  );
};

export default ClientManagement;
